import { Component, ElementRef, ExistingProvider, forwardRef, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractCombo } from './abstract-combo';
import { COMBO_ITEM_HOST, ComboItemDirective, ComboItemHost } from './combo-item.directive';

const COMBO_CONTROL_VALUE_ACCESSOR: ExistingProvider = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => ComboComponent),
	multi: true,
};

const COMBO_ITEM_HOST_PROVIDER: ExistingProvider = {
	provide: COMBO_ITEM_HOST,
	useExisting: forwardRef(() => ComboComponent),
};

@Component({
	selector: 'ca-combo',
	templateUrl: 'combo.component.html',
	providers: [COMBO_CONTROL_VALUE_ACCESSOR, COMBO_ITEM_HOST_PROVIDER],
})
export class ComboComponent extends AbstractCombo implements ComboItemHost, ControlValueAccessor {

	@Input()
	uncheckable: boolean = false;

	private items: ComboItemDirective[] = [];
	private modelValue: any = null;
	private activeItem: ComboItemDirective = null;
	readonly focusActive = true;

	selectedLabel: any = null;
	private _selectedCount: number = 0;

	get selectedCount(): number {
		return this._selectedCount;
	}

	constructor(elementRef: ElementRef, renderer: Renderer2) {
		super();
		renderer.addClass(elementRef.nativeElement, 'ca-combo');
	}

	setActiveItem(item: ComboItemDirective): void {
		this.selectedLabel = item.labelValue !== undefined ? item.labelValue : item.value;
		if (item === this.activeItem) {
			return;
		}
		if (this.activeItem) {
			this.activeItem.isActive = false;
		}
		this.activeItem = item;
		item.isActive = true;
		this._selectedCount = 1;
	}

	unsetActiveItem(): void {
		if (this.activeItem) {
			this.activeItem.isActive = false;
		}
		this.activeItem = null;
		this.selectedLabel = null;
		this._selectedCount = 0;
	}

	writeValue(value: any): void {
		this.modelValue = value;
		if (this.activeItem) {
			if (this.isEqual(this.activeItem.value, value)) {
				this.selectedLabel = this.activeItem.labelValue !== undefined ? this.activeItem.labelValue : this.activeItem.value;
				return;
			}
		}
		const item: ComboItemDirective = this.findItem(value);
		if (item) {
			this.setActiveItem(item);
			return;
		}
		this.unsetActiveItem();
	}

	findItem(value: any): ComboItemDirective {
		for (const item of this.items) {
			if (this.isEqual(item.value, value)) {
				return item;
			}
		}
		return null;
	}

	onItemClick(event: Event, item: ComboItemDirective): void {
		if (item && !item.isActive) {
			this.setActiveItem(item);
			this.modelValue = item.value;
			this.onChangeCallback(item.value);
		} else if (this.uncheckable) {
			this.unsetActiveItem();
			this.modelValue = null;
			this.onChangeCallback(null);
		}
		this.toggle(event);
		this.focus();
	}

	registerItem(item: ComboItemDirective): void {
		this.items.push(item);
		if (this.activeItem == null && this.modelValue != null) {
			if (this.isEqual(item.value, this.modelValue)) {
				this.setActiveItem(item);
			}
		}
	}

	unregisterItem(item: ComboItemDirective): void {
		const idx: number = this.items.indexOf(item);
		if (idx !== -1) {
			this.items.splice(idx, 1);
		}
		if (this.activeItem != null) {
			if (this.isEqual(item.value, this.activeItem.value)) {
				this.unsetActiveItem();
				this.modelValue = null;
				this.onChangeCallback(null);
			}
		}
	}
}
