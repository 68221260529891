import { ValidatorFn, Validators } from '@angular/forms';
import { commonFormFieldContentValidator, SimpleRegexpValidator} from './common-regexp-validators';

export class AddressValidators {
	private static nameValidator(maxLength: number = 100): ValidatorFn {
		return Validators.compose([Validators.required, Validators.maxLength(maxLength)]);

	}

	static city(): ValidatorFn {
		return Validators.compose([Validators.required, commonFormFieldContentValidator,
			Validators.maxLength(50)]);
	}

	static street(): ValidatorFn {
		return this.nameValidator(50);
	}

	static houseNumber(): ValidatorFn {
		return this.nameValidator(15);
	}

	static houseNumberStrict(): ValidatorFn {
		return Validators.compose([
			Validators.required,
			SimpleRegexpValidator(/^[A-Za-z0-9\[\]:;_\+&#\(\)\.,\/\-]+( *[A-Za-z0-9\[\]:;_\+&#\(\)\.,\/\-]+)*$/),
			Validators.maxLength(15)
		]);
	}

	static postcodeStrict(): ValidatorFn {
		return Validators.pattern(/^\d{2}-?\d{3}$/);
	}

	static postcode(): ValidatorFn {
		return Validators.compose([
			Validators.maxLength(10),
			Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\- ]+[a-zA-Z0-9]$/),
		]);
	}
}
