import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from '../dropdown/dropdown.module';
import { ComboButtonComponent } from './combo-button.component';
import { ComboItemAllComponent } from './combo-item-all.component';
import { ComboItemModalDirective } from './combo-item-modal.directive';
import { ComboItemDirective } from './combo-item.directive';
import { ComboMultiSelectedLabelComponent } from './combo-multi-selected-label.component';
import { ComboMultiTagComponent } from './combo-multi-tag.component';
import { ComboMultiComponent } from './combo-multi.component';
import { ComboComponent } from './combo.component';

@NgModule({
	imports: [
		CommonModule,
		DropdownModule,
		FormsModule,
	],
	declarations: [
		ComboButtonComponent,
		ComboComponent,
		ComboItemAllComponent,
		ComboItemDirective,
		ComboItemModalDirective,
		ComboMultiComponent,
		ComboMultiSelectedLabelComponent,
		ComboMultiTagComponent,
	],
	exports: [
		ComboButtonComponent,
		ComboComponent,
		ComboItemAllComponent,
		ComboItemDirective,
		ComboItemModalDirective,
		ComboMultiComponent,
		ComboMultiSelectedLabelComponent,
		ComboMultiTagComponent,
	],
})
export class ComboModule {}
