import {Component, OnInit} from '@angular/core';
import { locales } from 'locales';
import { config } from 'config';
import { ContextService } from '../../services/context.service';

@Component({
	selector: 'language-selector',
	templateUrl: 'language-selector.component.html',
})
export class LanguageSelectorComponent implements OnInit{

	languages: Language[];
	selectedLanguage: any;
	isOneLanguage: boolean;

	constructor(private contextService: ContextService) {
		this.languages = config.langs.map((code) => {
			return {
				code,
				label: locales.langLabels[code],
				active: code === config.lang,
			}
		});
	}

	ngOnInit() {
		this.languages.forEach(lang => {
			lang.label = this.mapLangLabel(lang.label);
			if(lang.active) {
				this.selectedLanguage = lang.label;
			}
		});
		if(this.languages.length == 1) {
			this.isOneLanguage = true;
		}
	}

	changeLang(lang: string) {
		this.contextService.setLanguage(lang);
		window.location.reload();
	}

	mapLangLabel(langCode: string): string {
		switch(langCode) {
			case 'en':
				return 'English';
			case 'fr':
				return 'Français';
			case 'pl':
				return 'Polski';
			default:
				return langCode;
		}
	}
}

export interface Language {
	code: string;
	label: string;
	active: boolean;
}
