import {Component, OnInit, Input} from "@angular/core";
import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, take} from "rxjs/operators";

@Component({
    selector: "search-filter",
    templateUrl: './search-filter.component.html'
})
export class SearchFilter implements OnInit {

    @Input() filters: Map<string, Function>;
    @Input() filterSearchFunction: Function;
    @Input() filterDataFunction: Function;
    @Input() filterStatusFunction: Function;
    @Input() states: string[];
    @Input() runFilters: Function;
    @Input() dropdownMessage: string;
    @Input() mainMessage: string;

    readonly dateFilterName: string = 'date';
    readonly termFilterName: string = 'term';
    readonly statusFilterName: string = 'status';
    dropDownValue: string = '';
    formDate: {from?: string, to?: string};
    inputTerm: string;
    searchTerms: Subject<string> = new Subject<string>();

    constructor(){}

    ngOnInit(){
        this.searchTerms
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(term=>{
                if(!term) {
                    this.filters.delete(this.termFilterName);
                }else {
                    this.filters.set(this.termFilterName,this.filterSearchFunction(term));
                }
                this.runFilters();
            })
    }

    public search(term: string) {
        this.searchTerms.next(term);
    }

    public searchByStatus(){
        if(this.dropDownValue){
            this.filters.set(this.statusFilterName, this.filterStatusFunction(this.dropDownValue));
            this.runFilters();
        }
    }

    public clearFilter(term: string): void{
        this.filterClear(term);
        this.runFilters();
    }

    public clearAllFilters(){
		this.formDate = { from: null, to: null };
		this.searchTerms.next();
		this.filters.delete(this.statusFilterName);
		this.filters.delete(this.dateFilterName);
		this.filters.delete(this.termFilterName);
		this.dropDownValue = '';
		this.runFilters();
    }

    public searchByDate(){
        if (!this.formDate || !this.formDate.from || !this.formDate.to) {
            this.filters.delete(this.dateFilterName);
        } else {
            this.filters.set(this.dateFilterName, this.filterDataFunction(this.formDate));
        }
        this.runFilters();
    }

    private filterClear(filterType: string):void{
        switch(filterType){
            case this.termFilterName:
                this.searchTerms.next();
                return;
            case this.dateFilterName:
                this.formDate = { from: null, to: null };
                this.filters.delete(this.dateFilterName);
                return;
            case this.statusFilterName:
                this.filters.delete(this.statusFilterName);
                return
            default:
                this.searchTerms.next();
        }
     }
}