<div class="pagination__container" *ngIf="isPaginationVisible"
	 [ngClass]="{'text-center': paginationPlacement == 'center', 'text-right': paginationPlacement == 'right'}">
	<div *ngIf="showLeftSelector" class="pagination__container--left">
		<div class="pagination__text">
			Items per page:
		</div>
		<div class="pagination__select-input">
			<select [value]="defaultItemsPerPage"
				(change)="selectItemsPerPage($event.target.value)">
				<option [value]="i" *ngFor="let i of itemsPerPage">
					{{i}}
				</option>
			</select>
		</div>
		<div class="pagination__separator-container">
			<div class="pagination__separator"></div>
		</div>
		<div class="pagination__text">
			{{(rowTo === 0 ? 0 : rowFrom)}} - {{ rowTo }}
			<ng-template [ngIf]="!infinitePages">of {{ totalItems }} items</ng-template>
		</div>
	</div>
	<div class="pagination__container--right">
		<div class="pagination__arrow-container">
			<div class="d-inline-block" (click)="prevPage()" (keyup.enter)="prevPage()" tabindex="0" aria-label="Previous banner"><i class="cds-icon-angle-left pagination__icon--arrow mr-4"></i></div>
				<p class="font-weight-bold d-inline-block">{{ page + 1}}</p>
				<ng-template [ngIf]="!infinitePages"> / {{ totalPages }}</ng-template>
			<div class="d-inline-block" (click)="nextPage()" (keyup.enter)="nextPage()" tabindex="0" aria-label="Next banner"><i class="cds-icon-angle-right pagination__icon--arrow ml-4"></i></div>
		</div>
	</div>
</div>
