import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BrowserUtil {

  public generateBrowserId(): string {
    return 'U-'
      + Math.random().toString(36).slice(2) + '-'
      + Math.random().toString(36).slice(2) + '-'
      + new Date().getTime().toString(36);
  }

}
