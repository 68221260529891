import { Directive, Input, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ConsumerFunction, noop, RunnableFunction } from '../../utils/facade';

@Directive()
export abstract class AbstractToggleComponent implements OnInit, ControlValueAccessor {

	isActive: boolean = false;
	isBlocked: boolean = false;
	isDisabled: boolean = false;

	private onChange: ConsumerFunction = noop;
	private onTouched: RunnableFunction = noop;
	private _value: any = true;

	@Input()
	protected value: any = true;

	@Input()
	protected uncheckable: boolean = true;

	ngOnInit(): void {
		this._value = this.value;
	}

	writeValue(modelValue: any): void {
		const isActive: boolean = modelValue === this._value;
		if (isActive !== this.isActive) {
			this.isActive = isActive;
			this.renderActive(isActive);
			this.updateBlocked(this.isDisabled);
		}
	}

	registerOnChange(fn: ConsumerFunction): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: RunnableFunction): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.updateBlocked(isDisabled);
		this.isDisabled = isDisabled;
		this.renderDisabled(isDisabled);
	}

	private updateBlocked(isDisabled: boolean) {
		this.isBlocked = isDisabled || (!this.uncheckable && this.isActive);
		this.renderBlocked(this.isBlocked);
	}

	protected setActive(active: boolean): void {
		this.isActive = active;
		this.renderActive(active);
		if (this._value === true) {
			this.onChange(active);
		} else {
			this.onChange(active ? this._value : null);
		}
		this.updateBlocked(this.isDisabled);
		this.onTouched();
	}

	protected renderDisabled(disabled: boolean): void {}
	protected renderBlocked(blocked: boolean): void {}
	protected renderActive(active: boolean): void {}
}
