import { Directive, ElementRef, Input, ViewChild } from '@angular/core';
import { ConsumerFunction, field, identity, isHTMLElement, noop, RunnableFunction } from '../../utils/facade';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class AbstractCombo {

	isDisabled: boolean = false;
	private _isOpen: boolean;
	protected onTouchedCallback: RunnableFunction = noop;
	protected onChangeCallback: ConsumerFunction = noop;
	protected trackByFunction: TrackByFunction = identity;

	@ViewChild('button', { read: ElementRef, static: true })
	private buttonElement: ElementRef;

	set isOpen(value: boolean) {
		if (!value && this._isOpen) {
			this.onTouchedCallback();
		}
		this._isOpen = value;
	}

	get isOpen(): boolean {
		return this._isOpen;
	}

	@Input()
	set trackBy(value: string | TrackByFunction) {
		if (typeof value === 'string') {
			this.trackByFunction = field(value);
		} else {
			this.trackByFunction = value;
		}
	}

	toggle(event: Event): void {
		if (!this.isDisabled) {
			if (event) {
				event.stopPropagation();
				event.preventDefault();
				this.isOpen = !this.isOpen;
			}
		}
	}

	registerOnChange(fn: ConsumerFunction): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: RunnableFunction): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	focus(): void {
		if (isHTMLElement(this.buttonElement.nativeElement)) {
			this.buttonElement.nativeElement.focus();
		}
	}

	blur(): void {
		if (isHTMLElement(this.buttonElement.nativeElement)) {
			this.buttonElement.nativeElement.blur();
		}
	}

	protected isEqual(valueA: any, valueB: any): boolean {
		return this.trackByFunction(valueA) === this.trackByFunction(valueB);
	}

	abstract selectedCount: number;
	abstract focusActive: boolean;
}

interface TrackByFunction {
	(obj: any): any;
}
