import { Component, Renderer2, ElementRef } from '@angular/core';

@Component({
	selector: 'ca-errors',
	templateUrl: 'errors.component.html',
})
export class ErrorsComponent {

	constructor(renderer: Renderer2, elementRef: ElementRef) {
		renderer.addClass(elementRef.nativeElement, 'ca-errors');
	}
}
