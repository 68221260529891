<div class="page-container">
	<app-header></app-header>
	<div class="container cds-grid--no-padding">
		<div class="row">
			<div class="col-md-4">
				<div class="container mt-5">
					<div class="row mt-xl-5">
						<p class="cds-headline-1">Internal error</p>
					</div>
					<div class="row mt-xl-5">
						<p class="cds-headline-2">Something went wrong...</p>
					</div>
					<div class="row mt-xl-2">
						<button type="submit" cdsButton class="cds-button-primary mr-2" (click)="home()">Home</button>
					</div>
				</div>
			</div>
			<div class="col-md-8">
				<div class="internal-error-image content-image">
				</div>
			</div>
		</div>
	</div>
	<app-footer></app-footer>
</div>

