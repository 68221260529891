import { Component } from '@angular/core';
import {XVLOTopMenuItemsProviderService} from "../../../services/xvlo/xvlo-top-menu-items-provider.service";
import {ProductCatalog} from "shared/app/index";
import {Router} from "@angular/router";
import {AppPath} from "../../../utils/app-path";
import {Offer} from "shared/app/index";
import {AppPathService} from "../../../services/app-path.service";

@Component({
	selector: 'ws-footer',
	templateUrl: './ws-footer.component.html',
})
export class WebshopFooterComponent {

	footerItems: ProductCatalog[] = [];
    open: Array<boolean>;
	constructor(private pathService: AppPathService, private topMenuProvider: XVLOTopMenuItemsProviderService) {
		this.topMenuProvider.getWebshopItems()
			.subscribe((data) => {
				this.footerItems  = data;
				this.open = Array<boolean>(data.length).fill(false);
			});
	}

	selectOffer(salesChannelName, offer:Offer) {
	    this.open = this.open.map(i=>false);
		this.pathService.navigate([AppPath.PATH_WEBSHOP_OFFER + salesChannelName.toLowerCase().split(' ').join('_') + '/' + offer.id]);
	}

	showUsePolicy() {
		this.pathService.navigate([AppPath.XVLO_PATH_WEBSHOP_TERMS + AppPath.XVLO_PATH_PARAM_WEBSHOP_TERMS_ACCEPTABLE_USE])

	}

	showPrivacyPolice() {
		this.pathService.navigate([AppPath.XVLO_PATH_WEBSHOP_TERMS + AppPath.XVLO_PATH_PARAM_WEBSHOP_TERMS_PRIVACY_POLICY])
	}
}
