import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import {
	AddContextInvoicesToPaySuccess,
	AddContextUserSuccess,
	AddContextAccountSuccess,
	AddContextAccountsSuccess,
	AddContextCustomerSuccess,
	AddContextBannersSuccess,
	GetContextSuccess, ContextActionsTypes, AddContextUserContactSuccess, AddContextDataSuccess, SetContextCustomerSuccess, SetContextLastUnreadNotification,
} from '../actions/context.actions';
import { map, withLatestFrom } from 'rxjs/operators';
import { ContextState } from '../state/context.state';
import { Store } from '@ngrx/store';
import { getContext } from '../selectors/context.selectors';

@Injectable()
export class ContextEffects {

	private APP_STORAGE_KEY = 'context';

	@Effect()
	addContextData$ = this.actions$.pipe(
		ofType(ContextActionsTypes.AddContextData),
		withLatestFrom(this.store.select(getContext)),
		map(([action, state]: any) => {
			state.user = action.payload.user;
			state.customer = action.payload.customer;
			state.account = action.payload.accounts[0];
			state.accounts = action.payload.accounts;
			state.userContact = action.payload.contact;
			localStorage.setItem(this.APP_STORAGE_KEY, JSON.stringify(state));
			localStorage.setItem('keys', JSON.stringify(action.payload.privileges));
			return new AddContextDataSuccess(action.payload);
		}),
	);

	@Effect()
	addContextUser$ = this.actions$.pipe(
		ofType(ContextActionsTypes.AddContextUser),
		withLatestFrom(this.store.select(getContext)),
		map(([action, state]: any) => {
			state.user = action.payload;
			localStorage.setItem(this.APP_STORAGE_KEY, JSON.stringify(state));
			return new AddContextUserSuccess(action.payload);
		}),
	);

	@Effect()
	addContextUserContact$ = this.actions$.pipe(
		ofType(ContextActionsTypes.AddContextUserContact),
		withLatestFrom(this.store.select(getContext)),
		map(([action, state]: any) => {
			state.userContact = action.payload;
			localStorage.setItem(this.APP_STORAGE_KEY, JSON.stringify(state));
			return new AddContextUserContactSuccess(action.payload);
		}),
	);

	@Effect()
	addContextBanners = this.actions$.pipe(
		ofType(ContextActionsTypes.AddContextBanners),
		withLatestFrom(this.store.select(getContext)),
		map(([action, state]: any) => {
			state.banners = action.payload;
			localStorage.setItem(this.APP_STORAGE_KEY, JSON.stringify(state));
			return new AddContextBannersSuccess(action.payload);
		}),
	);

	@Effect()
	addContextCustomer$ = this.actions$.pipe(
		ofType(ContextActionsTypes.AddContextCustomer),
		withLatestFrom(this.store.select(getContext)),
		map(([action, state]: any) => {
			state.customer = action.payload;
			localStorage.setItem(this.APP_STORAGE_KEY, JSON.stringify(state));
			return new AddContextCustomerSuccess(action.payload);
		}),
	);

	@Effect()
	addContextAccount$ = this.actions$.pipe(
		ofType(ContextActionsTypes.AddContextAccount),
		withLatestFrom(this.store.select(getContext)),
		map(([action, state]: any) => {
			state.account = action.payload;
			localStorage.setItem(this.APP_STORAGE_KEY, JSON.stringify(state));
			return new AddContextAccountSuccess(action.payload);
		}),
	);

	@Effect()
	addContextAccounts$ = this.actions$.pipe(
		ofType(ContextActionsTypes.AddContextAccounts),
		withLatestFrom(this.store.select(getContext)),
		map(([action, state]: any) => {
			state.accounts = action.payload;
			localStorage.setItem(this.APP_STORAGE_KEY, JSON.stringify(state));
			return new AddContextAccountsSuccess(action.payload);
		}),
	)

	@Effect()
	addContextInvoicesToPay$ = this.actions$.pipe(
		ofType(ContextActionsTypes.AddContextInvoicesToPay),
		withLatestFrom(this.store.select(getContext)),
		map(([action, state]: any) => {
			state.invoicesToPay = action.payload;
			localStorage.setItem(this.APP_STORAGE_KEY, JSON.stringify(state));
			return new AddContextInvoicesToPaySuccess(action.payload);
		}),
	);

	@Effect()
	setContextCustomer$ = this.actions$.pipe(
		ofType(ContextActionsTypes.SetContextCustomer),
		withLatestFrom(this.store.select(getContext)),
		map(([action, state]: any) => {
			let localData = JSON.parse(localStorage.getItem(this.APP_STORAGE_KEY));
			localData.customer = action.payload;
			localStorage.setItem(this.APP_STORAGE_KEY, JSON.stringify(localData));
			return new SetContextCustomerSuccess(action.payload);
		}),
	);

	@Effect({dispatch: false})
	setContextLastUnreadNotification$ = this.actions$.pipe(
		ofType(ContextActionsTypes.SetContextLastUnreadNotification),
		withLatestFrom(this.store.select(getContext)),
		map(([action, state]: any) => {
			state.lastUnreadNotificationDate = action.payload;
			localStorage.setItem(this.APP_STORAGE_KEY, JSON.stringify(state));
		}),
	);

	@Effect()
	getContext$ = this.actions$.pipe(
		ofType(ContextActionsTypes.GetContext),
		map(() => {
			const data = JSON.parse(localStorage.getItem(this.APP_STORAGE_KEY));
			return new GetContextSuccess(data);
		}),
	);

	@Effect({dispatch: false})
	clearContext$ = this.actions$.pipe(
		ofType(ContextActionsTypes.ClearContext),
		map(() => {
			localStorage.removeItem(this.APP_STORAGE_KEY);
		}),
	);

	constructor(private actions$: Actions<any>, private store: Store<ContextState>) {

	}

}
