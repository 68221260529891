<data-not-found-placeholder *ngIf="showPlaceholder" [items]="data"></data-not-found-placeholder>
<table class="table" *ngIf="data && data.length > 0">
	<thead>
	<tr>
		<th scope="col" *ngFor="let i of columns" [ngClass]="{'hide-on-small' : hideColumnKeysOnSmall.includes(i.key),
				'hide-on-medium': hideColumnKeysOnMedium.includes(i.key),
				'hide-on-large': hideColumnKeysOnLarge.includes(i.key)}">
			{{i.name}}
		</th>
		<th *ngIf="detailsLabel"></th>
	</tr>
	</thead>
	<tbody *ngIf="data">
	<tr *ngFor="let value of data" (click)="onEntireRowClick(value)"
		[ngClass]="{'table-row': isEntireRowClickable()}">
		<td *ngFor="let i of columns" [ngClass]="{
			'hide-on-small' : hideColumnKeysOnSmall.includes(i.key),
			'hide-on-medium': hideColumnKeysOnMedium.includes(i.key),
			'hide-on-large': hideColumnKeysOnLarge.includes(i.key)}">

			<ng-container [ngSwitch]="true">
				<ng-container *ngSwitchCase="getTemplate(i.key) != null">
					<ng-container *ngTemplateOutlet="getTemplate(i.key); context: {value: value[i.key], column: i.key, row: value}"></ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="isDateFormat(i.key, value[i.key])">
					{{ value[i.key] | date : dateFormat }}
				</ng-container>
				<ng-container *ngSwitchCase="isStateValue(i.key)">
					<state [stateInput]="value[i.key]"></state>
				</ng-container>
				<ng-container *ngSwitchCase="isCurrencyValue(i.key, value[i.key])">
					<p class="font-weight-bold p-0 m-0">{{ value[i.key] | currencyProfile}}</p>
				</ng-container>
				<ng-container *ngSwitchDefault>
					<wrappable-text-template [value]="value[i.key] | notEmpty"></wrappable-text-template>
				</ng-container>
			</ng-container>
		</td>
		<td *ngIf="detailsLabel" class="text-right">
			<button style="margin-top: -9px; margin-bottom: -10px" cdsButton class="cds-button-text" (click)="onClick(value)">{{ detailsLabel }}<i
				class="cds-icon-angle-right sc-icon"></i></button>
		</td>
	</tr>
	</tbody>
</table>
