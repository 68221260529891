import {
	Directive,
	Input,
	OnChanges,
	SimpleChanges,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import { config } from 'config';

@Directive({
	selector: '[caCustomization]',
})
export class CustomizationDirective implements OnChanges {
	@Input('caCustomization')
	customization: string | string[];

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef
	) {}

	ngOnChanges(changes: SimpleChanges) {
		if ('customization' in changes) {
			const customizations = Array.isArray(this.customization)
				? this.customization
				: [this.customization];
			this.viewContainer.clear();
			if (customizations.includes(config.custom)) {
				this.viewContainer.createEmbeddedView(this.templateRef);
			}
		}
	}
}
