import { HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { ErrorResponse } from '../models/error-response';

export class HttpErrorWrapper extends HttpErrorResponse {
	response?: ErrorResponse;
	httpParams?: HttpParams;
	httpMethod: string;

	constructor(init: {
		response: ErrorResponse,
		error?: any;
		headers?: HttpHeaders;
		status?: number;
		statusText?: string;
		url?: string;
		httpParams?: HttpParams;
		httpMethod: string;
	}) {
		if (init.response != null && init.error == null) {
			init.error = init.response
		}
		super(init);
		this.response = init.response;
		this.httpParams = init.httpParams;
		this.httpMethod = init.httpMethod;
	}
}
