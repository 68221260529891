export function getAllowedDaysData(allowedDays: string[]): AllowedDaysData {
	if (allowedDays) {
		const allowedDaysMap: { [day: string]: true } = {};
		let from: string;
		let to: string;
		if (allowedDays.length !== 0) {
			from = allowedDays[0];
			to = allowedDays[0];
			for (const allowedDay of allowedDays) {
				if (allowedDay < from) {
					from = allowedDay;
				}
				if (allowedDay > to) {
					to = allowedDay;
				}
				allowedDaysMap[allowedDay] = true;
			}
		}
		return { from, to, allowedDaysMap };
	}
	return null;
}

export interface AllowedDaysMap {
	[day: string]: true;
}

export interface AllowedDaysData {
	allowedDaysMap: AllowedDaysMap;
	from: string;
	to: string;
}
