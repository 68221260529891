export class PasswordRule {
	state: string;
	rule: string;
	key: string

	constructor(rule, key) {
		this.state = "untouched";
		this.rule = rule;
		this.key = key;
	}


	changeState(error) {
		if (error) {
			this.state = 'fail';
		} else {
			this.state = 'success';
		}
	}

	markSuccess() {
		this.state = 'success';
	}

	markFail() {
		this.state = 'fail';
	}

}
