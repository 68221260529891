import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { DropdownContext, DropdownService, ListDefaultOptions, ListRowExpandComponent } from '@cds/components-angular';
import { GenericListRow } from './generic-list-row';
import { Observable, Subscription } from 'rxjs';
import { StateMapper } from '../../services/state-mapper';
import { Constants } from '../../utils/constants';
import { GenericListColumn } from './generic-list-column';

@Component({
	selector: 'generic-list',
	templateUrl: 'generic-list.component.html',

})
export class GenericListComponent implements OnInit, OnDestroy {

	@Input()
	expandableContainer: TemplateRef<any>;
	@Input()
	rows: GenericListRow[] = [];
	@Input()
	appearance: ListDefaultOptions['appearance'];
	@Input()
	columns: GenericListColumn[] = [{key: 'default', name: 'Default'}];
	@Input()
	cellTemplates: { column: string, ref: TemplateRef<any> }[] = [];
	@Input()
	showPlaceholder: boolean = true;
	@Input()
	clickable: boolean = false;
	@Input()
	rowClickEvent: Observable<string>;

	@ViewChildren(ListRowExpandComponent) cdsRows: QueryList<ListRowExpandComponent>;

	@Output()
	onRowClick: EventEmitter<any> = new EventEmitter<any>();

	rowClickEventSubscription: Subscription;

	constructor(private stateMapper: StateMapper) {

	}

	ngOnInit(): void {
		if (this.rowClickEvent) {
			this.rowClickEventSubscription = this.rowClickEvent.subscribe((elementId) => {
				// @ts-ignore
				this.cdsRows.filter(cdsRow => cdsRow.expandedContext.id === elementId).forEach(cdsRow => cdsRow.toggle());
			});
		}
	}

	ngOnDestroy(): void {
		if (this.rowClickEventSubscription) {
			this.rowClickEventSubscription.unsubscribe();
		}
	}

	showLastIconColumn() {
		return this.rows.some(r => r.isClickable === true || r.isExpandable === true);
	}

	isStateValue(key) {
		return key === 'stateCode';
	}

	isDateValue(value) {
		return value && (value instanceof Date || Constants.DATE_REGEXP.test(value.toString()));
	}

	getTemplate(column: string): TemplateRef<any> | null {
		const cell = this.cellTemplates.find(t => t.column === column);
		return cell ? cell.ref : null;
	}

	isRowExpandable(row: GenericListRow): boolean {
		return row.isExpandable === true && this.expandableContainer != null;
	}

	isRowClickable(row: GenericListRow): boolean {
		return row.isClickable != null ? row.isClickable === true : this.clickable;
	}

	rowClicked(row: GenericListRow, cdsRow: ListRowExpandComponent, column: GenericListColumn) {
		if (column.clickable) {
			return;
		}

		if (this.isRowClickable(row)) {
			this.onRowClick.emit(row);
		} else if (this.isRowExpandable(row)) {
			cdsRow.toggle();
		}
	}

	mapState(stateCode: string) {
		return this.stateMapper.mapToState(stateCode);
	}
}
