import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root',
})
export class FileService {

	constructor(protected http: HttpClient, protected apiService: ApiService) {
	}

	getFile(path: string, params?: any): Observable<any> {
		return this.http.get(this.apiService.url(path), {responseType: 'blob', params: params});
	}
}
