import {Component, Input, OnInit} from '@angular/core';
import {ContractualTermViewModel} from './contractual-term-view-model';
import {locales} from 'locales';
import {ContractualTermService, FileUtils} from "shared/app/index";

@Component({
	selector: 'contractual-terms',
	templateUrl: './contractual-terms.component.html',
})
export class ContractualTermsComponent implements OnInit {

	@Input() terms: ContractualTermViewModel[];

	constructor(private termsService: ContractualTermService) {
	}

	ngOnInit(): void {
		for (let term of this.terms) {
			term.data.description = this.translate(term.data.description);
			term.data.descriptionSubtext = this.translate(term.data.descriptionSubtext);
		}
	}

	private translate(text: string): string {
		if (text && text.startsWith('sc.')) {
			const i = text.lastIndexOf('.');
			const key = text.substring(i + 1, text.length);
			const translation = locales.term[key];
			if (translation != null) {
				return translation;
			}
		}
		return text;
	}

	viewFile(fileName: string, termId: number, documentVersion: number) {
		this.termsService.getDocument(termId, documentVersion).subscribe(value => {
			FileUtils.initiateFileDownload(value, fileName, 'application/pdf');
		});
	}
}
