import { Component, OnDestroy, OnInit } from '@angular/core';
import { Constants } from '../../utils/constants';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AppPath } from '../../utils/app-path';
import { ProfileService } from '../../services/profile.service';
import { Subscription } from 'rxjs';
import {AppPathService} from "../../services/app-path.service";

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

	environment = environment;
	showFullHeader = false;
	showLoginButton = false;
	private subscription: Subscription;

	constructor(public router: Router, profile: ProfileService, private pathService: AppPathService) {
		this.showFullHeader = profile.isProfileXICT();
	}

	ngOnInit() {
		this.updateLoginButtonVisibility(this.router.url);
		if (this.showFullHeader) {
			this.subscription = this.router.events.subscribe((e) => {
				if (e instanceof NavigationEnd) {
					const url = e['urlAfterRedirects'];
					this.updateLoginButtonVisibility(url);
				}
			});
		}

	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}

	updateLoginButtonVisibility(url) {
		this.showLoginButton = url == this.pathService.getPath(AppPath.PATH_HOME)
				|| this.pathService.getPath(url.startsWith(AppPath.PATH_HOME + '?'))
				|| this.pathService.getPath(url.startsWith('?'))
				|| url.startsWith(AppPath.XICT_PATH_PRODUCTS)
				|| url == this.pathService.getPath(AppPath.PATH_FORGOT_PASSWORD)
				|| url.startsWith(this.pathService.getPath(AppPath.PATH_CHANGE_PASSWORD));
	}

	onClick() {
		environment.keycloak.enabled ? this.pathService.navigate([AppPath.PATH_SELFCARE_DASHBOARD]) : this.pathService.navigate([AppPath.PATH_LOGIN])
	}

}
