import { Component, OnInit } from '@angular/core';
import { TicketsService } from '../../../services/selfcare/tickets.service';
import { Ticket } from '../../../models/ticket/ticket';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { getCustomerId } from '../../../core/store/selectors/context.selectors';
import { ContextState } from '../../../core/store/state/context.state';
import { Store } from '@ngrx/store';
import { viewKey } from '../../../decorators/decorators';
import { AppPath } from '../../../utils/app-path';
import { AppPathService } from '../../../services/app-path.service';
import { ViewKeys } from '../../../utils/view-keys';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import {StateTranslationsPipe} from "../../../pipes/state-translations.pipe"
@Component({
	selector: 'app-tickets',
	templateUrl: './tickets.component.html',
	providers: [StateTranslationsPipe]
})
@viewKey(ViewKeys.V_SELFCARE_TICKET_VIEW)
export class TicketsComponent implements OnInit {

	isLoading: boolean = false;
	customerTickets: Ticket[];
	allCustomerTickets: Ticket[];
	headElements = ['Create date', 'Ticket number', 'Topic', 'Status', ''];

	contextCustomerId: number;
	public filters: Map<string, ((ticket: Ticket) => boolean)>;
	private error: any;
	public getAllState: string[] = [];
	inputTerm: string;

	constructor(private pathService: AppPathService, private ticketService: TicketsService, private store: Store<ContextState>,private statePipe: StateTranslationsPipe) {
		this.store.select(getCustomerId).pipe(take(1))
			.subscribe((customerId: number) => { this.contextCustomerId = customerId })
		this.filters = new Map<string, ((ticket: Ticket) => boolean)>();
	}

	ngOnInit() {
		//2410827
		this.runFilters = this.runFilters.bind(this);
		this.searchFilterFunction = this.searchFilterFunction.bind(this);
		this.filterDataFunction = this.filterDataFunction.bind(this);
		this.filterStatusFunction = this.filterStatusFunction.bind(this);
		this.isLoading = true;
		this.ticketService
			.getCustomerTickets()
			.subscribe((value) => {
				this.customerTickets = this.allCustomerTickets = value;
				this.getAllState = [... Array.from(new Set(this.allCustomerTickets.map(ticket => ticket.stateCode)))]
				this.isLoading = false;
			}, (error) => {
				this.error = error;
				this.isLoading = false;
			});
	}

    searchFilterFunction(term: string): Function{
        return (ticket: Ticket):boolean=>{
            return this.containsAny(term.toLowerCase(), ticket.assigneeName,
                ticket.topic.toLowerCase(), ticket.subject, ticket.ticketNumber.toLowerCase(),
                ticket.workgroupCode, ticket.description,
                this.statePipe.transform(new String(ticket.stateCode)
                                                .toString()
                                                .toLowerCase()) as string);
        }
    }

    filterDataFunction(formDate: {from?: string,to?: string}): Function{
        return (ticket: Ticket)=>{
            return new Date(ticket.createdDate).setHours(0,0,0,0) >= new Date(formDate.from).setHours(0,0,0,0)
                && new Date(ticket.createdDate).setHours(0,0,0,0) <= new Date(formDate.to).setHours(0,0,0,0);
         }
    }

    filterStatusFunction(dropDownValue: string){
        return (ticket: Ticket)=>{
            return this.containsAny(dropDownValue,ticket.stateCode)
        }
    }

	onRowClick(data) {
		this.pathService.navigate([AppPath.PATH_SELFCARE_TICKETS + '/' + data.objectId]);
	}

	private containsAny(term: string, ...fieldsToSearchIn: string[]): boolean {
		return fieldsToSearchIn.some(field => field !== null && field.includes(term));
	}

	public runFilters() {
		const filters = Array.from(this.filters.values());
		this.customerTickets = this.allCustomerTickets.filter(ticket =>
			filters.every(f => f(ticket))
		);
	}
}
