import { Directive, forwardRef, Input, Provider } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { noop, RunnableFunction } from '../../utils/facade';

export const INVALID_VALIDATOR: Provider = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => InvalidDirective),
	multi: true,
};

@Directive({
	selector: '[caInvalid]',
	providers: [INVALID_VALIDATOR],
})
export class InvalidDirective implements Validator {

	private invalid: boolean;
	private onValidatorChange: RunnableFunction = noop;

	@Input()
	set caInvalid(invalid: boolean) {
		this.invalid = invalid;
		this.onValidatorChange();
	}

	validate(control: AbstractControl): ValidationErrors | null {
		if (this.invalid) {
			return { 'invalid': true };
		}
		return null;
	}

	registerOnValidatorChange(fn: () => void): void {
		this.onValidatorChange = fn;
	}
}
