import { DatePipe } from '@angular/common';
import { config } from 'config';

const DAYS_OF_THE_WEEK = {
	0: 'sunday',
	1: 'monday',
	2: 'tuesday',
	3: 'wednesday',
	4: 'thursday',
	5: 'friday',
	6: 'saturday',
};

const NAMES_OF_THE_MONTH = {
	0: 'january',
	1: 'february',
	2: 'march',
	3: 'april',
	4: 'may',
	5: 'june',
	6: 'july',
	7: 'august',
	8: 'september',
	9: 'october',
	10: 'november',
	11: 'december',
};

const DAYS_IN_MONTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

const DAYS_IN_MONTH_LEAP_YEAR = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

const DATE_REGEX = /^(\d{4})-(\d{2})-(\d{2})(T(\d{2}):(\d{2})(:(\d{2}))?)?/;

export class Time {

	static datePipe: DatePipe = new DatePipe(config.lang);

	private constructor(private date: Date) { }

	static stringToDate(str: string): Date {
		const match = str.match(DATE_REGEX);
		if (match) {
			return new Date(+match[1], +match[2] - 1, +match[3], +match[5] || 0, +match[6] || 0, +match[8] || 0);
		}
		throw new Error('Invalid date string');
	}

	private static currentTime: number = Time.stringToDate(config.currentTime).getTime();

	static now(): Time {
		return new Time(new Date(Time.currentTime));
	}

	static fromString(dateString: string): Time {
		return new Time(Time.stringToDate(dateString));
	}

	static fromDate(date: Date): Time {
		return new Time(new Date(date.getTime()));
	}

	static fromAny(date: Date | string): Time {
		if (typeof date === 'string') {
			return new Time(Time.stringToDate(<string> date));
		} else if (date instanceof Date) {
			return new Time(new Date(date.getTime()));
		}
	}

	private floor(): Time {
		this.date.setHours(0);
		this.date.setMinutes(0);
		this.date.setMilliseconds(0);
		this.date.setSeconds(0);
		return this;
	}

	private ceil(): Time {
		this.date.setHours(23);
		this.date.setMinutes(59);
		this.date.setSeconds(59);
		this.date.setMilliseconds(999);
		return this;
	}

	private padNumber(number: number): string {
		return (number <= 9 ? '0' : '') + number;
	}

	clone(): Time {
		return Time.fromDate(this.date);
	}

	seconds(addSeconds: number): Time {
		if (addSeconds) {
			this.date.setSeconds(this.date.getSeconds() + addSeconds);
		}
		return this;
	}

	dayFloor(addDays?: number): Time {
		if (addDays) {
			this.date.setDate(this.date.getDate() + addDays);
		}
		this.floor();
		return this;
	}

	dayCeil(addDays?: number): Time {
		if (addDays) {
			this.date.setDate(this.date.getDate() + addDays);
		}
		this.ceil();
		return this;
	}

	weekFloor(addWeeks?: number): Time {
		if (addWeeks) {
			this.date.setDate(this.date.getDate() + addWeeks * 7);
		}
		const day = this.date.getDay() || 7;
		if (day !== 1) {
			this.date.setHours(-24 * (day - 1));
		}
		this.floor();
		return this;
	}

	weekCeil(addWeeks?: number): Time {
		this.weekFloor(1 + ((!addWeeks) ? 0 : addWeeks));
		this.date.setMilliseconds(-1);
		return this;
	}

	monthFloor(addMonths?: number): Time {
		this.date.setDate(1);
		if (addMonths) {
			this.date.setMonth(this.date.getMonth() + addMonths);
		}
		this.floor();
		return this;
	}

	monthCeil(addMonths?: number): Time {
		this.monthFloor(1 + ((!addMonths) ? 0 : addMonths));
		this.date.setMilliseconds(-1);
		return this;
	}

	yearFloor(addYears?: number): Time {
		if (addYears) {
			this.date.setFullYear(this.date.getFullYear() + addYears);
		}
		this.date.setMonth(0);
		this.date.setDate(1);
		this.floor();
		return this;
	}

	yearCeil(addYears?: number): Time {
		this.yearFloor(1 + ((!addYears) ? 0 : addYears));
		this.date.setMilliseconds(-1);
		return this;
	}

	noon(): Time {
		this.date.setHours(12);
		this.date.setMinutes(0);
		this.date.setSeconds(0);
		this.date.setMilliseconds(0);
		return this;
	}

	monthOffset(count: number): Time {
		const newDate = count > 0 ? this.date.getDate() - 1 : this.date.getDate() + 1;
		this.date.setDate(1);
		this.date.setMonth(this.date.getMonth() + count);

		const monthDays = this.getDaysInMonth();
		if (monthDays < newDate) {
			this.date.setMonth(this.date.getMonth() + 1);
		} else {
			this.date.setDate(newDate);
		}
		return count > 0 ? this.ceil() : this.floor();
	}

	nextWorkingDay(): Time {
		const dayOfWeek = this.getDayOfWeek();
		let inc = 0;

		if (dayOfWeek === 6) {
			inc = 2;
		} else if (dayOfWeek === 0) {
			inc = 1;
		}
		return this.dayFloor(inc);
	}

	nextMonth(): Time {
		this.date.setMonth(this.date.getMonth() + 1);
		return this;
	}

	nextMonthWorkingDay(): Time {
		this.date.setMonth(this.date.getMonth() + 1);

		const dayOfWeek = this.getDayOfWeek();
		let dec = 0;

		if (dayOfWeek === 6) {
			dec = 1;
		} else if (dayOfWeek === 0) {
			dec = 2;
		}
		return this.dayCeil(-dec);
	}

	getMonthPeriod(): DatePeriod {
		this.date.setDate(1);
		const startDate = this.toString();

		this.date.setDate(this.getDaysInMonth());
		const endDate = this.toString();
		return {
			dateFrom: startDate,
			dateTo: endDate,
		};
	}

	getDayOfWeek(): number {
		return this.date.getDay();
	}

	getDayOfMonth(): number {
		return this.date.getDate();
	}

	getMonth(): number {
		return this.date.getMonth();
	}

	getYear(): number {
		return this.date.getFullYear();
	}

	getTime(): number {
		return this.date.getTime();
	}

	setDayOfWeek(day: number): Time {
		const currentDayOfWeek = this.getDayOfWeek();
		const currentDayOfMonth = this.getDayOfMonth();

		this.date.setDate(currentDayOfMonth - currentDayOfWeek + day);
		return this;
	}

	setDayOfMonth(day: number): Time {
		this.date.setDate(day);
		return this;
	}

	setMonth(month: number): Time {
		this.date.setMonth(month);
		return this;
	}

	setYear(year: number): Time {
		this.date.setFullYear(year);
		return this;
	}

	isToday(): boolean {
		return this.clone().dayFloor(0).getTime() === Time.now().dayFloor(0).getTime();
	}

	isYesterday(): boolean {
		return this.clone().dayFloor(0).getTime() === Time.now().dayFloor(0).getTime() - 86400000.0;
	}

	isCurrentYear(): boolean {
		return this.getYear() === Time.now().getYear();
	}

	isWeekend(): boolean {
		const dayOfWeek: number = this.date.getDay();
		return dayOfWeek === 0 || dayOfWeek === 6;
	}

	toDate(): Date {
		return this.date;
	}

	getDayDiff(dateOrStr: string | Date): number {
		const dateTo = Time.fromAny(dateOrStr);
		return Math.round(Math.abs(dateTo.getTime() - this.getTime()) / 86400000.0);
	}

	getDaySlugName(): string {
		return DAYS_OF_THE_WEEK[this.date.getDay()];
	}

	getMonthSlugName(): string {
		return NAMES_OF_THE_MONTH[this.date.getMonth()];
	}

	getDaysInMonth(): number {
		const year: number = this.date.getFullYear();
		const month: number = this.date.getMonth();
		const isLeapYear: boolean = ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0);

		return (isLeapYear ? DAYS_IN_MONTH_LEAP_YEAR : DAYS_IN_MONTH)[month];
	}

	toString(): string {
		const day: string = this.padNumber(this.date.getDate());
		const month: string = this.padNumber(this.date.getMonth() + 1);
		const year: string = '' + this.date.getFullYear();
		return `${year}-${month}-${day}`;
	}

	getField(field: string): string {
		switch (field) {
			case 'day': return this.padNumber(this.date.getDate());
			case 'month': return this.padNumber(this.date.getMonth() + 1);
			case 'year': return '' + this.date.getFullYear();
		}
		return null;
	}

	getDatePeriod(period: string): DatePeriod {
		let fromDate: Time;
		let toDate: Time;
		const date: Time = this.clone();
		if (period === 'today') {
			fromDate = date.clone().floor();
			toDate = date.clone().ceil();
		} else if (period === 'tomorrow') {
			fromDate = date.clone().dayFloor(1);
			toDate = date.clone().dayCeil(1);
		} else if (period === 'yesterday') {
			fromDate = date.clone().dayFloor(-1);
			toDate = date.clone().dayCeil(-1);
		} else if (period === 'previousMonth') {
			fromDate = date.clone().monthFloor(-1);
			toDate = date.clone().monthCeil(-1);
		} else if (period === 'nextMonth') {
			fromDate = date.clone().monthFloor(1);
			toDate = date.clone().monthCeil(1);
		} else if (period === 'all') {
			return {
				dateFrom: '',
				dateTo: '',
			};
		} else {
			const type: string = period[period.length - 1];
			const negative: boolean = period[0] === '-';
			const count: number = parseInt(period.substr(0, period.length-1), 10);
			const borderDate: Time = date.clone();
			if (type === 'd') {
				borderDate.setDayOfMonth(borderDate.getDayOfMonth() + count);
			} else if (type === 'w') {
				borderDate.setDayOfMonth(borderDate.getDayOfMonth() + 7*count);
			} else if (type === 'M') {
				borderDate.setMonth(borderDate.getMonth() + count);
			} else if (type === 'y') {
				borderDate.setYear(borderDate.getYear() + count);
			}
			if (negative) {
				fromDate = borderDate.dayFloor(1);
				toDate = date.clone().ceil();
			} else {
				fromDate = date.clone().floor();
				toDate = borderDate.dayCeil(-1);
			}
		}
		return {
			dateFrom: fromDate.toString(),
			dateTo: toDate.toString(),
		};
	}

	format(pattern: string): string {
		return Time.datePipe.transform(this.date, pattern);
	}
}

export interface DatePeriod {
	dateFrom: string;
	dateTo: string;
}
