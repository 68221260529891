<ng-template #cookiesDialog let-dialog>
	<cds-dialog class="cds-dialog">
		<header class="action-link pt-4 pl-4">
			Protecting your data is our priority
		</header>
		<section class="pl-4">
			<span style="font-size: 16px;">Our website uses cookies. Some of these files are necessary for<br> the proper functioning of the website and therefore cannot be<br> rejected.</span>
		</section>
		<footer>
			<div class="cds-dialog-buttons flex-row">
				<a href="https://www.comarch.com/privacy-policy/" target="_blank">Read more <span class="icon-open_in_new"></span></a>
				<button cdsButton class="ml-4 cds-button-primary" (click)="acceptCookies(dialog.resolve)">Accept</button>
			</div>
		</footer>
	</cds-dialog>
</ng-template>
