import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { noop } from '../../utils/facade';
import { BaseModalInstance } from './modal-instance';

@Component({
	selector: 'ca-modal-floating',
	templateUrl: 'modal-floating.component.html',
	animations: [
		trigger('modalFloatingAnimation', [
			transition('open => close', group([
				query('.ca-modal > .body', [
					style({ opacity: 1, transform: 'translate3d(0, 0, 0)' }),
					animate('0.5s cubic-bezier(0.165, 0.84, 0.44, 1)', style({ opacity: 0, transform: 'translate3d(0, 20%, 0)' })),
				]),
				query('.ca-modal > .overlay', [
					style({ opacity: 1 }),
					animate('0.5s cubic-bezier(0.165, 0.84, 0.44, 1)', style({ opacity: 0 })),
				]),
			])),
			transition('void => open', group([
				query('.ca-modal > .body', [
					style({ opacity: 0, transform: 'translate3d(0, 20%, 0)' }),
					animate('0.5s cubic-bezier(0.165, 0.84, 0.44, 1)', style({ opacity: 1, transform: 'translate3d(0, 0, 0)' })),
				]),
				query('.ca-modal > .overlay', [
					style({ opacity: 0 }),
					animate('0.5s cubic-bezier(0.165, 0.84, 0.44, 1)', style({ opacity: 1 })),
				]),
			])),
		]),
	],
	host: {
		'[@modalFloatingAnimation]': 'state',
		'(@modalFloatingAnimation.done)': 'animationDone($event.toState)',
	},
})
export class ModalFloatingComponent {

	state: string = 'open';
	private onClose: () => void = noop;

	constructor(private instance: BaseModalInstance) {
		instance.onDestroy(next => {
			this.state = 'close';
			this.onClose = next;
		});
	}

	animationDone(state: string) {
		if (state === 'close' && this.onClose) {
			this.onClose();
		}
	}
}
