import { NgModule } from '@angular/core';
import { AbsoluteValuePipe } from './absolute.value.pipe';
import { XICTOpenstackLocationPipe } from './xict/xict-openstack-location.pipe';
import { XICTProcessorTypePipe } from './xict/xict-processor-type.pipe';
import { XICTRemoveQuotationMarks } from './xict/xict-remove-quotation-marks.pipe';
import { XICTOperatingSystemPipe } from './xict/xict-operating-system.pipe';
import { SafePipe } from './safe.pipe';
import { XICTPriceDisplayAreaCodePipe } from './xict/xict-price-display-area-code-pipe';
import { XICTNonZeroCpuPipePipe } from './xict/xict-nonzero-cpu.pipe';
import { XICTSSHKeyPipe } from './xict/xict-ssh-key.pipe';
import { XICTPinTypePipe } from './xict/xict-pin-type.pipe';
import { XICTRoundToFixedWithDecimalPlaces } from './xict/xict-round-to-fixed-pipe';

@NgModule({
	imports: [
		// dep modules
	],
	declarations: [
		AbsoluteValuePipe,
		SafePipe,
		XICTOpenstackLocationPipe,
		XICTProcessorTypePipe,
		XICTNonZeroCpuPipePipe,
		XICTRemoveQuotationMarks,
		XICTOperatingSystemPipe,
		XICTPriceDisplayAreaCodePipe,
		XICTSSHKeyPipe,
		XICTPinTypePipe,
		XICTRoundToFixedWithDecimalPlaces,
	],
	exports: [
		AbsoluteValuePipe,
		XICTOpenstackLocationPipe,
		XICTNonZeroCpuPipePipe,
		XICTProcessorTypePipe,
		XICTPriceDisplayAreaCodePipe,
		XICTRemoveQuotationMarks,
		XICTOperatingSystemPipe,
		XICTSSHKeyPipe,
		XICTPinTypePipe,
		XICTRoundToFixedWithDecimalPlaces,
		SafePipe,
	],
})
export class PipesModule {
}
