import { ModuleWithProviders, NgModule } from '@angular/core';

@NgModule({
	declarations: [],
	imports: [],
	exports: []
})
export class SmartBSSLibModule {

	public static forRoot(environment: Environment): ModuleWithProviders<SmartBSSLibModule> {

		return {
			ngModule: SmartBSSLibModule,
			providers: [
				{
					provide: 'env',
					useValue: environment
				}
			]
		};
	}

}

export interface Environment {
	api_url: string;
	webshop_after_login: boolean;
	webshop_logged_user_offer: boolean;
	brand?: {
		brandCode: string;
		checkBrand: boolean
	}
}
