import { Injectable } from '@angular/core';
import { ErrorResponse } from '../models/error-response';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpErrorWrapper } from '../utils/http-error-wrapper';
import { Router } from '@angular/router';
import { ApiService, TranslationUtil } from 'shared/app/index';
import { ServiceBreakDto } from '../models/xict/service-break-dto';
import { CDSToastService } from './cds-toast.service';
import { __ as translate } from '@comarch/seed-core/lib/i18n';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class ErrorHandlerService {

	constructor(protected router: Router, protected apiService: ApiService, protected cdsToastService: CDSToastService) {
	}

	getOngoingServiceBreak(): Observable<ServiceBreakDto> {
		return  this.apiService.get('/ict/service-break');
	}

	isServiceBreakError(errorResponse: ErrorResponse): boolean {
		return errorResponse.errorCode == 423 && errorResponse.errorMessage === 'Backend is not available';
	}

	redirectToOtherPageOnBackendUnavailable(httpBasedObservable: Observable<any>, redirectPath: string): Observable<any> {
		return httpBasedObservable.pipe(tap({
			error: (error: HttpErrorWrapper) => {
				if (this.isServiceBreakError(error.response)) {
					this.redirectTo(redirectPath);
				}
			},
		}));
	}

	public redirectTo(redirectPath: string) {
		this.router.navigate([redirectPath]);
	}

	showErrorMsg(errorResponse: ErrorResponse) {
		this.cdsToastService.showDanger(TranslationUtil.getOrDefault(errorResponse.errorMessage));
	}

	xehcShowErrorMsg(error: HttpErrorResponse) {
		let msg: string;
		switch (error.status) {
			case 400: {
				msg = translate('sc.common.error_code_400');
				break;
			}
			case 401: {
				msg = translate('sc.common.error_code_401');
				break;
			}
			case 403: {
				msg = translate('sc.common.error_code_403');
				break;
			}
			case 404: {
				msg = translate('sc.common.error_code_404');
				break;
			}
			case 408: {
				msg = translate('sc.common.error_code_408');
				break;
			}
			default: {
				msg = translate('sc.common.error_global');
			}
		}

		if (error.error.errorCode == 612) {
			msg = translate('sc.xehc.company_already_registered');
		}

		this.cdsToastService.showDanger(msg);
	}
}
