import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'XICTNonZeroCpuPipePipe',
	pure: true,
})
export class XICTNonZeroCpuPipePipe implements PipeTransform {
	transform(value: any, ...args: any[]): any {
		if (value === 0) {
			return '-';
		} else {
			return value;
		}

	}
}
