export class TaxIdValidationUtils {

	private static readonly EU_TAX_ID_PATTERNS: { [countryCode: string]: { pattern: string[], regexp: RegExp } } = {
		'AT': {pattern: ['L00000000'], regexp: /^[A-Z]\d\d\d\d\d\d\d\d$/}, // Austria
		'BE': {pattern: ['0000000000'], regexp: /^\d\d\d\d\d\d\d\d\d\d$/}, // Belgium
		'BG': {pattern: ['000000000', '0000000000'], regexp: /^\d\d\d\d\d\d\d\d\d\d?$/}, // Bularia
		'CY': {pattern: ['00000000L'], regexp: /^\d\d\d\d\d\d\d\d[A-Z]$/}, // Cyprus
		'CZ': {pattern: ['00000000', '000000000', '0000000000'], regexp: /^\d\d\d\d\d\d\d\d\d?\d?$/}, // Czech Republic
		'DE': {pattern: ['000000000', '00000000000'], regexp: /^\d\d\d\d\d\d\d\d\d(\d\d)?$/}, // Germany
		'DK': {pattern: ['00000000'], regexp: /^\d\d\d\d\d\d\d\d$/}, // Denmark
		'EE': {pattern: ['00000000', '000000000'], regexp: /^\d\d\d\d\d\d\d\d\d?$/}, // Estonia
		'ES': {pattern: ['L0000000L'], regexp: /^\w\d\d\d\d\d\d\d\w$/}, // Spain
		'FI': {pattern: ['00000000', '000000000X'], regexp: /^\d\d\d\d\d\d\d\d(\d\w)?$/}, // Finland
		'FR': {pattern: ['XX000000000', '000000000'], regexp: /^(\w\w)?\d\d\d\d\d\d\d\d\d$/}, // France
		'GB': {pattern: ['000000000'], regexp: /^\d\d\d\d\d\d\d\d\d$/}, // United Kingdom
		'GR': {pattern: ['000000000'], regexp: /^\d\d\d\d\d\d\d\d\d$/}, // Greece
		'HR': {pattern: ['00000000000'], regexp: /^\d\d\d\d\d\d\d\d\d\d\d$/}, // Croatia
		'HU': {pattern: ['00000000'], regexp: /^\d\d\d\d\d\d\d\d$/}, // Hungary
		'IE': {pattern: ['0000000L', '0000000LL'], regexp: /^\d\d\d\d\d\d\d[A-Z][A-Z]?$/}, // Ireland
		'IT': {pattern: ['00000000000'], regexp: /^\d\d\d\d\d\d\d\d\d\d\d$/}, // Italy
		'LT': {pattern: ['000000000', '000000000000'], regexp: /^\d\d\d\d\d\d\d\d\d(\d\d\d)?$/}, // Lithuania
		'LU': {pattern: ['00000000'], regexp: /^\d\d\d\d\d\d\d\d$/}, // Luxembourg
		'LV': {pattern: ['00000000'], regexp: /^\d\d\d\d\d\d\d\d\d\d\d$/}, // Latvia
		'MT': {pattern: ['00000000'], regexp: /^\d\d\d\d\d\d\d\d$/}, // Malta
		'NL': {pattern: ['000000000L00'], regexp: /^\d\d\d\d\d\d\d\d\d[A-Z]\d\d$/}, // Netherlands
		'PL': {pattern: ['0000000000'], regexp: /^\d\d\d\d\d\d\d\d\d\d$/}, // Poland
		'PT': {pattern: ['000000000'], regexp: /^\d\d\d\d\d\d\d\d\d$/}, // Portugal
		'RO': {pattern: ['00000000'], regexp: /^\d\d\d\d\d\d\d\d$/}, // Romania
		'SE': {pattern: ['000000000000'], regexp: /^\d\d\d\d\d\d\d\d\d\d\d\d$/}, // Sweden
		'SI': {pattern: ['00000000'], regexp: /^\d\d\d\d\d\d\d\d$/}, // Slovenia
		'SK': {pattern: ['0000000000'], regexp: /^\d\d\d\d\d\d\d\d\d\d$/}, // Slovakia
	};

	static getTaxIdPatternForCountryCode(countryCode: string): { pattern: string[], regexp: RegExp } | null {
		return this.EU_TAX_ID_PATTERNS[countryCode];
	}

	static validateTaxIdChecksum(taxId: string, countryCode: string): boolean | null {
		if (countryCode == null) return true;
		switch (countryCode.toUpperCase()) {
			case 'PL':
				const nip = taxId.replace(/[\s-]/g, '');
				const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];

				if (nip.length === 10 && parseInt(nip, 10) > 0) {
					let sum = 0;
					for (let i = 0; i < 9; i++) {
						sum += Number(nip[i]) * weights[i];
					}
					return (sum % 11) === Number(nip[9]);
				}
				return false;
			default:
				return true;
		}
	}
}
