import { Directive, ExistingProvider, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { noop, RunnableFunction } from '../../utils/facade';

const LENGTH_VALIDATOR: ExistingProvider = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => LengthDirective),
	multi: true,
};

@Directive({
	selector: '[caLength]',
	providers: [LENGTH_VALIDATOR],
})
export class LengthDirective implements Validator {

	private length: number;
	private onValidatorChange: RunnableFunction = noop;

	@Input()
	set caLength(length: number) {
		this.length = length;
		this.onValidatorChange();
	}

	validate(control: AbstractControl): {} {
		if (control.value == null || !control.value.length) {
			return null;
		}

		if (control.value.length !== this.length) {
			return {
				length: { requiredLength: this.length },
			};
		}

		return null;
	}

	registerOnValidatorChange(fn: () => void): void {
		this.onValidatorChange = fn;
	}
}
