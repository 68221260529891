import {Component, Input, OnInit} from '@angular/core';
import {States} from '../../utils/states';


@Component({
  selector: 'state',
  templateUrl: './state.component.html'
})
export class StateComponent implements OnInit {

	 @Input() public stateInput: string;

  constructor() {

  }

  ngOnInit() {

  }

  setStatusColor(): string {
	switch(this.stateInput) {
		case States.OST_ACTIVE:
		case States.OST_ENABLED:
		case States.OST_OPENED:
		case States.OST_ACCEPTED:
		case States.OST_OUTGOING_PENDING:
		case States.OST_ISSUED:
		case States.OST_SUBSCRIPTION_MODE_ACTIVE:
			return 'bg-success';
		case States.OST_CREATED:
		case States.OST_DUPLICATED:
		case States.OST_MATCHED_EQUAL:
		case States.OST_PAID:
		case States.OST_SUBSCRIPTION_MODE_WAITING_FOR_ACTIVATION:
		case States.OST_SUBSCRIPTION_MODE_RENEWAL_IN_PROGRESS:
		case States.OST_SUBSCRIPTION_MODE_SWITCH_IN_PROGRESS:
			return 'bg-info';
		case States.OST_CLOSED:
		case States.OST_PARTIALLY_PAID:
		case States.OST_PENDING:
		case States.OST_LOCKED:
			return 'bg-warning';
		case States.OST_FAILED:
		case States.OST_REJECTED:
		case States.OST_DELETED:
			return 'bg-danger';
		case States.OST_SUBSCRIPTION_MODE_INACTIVE:
			return 'bg-secondary';
		default:
			return 'bg-secondary';
	}
  }

}
