<div class="container-fluid cds-grid--no-padding carousel-item__bg" [style.backgroundImage]="'url('+current.backgroundImageUrl+')'"
	 *ngIf="carouselItems && current">
	<div class="row m-0 position-absolute w-100">
		<div class="col-xl-12 p-0">
			<ws-carousel-item  [item]="current"></ws-carousel-item>
		</div>
	</div>
	<div class="carousel-container__selection" *ngIf="carouselItems.length > 1">
		<ng-container *ngFor="let item of carouselItems">
			<div class="carousel-container__selection-item"
			[ngClass]="{'carousel-container__selection-item--active': selection == item.id}"
			(click)="changeCarouselItem(item)"></div>
		</ng-container>
	</div>
	<div class="carousel-container__navigation" *ngIf="carouselItems.length > 1">
		<div (click)="displayPreviousCarouselItem()" class="carousel-container__navigation-item">
			<i class="cds-icon-angle-left cds-icon--size-5x"></i>
		</div>
		<div (click)="displayNextCarouselItem()" class="carousel-container__navigation-item">
			<i class="cds-icon-angle-right cds-icon--size-5x"></i>
		</div>
	</div>
</div>