import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { config } from 'config';

@Directive({
	selector: '[caCustomizationHide]',
})
export class CustomizationHideDirective {

	@Input('caCustomizationHide')
	set customization(customization: string[]) {
		if (customization.includes(config.custom)) {
			this.viewContainer.clear();
		} else {
			this.viewContainer.createEmbeddedView(this.templateRef);
		}
	}

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef) {}

}
