import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { RequestUtil } from '../utils';
import { FirstPasswordRequest, PasswordChangeRequest, UserRegisterRequest } from '../models';
import { PasswordResetDTO } from '../dto/password-reset.dto';
import { UserRole } from '../models/user-role';
import { PasswordProfile } from '../models/password-profile';

@Injectable({
	providedIn: 'root',
})

export class UserService {

	constructor(@Inject('env') public env, protected http: HttpClient,
				protected apiService: ApiService, protected requestUtil: RequestUtil) {
	}

	sendResetPasswordLink(userEmail: string, login?: string) {
		const requestBody = new PasswordResetDTO({
			email: userEmail,
			login: login ?? '',
		});
		return this.apiService.post('/password-reset', requestBody);
	}

	validateToken(token: string): Observable<boolean> {
		const path = `/v2/users/tokens/${token}/verification`;
		return this.apiService.get(path);
	}

	getToken(token: string): Observable<any> {
		const path = '/v2/users/tokens/' + token;
		return this.apiService.get(path, { headers: this.requestUtil.injectHeaders() });
	}

	getUserPasswordProfileByToken(token: string): Observable<PasswordProfile> {
		return this.apiService.get(`/v2/users/tokens/${token}/password-profile`);
	}

	getUserPasswordProfile(userId: number): Observable<PasswordProfile> {
		return this.apiService.get(`/users/${userId}/password-profile`);
	}

	resetPassword(userId: number, passwordChangeRequestBody: PasswordChangeRequest): Observable<string> {
		const headers = this.requestUtil.injectHeaders();
		headers.append('Content-Type', 'application/json');
		return this.apiService.post('/users/' + userId + '/reset-password', passwordChangeRequestBody, {
			headers: headers,
			responseType: 'text',
		});
	}

	changePassword(userId: number, passwordChangeRequestBody: PasswordChangeRequest): Observable<string> {
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		return this.apiService.put('/users/' + userId + '/password', passwordChangeRequestBody, {
			headers: headers,
			responseType: 'text',
		});
	}

	register(userRegisterRequestBody: UserRegisterRequest): Observable<string> {
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		return this.apiService.post('/users/register', userRegisterRequestBody, {
			headers: headers,
			responseType: 'text',
		});
	}

	resendActivationLink(login: string): Observable<string> {
		return null;
	}

	getUserContact(userId): Observable<any> {
		return this.apiService.get(`/users/${userId}/contact`);
	}

	setFirstPassword(userId: number, firstPasswordRequestBody: FirstPasswordRequest): Observable<any> {
		return this.apiService.post('/users/' + userId + '/first-password', firstPasswordRequestBody, {
			headers: this.requestUtil.injectHeaders().append('Content-Type', 'application/json'),
			responseType: 'text',
		});
	}

	checkLoginAvailability(login): Observable<any> {
		return this.http.head(this.env.api_url + '/users' + '?login=' + login, {
			headers: this.requestUtil.injectHeaders(),
		});
	}

	modifyUserRoles(userId: number, roles: string[]): Observable<any> {
		return this.apiService.put('/users/' + userId + '/roles', roles);
	}

	getAvailableRoles(userId: number): Observable<UserRole[]> {
		return this.apiService.get('/users/' + userId + '/available-roles');
	}

	getUserAddress(userId: number): Observable<any> {
		return this.apiService.get('/users/' + userId + '/addresses');
	}
}
