import { Component, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { FileDialogSelection } from './file-dialog-selection';

@Component({
	selector: 'ca-file-dialog',
	template: '',
})
export class FileDialogComponent {

	accept: string = '';

	@Input()
	multiple: boolean = false;

	@Output()
	pick: EventEmitter<FileDialogSelection> = new EventEmitter<FileDialogSelection>();

	private input: HTMLInputElement;

	@Input('accept')
	set acceptSetter(value: string|string[]) {
		const extensions: string[] = Array.isArray(value) ? value : value ? [value] : [];
		this.accept = extensions.map(addDot).join(', ');
	}

	constructor(private renderer: Renderer2, private element: ElementRef) {
		renderer.setStyle(element.nativeElement, 'display', 'none');
	}

	open(): void {
		if (this.input) {
			this.renderer.removeChild(this.element.nativeElement, this.input);
			this.input = null;
		}
		const input: HTMLInputElement = this.renderer.createElement('input');
		this.renderer.setAttribute(input, 'type', 'file');
		if (this.accept) {
			this.renderer.setAttribute(input, 'accept', this.accept);
		}
		if (this.multiple) {
			this.renderer.setAttribute(input, 'multiple', 'multiple');
		}
		this.renderer.appendChild(this.element.nativeElement, input);
		this.renderer.listen(input, 'change', () => {
			this.input = null;
			if (input.files.length) {
				this.pick.emit({
					files: input.files,
					destroy: () => {
						this.renderer.removeChild(this.element.nativeElement, input);
					},
				});
			}
		});
		this.input = input;
		input.click();
	}
}

function addDot(accept) {
	if (accept[0] !== '.' && accept.match(/^[\.a-zA-Z0-9]+$/)) {
		return `.${accept}`;
	} else {
		return accept;
	}
}
