import {Component, Input, OnInit} from "@angular/core";
import {Subscription} from "shared/app/index";

@Component({
	selector: 'subscriptions-bundle-item',
	templateUrl: 'subscriptions-bundle-item.component.html',
})
export class SubscriptionBundleItemComponent implements OnInit {
	@Input() bundleData: Subscription[];
	constructor() {

	}
	ngOnInit(): void {
		this.checkBundleState(this.bundleData);
	}

	private checkBundleState(bundleList: Subscription[]) {
		bundleList.map((bundle) => bundle.stateCode ? bundle.stateCode : bundle.stateCode = 'OST_DISABLED');
	}

}
