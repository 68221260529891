import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {RequestUtil} from "../utils";
import {Observable} from "rxjs";
import {TransactionInformationRequest} from "../models/transaction-information-request";
import {TransactionInformationResponse} from "../models/transaction-information-response";

@Injectable({
  providedIn: 'root',
})
export class TransactionService {

  constructor(protected apiService: ApiService, protected requestUtil: RequestUtil) {}

  getUserTransactionInformation(body: TransactionInformationRequest): Observable<TransactionInformationResponse[]> {
    return this.apiService.post('/payments/transaction', body, {
      headers: this.requestUtil.injectHeaders()
    })
  }
}
