import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ToastConfig, ToastContext, ToastService } from '@cds/components-angular';
import { CDSToastService } from '../../services/cds-toast.service';
import { ToastType } from '../../utils/toast-type';
import { ToastMessage } from '../../utils/toast-message';

interface MyToastContext {
	t: string;
	m: string;
}

@Component({
	selector: 'cds-toast-component',
	templateUrl: './cds-toast.component.html',
})
export class CDSToastComponent implements OnInit {
	@ViewChild('toastInfo', {static: false}) toastInfoRef: TemplateRef<ToastContext<void> & MyToastContext>
	@ViewChild('toastSuccess', {static: false}) toastSuccessRef: TemplateRef<ToastContext<void> & MyToastContext>
	@ViewChild('toastWarning', {static: false}) toastWarningRef: TemplateRef<ToastContext<void> & MyToastContext>
	@ViewChild('toastDanger', {static: false}) toastDangerRef: TemplateRef<ToastContext<void> & MyToastContext>

	config: Partial<ToastConfig> = { position: 'bottom-right', duration: 4000 };
	configDangerToast: Partial<ToastConfig> = { position: 'bottom-right', duration: 30000 };
	toastMessages: ToastMessage[] = []

	constructor(private cdsToastService: CDSToastService, private toastService: ToastService) {

	}

	ngOnInit() {
		this.cdsToastService.message.subscribe((val) => {
			if (val) {
				if(this.toastMessages.filter(toastMsg=>toastMsg.title === val.title && toastMsg.message === val.message).length > 0) {
					return;
				}
				this.toastMessages.push(val);
				this.toastService
					.open(this.getTemplateToOpen(val.toastType), this.getConfig(val), { t: val.title, m: val.message })
					.then( res =>{
						this.toastMessages.shift();
					})
					.catch(()=>{
						this.toastMessages.shift();
					});

			}
		});
	}

	private getTemplateToOpen(toastType: ToastType) {
		switch (toastType) {
			case ToastType.INFO: return this.toastInfoRef;
			case ToastType.DANGER: return this.toastDangerRef;
			case ToastType.SUCCESS: return this.toastSuccessRef;
			case ToastType.WARNING: return this.toastWarningRef;
		}
	}

	private getConfig(val: ToastMessage): Partial<ToastConfig> {
		const copiedConfig = Object.assign({}, val.toastType == ToastType.DANGER ? this.configDangerToast : this.config);
		if (val.duration) {
			copiedConfig.duration = val.duration;
		}
		return copiedConfig;
	}
}
