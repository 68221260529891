import {Injectable} from "@angular/core";
import { HttpParams} from "@angular/common/http";
import {Observable} from "../../../../../node_modules/rxjs/Rx";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {ApiService, Term} from "shared/app/index";
import {UtilService} from "../utils/util.service";

@Injectable({
	providedIn: 'root',
})

export class TermsService {
	showTermsModal: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(private apiService: ApiService, private utilService: UtilService) {}

	// TODO get one ReadOnly Terms for all application
	readOnlyDocumentsCache: BehaviorSubject<any[]>  = new BehaviorSubject<any[]>(null);
	headerBrand = this.utilService.injectHeaders();
	acceptedTerms: BehaviorSubject<Term[]> = new BehaviorSubject<Term[]>([]);

	getTerms(type?: string, code?: string, relatedTermType? :string, offeringId? :number): Observable<any> {
		let params = new HttpParams();
		if (type) params = params.set('type', type.toString());
		if (code) params = params.set('code', code.toString());
		if (relatedTermType) params = params.set('relatedTermType', relatedTermType.toString());
		if (offeringId) params = params.set('product-offering-id', offeringId.toString());
		return this.apiService.get('/global/terms', {
			headers: this.headerBrand,
			params: params,
		});
	}

	getDocument(id: number, version: number): Observable<any> {
		return this.apiService.get('/global/terms/' + id + '/' + version + '/document', {
			headers: this.headerBrand,
			responseType: 'text',
		});
	}

}
