<ng-template #toastSuccess let-toastSuccess let-title="t" let-message="m">
	<cds-toast role="alert" aria-atomic="true" aria-live="polite" status="success" [statusIcon]="true" (toastClose)="toastSuccess.resolve()">
		<cds-slot-title>{{ title }}</cds-slot-title>
		<cds-slot-content>{{ message }}</cds-slot-content>
	</cds-toast>
</ng-template>
<ng-template #toastDanger let-toastDanger let-title="t" let-message="m">
	<cds-toast role="alert" aria-atomic="true" aria-live="polite" status="danger" [statusIcon]="true" (toastClose)="toastDanger.resolve()">
		<cds-slot-title>{{ title }}</cds-slot-title>
		<cds-slot-content>{{ message }}</cds-slot-content>
	</cds-toast>
</ng-template>
<ng-template #toastWarning let-toastWarning let-title="t" let-message="m">
	<cds-toast role="alert" aria-atomic="true" aria-live="polite" status="warning" [statusIcon]="true" (toastClose)="toastWarning.resolve()">
		<cds-slot-title>{{ title }}</cds-slot-title>
		<cds-slot-content>{{ message }}</cds-slot-content>
	</cds-toast>
</ng-template>
<ng-template #toastInfo let-toastInfo let-title="t" let-message="m">
	<cds-toast role="alert" aria-atomic="true" aria-live="polite" status="info" [statusIcon]="true" (toastClose)="toastInfo.resolve()">
		<cds-slot-title>{{ title }}</cds-slot-title>
		<cds-slot-content>{{ message }}</cds-slot-content>
	</cds-toast>
</ng-template>