import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	Renderer2
} from '@angular/core';

@Component({
	selector: 'ca-tag',
	templateUrl: 'tag.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent implements OnInit {

	@Input()
	label: string;

	@Input()
	disabled: boolean = false;

	@Output()
	closeTag: EventEmitter<void> = new EventEmitter<void>();

	constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

	ngOnInit() {
		if (!this.closeTag.observers.length) {
			this.renderer.addClass(this.elementRef.nativeElement, 'hide-icon-reset');
		}
	}

	onClose(): void {
		if (!this.disabled) {
			this.closeTag.emit(null);
		}
	}
}
