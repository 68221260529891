import {Component, Input, OnInit} from '@angular/core';
import { States } from '../../utils/states';
import {InvoiceTypes} from "../../utils/invoice-types";


@Component({
	selector: 'invoice-type',
	templateUrl: './invoice-type.component.html'
})
export class InvoiceTypeComponent implements OnInit {

	@Input() invoiceTypeInput: string;

	constructor() {

	}

	ngOnInit() {
		this.invoiceTypeInput = this.setInvoiceType(this.invoiceTypeInput)
	}

	setInvoiceType(invoiceType): string {
		switch(invoiceType) {
			case InvoiceTypes.INVOICE_TYPE_CORRECTION:
				return 'Correction';
			case InvoiceTypes.INVOICE_TYPE_CREDIT_NOTE:
				return 'Credit note';
			case InvoiceTypes.INVOICE_TYPE_INVOICE:
				return 'Invoice';
			default:
				return invoiceType;
		}
	}

}
