import { ComponentFactoryResolver, Inject, ModuleWithProviders, NgModule, SkipSelf } from '@angular/core';
import { ModalFloatingComponent } from './modal-floating.component';
import { ModalPlaceholderComponent } from './modal-placeholder.component';
import { ModalService } from './modal.service';

export function lazyModalServiceFactory(modalService: ModalService, resolver: ComponentFactoryResolver): ModalService {
	modalService = Object.create(modalService);
	modalService.getResolver = () => resolver;
	return modalService;
}

@NgModule({
	declarations: [
		ModalPlaceholderComponent,
		ModalFloatingComponent,
	],
	exports: [
		ModalPlaceholderComponent,
		ModalFloatingComponent,
	],
})
export class ModalModule {

	static forRoot(): ModuleWithProviders<ModalModule> {
		return {
			ngModule: ModalModule,
			providers: [
				ModalService,
			],
		};
	}

	static forLazyModule(): ModuleWithProviders<ModalModule> {
		return {
			ngModule: ModalModule,
			providers: [
				{ provide: ModalService, useFactory: lazyModalServiceFactory, deps: [[new Inject(ModalService), new SkipSelf()], ComponentFactoryResolver] },
			],
		};
	}
}
