import { Component, OnInit } from '@angular/core';
import { viewKey } from '../../../decorators/decorators';
import { config } from 'config';
import { AppPath } from '../../../utils/app-path';
import { AppPathService } from '../../../services/app-path.service';
import { ViewKeys } from '../../../utils/view-keys';
import {ThemeService} from "../../../services/theme.service";
import { AppProfile } from '../../../utils/app-profile';

@Component({
	selector: 'app-invoices',
	templateUrl: './invoices.component.html',
})
@viewKey(ViewKeys.V_SELFCARE_INVOICE_VIEW)
export class InvoicesComponent implements OnInit {

	constructor(private pathService: AppPathService) { }

	ngOnInit() {

	}

	goToInvoiceDetails(invoiceId: any) {
		// @ts-ignore
		if(config.custom != AppProfile.XICT || config.custom != AppProfile.XICT_INTERNAL) {
			this.pathService.navigate([AppPath.PATH_SELFCARE_INVOICE_DETAILS + '/' + invoiceId]);
		}
	}

}
