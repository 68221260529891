import { NgModule } from '@angular/core';
import { CustomizationDirective } from './customization.directive';

@NgModule({
	declarations: [
		CustomizationDirective,
	],
	exports: [
		CustomizationDirective,
	],
})
export class CustomizationModule {
}
