import { Pipe, PipeTransform } from '@angular/core';
import { States } from '../utils/states';
import { __ as translate } from '@comarch/seed-core/lib/i18n';
import { TranslationUtil } from 'shared/app';

@Pipe({name: 'stateTranslationsPipe'})
export class StateTranslationsPipe implements PipeTransform {
	transform(state: String, prefix?: string): String {
		switch (state) {
			case States.OST_CREATED:
				return translate('app.state.created');
			case States.OST_ACCEPTED:
				return translate('app.state.accepted');
			case States.OST_ISSUED:
				return translate('app.state.issued');
			case States.OST_PAID:
				return translate('app.state.paid');
			case States.OST_ONLINE_PAID:
				return translate('app.state.online_paid');
			case States.OST_FAILED:
				return translate('app.state.failed');
			case States.OST_OPENED:
				return translate('app.state.opened');
			case States.OST_PARTIALLY_PAID:
				return translate('app.state.partially_paid');
			case States.OST_INACTIVE:
				return translate('app.state.inactive');
			case States.OST_ENABLED:
				return translate('app.state.enabled');
			case States.OST_PROCESSED:
				return translate('app.state.processed');
			case States.OST_MATCHED_OVERPAYMENT:
				return translate('app.state.matched_overpayment');
			case States.OST_MATCHED_UNDERPAYMENT:
				return translate('app.state.matched_underpayment');
			case States.OST_MATCHED_EQUAL:
				return translate('app.state.macthed_equal');
			case States.OST_DISABLED:
				return translate('app.state.disabled');
			case States.OST_OUTGOING_PENDING:
				return translate('app.state.outgoing_pending');
			case States.OST_CLOSED:
				return translate('app.state.closed');
			case States.OST_REVIEW:
				return translate('app.state.review');
			case States.OST_SUBSCRIPTION_MODE_INACTIVE:
				return translate('app.state.subscription_mode_inactive');
			case States.OST_SUBSCRIPTION_MODE_SWITCH_IN_PROGRESS:
				return translate('app.state.subscription_mode_switch_in_progress');
			case States.OST_SUBSCRIPTION_MODE_RENEWAL_IN_PROGRESS:
				return translate('app.state.subscription_mode_renewal_in_progress');
			case States.OST_SUBSCRIPTION_MODE_WAITING_FOR_ACTIVATION:
				return translate('app.state.subscription_mode_waiting_for_activation');
			case States.OST_SUBSCRIPTION_MODE_ACTIVE:
				return translate('app.state.subscription_mode_active');
			case States.OST_RESOLVED:
				return translate('app.state.resolved');
			case States.OST_PROCESSING:
				return translate('app.state.processing');
			case States.OST_RETURNED:
				return translate('app.state.returned');
			case States.OST_DELETED:
				return translate('app.state.deleted');
			case States.OST_REJECTED:
				return translate('app.state.rejected');
			case States.OST_LOCKED:
				return translate('app.state.locked');
			case States.OST_ACTIVE:
				return translate('app.state.active');
			case States.OST_PENDING:
				return translate('app.state.pending');
			case States.OST_CANCELLED:
				return translate('app.state.cancelled');
			case States.OST_SPLITTED:
				return translate('app.state.splitted');
			case States.OST_WAIT_FOR_PAYMENT:
				return translate('app.state.wait_for_payment');
			case States.OST_BOOKED:
				return translate('app.state.booked');
			case States.OST_FULFILLING:
				return translate('app.state.fulfilling');
			case States.OST_FULFILLED:
				return translate('app.state.fulfilled');
			case States.ACTIVE:
				return translate('app.state.active');
			case States.SHUTOFF:
				return translate('app.state.shutoff');
			case States.ERROR:
				return translate('app.state.error');
			case States.MACHINE_STATUS_CREATING:
				return translate('app.state.machine_status_creating');
			case States.MACHINE_STATUS_SHUTTING_OFF:
				return translate('app.state.machine_status_shutting_off');
			case States.MACHINE_STATUS_TURNING_ON:
				return translate('app.state.machine_status_turning_on');
			case States.MACHINE_STATUS_REBOOTING:
				return translate('app.state.machine_status_rebooting');
			case States.MACHINE_STATUS_DELETING:
				return translate('app.state.machine_status_deleting');
			case States.MACHINE_STATUS_INITIALIZING:
				return translate('app.state.machine_status_initializing');
			case States.MACHINE_STATUS_MODIFYING:
				return translate('app.state.machine_status_modifying');
			case States.MACHINE_STATUS_RESIZING:
				return translate('app.state.machine_status_resizing');
			case States.MACHINE_STATUS_RENAMING:
				return translate('app.state.machine_status_renaming');
			case States.MACHINE_STATUS_DELETING_ERROR:
				return translate('app.state.machine_status_deleting_error');
			case States.MACHINE_STATUS_NETWORK_ADDING:
				return translate('app.state.machine_status_network_adding');
			case States.MACHINE_STATUS_ACTIVATING_DST:
				return translate('app.state.machine_status_activating_dst');
			case States.VERIFY_RESIZE:
				return translate('app.state.verify_resize');
			default:
				if (prefix) {
					return TranslationUtil.getByDottedKey(prefix + '.' + TranslationUtil.nameToCode(state.toString())).toString();
				}
				return state;
		}
	}

}
