import {
	Directive,
	forwardRef,
	Host,
	HostBinding,
	HostListener,
	Inject,
	InjectionToken,
	Input,
	OnDestroy,
	OnInit
} from '@angular/core';

@Directive({
	selector: '[caComboItem]',
})
export class ComboItemDirective implements OnDestroy, OnInit {

	@Input('caComboItem')
	value: any;

	@Input()
	labelValue: any = undefined;

	@HostBinding('class.active')
	isActive: boolean = false;

	constructor(@Host() @Inject(forwardRef(() => COMBO_ITEM_HOST)) protected combo: ComboItemHost) {
	}

	@HostListener('click', ['$event'])
	onClick(event: Event): void {
		this.combo.onItemClick(event, this);
	}

	ngOnInit(): void {
		this.combo.registerItem(this);

	}

	ngOnDestroy(): void {
		Promise.resolve().then(() => {
			this.combo.unregisterItem(this);
		});
	}
}

export const COMBO_ITEM_HOST: InjectionToken<ComboItemHost> = new InjectionToken<ComboItemHost>('COMBO_ITEM_HOST');

export interface ComboItemHost {
	findItem(value: any): ComboItemDirective;
	registerItem(item: ComboItemDirective): void;
	unregisterItem(item: ComboItemDirective): void;
	onItemClick(event: Event, item: ComboItemDirective): void;
}
