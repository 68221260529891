import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConsumerFunction, noop, RunnableFunction } from '../../utils/facade';

const RATE_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => RateComponent),
	multi: true,
};

@Component({
	selector: 'ca-rate',
	templateUrl: 'rate.component.html',
	providers: [RATE_CONTROL_VALUE_ACCESSOR],
})
export class RateComponent implements OnChanges, ControlValueAccessor {

	@Input()
	maxValue: number = 5;
	values = Array(5);

	disabled: boolean = false;
	private _value: number = 0;

	private onChangeCallback: ConsumerFunction = noop;
	private onTouchedCallback: RunnableFunction = noop;

	get value(): number {
		return this._value;
	}

	set value(value: number) {
		this._value = value;
		this.onChangeCallback(value);
		this.onTouchedCallback();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ('maxValue' in changes) {
			this.values = Array(this.maxValue || 0);
		}
	}

	writeValue(obj: any): void {
		this._value = obj || 0;
	}

	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = true;
	}
}
