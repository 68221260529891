import { Directive, forwardRef, Host, HostListener, Inject, InjectionToken, Input } from '@angular/core';

export const TYPEAHEAD_ITEM_HOST: InjectionToken<TypeaheadItemHost> = new InjectionToken<TypeaheadItemHost>('TYPEAHEAD_ITEM_HOST');

@Directive({
	selector: '[caTypeaheadItem]',
})
export class TypeaheadItemDirective {

	@Input('caTypeaheadItem')
	value: any;

	@Input()
	labelValue: any = undefined;

	constructor(@Host() @Inject(forwardRef(() => TYPEAHEAD_ITEM_HOST)) private typeahead: TypeaheadItemHost) {}

	@HostListener('click', ['$event'])
	onClick(event: Event): void {
		event.preventDefault();
		event.stopPropagation();
		this.typeahead.selectSuggestion(this.value, this.labelValue == null ? this.value : this.labelValue);
	}
}

export interface TypeaheadItemHost {
	selectSuggestion(value: any, labelValue: string): void;
}
