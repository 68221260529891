import {CartItem} from "../../../models/cart-item";

export interface CartState {
	items: CartItem[];
	totalCount: number;
	totalPricePeriodic: number;
	totalPriceOneTime: number;
}

export const initialCartState: CartState = {
	items: [],
	totalCount: 0,
	totalPricePeriodic: 0,
	totalPriceOneTime: 0
};

export const emptyCartState: CartState = {
	items: [],
	totalCount:0,
	totalPricePeriodic: 0,
	totalPriceOneTime: 0
};
