import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
	selector: 'pagination',
	templateUrl: './pagination.component.html',
})
export class PaginationComponent implements OnInit, OnChanges {
	@Output() pageChange = new EventEmitter();
	@Output() pageRangeChange = new EventEmitter();
	@Input() totalItems: number = 0;
	@Input() page: number = 0;
	@Input() size: number = 5;
	@Input() infinitePages: boolean = false;
	@Input() pageLoadedItems: number = null;
	@Input() hasNextPage: boolean = false;
	@Input() itemsPerPageConfig: { default: number, others: number[] } = {
		default: 5,
		others: [10, 20],
	};
	@Input() showLeftSelector: boolean = false;
	@Input() paginationPlacement: string = null;

	itemsPerPage: number[];
	defaultItemsPerPage: number;

	totalPages: number;
	rowFrom: number;
	rowTo: number;
	isPaginationVisible: boolean = false;

	constructor() {

	}

	ngOnInit() {
		this.defaultItemsPerPage = this.itemsPerPageConfig.default;
		this.itemsPerPage = this.itemsPerPageConfig.others.concat(this.defaultItemsPerPage).sort((n1, n2) => n1 - n2);
		this.size = this.defaultItemsPerPage;
		this.page = 0;

		this.getPages(this.size, this.totalItems);
	}

	ngOnChanges() {
		this.isPaginationVisible = (this.totalItems && (this.totalItems > this.size)) || this.infinitePages;
		this.getPages(this.size, this.totalItems);
	}

	getPages(size: number, totalItems: number) {
		this.totalPages = this.getTotalPages(size, totalItems);
		this.loadPageRange();
	}

	loadData() {
		this.pageRangeChange.emit(this.getPageItemRange());
		this.pageChange.emit(this.getPagination());
		this.loadPageRange();
	}

	loadPageRange() {
		this.rowFrom = this.getPageItemRange().from + 1;
		if (this.infinitePages && this.pageLoadedItems != null) {
			this.rowTo = this.rowFrom + this.pageLoadedItems - 1;
		} else if (this.totalItems != 0) {
			this.rowTo = Math.min(this.totalItems, this.getPageItemRange().to);
		} else {
			this.rowTo = this.getPageItemRange().to;
		}
	}

	getCurrentPage(fromRow: number, itemsPerPage: number): number {
		return Math.floor(fromRow / itemsPerPage) || 0;
	}

	getTotalPages(itemsPerPage: number, itemsCount: number): number {
		return Math.ceil(Math.max(itemsCount, 1) / Math.max(itemsPerPage, 1));
	}

	selectItemsPerPage(perPage: number): void {
		this.size = +perPage;
		this.page = 0;
		this.totalPages = this.getTotalPages(this.size, this.totalItems);
		this.loadData();
	}

	nextPage() {
		if ((!this.infinitePages && this.page < this.totalPages - 1) || (this.infinitePages && this.hasNextPage)) {
			this.page = this.page + 1;
			this.loadData();
		}
	}

	prevPage() {
		if (this.page > 0) {
			this.page = this.page - 1;
			this.loadData();
		}
	}

	getPageItemRange(): { from: number, to: number } {
		return {
			from: (this.page * this.size),
			to: ((this.page + 1) * this.size),
		};
	}

	getPagination(): { page: number, size: number } {
		return {
			page: this.page,
			size: this.size,
		};
	}

}
