import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {RequestUtil} from "../utils";
import {Observable} from "rxjs";
import {UpdateB2bOrganizationData} from "../models/update-b2b-organization-data";
import {UpdateB2bOrganizationDataResponse} from "../models/update-b2b-organization-data-response";
import {InvoicePaymentRequest} from "../models/requests/invoice-payment-request";
import {OrderPaymentRequest} from "../models/requests/order-payment-request";
import {PaymentResponse} from "../models/payment-response";

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {

  constructor(protected apiService: ApiService, protected requestUtil: RequestUtil) {

  }

  configurePaymentGateways(body: {paymentChannel: string, accountId: number, currencyCode: string}): Observable<any> {
    return this.apiService.post('/payments/service/b2b-register', body, {
      headers: this.requestUtil.injectHeaders()
    })
  }

  updatePaymentGatewayData(body: UpdateB2bOrganizationData): Observable<UpdateB2bOrganizationDataResponse> {
    return this.apiService.post('/payments/service/b2b-update', body, {
      headers: this.requestUtil.injectHeaders()
    })
  }

  paymentServiceConfigUpdate(body: {paymentChannel: string, accountId: number, serviceId: string, allowOnlinePayments: boolean}): Observable<any> {
    return this.apiService.put('/payments/service/config/update', body, {
      headers: this.requestUtil.injectHeaders()
    })
  }

  getAvailablePaymentGateways(): Observable<any> {
    return this.apiService.get('/payments/channels', {
      headers: this.requestUtil.injectHeaders()
    })
  }

  getAccountConfiguredPaymentGateways(accountId: number): Observable<any> {
    return this.apiService.get('/payments/service/config?accountId=' + accountId, {
      headers: this.requestUtil.injectHeaders()
    })
  }

  getPaymentGatewayBalance(serviceId: string): Observable<any> {
    return this.apiService.get('/customer/payments/service/balance?serviceId=' + serviceId, {
      headers: this.requestUtil.injectHeaders()
    })
  }

  getPaymentGatewayFrozenBalance(serviceId: string): Observable<any> {
    return this.apiService.get('/customer/payments/service/frozen-balance?serviceId=' + serviceId, {
      headers: this.requestUtil.injectHeaders()
    })
  }

  getPaymentGatewayCompanyData(serviceId: string): Observable<any> {
    const p = {
      'serviceId': serviceId
    };
    return this.apiService.get('/payments/service/company-data', {headers: this.requestUtil.injectHeaders(), params: p})
  }

  payInvoiceManually(body: InvoicePaymentRequest): Observable<PaymentResponse> {
    return this.apiService.post('/payments/transaction/manualchoose/invoice', body, {
      headers: this.requestUtil.injectHeaders(),
    });
  }

  payOrderManually(body: OrderPaymentRequest): Observable<PaymentResponse> {
    return this.apiService.post('/payments/transaction/manualchoose/order', body, {
      headers: this.requestUtil.injectHeaders(),
    });
  }
}
