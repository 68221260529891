import { Component, Input } from '@angular/core';

@Component({
	selector: 'ca-combo-multi-tag',
	templateUrl: 'combo-multi-tag.component.html',
})
export class ComboMultiTagComponent {

	@Input()
	allLabel: string;

	@Input()
	selectedLabel: string;

	@Input()
	selectedCount: number;

	@Input()
	totalCount: number;
}
