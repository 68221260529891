import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { DialogContext, DialogService } from '@cds/components-angular';
import { Constants } from '../../utils/constants';

@Component({
	selector: 'app-cookies',
	templateUrl: './cookies.component.html',
})
export class CookiesComponent implements AfterViewInit {
	@ViewChild('cookiesDialog', {static: false})
	cookiesDialog: TemplateRef<DialogContext>;

	constructor(private dialogService: DialogService) {

	}

	ngAfterViewInit(): void {
		if (this.cookiesDialog && !this.cookiesAccepted()) {
			this.dialogService.open(this.cookiesDialog, {})
					.then(_ => {

					});
		}
	}

	cookiesAccepted() {
		return (localStorage.getItem(Constants.COOKIES_ACCEPTED_KEY) === Constants.COOKIES_ACCEPTED_VALUE);
	}

	acceptCookies(acceptCallback) {
		localStorage.setItem(Constants.COOKIES_ACCEPTED_KEY, Constants.COOKIES_ACCEPTED_VALUE);
		acceptCallback();
	}

}
