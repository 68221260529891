import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { of } from 'rxjs/internal/observable/of';
import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import { RequestUtil } from '../utils';
import { Address } from '../models';

@Injectable({
	providedIn: 'root',
})
export class AccountService {

	constructor(protected apiService: ApiService, protected requestUtil: RequestUtil) {
	}

	getSubscriptionsForAccount(accountId: number, fromRow?: number, toRow?: number): Observable<any[]> {
		let params;
		(fromRow && toRow) ? params = new HttpParams().set('fromRow', fromRow.toString()).set('toRow', toRow.toString()) : '';
		return this.apiService.get(`/accounts/${accountId}/subscriptions`, {params: params});
	}

	modifySubscriptionForAccount(accountId: number, subscriptionOperation: any[]) {
		const httpOptions = {
			headers: this.requestUtil.injectHeaders(),
		};
		return this.apiService.post('/accounts/' + accountId + '/subscriptions', subscriptionOperation, httpOptions);
	}

	getBalanceForAccount(accountId: number): Observable<{ amount: number, currencyCode: string }> {
		return of({amount: 0, currencyCode: ''});
	}

	getAddressesForAccount(accountId: number): Observable<Address[]> {
		return this.apiService.get('/account/' + accountId + '/addresses' );
	}

	getDistributionChannelForAccount(accountId: number): Observable<any> {
		return this.apiService.get('/accounts/' + accountId + '/distribution-channel' );
	}

	modifyDistributionChannelForAccount(accountId: number, distributionChannel: { distributionChannelName: string }) {
		const httpOptions = {
			headers: this.requestUtil.injectHeaders(),
		};
		return this.apiService.put('/accounts/' + accountId + '/distribution-channel', distributionChannel, httpOptions);
	}

	getOrdersForAccount(accountId: number, filters?: {search: string, issuedBefore: string, issuedAfter: string, orderStatuses: string[], transactionStatuses: string[], createdBefore: string, createdAfter: string}, pagination?: {page: number, size: number}, orderTypeCode?: string): Observable<any> {
    let requestParams = new HttpParams();
	if(orderTypeCode) {
		requestParams = requestParams.append('orderTypeCode', orderTypeCode.toString())
	}
    if(filters && filters.search) {
      requestParams = requestParams.append('search', filters.search.toString())
    }
    if(filters && filters.issuedBefore) {
      requestParams = requestParams.append('issuedBefore', filters.issuedBefore.toString())
    }
    if(filters && filters.issuedAfter) {
      requestParams = requestParams.append('issuedAfter', filters.issuedAfter.toString())
    }
	if(filters && filters.orderStatuses) {
		requestParams = requestParams.append('orderStatuses', filters.orderStatuses.toString())
	}
		if(filters && filters.transactionStatuses) {
			requestParams = requestParams.append('transactionStatuses', filters.transactionStatuses.toString())
		}
		if(filters && filters.createdBefore) {
			requestParams = requestParams.append('createdBefore', filters.createdBefore.toString())
		}
		if(filters && filters.createdAfter) {
			requestParams = requestParams.append('createdAfter', filters.createdAfter.toString())
		}
    if(pagination && pagination.size) {
      requestParams = requestParams.append('size', pagination.size.toString())
    }
    if(pagination && pagination.page) {
      requestParams = requestParams.append('page', pagination.page.toString())
    }
	  return this.apiService.get(`/v2/accounts/${accountId}/orders`, {params: requestParams})
  }
}
