import {AuthRequest} from "../models/auth-request";

export class AuthRequestDTO implements AuthRequest{
	username: string;
	password: string;

	constructor(u: string, p: string) {
		this.username = u;
		this.password = p;
	}
}
