<ng-container *ngIf="!isOneLanguage">
	<cds-combo class="cds-combo-shadow language-selector__container"
			   [(ngModel)]="selectedLanguage"
			   [uncheckable]="false">
		<label>{{selectedLanguage}}</label>
		<ul *ngFor="let lang of languages">
			<li cdsComboItem="{{ lang.label }}"
				(click)="changeLang(lang.code)">{{ lang.label }}</li>
		</ul>
	</cds-combo>
</ng-container>