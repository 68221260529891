import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Directive({
	selector: '[caHasPermission]',
})
export class HasPermissionDirective {

	@Input('caHasPermission')
	set caHasPermission(permission: string | string[]) {
		if ((Array.isArray(permission) && this.checkArray(permission)) || this.authenticationService.hasPrivilege(permission.toString())) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}

	private checkArray(permission: string[]) {
		return permission.some(p => this.authenticationService.hasPrivilege(p));
	}

	constructor(
			private templateRef: TemplateRef<any>,
			private authenticationService: AuthenticationService,
			private viewContainer: ViewContainerRef) {
	}
}
