<div class="row">
	<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
		<div class="font-weight-bold">
			Your selected products
		</div>
	</div>
</div>

<div class="row mt-4" *ngIf="activationDate">
	<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
		<div class="checkout__available">
			<div class="icon-location checkout__available-icon"></div>
			Products will be available from {{activationDate | date: 'mediumDate'}}
		</div>
	</div>
</div>

<div class="mt-4">
	<ng-container *ngIf="cartItems.length > 0; else emptyCart">
		<div class="row mb-4" *ngFor="let item of cartItems">
			<div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-8 text-left">
				{{ item.name }}
			</div>
			<ng-container *ngIf="item.price.length > 1; else oneTimeOrPeriodic">
				<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 text-right">
					<div *ngFor="let price of item.price">
						{{ price.amount | currencyProfile }}<ng-container *ngIf="price.chargingType == 'Periodic'">/mo</ng-container>
						{{ price.chargedInAdvance ? ' (prepaid)' : ' (postpaid)' }}
					</div>
				</div>
			</ng-container>
			<ng-template #oneTimeOrPeriodic>
				<div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 text-right">
					{{ item.price.amount | currencyProfile }}<ng-container *ngIf="item.isPeriodic">/mo</ng-container>
					{{ item.price.chargedInAdvance ? ' (prepaid)' : ' (postpaid)' }}
				</div>
			</ng-template>
		</div>
	</ng-container>
</div>
<ng-template #emptyCart>
	<div class="mt-4 mb-4 font-italic">
		Your cart is empty
	</div>
</ng-template>

<hr>

<div class="row">
	<div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-left font-weight-bold">
		Total
	</div>
	<div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 text-right">

		<div class="row">
			<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 text-left">
				Periodic
			</div>
			<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 font-weight-bold text-right">
				<ng-container *ngIf="discountPercentValue > 0 && cartTotalPricePeriodic > 0">
					<div class="cart__price-total-value">
						{{ ((cartTotalPricePeriodic / 100) - ((cartTotalPricePeriodic/100) * (discountPercentValue/100))) | currencyProfile}}/mo
					</div>
				</ng-container>
				<div style="font-size: 1.4rem !important;" [ngClass]="{'price__discount': discountPercentValue > 0 && cartTotalPricePeriodic > 0}">
					{{ (cartTotalPricePeriodic / 100) | currencyProfile}}/mo
				</div>
				<ng-container *ngIf="discountPercentValue > 0 && cartTotalPricePeriodic > 0">
					<div class="price__discount--label mt-2">
						-{{discountPercentValue}}%
					</div>
				</ng-container>
			</div>
		</div>

		<div class="row mt-2">
			<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 text-left">
				One-time payment
			</div>
			<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 font-weight-bold text-right">
				<ng-container *ngIf="discountPercentValue > 0 && cartTotalPriceOneTime > 0">
					<div class="cart__price-total-value">
						{{ ((cartTotalPriceOneTime / 100) - ((cartTotalPriceOneTime/100) * (discountPercentValue/100))) | currencyProfile}}
					</div>
				</ng-container>
				<div style="font-size: 1.4rem !important;" [ngClass]="{'price__discount': discountPercentValue > 0 && cartTotalPriceOneTime > 0}">
					{{ (cartTotalPriceOneTime / 100) | currencyProfile}}
				</div>
				<ng-container *ngIf="discountPercentValue > 0 && cartTotalPriceOneTime > 0">
					<div class="price__discount--label mt-2">
						-{{discountPercentValue}}%
					</div>
				</ng-container>
			</div>
		</div>

	</div>
</div>