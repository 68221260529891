import { Actions, Effect, ofType } from '@ngrx/effects';
import {Injectable, TemplateRef, ViewChild} from '@angular/core';
import {map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import {CartState, emptyCartState, initialCartState} from "../state/cart.state";
import {
	AddCartItemAssociationRequireSuccess,
	AddCartItemDeclined,
	AddCartItemSuccess,
	CartActionsTypes, ClearCartSuccess,
	GetCartItemsSuccess,
	RemoveCartItemSuccess
} from "../actions/cart.actions";
import {getCart} from "../selectors/cart.selectors";
import {CartItem} from "../../../models/cart-item";
import {WebshopConstant} from "../../../utils/webshop.constant";
import {ServiceabilityCheckService} from "../../../services/webshop/serviceability-check.service";
import {ProfileService} from "../../../services/profile.service";
import {CartModalService} from "../../../services/webshop/cart-modal.service";
import {CDSToastService} from "../../../services/cds-toast.service";

@Injectable()
export class CartEffects {

	constructor(private actions$: Actions<any>, private store: Store<CartState>, private cdsToast: CDSToastService,
				private profileService: ProfileService, private locationService: ServiceabilityCheckService,
				private shoppingCartModalService: CartModalService) {

	}

	@Effect()
	addCartItem$ = this.actions$.pipe(
		ofType(CartActionsTypes.AddCartItem),
		withLatestFrom(this.store.select(getCart)),
		map(([action, state]:any) => {
			if(this.profileService.isProfileXVLO() && !this.locationService.isCacheData()) {
				this.locationService.showModal.next(true);
				return new AddCartItemDeclined()
			}
			let index = state.items.findIndex((item: CartItem) => item.id == action.payload.id)
			if(index < 0) {
				state.items.push(action.payload);
				if(action.payload.price.length > 1) {
					let periodicTotal = 0;
					let oneTimeTotal = 0;
					action.payload.price.forEach((price) => {
						if(price.chargingType == 'Periodic') {
							periodicTotal = periodicTotal + price.amount;
						} else {
							oneTimeTotal = oneTimeTotal + price.amount;
						}
					});
					state.totalPricePeriodic = state.totalPricePeriodic + periodicTotal*100;
					state.totalPriceOneTime = state.totalPriceOneTime + oneTimeTotal*100;
				} else if(action.payload.isPeriodic) {
					state.totalPricePeriodic = state.totalPricePeriodic + (state.items[state.items.length-1].price.amount*100);
				} else if(!action.payload.isPeriodic){
					state.totalPriceOneTime = state.totalPriceOneTime + (state.items[state.items.length-1].price.amount*100);
				}
				state.totalCount++;
				if(action.payload.additionalOfferings && action.payload.additionalOfferings.requires.length > 0) {
					this.showAssociationsModal(action.payload);
				} else if(!action.payload.isRequiredForAssociation){
					this.showAddModal(action.payload);
				}
			} else if(!action.payload.isRequiredForAssociation){
				this.showDuplicatedModal(action.payload);
				// this.increaseValue(state, index);
			}
			localStorage.setItem(WebshopConstant.LOCALSTORAGE_CART_DATA, JSON.stringify(state));
			return new AddCartItemSuccess(action.payload);
		})
	);

	@Effect()
	addCartItemRequire$ = this.actions$.pipe(
		ofType(CartActionsTypes.AddCartItemAssociationRequire),
		withLatestFrom(this.store.select(getCart)),
		map(([action, state]:any) => {
			let index = state.items.findIndex((item: CartItem) => item.id == action.payload.id)
			if(index < 0) {
				state.items.push(action.payload);
				if(action.payload.isPeriodic) {
					state.totalPricePeriodic = state.totalPricePeriodic + (state.items[state.items.length-1].price.amount*100);
				} else if(!action.payload.isPeriodic) {
					state.totalPriceOneTime = state.totalPriceOneTime + (state.items[state.items.length-1].price.amount*100);
				}
				state.totalCount++;
			}
			localStorage.setItem(WebshopConstant.LOCALSTORAGE_CART_DATA, JSON.stringify(state));
			return new AddCartItemAssociationRequireSuccess(action.payload);
		})
	);

	@Effect()
	removeCartItem$ = this.actions$.pipe(
		ofType(CartActionsTypes.RemoveCartItem),
		withLatestFrom(this.store.select(getCart)),
		map(([action, state]:any) => {
			let index = state.items.findIndex((item: CartItem) => item.id == action.payload.id)
			if(index >= 0) {

				// vvv Remove required items vvv
				let requiredItems = state.items[index].additionalOfferings.requires;
				if(requiredItems.length > 0) {
					requiredItems.forEach((itemRequired: {id: number, name: string}) => {
						let indexRequiredItem = state.items.findIndex((cartItem: CartItem) => cartItem.id == itemRequired.id);
						if(indexRequiredItem >= 0) {
							if(state.items[indexRequiredItem].isPeriodic) {
								state.totalPricePeriodic = state.totalPricePeriodic - (state.items[indexRequiredItem].count * state.items[indexRequiredItem].price.amount * 100);
							} else {
								state.totalPriceOneTime = state.totalPriceOneTime - (state.items[indexRequiredItem].count * state.items[indexRequiredItem].price.amount * 100);
							}
							state.totalCount = state.totalCount - state.items[index].count;
							state.items.splice(indexRequiredItem, 1);
						}
					})
					// Refresh array index after removing
					index = state.items.findIndex((item: CartItem) => item.id == action.payload.id);
				}
				// ^^^ Remove required items ^^^


				if(action.payload.price.length > 1) {
					let periodicTotal = 0;
					let oneTimeTotal = 0;
					action.payload.price.forEach((price) => {
						if(price.chargingType == 'Periodic') {
							periodicTotal = periodicTotal + price.amount;
						} else {
							oneTimeTotal = oneTimeTotal + price.amount;
						}
					});
					state.totalPricePeriodic = state.totalPricePeriodic - (state.items[index].count * periodicTotal*100);
					state.totalPriceOneTime = state.totalPriceOneTime - (state.items[index].count * oneTimeTotal*100);
				} else if(action.payload.isPeriodic) {
					state.totalPricePeriodic = state.totalPricePeriodic - (state.items[index].count * state.items[index].price.amount * 100);
				} else if(!action.payload.isPeriodic) {
					state.totalPriceOneTime = state.totalPriceOneTime - (state.items[index].count * state.items[index].price.amount * 100);
				}
				state.totalCount = state.totalCount - state.items[index].count;
				state.items.splice(index, 1);
			}
			localStorage.setItem(WebshopConstant.LOCALSTORAGE_CART_DATA, JSON.stringify(state));
			return new RemoveCartItemSuccess(action.payload);
		})
	);

	@Effect()
	getCartItems$ = this.actions$.pipe(
		ofType(CartActionsTypes.GetCartItems),
		withLatestFrom(this.store.select(getCart)),
		map(() => {
			let data;
			if(localStorage.getItem(WebshopConstant.LOCALSTORAGE_CART_DATA)) {
				data = JSON.parse(localStorage.getItem(WebshopConstant.LOCALSTORAGE_CART_DATA));
			} else {
				data = emptyCartState;
			}
			return new GetCartItemsSuccess(data);
		})
	);

	@Effect()
	clearCart$ = this.actions$.pipe(
		ofType(CartActionsTypes.ClearCart),
		withLatestFrom(this.store.select(getCart)),
		map(() => {
			localStorage.setItem(WebshopConstant.LOCALSTORAGE_CART_DATA, JSON.stringify(emptyCartState));
			return new ClearCartSuccess(emptyCartState)
		})
	)

	private increaseValue(state, index) {
		state.items[index].count++;
		state.totalPrice = state.totalPrice + (state.items[index].price.amount*100);
		state.totalCount++;
	}

	private showAssociationsModal(data: CartItem) {
		this.shoppingCartModalService.showRequiredModal.next(data);
	}

	private showAddModal(data: CartItem) {
		this.shoppingCartModalService.showAddedToCartModal.next(data);
	}

	private showDuplicatedModal(data: CartItem) {
		this.shoppingCartModalService.showDuplicatedModal.next(data);
	}
}
