import { getLocalePluralCase } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

const PLURAL_CASES = ['zero', 'one', 'two', 'few', 'many', 'other'];

@Injectable({
  providedIn: 'root',
})
export class PluralTranslationService {
  private getCase: (value: number) => number;

  constructor(@Inject(LOCALE_ID) localeId: string) {
    this.getCase = getLocalePluralCase(localeId);
  }

  get(json: string, value: number): string {
    const obj = JSON.parse(json);
    const pluralCase = PLURAL_CASES[this.getCase(value)];
    return obj[pluralCase];
  }
}
