import { Injectable } from '@angular/core';
import { TopMenuItem } from '../../selfcare/components/top-menu/top-menu-item';
import { locales } from 'locales';
import { InvoicesComponent } from '../../selfcare/components/invoices/invoices.component';
import {TopMenuItemsProviderService} from "../top-menu-items-provider.service";
import {AppPath} from "../../utils/app-path";

@Injectable({
	providedIn: 'root',
})
export class XVLOTopMenuItemsProviderService extends TopMenuItemsProviderService {

	getTopMenuItems(): TopMenuItem[] {
		return [{name: locales.menu.dashboard, path: AppPath.PATH_SELFCARE_DASHBOARD, viewKey: null},
			{name: locales.menu.contracts, path: AppPath.PATH_SELFCARE_CONTRACTS, viewKey: null},
			{name: locales.menu.invoices, path: AppPath.PATH_SELFCARE_INVOICES, viewKey: InvoicesComponent.prototype['key']},
			{name: locales.menu.support, path: AppPath.PATH_SELFCARE_SUPPORT, viewKey: null},
		];
	}
}
