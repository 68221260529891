import { NgModule } from '@angular/core';
import { VirtualizationModule } from '../../ui/virtualization/virtualization.module';
import { DropdownDisableKeydownDirective } from './dropdown-disable-keydown.directive';
import { DropdownDirective } from './dropdown.directive';

@NgModule({
	imports: [
		VirtualizationModule,
	],
	declarations: [
		DropdownDirective,
		DropdownDisableKeydownDirective,
	],
	exports: [
		DropdownDirective,
		DropdownDisableKeydownDirective,
	],
})
export class DropdownModule {}
