import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ToastMessage } from '../utils/toast-message';
import { ToastType } from '../utils/toast-type';
import { __ as translate } from '@comarch/seed-core/lib/i18n';

@Injectable({
	providedIn: 'root',
})
export class CDSToastService {

	message: BehaviorSubject<ToastMessage> = new BehaviorSubject<ToastMessage>(null);

	constructor() {}

	showInfo(message: string, title?: string, duration?: number) {
		const toastMessage = this.createToastMessage(title ?? translate('app.toast.info_title'), message, duration, ToastType.INFO);
		this.message.next(toastMessage);
	}

	showSuccess(message: string, title?: string, duration?: number) {
		const toastMessage = this.createToastMessage(title ?? translate('app.toast.success_title'), message, duration, ToastType.SUCCESS);
		this.message.next(toastMessage);
	}

	showWarning(message: string, title?: string, duration?: number) {
		const toastMessage = this.createToastMessage(title ?? translate('app.toast.warning_title'), message, duration, ToastType.WARNING);
		this.message.next(toastMessage);
	}

	showDanger(message: string, title?: string, duration?: number) {
		const toastMessage = this.createToastMessage(title ?? translate('app.toast.danger_title'), message, duration, ToastType.DANGER);
		this.message.next(toastMessage);
	}

	private createToastMessage(title: string, message: string, duration: number, toastType: ToastType) {
		const toastMessage: ToastMessage = {
			title: title,
			message: message,
			duration: duration,
			toastType: toastType,
		};
		return toastMessage;
	}

}
