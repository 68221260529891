import { Pipe, PipeTransform } from '@angular/core';
import { XICTMathUtils } from '../../utils/xict/xict-math-utils';

@Pipe({
	name: 'XICTRoundToFixed',
	pure: true,
})
export class XICTRoundToFixedWithDecimalPlaces implements PipeTransform {
	transform(value: any, ...args: any[]): any {

		let decimalPlaces: number;
		if (args.length == 0) {
			decimalPlaces = 2;
		} else {
			decimalPlaces = args[0];
		}
		return XICTMathUtils.roundToFixedWithDecimalPlaces(value, decimalPlaces);
	}
}
