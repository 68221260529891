import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { config } from 'config';
import { locales } from 'locales';
import { ProfileService } from '../../services/profile.service';
import {SystemVersionService} from "shared/app/index";

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {

	currentDate: Date;
	apiVersion: string;
	title: string;

	constructor(private systemVersionService: SystemVersionService, private profileService: ProfileService) {
		this.currentDate = new Date();
		this.title = locales[config.theme] ? locales[config.theme].rsp_name : 'RSP_NAME';
	}

	ngOnInit() {
		this.systemVersionService.getSystemVersion().pipe(take(1)).subscribe(
				(systemVersion) => {
					this.apiVersion = systemVersion;
				}
		);
	}
}
