export const environment = {
	production: true,
	//@ts-ignore
	api_url: window.$config.apiUrl,
	//@ts-ignore
	selfcare_env: window.$config.selfcareEnv,
	app_profile: 'selfcare',
	app_path: '/',
	auth_path: 'authenticate',
	payments: {
		//@ts-ignore
		useMock: window.$config.useMock,
	},
	brand: {
		//@ts-ignore
		brandCode: window.$config.brandCode,
		//@ts-ignore
		checkBrand: window.$config.checkBrand,
	},
	//@ts-ignore
	httpInterceptorLoggingEnabled: window.$config.httpInterceptorLoggingEnabled,
	keycloak: {
		//@ts-ignore
		enabled: window.$config.keycloakEnabled,
		//@ts-ignore
		url: window.$config.keycloakUrl,
		//@ts-ignore
		realm: window.$config.keycloakRealm,
		//@ts-ignore
		clientId: window.$config.keycloakClientId,
		//@ts-ignore
		silentCheckSsoEnabled: window.$config.keycloakSilentCheckSsoEnabled,
		//@ts-ignore
		loadUserProfileAtStartUp: window.$config.keycloakLoadUserProfileAtStartUp,
	},
	//@ts-ignore
	box_feature: (window.$config.boxFeature == 'true'),
	//@ts-ignore
	webshop: (window.$config.webshop == 'true'),
	//@ts-ignore
	webshop_after_login: (window.$config.webshopAfterLogin == 'true'),
	//@ts-ignore
	webshop_logged_user_offer: (window.$config.webshopLoggedUserOffer == 'true'),
	//@ts-ignore
	webshop_serviceability_check: (window.$config.webshopServiceabilityCheck == 'true'),
	//@ts-ignore
	webshop_url: window.$config.webshopUrl,
	//@ts-ignore
	hide_breadcrumb: window.$config.hide_breadcrumb,
	recaptcha: {
		//@ts-ignore
		siteKey: window.$config.reCaptchaSiteKey,
		//@ts-ignore
		enabled: window.$config.reCaptchaEnabled,
	},
	token: {
		//@ts-ignore
		refreshEnabled: window.$config.tokenRefreshEnabled,
		//@ts-ignore
		refreshPeriodMinutes: window.$config.tokenRefreshPeriodMinutes,
		//@ts-ignore
		validForMinutes: window.$config.tokenValidForMinutes,
	},
	//@ts-ignore
	google_analytics_track_id: window.$config.googleAnalyticsTrackId,
	grace_period_length: 3,
	numberOfDaysBeforeSubscriptionExpiresAndReminderAppears: 7,
	minimalNumberOfDaysBeforeSubscriptionUpgrade: 3,
	maximumNumberOfLicense: 50,
	navigationLinks: {
		//@ts-ignore
		enabled: window.$config.navigationLinksEnabled,
		//@ts-ignore
		value: window.$config.navigationLinksValue,
	},
};
