import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {Banner} from "../models/banner";
import {Observable} from "rxjs";

@Injectable({
	providedIn: 'root',
})
export class BannerService {
	constructor(protected apiService: ApiService) { }

	getActiveBannersForUser(userId: number, customerId: number): Observable<Banner[]> {
		return this.apiService.get('/banners/' + userId + '/' + customerId);
	}

	invalidateBannerForUser(userId: number, bannerId: number): Observable<any> {
		return this.apiService.post('/banners/' + bannerId + '/' + userId);
	}
}
