import {Component, OnInit} from '@angular/core';
import {AppPath} from "../../utils/app-path";
import {AppPathService} from "../../services/app-path.service";

@Component({
	selector: 'app-connection-refused',
	templateUrl: './connection-refused.component.html',
})
export class ConnectionRefusedComponent implements OnInit {

	constructor(private pathService: AppPathService) {
	}

	ngOnInit() {
	}

	home() {
		this.pathService.navigate([AppPath.PATH_HOME]);
	}
}
