export enum STATE_CONST {
	OST_FAILED = 'OST_FAILED',
	OST_OPENED = 'OST_OPENED',
	OST_PAID = 'OST_PAID',
	OST_ONLINE_PAID = 'OST_ONLINE_PAID',
	OST_INACTIVE = 'OST_INACTIVE',
	OST_ENABLED = 'OST_ENABLED',
	OST_ACCEPTED = 'OST_ACCEPTED',
	OST_CREATED = 'OST_CREATED',
	OST_ISSUED = 'OST_ISSUED',
	OST_PENDING = 'OST_PENDING',
	OST_CANCELLED = 'OST_CANCELLED',
	OST_CLOSED = 'OST_CLOSED',
	OST_DELETED = 'OST_DELETED',
	OST_REJECTED = 'OST_REJECTED',
	OST_PARTIALLY_PAID = 'OST_PARTIALLY_PAID',
	OST_WAIT_FOR_PAYMENT = 'OST_WAIT_FOR_PAYMENT',
	OST_PROCESSED = 'OST_PROCESSED',
	OST_MATCHED_EQUAL = 'OST_MATCHED_EQUAL',
	OST_MATCHED_OVERPAYMENT = 'OST_MATCHED_OVERPAYMENT',
	OST_MATCHED_UNDERPAYMENT = 'OST_MATCHED_UNDERPAYMENT',
	OST_DUPLICATED = 'OST_DUPLICATED',
	OST_SPLITTED = 'OST_SPLITTED',
	OST_BALANCED = 'OST_BALANCED',
	OST_OUTGOING_PENDING = 'OST_OUTGOING_PENDING',
	OST_DISABLED = 'OST_DISABLED',
	OST_REVIEW = 'OST_REVIEW',
	OST_SUBSCRIPTION_MODE_ACTIVE = 'A',
	OST_SUBSCRIPTION_MODE_INACTIVE = 'I',
	OST_SUBSCRIPTION_MODE_SWITCH_IN_PROGRESS = 'W',
	OST_SUBSCRIPTION_MODE_RENEWAL_IN_PROGRESS = 'E',
	OST_SUBSCRIPTION_MODE_WAITING_FOR_ACTIVATION = 'N',
	OST_RESOLVED = 'OST_RESOLVED',
	OST_PROCESSING = 'OST_PROCESSING',
	OST_RETURNED = 'OST_RETURNED',
	OST_LOCKED = 'OST_LOCKED',
	OST_ACTIVE = 'OST_ACTIVE'
}

