import { NgModule } from '@angular/core';
import { FileDialogComponent } from './file-dialog.component';

@NgModule({
	declarations: [
		FileDialogComponent,
	],
	exports: [
		FileDialogComponent,
	],
})
export class FileDialogModule {}
