import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import {FirstPasswordRequest, PasswordChangeRequest, UserService, RequestUtil, ApiService} from "shared/app/index";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable({
	providedIn: 'root',
})
export class XVLOUserService extends UserService {

	constructor(protected http: HttpClient, protected apiService: ApiService, protected requestUtil: RequestUtil) {
		super(environment, http, apiService, requestUtil);
	}

	changePassword(userId: number, passwordChangeRequestBody: PasswordChangeRequest): Observable<string> {
		const headers = this.requestUtil.injectHeaders();
		headers.append('Content-Type', 'application/json');
		return this.apiService.put('/vlo/users/' + userId + '/password', passwordChangeRequestBody, {
				headers: headers,
				responseType: 'text',
			}
		);
	}

	resetPassword(userId: number, passwordChangeRequestBody: PasswordChangeRequest): Observable<string> {
		const headers = this.requestUtil.injectHeaders();
		headers.append('Content-Type', 'application/json');
		return this.apiService.post('/vlo/users/' + userId + '/reset-password', passwordChangeRequestBody, {
			headers: headers,
			responseType: 'text',
		});
	}

	validateToken(token: string): Observable<boolean> {
		const path = `/v2/users/tokens/${token}/verification`;
		return this.apiService.get(path);
	}

	getToken(token: string): Observable<any> {
		const path = '/v2/users/tokens/' + token;
		return this.apiService.get(path, {
			headers: this.requestUtil.injectHeaders()
		});
	}
}
