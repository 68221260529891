import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {BoxConfigurationService} from "../services/box-configuration.service";
import {CustomBrandConfigurationService} from '../services/custom-brand-configuration.service';

@Directive({
	selector: '[boxCustomizableElement]'
})
export class BoxCustomizableElementDirective implements OnInit{

	@Input() boxCustomizableElement: BoxElementType = null;

	@HostListener('mouseover') onMouseOver() {
		if(this.boxCustomizableElement && this.customBrandConfigurationService.checkApplicationBranding()) {
			this.setElementStyleOnHover(this.boxCustomizableElement);
		}
	}

	@HostListener('mouseleave') onMouseLeave() {
		if(this.boxCustomizableElement && this.customBrandConfigurationService.checkApplicationBranding()) {
			this.setElementStyle(this.boxCustomizableElement);
		}
	}

	constructor(private el: ElementRef, private boxConfigurationService: BoxConfigurationService, private customBrandConfigurationService: CustomBrandConfigurationService) {

	}

	ngOnInit() {
		if(this.boxCustomizableElement && this.customBrandConfigurationService.checkApplicationBranding()) {
			const elementTypes = this.boxCustomizableElement.split(' ');
			elementTypes.forEach(elementType => this.setElementStyle(elementType));
		}
	}

	private setElementStyle(type: string) {
		if(type == BoxElementType.LOGO) {
			this.setLogo(this.el);
		} else if(type == BoxElementType.TOP_MENU_ITEM) {
			this.setTopMenuItemStyle(this.el);
		} else if(type == BoxElementType.BUTTON_PRIMARY) {
			this.setButtonPrimaryStyle(this.el);
		} else if(type == BoxElementType.BUTTON_SECONDARY) {
			this.setButtonSecondaryStyle(this.el);
		} else if(type == BoxElementType.BUTTON_TEXT) {
			this.setButtonTextStyle(this.el);
		} else if(type == BoxElementType.TEXT) {
			this.setTextColorStyle(this.el);
		} else if(type == BoxElementType.BACKGROUND) {
			this.setBackgroundColorStyle(this.el);
		} else if(type == BoxElementType.BORDER) {
			this.setBorderColorStyle(this.el);
		}
	}

	private setElementStyleOnHover(type: string) {
		if(type == BoxElementType.BUTTON_PRIMARY) {
			this.setButtonPrimaryStyleHover(this.el);
		} else if(type == BoxElementType.BUTTON_SECONDARY) {
			this.setButtonSecondaryStyleHover(this.el);
		} else if(type == BoxElementType.TOP_MENU_ITEM) {
			this.setTopMenuItemStyleHover(this.el);
		}
	}

	private setLogo(el: ElementRef) {
		if(this.boxConfigurationService.getLogoUrl()) {
			el.nativeElement.style.width = '125px';
			el.nativeElement.style.height = '46px';
			el.nativeElement.style.background = `url(${this.boxConfigurationService.getLogoUrl()}) no-repeat left`
			el.nativeElement.style.backgroundSize = '85% 100%';
			el.nativeElement.style.marginTop = '-8px';
			el.nativeElement.style.marginBottom = '0';
		}
	}

	private setTopMenuItemStyle(el: ElementRef) {
		el.nativeElement.style.color = "#000000"
	}

	private setTopMenuItemStyleHover(el: ElementRef) {
		el.nativeElement.style.color = this.boxConfigurationService.getPrimaryColor();
	}

	private setButtonPrimaryStyle(el: ElementRef) {
		el.nativeElement.setAttribute("style", "filter: brightness(1)");
		el.nativeElement.style.backgroundColor = this.boxConfigurationService.getPrimaryColor();
	}

	private setButtonPrimaryStyleHover(el: ElementRef) {
		el.nativeElement.setAttribute("style", "filter: brightness(1.2)");
		el.nativeElement.style.backgroundColor = this.boxConfigurationService.getPrimaryColor();
	}

	private setButtonSecondaryStyle(el: ElementRef) {
		el.nativeElement.setAttribute("style", "filter: brightness(1)");
		el.nativeElement.style.backgroundColor = "#fffff";
		el.nativeElement.style.borderColor = this.boxConfigurationService.getPrimaryColor();
		el.nativeElement.style.color = this.boxConfigurationService.getPrimaryColor();
	}

	private setButtonSecondaryStyleHover(el: ElementRef) {
		el.nativeElement.setAttribute("style", "filter: brightness(1.2)");
		el.nativeElement.style.backgroundColor = "#fffff";
		el.nativeElement.style.borderColor = this.boxConfigurationService.getPrimaryColor();
		el.nativeElement.style.color = this.boxConfigurationService.getPrimaryColor();
	}

	private setButtonTextStyle(el: ElementRef) {
		el.nativeElement.style.color = this.boxConfigurationService.getPrimaryColor();
	}

	private setTextColorStyle(el: ElementRef) {
		el.nativeElement.style.color = this.boxConfigurationService.getPrimaryColor();
	}

	private setBackgroundColorStyle(el: ElementRef) {
		el.nativeElement.style.background = this.boxConfigurationService.getPrimaryColor();
	}

	private setBorderColorStyle(el: ElementRef) {
		el.nativeElement.style.borderColor = this.boxConfigurationService.getPrimaryColor();
	}
}

export enum BoxElementType {
	LOGO = "logo",
	TOP_MENU_ITEM = "topMenuItem",
	BUTTON_PRIMARY = "buttonPrimary",
	BUTTON_SECONDARY = "buttonSecondary",
	BUTTON_TEXT = "buttonText",
	TEXT = "textColor",
	BACKGROUND = "backgroundColor",
	BORDER = "borderColor"
}
