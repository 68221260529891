<div *caCustomizationHide="[profile.XEHC]">
	<div *ngIf="formUtils.shouldShowErrors(control, passwordForm)" class="text-muted">
		<div *ngFor="let error of getUpdatedPasswordRules()">
			<div [class.text-success]="error.state === 'success'">
				<i class="cds-icon-info-circle cds-icon--size-24 pr-3"
				   [class.cds-icon-tick-circle]="error.state === 'success'">
				</i>
				<span class="fs-5">{{error.rule}}</span>
			</div>
		</div>
	</div>
</div>
<div *caCustomizationShow="[profile.XEHC]">
	<div class="text-muted">
		<div *ngFor="let error of getUpdatedPasswordRules()">
			<div class="pt-2">
				<img *ngIf="error.state ==='success'" src="img/success-checkmark.png" style="width: 24px">
				<span class="mr-3 ml-3" *ngIf="error.state !='success'">&bull;</span>
				<span [ngStyle]="{'color': error.state ==='success' ? 'black' : null}" class="fs-5">{{error.rule}}</span>
			</div>
		</div>
	</div>
</div>
