import { NgModule } from '@angular/core';
import { TopMenuItemsProviderService } from '../../../services/top-menu-items-provider.service';
import {XVLOTopMenuItemsProviderService} from "../../../services/xvlo/xvlo-top-menu-items-provider.service";
import {XVLOUserService} from "../../../services/xvlo/xvlo-user.service";
import {XVLOInvoicesService} from "../../../services/xvlo/xvlo-invoices.service";
import {InvoicesService, UserService} from "shared/app/index";

/*	### EXAMPLE
**	providers: [
**		{ provide: CoreService, useClass: OverridingService}
**	]
 */
@NgModule({
	providers: [
		{provide: TopMenuItemsProviderService, useClass: XVLOTopMenuItemsProviderService},
		{provide: UserService, useClass: XVLOUserService},
		{provide: InvoicesService, useClass: XVLOInvoicesService}
	],
})
export class ProvidersModule {

}
