export class States {
	public static OST_FAILED = 'OST_FAILED';
	public static OST_OPENED = 'OST_OPENED';
	public static OST_PAID = 'OST_PAID';
	public static OST_ONLINE_PAID = 'OST_ONLINE_PAID';
	public static OST_INACTIVE = 'OST_INACTIVE';
	public static OST_ENABLED = 'OST_ENABLED';
	public static OST_ACCEPTED = 'OST_ACCEPTED';
	public static OST_CREATED = 'OST_CREATED';
	public static OST_ISSUED = 'OST_ISSUED';
	public static OST_PENDING = 'OST_PENDING';
	public static OST_CANCELLED = 'OST_CANCELLED';
	public static OST_CLOSED = 'OST_CLOSED';
	public static OST_DELETED = 'OST_DELETED';
	public static OST_REJECTED = 'OST_REJECTED';
	public static OST_PARTIALLY_PAID = 'OST_PARTIALLY_PAID';
	public static OST_WAIT_FOR_PAYMENT = 'OST_WAIT_FOR_PAYMENT';
	public static OST_PROCESSED = 'OST_PROCESSED';
	public static OST_MATCHED_EQUAL = 'OST_MATCHED_EQUAL'
	public static OST_MATCHED_OVERPAYMENT = 'OST_MATCHED_OVERPAYMENT'
	public static OST_MATCHED_UNDERPAYMENT = 'OST_MATCHED_UNDERPAYMENT'
	public static OST_DUPLICATED = 'OST_DUPLICATED'
	public static OST_SPLITTED = 'OST_SPLITTED'
	public static OST_BALANCED = 'OST_BALANCED'
	public static OST_OUTGOING_PENDING = 'OST_OUTGOING_PENDING'
	public static OST_DISABLED = 'OST_DISABLED';
	public static OST_REVIEW = 'OST_REVIEW';

	public static OST_SUBSCRIPTION_MODE_ACTIVE = 'A';
	public static OST_SUBSCRIPTION_MODE_INACTIVE = 'I';
	public static OST_SUBSCRIPTION_MODE_SWITCH_IN_PROGRESS = 'W';
	public static OST_SUBSCRIPTION_MODE_RENEWAL_IN_PROGRESS = 'E';
	public static OST_SUBSCRIPTION_MODE_WAITING_FOR_ACTIVATION = 'N';
	public static OST_RESOLVED = 'OST_RESOLVED';
	public static OST_PROCESSING = 'OST_PROCESSING';
	public static OST_RETURNED = 'OST_RETURNED';
	public static OST_LOCKED = 'OST_LOCKED';
	public static OST_ACTIVE = 'OST_ACTIVE';

	public static OST_BOOKED = 'OST_BOOKED';
	public static OST_FULFILLED = 'OST_FULFILLED';
	public static OST_FULFILLING = 'OST_FULFILLING';

	public static ACTIVE = 'ACTIVE'
	public static SHUTOFF = 'SHUTOFF'
	public static ERROR = 'ERROR'
	public static MACHINE_STATUS_CREATING = "BUILDING";
	public static MACHINE_STATUS_SHUTTING_OFF = "POWERING OFF";
	public static MACHINE_STATUS_TURNING_ON = "POWERING ON";
	public static MACHINE_STATUS_REBOOTING = "RESTARTING";
	public static MACHINE_STATUS_DELETING = "DELETING";
	public static MACHINE_STATUS_INITIALIZING = "INITIALIZING";
	public static MACHINE_STATUS_MODIFYING = "MODIFYING";
	public static MACHINE_STATUS_RESIZING = "RESIZE";
	public static MACHINE_STATUS_RENAMING = "RENAME";
	public static MACHINE_STATUS_DELETING_ERROR = "ERROR WHILE DELETING";
	public static MACHINE_STATUS_NETWORK_ADDING = "CONNECTING";
	public static MACHINE_STATUS_ACTIVATING_DST = "Activating DST";
	public static VERIFY_RESIZE = "VERIFY_RESIZE";
}
