import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'XICTOperatingSystemPipe',
	pure: true,
})
export class XICTOperatingSystemPipe implements PipeTransform {
	transform(value: any, ...args: any[]): any {
		if (!value) {
			return value;
		} else if (value.toLowerCase() === 'ibmi') {
			return 'IBM i';
		} else if (value.toLowerCase() === 'aix') {
			return value.toUpperCase();
		} else {
			return value.toUpperCase();
		}

	}
}
