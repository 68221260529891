import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RateComponent } from './rate.component';

@NgModule({
	imports: [
		CommonModule,
	],
	declarations: [
		RateComponent,
	],
	exports: [
		RateComponent,
	],
})
export class RateModule {}
