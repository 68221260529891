<div>
	<div class="w-100 mt-4" *ngFor="let regulation of paymentRegulations">
		<div class="d-flex">
			<cds-checkbox [(ngModel)]="regulation.checked" (click)="validate()"></cds-checkbox>
			<span class="ml-4 pr-0"  [innerHTML]="getRegulationHtml(regulation)"></span>
		</div>
		<div *ngIf="!regulation.valid" class="cds-errors">This field is required</div>
	</div>

</div>
