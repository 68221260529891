import {
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
	Directive,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	ViewContainerRef
} from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, timer } from 'rxjs';
import { debounce, filter } from 'rxjs/operators';
import { VirtualizationContainerDirective } from './virtualization-container.directive';
import { VirtualizationLoaderComponent } from './virtualization-loader.component';

@Directive({
	selector: '[caVirtualizationList]',
	exportAs: 'caVirtualizationList',
})
export class VirtualizationListDirective implements OnInit, OnDestroy {

	private loaderComponentRef: ComponentRef<any>;
	private loaderComponentFactory: ComponentFactory<any>;
	private nativeElement: HTMLElement;
	private fullList: any[];
	private scrollSubscription: Subscription = Subscription.EMPTY;
	private scrollSubject$: Subject<UIEvent> = new Subject<UIEvent>();
	private slicedListSubject$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	readonly list$: Observable<any[]>;

	constructor(
			elementRef: ElementRef,
			private vcRef: ViewContainerRef,
			private container: VirtualizationContainerDirective,
			private componentFactoryResolver: ComponentFactoryResolver) {

		this.nativeElement = <HTMLElement> elementRef.nativeElement;
		this.list$ = this.slicedListSubject$.asObservable();
	}

	@Input()
	set caVirtualizationList(list: any[]) {
		if (!list) {
			this.fullList = [];
			this.slicedListSubject$.next([]);
			this.setLoadedAll(true);
		} else {
			this.fullList = list;
			const fullListLength: number = list.length;
			if (fullListLength > this.virtualizationStep) {
				this.slicedListSubject$.next(list.slice(0, this.virtualizationStep));
				this.setLoadedAll(false);
			} else {
				this.slicedListSubject$.next(list);
				this.setLoadedAll(true);
			}
		}
	}

	@Input()
	virtualizationStep: number = 20;

	ngOnInit(): void {
		this.initLoaderComponent();
		this.scrollSubject$.pipe(
			filter((event: UIEvent) => {
				const containerElement: HTMLElement = <HTMLElement> event.target;
				const listTop: number = this.nativeElement.offsetTop - containerElement.offsetTop;
				const listBottom: number = listTop + this.nativeElement.offsetHeight;
				const scrollPosition: number = containerElement.scrollTop + containerElement.clientHeight;
				return scrollPosition > listBottom - 200;
			})
		)
		.subscribe(() => {
			if (this.fullList && this.virtualizationStep !== null) {
				const fullListLength: number = this.fullList.length;
				const slicedLength: number = this.slicedListSubject$.getValue().length;
				if (fullListLength > slicedLength) {
					const newLength: number = Math.min(slicedLength + this.virtualizationStep, fullListLength);

					this.slicedListSubject$.next(this.fullList.slice(0, newLength));
					this.setLoadedAll(newLength === fullListLength);
				}
			}
		});
	}

	ngOnDestroy(): void {
		this.scrollSubscription.unsubscribe();
		this.scrollSubject$.complete();
		this.scrollSubject$.unsubscribe();
	}

	private setLoadedAll(loadedAll: boolean): void {
		if (loadedAll) {
			if (this.loaderComponentRef) {
				this.scrollSubscription.unsubscribe();
				this.loaderComponentRef.destroy();
				this.loaderComponentRef = null;
			}
		} else {
			if (!this.loaderComponentRef) {
				this.initLoaderComponent();
				this.loaderComponentRef = this.vcRef.createComponent(this.loaderComponentFactory);
				this.scrollSubscription = this.container.scroll$.pipe(
					debounce(() => timer(100))
				).subscribe(this.scrollSubject$);
			}
		}
	}

	private initLoaderComponent(): void {
		if (!this.loaderComponentFactory) {
			this.loaderComponentFactory = this.componentFactoryResolver.resolveComponentFactory(VirtualizationLoaderComponent);
		}
	}
}
