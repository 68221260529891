export class FileUtils {
	public static initiateFileDownload(fileContent: any, fileName: string, mediaType: string) {
		const blob = new Blob([fileContent], {type: mediaType});
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(blob, fileName + this.getFileExtension(mediaType));
			return;
		}
		const file = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = file;
		link.download = fileName;
		// this is necessary as link.click() does not work on the latest firefox
		link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
		setTimeout(function () {
			// For Firefox it is necessary to delay revoking the ObjectURL
			window.URL.revokeObjectURL(file);
		}, 100);
	}

	/**
	 * Transform between units
	 * @param currentMultiple index of current multiple or code
	 * 	e.g bytes is 0, kilobytes is 1, etc.
	 *
	 * 	Mapping is 0 = 'B', 1 = 'KB', 2 = 'MB', 3 = 'GB', 4 = 'TB', 5 = 'PB', 6 = 'EB', 7 = 'ZB'
	 */
	public static fileSizeTransform(value: number, currentMultiple: number | string = 0): { unit: string; value: number } {
		const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB'];
		let currentMultipleIndex;
		if (typeof currentMultiple === 'string') {
			currentMultipleIndex = units.indexOf(currentMultiple);
		} else {
			currentMultipleIndex = currentMultiple
		}

		if (currentMultipleIndex >= units.length) {
			throw new Error('Current multiple index is out of range. Max value is ' + (units.length - 1));
		}
		if (value == 0) return {value: 0, unit: units[currentMultipleIndex]};
		const i = Math.floor(Math.log(value) / Math.log(1024));
		if (i === 0) return {value: value, unit: units[i + currentMultipleIndex]};

		const result = value / (1024 ** i);
		let fraction = 0;
		if (result < 10) {
			fraction = 2;
		} else if (result < 100) {
			fraction = 1;
		}
		return {value: parseFloat((result).toFixed(fraction)), unit: units[i + currentMultipleIndex]};
	}

	private static getFileExtension(mimetype: string) {
		let text;
		switch (mimetype) {
			case "application/pdf":
				text = ".pdf";
				break;
			case "image/bmp":
				text = ".bmp";
				break;
			case "image/gif":
				text = ".gif";
				break;
			case "image/jpeg":
				text = ".jpeg";
				break;
			case "image/jpg":
				text = ".jpg";
				break;
			case "image/pjpeg":
				text = ".pjpeg";
				break;
			case "image/png":
				text = ".png";
				break;
			case "image/tiff":
				text = ".tiff";
				break;
			case "application/vnd.ms-outlook":
				text = ".msg";
				break;
			case "application/x-filenet-filetype-msg":
				text = ".msg";
				break;
			case "message/rfc822":
				text = ".eml";
				break;
			case "application/vnd.ms-excel":
				text = ".xls";
				break;
			case "application/vnd.ms-powerpoint":
				text = ".ppt";
				break;
			case "application/msword":
				text = ".doc";
				break;
			case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
				text = ".pptx";
				break;
			case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
				text = ".xlsx";
				break;
			case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
				text = ".docx";
				break;
			case "application/octet-stream":
				text = "";
				break;

			default:
				text = "";
		}
		return text;
	}

	public static getFileName(filePath: string) {
		return filePath ? filePath.substring(filePath.lastIndexOf('/') + 1) : null;
		// return filePath ? filePath.replace(/^.*[\\\/]/, '') : null;
		// return filePath ? filePath.split('/').pop() : null;
	}
}
