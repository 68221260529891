import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {

	constructor(private apiService: ApiService) { }

	getCustomerNotifications(page: number, size: number, deliveryChannel: string, isRead: boolean, from: Date = this.getFromDate(new Date())): Observable<any> {

		const date = new Date();

		const params = new HttpParams()
		.set('page', page.toString())
		.set('size', size.toString())
		.set('to', date.toISOString())
		.set('from', from.toISOString())
		.set('deliveryChannel', deliveryChannel)
		.set('isRead', String(isRead));

		return this.apiService.get('/notifications', {params: params});
	}

	getNotificationDetails(id: number): Observable<Notification> {
		return this.apiService.get('/notifications/' + id);
	}

	getCustomerNotificationCount(): Observable<any> {

		const date = new Date();

		const params = new HttpParams().set('to', date.toISOString()).set('from', this.getFromDate(date).toISOString());
		return this.apiService.get('/notifications/count', {params: params});
	}

	markAsRead(id: string) {
		const headers = new HttpHeaders({'Content-Type': 'application/json'});
		return this.apiService.post('/notifications/read/' + id, {}, {headers: headers, responseType: 'text'});
	}

	markAllAsRead() {

		const date = new Date();

		const params = new HttpParams().set('to', date.toISOString()).set('from', this.getFromDate(date).toISOString());
		const headers = new HttpHeaders({'Content-Type': 'application/json'});

		return this.apiService.post('/notifications/read', {}, {params: params, headers: headers, responseType: 'text'});
	}

	getFromDate(date: Date) {
		date.setMonth(date.getMonth() - 3);
		return date;
	}

	getNumberOfUnreadNotifications() : Observable<any> {
		return this.apiService.get('/notifications/status');
	}

	delete(id: string) {
		const headers = new HttpHeaders({'Content-Type': 'application/json'});
		return this.apiService.post('/notifications/delete/' + id, {}, {headers: headers, responseType: 'text'});
	}

	deleteAll() {
		const date = new Date();
		const params = new HttpParams().set('to', date.toISOString()).set('from', this.getFromDate(date).toISOString());
		const headers = new HttpHeaders({'Content-Type': 'application/json'});

		return this.apiService.post('/notifications/delete', {}, {params: params, headers: headers, responseType: 'text'});
	}
}
