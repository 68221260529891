import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {CartItem} from "../../models/cart-item";

@Injectable({
	providedIn: 'root',
})
export class CartModalService  {

	showRequiredModal: BehaviorSubject<CartItem> = new BehaviorSubject<CartItem>(null);
	showDuplicatedModal: BehaviorSubject<CartItem> = new BehaviorSubject<CartItem>(null);
	showAddedToCartModal: BehaviorSubject<CartItem> = new BehaviorSubject<CartItem>(null);

	showRemoveRequiredModal: BehaviorSubject<CartItem> = new BehaviorSubject<CartItem>(null);

	showNotSupportedLocationModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

}
