
	<cds-date-form class="w-100"
				   [(ngModel)]="inputValue"
				   format="yyyy-MM-dd"
				   placeholder="yyyy-mm-dd"
				   label="Date"
				   [dateRequired]="true"
				   [from]="currentDate"
				   [showInputs]="true"
				   (ngModelChange)="changeModelHandler()"></cds-date-form>
