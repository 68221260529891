import {
	Directive,
	ElementRef,
	Input,
	EventEmitter,
	HostListener,
	HostBinding,
	Output,
	Renderer2
} from '@angular/core';
import { isHTMLElement } from '../../utils/facade';

const KEY_CODE_DOWN: number = 40;
const KEY_CODE_UP: number = 38;
const KEY_CODE_ENTER: number = 13;
const KEY_CODE_ESC: number = 27;

@Directive({
	selector: '[caDropdown]',
})
export class DropdownDirective  {

	private element?: HTMLElement;

	constructor(elementRef: ElementRef, renderer: Renderer2) {
		const element: any = elementRef.nativeElement;
		renderer.addClass(element, 'ca-dropdown');
		if (isHTMLElement(element)) {
			this.element = element;
		}
	}

	@Input()
	closeOnEscape: boolean = true;

	@Input()
	closeOnDocumentClick: boolean = true;

	@Input()
	focusActive: boolean = false;

	@HostBinding('class.open')
	isOpen: boolean = false;

	@Input('caDropdown')
	set dropdownOpened(isOpen: boolean) {
		if (isOpen) {
			this.isOpenAfterKeyDown = this.isKeyDown;
			if (this.focusActive) {
				const currentElement: HTMLElement = <HTMLElement> this.element.querySelector('.dropdown-menu li.active');
				if (currentElement) {
					setTimeout(() => currentElement.focus());
				}
			} else {
				const scrollableElement: HTMLElement = <HTMLElement> this.element.querySelector('.dropdown-menu');
				if (scrollableElement) {
					setTimeout(() => scrollableElement.scrollTop = 0);
				}
			}
		}
		this.isKeyDown = false;
		this.isOpen = isOpen;
	}

	get dropdownOpened(): boolean {
		return this.isOpen;
	}

	@Output('caDropdownChange')
	isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output()
	dropdownEscape: EventEmitter<void> = new EventEmitter<void>();

	private isKeyDown: boolean = false;
	private isOpenAfterKeyDown: boolean = false;

	@HostListener('document:mouseup', ['$event'])
	@HostListener('document:touchstart', ['$event'])
	onDocumentClick(event: MouseEvent|TouchEvent): void {
		if (this.isOpen && this.closeOnDocumentClick) {
			if (this.element && !this.element.contains(<Node> event.target)) {
				this.isOpen = false;
				this.isOpenChange.emit(false);
			}
		}
	}

	@HostListener('keydown.enter')
	selfKeyUp() {
		this.isKeyDown = true;
	}

	@HostListener('document:keyup', ['$event'])
	onKeyUp(event: KeyboardEvent): boolean {

		const prevent: boolean = this.isOpenAfterKeyDown;
		this.isKeyDown = false;
		this.isOpenAfterKeyDown = false;
		if (prevent) {
			return false;
		}

		const keyCode = event.keyCode;

		if (keyCode === KEY_CODE_ESC && this.closeOnEscape) {
			event.preventDefault();
			event.stopPropagation();
			if (this.isOpen) {
				this.isOpen = false;
				this.isOpenChange.emit(false);
				this.dropdownEscape.emit();
			}
		}

		if ([KEY_CODE_DOWN, KEY_CODE_UP, KEY_CODE_ENTER].indexOf(keyCode) === -1) {
			return false;
		}

		event.preventDefault();
		event.stopPropagation();

		let currentElement: HTMLElement = <HTMLElement> this.element.querySelector('.dropdown-menu li:focus');

		if (keyCode === KEY_CODE_ENTER) {
			if (currentElement) {
				currentElement.click();
				return true;
			}
		}

		if (!currentElement) {
			currentElement = <HTMLElement> this.element.querySelector('.dropdown-menu li[tabindex]');
		} else {
			if (keyCode === KEY_CODE_DOWN) {
				do {
					currentElement = <HTMLElement> currentElement.nextElementSibling;
				} while (currentElement && !currentElement.hasAttribute('tabindex'));
			} else if (keyCode === KEY_CODE_UP) {
				do {
					currentElement = <HTMLElement> currentElement.previousElementSibling;
				} while (currentElement && !currentElement.hasAttribute('tabindex'));
			}
		}

		if (currentElement) {
			currentElement.focus();
		}

		return true;
	}
}
