<div *ngIf="type === 'large' || type === 'largeCustomContent'" class="row">
	<div class="col-12 page-title__container d-flex">
		<button cdsButton class="cds-button-icon mr-2" (click)="onButtonClick(pathBack)"><span class="icon cds-button-icon icon-2x icon-back"></span></button>
		<ng-container>
			<p *ngIf="type === 'large'" (click)="onButtonClick(pathBack)" class="pointer page-title__text m-0">{{label}}</p>
			<div *ngIf="type === 'largeCustomContent'" (click)="onButtonClick(pathBack)" class="pointer d-inline-block">
				<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
			</div>
		</ng-container>
	</div>
</div>
<div *ngIf="type === 'small' || type === 'smallCustomContent'" class="row">
	<div class="col-12">
		<button (click)="onButtonClick(pathBack)" *ngIf="type === 'small'" cdsButton class="cds-button-text cds-button--with-left-icon pr-4">
			<i class="mr-2" [ngClass]="useArrowClass ? 'icon-ca-arrow-left' : 'cds-icon-angle-left move-icon-left '"></i>
			{{label}}
		</button>
		<div *ngIf="type === 'smallCustomContent'" (click)="onButtonClick(pathBack)" class="pointer d-inline-block">
			<ng-container *ngTemplateOutlet="contentTpl"></ng-container>
		</div>
	</div>
</div>
<ng-template #contentTpl>
	<ng-content></ng-content>
</ng-template>
