<div class="row subscription__body">
	<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

		<div class="row align-items-center p-4 subscription__header">
			<div class="col-xl-1 col-lg-1 col-md-1 col-sm-2 d-none d-sm-block">
				<div class="subscription__title--icon icon-devices_other float-left"></div>
			</div>
			<div class="col-xl-7 col-lg-7 col-md-6 col-sm-5 col-12 font-weight-bold mb-2" tabindex="0">
				<div class="subscriptions__expand-title font-weight-normal">Product name</div>
				{{subscription.productOfferingName}}
			</div>
			<div
				class="col-xl-4 col-lg-4 col-md-5 col-sm-5 col-12 text-left text-sm-right text-md-right text-lg-right text-xl-right"
				title="Status">
				<button cdsbutton class="cds-button-primary cds-button-small mr-4"
						(click)="renewSubscription(subscription.objectId)"
						(keydown.enter)="renewSubscription(subscription.objectId)"
						*ngIf="bindingPeriodInfo && bindingPeriodInfo.isRenewalAvailable
						&& checkSubscriptionIsActive(subscription.subscriptionMode)
						&& subscription.pendingTransitionId == null"
						[disabled]="isModalLoading">
					Renew subscription
				</button>
				<button cdsbutton class="cds-button-primary cds-button-small mr-4"
						(click)="getTransitions(subscription.objectId)"
						(keydown.enter)="getTransitions(subscription.objectId)"
						*ngIf="isRenewPrivilegeEnable && isSwitchPrivilegeEnable && checkSubscriptionIsActive(subscription.subscriptionMode)"
						[disabled]="isModalLoading">
					Change plan
				</button>
			</div>
		</div>

		<!-- Loading -->
		<div class="row align-items-center p-4" *ngIf="isBindingPeriodLoading">
			<cds-spinner></cds-spinner>
		</div>

		<div class="row align-items-center p-4 subscription__binding-period-wrapper" *ngIf="!isBindingPeriodLoading">

			<div class="offset-xl-1 col-xl-2 col-lg-3 col-md-3 col-sm-3 col-6 mb-2" tabindex="0">
				<div class="subscriptions__expand-title">Status</div>
				<div class="subscriptions__expand-value">
					<state [stateInput]="subscription.subscriptionMode"></state>
				</div>
			</div>
			<ng-container *ngIf="subscription.monthlyNetPrice != null; else oneTimePrice">
				<div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-6 mb-2" tabindex="0">
					<div class="subscriptions__expand-title">Monthly price (Net)</div>
					<div
						class="subscriptions__expand-value">{{ subscription.monthlyNetPrice | currencyProfile  | notEmpty}}</div>
				</div>
			</ng-container>
			<ng-template #oneTimePrice>
				<div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-6 mb-2" tabindex="0">
					<div class="subscriptions__expand-title">One-time fee (Net)</div>
					<div
						class="subscriptions__expand-value">{{ subscription.oneTimeNetPrice | currencyProfile  | notEmpty}}</div>
				</div>
			</ng-template>


			<div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-6 mb-2" tabindex="0">
				<div class="subscriptions__expand-title">Term</div>
				<div
					class="subscriptions__expand-value">
					<ng-container *ngIf="bindingPeriodInfo && bindingPeriodInfo.bindingPeriod && bindingPeriodInfo.bindingPeriod.duration; else unlimitedTerm">
						{{bindingPeriodInfo.bindingPeriod.duration.value | notEmpty}} {{bindingPeriodInfo.bindingPeriod.duration.unit }}
					</ng-container>
					<ng-template #unlimitedTerm>
						<ng-container *ngIf="bindingPeriodInfo; else emptyTerm">
							Unlimited
						</ng-container>
					</ng-template>
					<ng-template #emptyTerm>
						-
					</ng-template>
				</div>
			</div>

			<div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-6 mb-2" tabindex="0">
				<div class="subscriptions__expand-title">Order date</div>
				<div
					class="subscriptions__expand-value">{{subscription.createdDate | date : 'mediumDate' | notEmpty}}</div>
			</div>

			<div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-6 mb-2" tabindex="0">
				<div class="subscriptions__expand-title">Activation date</div>
				<ng-container *ngIf="bindingPeriodInfo; else noChargingDate">
					<div class="subscriptions__expand-value">{{bindingPeriodInfo.chargingStartDate | date : 'mediumDate' | notEmpty}}</div>
				</ng-container>
				<ng-template #noChargingDate>
					-
				</ng-template>
			</div>
		</div>

		<ng-container *ngIf="hasBundleItems(subscription)">
			<subscriptions-bundle-item [bundleData]="subscription.bundlesList"></subscriptions-bundle-item>
		</ng-container>


		<ng-container
			*ngIf="subscription.transitionOfferingName && checkSubscriptionIsSwitch(subscription.subscriptionMode) && bindingPeriodInfo">
			<div class="row align-items-center mb-4">
				<div class="offset-xl-1 col-xl-11 col-lg-12 col-md-12 col-sm-12 col-12">
					<div class="cds-icon-info-circle cds-icon-md ml-2 d-inline-block mb-2"></div>
					<div class="subscription___switch_value ml-2 d-inline-block">
						Switching to product:
						{{ subscription.transitionOfferingName }}
						scheduled for {{ bindingPeriodInfo.bindingEndDate | date : 'mediumDate' | notEmpty}}.
					</div>
				</div>
			</div>
		</ng-container>

		<!-- Loading -->
		<cds-spinner-wrapper mode="partial" [overlay]="true" *ngIf="isModalLoading">
			<cds-spinner></cds-spinner>
		</cds-spinner-wrapper>

	</div>
</div>

