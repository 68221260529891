import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {ApiService} from "./api.service";
import {Observable} from "rxjs";
import {Address, Customer} from "../models";
import {Payment} from "../models/payment";
import { Account } from 'shared/app/index';

@Injectable({
	providedIn: 'root',
})
export class CustomerService {
	constructor(private apiService: ApiService) {
	}

	getCustomerById(customerId: number): Observable<Customer> {
		return this.apiService.get('/v2/customers/' + customerId);
	}

	getCustomer(): Observable<Customer> {
		return this.apiService.get('/v2/customers/current');
	}

	getCustomerContacts(customerId: number) {
		return this.apiService.get(`/customers/${customerId}/contacts`)
	}

	getAccountsForCustomer(customerId): Observable<Account[]> {
		return this.apiService.get(`/v2/customers/${customerId}/accounts`);
	}

	mergeCustomer(customer: Customer): Observable<Address> {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
		};
		return this.apiService.put('/v2/customers/' + customer.id, customer, httpOptions);
	}

	getCustomerType(customerId: number): Observable<String> {
		return this.apiService.get('/customers/' + customerId + '/type');
	}

  getCustomerPayments(customerId: number): Observable<Payment[]> {
    return this.apiService.get('/customers/' + customerId + '/payments');
  }

  getCustomerAddress(customerId: number) {
	  return this.apiService.get('/customers/' + customerId + '/addresses');
  }

}
