import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {environment} from "../../../environments/environment";
import {AppPath} from "../../utils/app-path";
import {AuthenticationService} from "../../services/authentication.service";
import {AppPathService} from "../../services/app-path.service";

@Component({
	selector: 'app-internal-error',
	templateUrl: './internal-error.component.html',
})
export class InternalErrorComponent implements OnInit {

	constructor(private pathService: AppPathService, private auth: AuthenticationService) {
	}

	ngOnInit() {
	}

	home() {
		if(environment.webshop && !this.auth.isLogged()) {
			this.pathService.navigate([AppPath.PATH_WEBSHOP_HOME]);
		} else {
			this.pathService.navigate([AppPath.PATH_HOME]);
		}
	}
}
