import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl } from '@angular/forms';
import { noop, RunnableFunction } from '../../utils/facade';

const EQUAL_VALIDATOR: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => EqualDirective),
	multi: true,
};

@Directive({
	selector: '[caEqual]',
	providers: [EQUAL_VALIDATOR],
})
export class EqualDirective {

	private equalTo: string;
	private onValidatorChange: RunnableFunction = noop;

	@Input()
	set caEqual(equalTo: string) {
		this.equalTo = equalTo;
		this.onValidatorChange();
	}

	validate(control: AbstractControl): {} {
		if (control.value == null) {
			return null;
		}

		if (control.value !== this.equalTo) {
			return {
				notEqual: { valid: false },
			};
		}

		return null;
	}

	registerOnValidatorChange(fn: () => void): void {
		this.onValidatorChange = fn;
	}
}
