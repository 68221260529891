import {Pipe, PipeTransform} from '@angular/core';
import {locales} from 'locales';

@Pipe({name: 'addressTypeCode'})
export class AddressTypeCodePipe implements PipeTransform {
	transform(value: string): any {
		value = value.toLowerCase().replace('_type', '');
		return locales.address_type[value];
	}
}
