import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormUtils } from '../../utils/form-utils';
import { ValidationMessageMap } from '../../utils/validation-message-map';

/**
* ##Example usage:
*
* ###For single message
* ```html
* <form-error [control]="'companyName'" message="Company name or First name and Last name are required" error="companyOrPersonalNameRequired" [form]="invoiceAddressForm"></form-error>
* ```
*
*
* ###For all messages
* ```html
* <form-error [control]="'companyName'" [allMessages]="validationMessages" [form]="invoiceAddressForm"></form-error>
* ```
*  or
* ```
* <form-error [control]="'companyName'" [allMessages]="{notAllowedCharacters: 'message about error'}" [form]="invoiceAddressForm"></form-error>
* ```
*
* `allMessages` is map of type `ValidationMessageMap`
*
*/
@Component({
	selector: 'form-error',
	templateUrl: 'form-error.component.html'
})
export class FormErrorComponent {

	/*
	* `allMessages` map or single `message` and `error` string is required
	* */
	@Input() allMessages: ValidationMessageMap = {};
	@Input() message: string;
	@Input() error: string;
	@Input() form: FormGroup;
	@Input() control: string;

	constructor(public formUtils: FormUtils) {

	}

	canShowSingleMessage() {
		const c = this.form.get(this.control);
		const thisError = this.error ? c.hasError(this.error) : true;
		return (this.message && c && thisError);
	}
}
