<div [class.disabled]="isDisabled" [(caDropdown)]="isOpen" (dropdownEscape)="button.focus()" [focusActive]="focusActive">
	<button class="dropdown-toggle" #button (click)="toggle($event)" [disabled]="isDisabled">
		<span class="dropdown-label">
			<ng-content select="label[all]"></ng-content>
		</span>
		<span class="dropdown-label" *ngIf="selectedCount === 0">
			<ng-content select="label[default]:not([all])"></ng-content>
		</span>
		<span class="dropdown-label" *ngIf="selectedCount !== 0">
			<ng-content select="label:not([default]):not([all])"></ng-content>
		</span>
		<span class="caret icon icon-ca-caret-down"></span>
	</button>
	<div class="dropdown-menu" caDropdownDisableKeydown>
		<ng-content select="ul"></ng-content>
	</div>
</div>