import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {ApiService} from "./api.service";
import {FileService} from "./file.service";
import {FileUtils} from "../utils";
import {ContractualTerm} from "../models";

@Injectable({
	providedIn: 'root',
})
export class ContractualTermService {
	constructor(protected apiService: ApiService, protected fileService: FileService) {
	}

	getTermsByPrefix(prefix: string): Observable<ContractualTerm[]> {
		return this.apiService.get('/terms/prefix/' + prefix).pipe(map(terms => this.mapTerms(terms)));
	}

	getDocument(termId: number, documentVersion: number): Observable<any> {
		return this.fileService.getFile('/terms/' + termId + '/' + documentVersion + '/document');
	}

	mapTerms(termList: any): ContractualTerm[] {
		return termList.map(term => {
				const fileName = FileUtils.getFileName(term.path);

				return {
					code: term.code,
					description: term.description,
					descriptionSubtext: term.descriptionSubText,
					contractTermId: term.id,
					required: term.type === 'Required',
					documentVersion: term.version,
					documentName: fileName ? fileName : term.code + '.pdf',
					contentPath: term.path
				}
			}
		);
	}
}
