import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'tab',
	templateUrl: './tab.component.html',
})
export class TabComponent implements OnInit {

	@Input()
	title: Object = '';
	@Input()
	active: boolean;

	ngOnInit(): void {
	}

}
