import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { WizardStep } from './wizard-step';
import { WizardProvider } from './wizard-provider';
import { first } from 'rxjs/operators';

@Component({
	selector: 'wizard-base, [wizard-base]',
	templateUrl: './wizard-base.component.html',
})
export class WizardBaseComponent implements OnInit, AfterViewInit {

	@Input() provider: WizardProvider;
	@Input() submitButtonText: string;
	@Input() previousScreenButtonText: string | null = null;
	currentIndex: number = 0;
	model: any = null;
	isLoading: boolean = false;

	steps: WizardStep[] = [];

	constructor(private changeDetector: ChangeDetectorRef) {
	}

	ngOnInit(): void {
		this.provider.goToNextStepCallback(() => {
			this.goToNextStep();
			return this.steps[this.currentIndex];
		})
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.steps.push(...this.provider.getSteps().toArray());
			this.model = this.provider.getModel();
			this.provider.onStepChanged(0, this.steps);
		});
	}


	goToNextStep() {
		let i = this.currentIndex + 1;
		if (i >= this.steps.length) {
			this.isLoading = true;
			this.provider.onSubmit(this.steps).pipe(first()).subscribe(
				resp => {
					this.isLoading = false;
				},
				error => {
					this.isLoading = false;
				});
		} else {
			const canChange = this.provider.onStepChangedPreview(i, this.steps);
			if (canChange) {
				this.currentIndex = i;
				this.provider.onStepChanged(i, this.steps);
			}
		}
		this.changeDetector.detectChanges();
	}

	goToPreviousStep() {
		let i = this.currentIndex - 1;
		if (i >= 0) {
			this.currentIndex = i;
			this.provider.onStepChanged(i, this.steps);
		} else {
			this.provider.onGoPageBack();
		}
	}
}

