import { Attribute, Directive, HostBinding, Input } from '@angular/core';
import { AbstractControl, AbstractControlDirective, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { keyBy } from '../../utils/facade';

@Directive({
	selector: '[caErrors]',
})
export class ErrorsDirective {

	private control: Control;
	private ignoreErrors: { [error: string]: string };
	private handlers: { [name: string]: ErrorHandler } = {};
	private lastError: string = null;

	constructor(@Attribute('ignoreErrors') ignoreErrors: string) {
		this.ignoreErrors = ignoreErrors ? keyBy(ignoreErrors.split(','), error => error) : {};
	}

	@Input()
	set caErrors(control: AbstractControlDirective|AbstractControl) {
		if (!this.control && control) {
			this.control = toControl(control);

			this.control.statusChanges.pipe(startWith(this.control.status)).subscribe(status => {
				let showError: string = null;
				if (status === 'INVALID' && this.control.errors) {
					showError = Object.keys(this.control.errors).reduce((showError, error) => {
						if (error === 'required' && this.handlers['required']) {
							return 'required';
						} else if (showError) {
							return showError;
						} else if (this.ignoreErrors[error] !== error && this.handlers[error]) {
							return error;
						} else {
							return null;
						}
					}, null);
				}

				if (this.lastError === showError) {
					if (showError && this.handlers.hasOwnProperty(showError)) {
						this.handlers[showError].show(this.control.errors[showError]);
					}
					return;
				}
				this.lastError = showError;

				for (const error in this.handlers) {
					if (this.handlers.hasOwnProperty(error)) {
						if (error === showError) {
							this.handlers[error].show(this.control.errors[error]);
						} else {
							this.handlers[error].hide();
						}
					}
				}
			});
		}
	}

	@HostBinding('class.hidden')
	get hidden(): boolean {
		return this.lastError == null || (this.control.pristine && this.control.untouched && (!this.control.value || this.control.value.length === 0));
	}

	registerError(name: string, handler: ErrorHandler): void {
		this.handlers[name] = handler;
	}

	hasError(name: string): boolean {
		return this.handlers[name] != null;
	}
}

function toControl(control: AbstractControlDirective|AbstractControl): Control {
	const controlAny: any = control as any;
	if (controlAny.status) {
		return controlAny;
	} else {
		return controlAny.control;
	}
}

interface Control {
	statusChanges:  Observable<any> | null;
	errors: ValidationErrors | null;
	value: any;
	status: string;
	pristine: boolean;
	untouched: boolean;
}

export interface ErrorHandler {
	show(error: any): void;
	hide(): void;
}
