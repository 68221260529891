export class XICTMathUtils {

	public static isNumber(value) {
		return value && !isNaN(value) && parseFloat(value);
	}

	public static isInteger(value) {
		return value && !isNaN(value) && (parseFloat(value) == parseInt(value));
	}

	public static getSafeRemainder(dividend: number, divisor: number) {
		if (this.isInteger(dividend) && this.isInteger(divisor)) {
			return dividend % divisor;
		}

		return this.getFloatSafeRemainder(dividend, divisor);
	}

	public static getFloatSafeRemainder(dividend: number, divisor: number) {
		dividend = parseFloat(dividend.toString());
		divisor = parseFloat(divisor.toString());
		const dividendDecimalCount = (dividend.toString().split('.')[1] || '').length;
		const divisorDecimalCount = (divisor.toString().split('.')[1] || '').length;
		const decimalCount = dividendDecimalCount > divisorDecimalCount ? dividendDecimalCount : divisorDecimalCount;
		const dividendInt = parseInt(dividend.toFixed(decimalCount).replace('.', ''));
		const divisorInt = parseInt(divisor.toFixed(decimalCount).replace('.', ''));
		return (dividendInt % divisorInt) / Math.pow(10, decimalCount);
	}

	public static repeatArray(inputArray, times): any[] {
		if (times == 0) return [];
		let tempArray = [inputArray];
		while (tempArray.length * 2 <= times) tempArray = tempArray.concat(tempArray);
		if (tempArray.length < times) tempArray = tempArray.concat(tempArray.slice(0, times - tempArray.length));
		return tempArray;
	}

	public static roundWithDecimalPlaces(value, decimalPlaces = 2) {
		const multiplier = Math.pow(10, decimalPlaces);
		return (Math.round(value * multiplier) / multiplier);
	}

	public static roundToFixedWithDecimalPlaces(value: number, decimalPlaces = 2) {
		const multiplicator = Math.pow(10, decimalPlaces);
		value = parseFloat((value * multiplicator).toFixed(11));
		const roundTo = (valueToRound: number): number => Math.round(valueToRound) / multiplicator;

		return Number.parseFloat(roundTo(value).toFixed(decimalPlaces));

	}
}
