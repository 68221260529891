<page-title [pageTitle]="'Payments'"></page-title>
<div class="col-xl-12" *ngIf="payments && payments.length > 0">
    <cds-list>
        <cds-list-row head role="table" tabindex="0" aria-label="Payments">
            <div role="cell" class="cds-list-cell d-none d-xl-block">Ref. number</div>
            <div role="cell" class="cds-list-cell">Invoice number</div>
            <div role="cell" class="cds-list-cell">Amount</div>
            <div role="cell" class="cds-list-cell d-none d-md-block">Transaction date</div>
            <div role="cell" class="cds-list-cell">Status</div>
        </cds-list-row>
        <cds-list-row *ngFor="let item of payments" tabindex="-1">
            <div role="cell" class="cds-list-cell" aria-selected="true" title="Invoice number">
                <button *ngIf="isInvoiceDetailsEnabled()" cdsButton class="cds-button-text" title="Invoice number, click for details"
                        (click)="goToInvoiceDetails(item.invoiceId)" (keyup.enter)="goToInvoiceDetails(item.invoiceId)">
                    {{item.invoiceId | notEmpty}}
                </button>
                <ng-container *ngIf="!isInvoiceDetailsEnabled()">
                    {{item.invoiceId | notEmpty}}
                </ng-container>
            </div>
            <div role="cell" class="cds-list-cell d-none d-xl-block" tabindex="0" title="Reference number">{{item.referenceNumber}}</div>
            <div role="cell" class="cds-list-cell" tabindex="0" title="Amount">{{item.amount | currencyProfile}}</div>
            <div role="cell" class="cds-list-cell d-none d-md-block" tabindex="0" title="Created date">{{item.transactionDate | date: 'mediumDate'}}</div>
            <div role="cell" class="cds-list-cell" tabindex="0" title="Status"><state [stateInput]="item.stateCode"></state></div>
        </cds-list-row>
    </cds-list>
</div>
<cds-spinner *ngIf="isLoading"></cds-spinner>