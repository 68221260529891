import {Inject, Injectable} from '@angular/core';
import { ApiService } from './api.service';
import {Contact} from "../models/contact";

@Injectable({
  providedIn: 'root',
})

export class ContactService {

  constructor(protected apiService: ApiService) { }

  updateContact(contactId: number, body: Contact) {
    return this.apiService.put(`/contacts/${contactId}`, body);
  }

}
