import { InjectionToken, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { BaseStore } from './base-store';

@NgModule()
export class StoreModule {

	static forRoot<Store extends BaseStore>(storeToken: Type<Store>|InjectionToken<Store>): ModuleWithProviders<StoreModule> {
		return {
			ngModule: StoreModule,
			providers: [
				{ provide: BaseStore, useExisting: storeToken },
			],
		};
	}
}
