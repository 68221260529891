import {Injectable} from '@angular/core';
import {HttpParams} from "@angular/common/http";
import {ApiService} from "./api.service";
import {RequestUtil} from "../utils";

@Injectable({
	providedIn: 'root',
})

export class CarouselService {

	constructor(protected apiService: ApiService, protected requestUtil: RequestUtil) {

	}

	getCarouselForSalesChannel(salesChannelCode: string) {
		let brandAndCodeHeader = this.requestUtil.injectHeaders().append('Code', salesChannelCode);
		return this.apiService.get('/global/carousel-items', {
			headers: brandAndCodeHeader
		});
	}

}
