import { NgModule } from '@angular/core';
import { StoreModule } from 'framework/app/core/store/store.module';
import { Store } from './store/store';
import { ProvidersModule } from './providers/default/providers.module';

@NgModule({
	imports: [
		StoreModule.forRoot(Store),
		ProvidersModule
	],
})
export class CoreModule {}
