import { Directive, HostListener } from '@angular/core';

/**
 * The directive blocks the propagation of the keydown event beyond the element on which it was placed.
 * Used in conjunction with the caDropdown directive, blocks page scrolling when switching between options.
 *
 * It is not possible to transfer the code to DropdownDirective due to TypeaheadComponent which use this directive.
 * Would block in the typeahead input element the possibility to go to the beginning and end of the text using the arrows on the keyboard.
 */
@Directive({
	selector: '[caDropdownDisableKeydown]',
})
export class DropdownDisableKeydownDirective {

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent): void {
		event.preventDefault();
		event.stopPropagation();
	}
}
