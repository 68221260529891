import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { BreadcrumbService } from 'ng-breadcrumb';
import { DynamicTitleService } from '../services/dynamic-title.service';
import { AuthenticationService } from '../services/authentication.service';
import { CartService } from '../services/webshop/cart.service';
import { AppPath } from '../utils/app-path';
import { ProfileService } from '../services/profile.service';
import { ContextService } from '../services/context.service';
import { AppPathService, CustomBreadcrumb } from '../services/app-path.service';
import { WebshopUtil } from '../utils/webshop.util';
import { ViewKeys } from '../utils/view-keys';
import { viewKey } from '../decorators/decorators';
import { AppProfile } from '../utils/app-profile';

@Component({
	selector: 'ca-webshop',
	templateUrl: 'webshop.component.html',
})
@viewKey(ViewKeys.V_WEBSHOP_ALLOW_VIEW)
export class WebshopComponent implements OnInit, AfterViewInit {

	profiles = AppProfile;

	@ViewChild('wsFooter', {read: ElementRef}) footerElement: ElementRef;

	isLoading: boolean = false;
	isBreadcrumbVisible: boolean = true;
	isValoProfile: boolean = false;
	footerHeight: number = 0;
	customBreadcrumbs: CustomBreadcrumb[] = null;

	constructor(private webshopUtil: WebshopUtil, private router: Router, private activatedRoute: ActivatedRoute,
				private breadcrumpService: BreadcrumbService, private titleService: DynamicTitleService, private authService: AuthenticationService,
				private cartService: CartService, private profileService: ProfileService, private contextService: ContextService,
				private cdRef: ChangeDetectorRef, private pathService: AppPathService) {
		this.isValoProfile = this.profileService.isProfileXVLO();
		this.cartService.dispatch();
		this.titleService.setPageTitleWithBrand('Webshop');
		if (this.authService.isLogged()) {
			this.contextService.dispatch();
		}
		this.pathService.customBreadcrumbs.subscribe(breadcrumbs => {
			this.customBreadcrumbs = breadcrumbs;
		});
		this.createBreadcrumb();
	}

	ngOnInit() {
		if (!this.webshopUtil.isWebshopEnabledAfterLogin() && this.authService.isLogged()) {
			this.authService.logOut(false);
		}
		this.hideBreadcrumb(this.router.url);
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				this.hideBreadcrumb(event.url);
			}
		});
	}

	ngAfterViewInit() {
		this.footerHeight = this.footerElement.nativeElement.childNodes[0].offsetHeight;
		this.cdRef.detectChanges();
	}

	hideBreadcrumb(url: string) {
		if (url.startsWith(this.pathService.getBoxPrefixUrl() + AppPath.PATH_WEBSHOP_CHECKOUT)) {
			this.isBreadcrumbVisible = false;
		} else if (this.profileService.isProfileXEHC() || this.profileService.isProfileDefault()) {
			this.isBreadcrumbVisible = false;
		} else {
			this.isBreadcrumbVisible = true;
		}
	}

	createBreadcrumb() {
		let boxCode = this.pathService.getBoxPrefixUrl();
		this.breadcrumpService.hideRoute(boxCode + '/webshop');
		this.breadcrumpService.addFriendlyNameForRoute(boxCode + '/webshop/cart', 'Shopping cart');
		this.breadcrumpService.hideRoute(boxCode + '/webshop/channel');
		this.breadcrumpService.hideRoute(boxCode + '/webshop/terms');
		this.breadcrumpService.hideRouteRegex(boxCode + '/webshop/channel/[^\\/]+$');
		this.breadcrumpService.hideRoute(boxCode);
		this.breadcrumpService.hideRouteRegex(boxCode + '/webshop/offering/*');
		this.breadcrumpService.addFriendlyNameForRouteRegex(boxCode + '/webshop/channel/residential/*', 'Residential');
		this.breadcrumpService.addFriendlyNameForRouteRegex(boxCode + '/webshop/channel/small_and_medium_enterprise/*', 'Small and Medium Enterprise');
		this.breadcrumpService.addFriendlyNameForRouteRegex(boxCode + '/webshop/checkout.*', 'Order process');
		this.breadcrumpService.addFriendlyNameForRouteRegex(boxCode + '/webshop/terms/.*', 'Terms and conditions');
		this.breadcrumpService.hideRoute(boxCode + '/webshop/questionnaire');
		this.breadcrumpService.addFriendlyNameForRouteRegex(boxCode + '/webshop/questionnaire/*', 'Small and Medium Enterprise request');
		this.breadcrumpService.addFriendlyNameForRoute(boxCode + '/webshop/request-residential', 'Residential request');
	}

	onCustomBreadcrumbClick(breadcrumb: CustomBreadcrumb) {
		this.pathService.navigate([breadcrumb.path]);
	}

}
