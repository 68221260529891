import {CartItem} from "../models/cart-item";
import {Offering, Price} from "shared/app/index";

export class CartItemDTO implements CartItem {
	id: number;
	name: string;
	description: string;
	price: any;
	additionalOfferings: {excludes: [], requires: [], optional: [], requiresAnyOf: []};
	count: number = 1;
	isPeriodic: boolean;
	isRequiredForAssociation: boolean;

	constructor(object: Offering, price: Price) {
		this.id = object.id;
		this.name = object.name;
		this.description = object.description;
		this.isPeriodic = object.isPeriodic;
		this.additionalOfferings = object.additionalOfferings;
		this.isRequiredForAssociation = (object.isRequiredForAssociation) ? object.isRequiredForAssociation : null;
		this.price = price;
	}
}
