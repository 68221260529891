import {Component, Input, OnInit} from '@angular/core';
import {Carousel} from "shared/app/index";

@Component({
	selector: 'ws-carousel-item',
	templateUrl: 'carousel-item.component.html',
})
export class CarouselItemComponent implements OnInit {

	@Input() item: Carousel;

	constructor() { }
	ngOnInit() {}
	onClickEvent() {
		if(this.item.buttonUrl && this.item.buttonUrl.toLowerCase().startsWith('http') || this.item.buttonUrl.startsWith('https')) {
			window.open(this.item.buttonUrl);
		} else {
			console.error('Incorrect redirect URL address.');
		}
	}

}
