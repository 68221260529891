import { ModalInstance } from './modal-instance';

export class ModalInstanceRegistry {
	private instances: Set<ModalInstance<any, any>> = new Set<ModalInstance<any, any>>();

	register(instance: ModalInstance<any, any>): void {
		this.instances.add(instance);
	}

	unregister(instance: ModalInstance<any, any>): void {
		this.instances.delete(instance);
	}

	forEach(callback: (instance: ModalInstance<any, any>) => void): void {
		this.instances.forEach(callback);
	}
}
