import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { PasswordProfile } from 'shared/app/models/password-profile';
import { BaseRegexpValidator, commonFormFieldContentValidator, SimpleRegexpValidator } from './common-regexp-validators';
import { TaxIdFormatValidator } from './tax-id-validators';

export class CustomFormValidators {

	static getPhoneNumberValidator(): ValidatorFn {
		return Validators.compose([Validators.required, this.getOptionalPhoneNumberValidator()]);
	}

	static getOptionalPhoneNumberValidator(): ValidatorFn {
		return Validators.compose([Validators.maxLength(20), SimpleRegexpValidator(/^(\(?\+[0-9]{1,3}\)? ?)?\d+((-| +)?\d){2,}$/)]);
	}

	static getOptionalFullPhoneNumberValidator(): ValidatorFn {
	    return Validators.compose([Validators.maxLength(11), BaseRegexpValidator(/^([0-9]{2})([0-9]{9})$/, 'wrongNumber')]);
	}

	static getFirstNameValidator(): ValidatorFn {
		return Validators.compose([Validators.required, Validators.maxLength(50),
			commonFormFieldContentValidator]);
	}

	static getOptionalFirstNameValidator(): ValidatorFn {
		return Validators.compose([Validators.maxLength(50),
			commonFormFieldContentValidator]);
	}

	static getCompanyNameValidator(): ValidatorFn {
		return Validators.compose([Validators.required, Validators.maxLength(100)]);
	}

	static getOptionalCompanyNameValidator(): ValidatorFn {
		return Validators.maxLength(100);
	}

	static getUsernameValidator(): ValidatorFn {
		return Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(32), Validators.pattern(/^(?!-)[a-z0-9._-]*[a-z._-][0-9]*$/)]);
	}

	static getPasswordValidator(passwordProfile: PasswordProfile): ValidatorFn {
		return Validators.compose([
			BaseRegexpValidator('^.{' + passwordProfile.minLength + ',}$', 'minlength'),
			Validators.maxLength(passwordProfile.maxLength),
			passwordProfile.minSymbol ? BaseRegexpValidator('[^A-Za-z0-9\\040]{' + passwordProfile.minSymbol + ',}', 'specialCharacter') : undefined,
			passwordProfile.minNumeric ? BaseRegexpValidator('\\d{' + passwordProfile.minNumeric + ',}', 'number') : undefined,
			passwordProfile.minLowerCase ? BaseRegexpValidator('[a-z]{' + passwordProfile.minLowerCase + ',}', 'smallLetter') : undefined,
			passwordProfile.minUpperCase ? BaseRegexpValidator('[A-Z]{' + passwordProfile.minUpperCase + ',}', 'capitalLetter') : undefined,
			BaseRegexpValidator(/^\S*$/, 'whitespaceCharacter'),
			BaseRegexpValidator(/^([a-zA-Z0-9!@#$%^&*()=_+,./<>?;':~`|-]+)$/, 'invalidCharacter'),
		]);
	}

	static getBasicPasswordValidator(): ValidatorFn {
		return Validators.compose([
			BaseRegexpValidator(/^.{8,}$/, 'minlength'),
			BaseRegexpValidator(/^\S*$/, 'whitespaceCharacter'),
			BaseRegexpValidator(/[^A-Za-z0-9\040]/, 'specialCharacter'),
			BaseRegexpValidator(/^([a-zA-Z0-9!@#$%^&*()=_+,./<>?;':~`|-]+)$/, 'invalidCharacter'),
			BaseRegexpValidator(/\d/, 'number'),
			BaseRegexpValidator(/[A-Z]/, 'capitalLetter'),
			BaseRegexpValidator(/[a-z]/, 'smallLetter'),
			Validators.maxLength(50),
		]);
	}

	static getTaxIdValidator(countryCodeProvider: () => string): ValidatorFn {
		return Validators.compose([
			Validators.maxLength(40),
			TaxIdFormatValidator(countryCodeProvider),
		]);
	}

	static getBasicEmailValidator(): ValidatorFn {
		return Validators.compose([Validators.required, Validators.maxLength(100), Validators.email]);
	}

	static getAdvancedEmailValidator(): ValidatorFn {
		return Validators.compose([Validators.required, Validators.maxLength(100), SimpleRegexpValidator(/^[a-zA-Z0-9.!#$%&’*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/)]);
	}

	static getAdvancedComarchEmailValidator(): ValidatorFn {
		return Validators.compose([Validators.required, Validators.maxLength(100), BaseRegexpValidator(/^[a-zA-Z0-9.!#$%&’*+\\/=?^_`{|}~-]+@[a-zA-Z0-9.]*comarch\.[a-zA-Z0-9]+$/, 'customerInternalMail')]);
	}

	static getOptionalAdvancedEmailValidator(): ValidatorFn {
		return Validators.compose([Validators.maxLength(100), SimpleRegexpValidator(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/)]);
	}

	static getDoctorRegisterNumberValidator(): ValidatorFn {
		return Validators.compose([SimpleRegexpValidator(/^[0-9]{7}/), this.basicDoctorRegisterNumberValidator()]);
	}

	private static basicDoctorRegisterNumberValidator() {
		return (control: AbstractControl): ValidationErrors | null => {
			const actualControlDigit = Math.floor(control.value / 1000000);
			const splitRegisterNumber = control.value.toString().split('').map(Number);
			let expectedControlDigit = 0;
			for (let i = 1; i < splitRegisterNumber.length; i++) {
				expectedControlDigit += splitRegisterNumber[i] * i;
			}
			expectedControlDigit %= 11;
			return actualControlDigit == expectedControlDigit ? null : { invalidRegisterNumber: { value: control.value } };
		  };
	}
}
