import {Component, OnInit} from '@angular/core';
import {AppPath} from "../../utils/app-path";
import {AppPathService} from "../../services/app-path.service";
import {BoxConfigurationService} from "../../services/box-configuration.service";

@Component({
	selector: 'app-not-found',
	templateUrl: './not-found.component.html',
})
export class NotFoundComponent implements OnInit {

	isLogoVisible: boolean = false;

	constructor(private appPathService: AppPathService, private boxConfigurationService: BoxConfigurationService) {
		this.isLogoVisible = !this.boxConfigurationService.isBoxFeatureEnabled();
	}

	ngOnInit() {
	}

	home() {
		if (this.boxConfigurationService.isBoxFeatureEnabled()) {
			if (this.appPathService.getBoxShortCode()) {
				this.appPathService.navigate([AppPath.PATH_HOME + this.getSource()]);
			} else {
				window.open("https://www.comarch.pl/telekomunikacja/rozwiazania-bss/", '_self')
			}
		} else {
			this.appPathService.navigate([AppPath.PATH_HOME + this.getSource()]);
		}
	}

	getSource(): string {
		let lastUrl = this.appPathService.getLastUrl();
		return lastUrl.match(AppPath.PATH_SELFCARE_HOME)
			? AppPath.PATH_SELFCARE_HOME : lastUrl.match(AppPath.PATH_WEBSHOP_HOME)
				? AppPath.PATH_WEBSHOP_HOME : ''
	}
}
