import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { config } from 'config';
import { ViewKey } from '../models/view-key';
import { ContextState } from '../core/store/state/context.state';
import { Store } from '@ngrx/store';
import {
	getAccountId,
	getCustomer,
	getCustomerId,
	getCustomerTypeCode,
	getResellerId,
	getUser,
	getUserContact,
	getUserId,
} from '../core/store/selectors/context.selectors';
import { take } from 'rxjs/operators';
import { AddContextUserContact, ClearContext, GetContext, SetContextCustomer } from '../core/store/actions/context.actions';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Constants } from '../utils/constants';
import { XICTContextState } from '../core/store/state/xict-context.state';
import { XICTClearContext } from '../core/store/actions/xict-context.actions';

@Injectable({
	providedIn: 'root',
})
export class ContextService {

	// private IRREMOVABLE_STORAGE_KEYS = [Constants.BROWSER_ID_KEY, Constants.COOKIES_ACCEPTED_KEY];

	isSelfcareContextActive: BehaviorSubject<boolean> = new BehaviorSubject(null);

	constructor(protected cookieService: CookieService, protected store: Store<ContextState>,
				protected xictStore: Store<XICTContextState>,
	) {
		if (!this.cookieService.get('lang') || this.cookieService.get('lang') == '') {
			this.cookieService.set('lang', config.lang);
		}
	}

	setLanguage(lang: string) {
		if (this.cookieService.get('lang') && this.cookieService.get('lang') != '') {
			this.cookieService.delete('lang');
		}
		this.cookieService.set('lang', lang);
	}

	getLanguage(): string {
		return this.cookieService.get('lang');
	}

	public getViewKeys(): ViewKey[] {
		return localStorage.getItem('keys') ? JSON.parse(localStorage.getItem('keys')) : null;
	}

	public setViewKeys(keys: ViewKey[]) {
		localStorage.setItem('keys', JSON.stringify(keys));
	}

	public clearStorage(withCookies = false) {
		this.store.dispatch(new ClearContext());
		this.xictStore.dispatch(new XICTClearContext());
		localStorage.removeItem(Constants.LOCALSTORAGE_CONTEXT);
		localStorage.removeItem(Constants.LOCALSTORAGE_TOKEN);
		localStorage.removeItem(Constants.LOCALSTORAGE_VIEW_KEYS);
		if (withCookies) {
			this.cookieService.deleteAll();
		}
	}

	dispatch() {
		this.store.dispatch(new GetContext());
	}

	getUser() {
		return this.store.select(getUser).pipe(take(1));
	}

	getUserId() {
		return this.store.select(getUserId).pipe(take(1));
	}

	getUserContact() {
		return this.store.select(getUserContact).pipe(take(1));
	}

	setUserContact(contactData) {
		this.store.dispatch(new AddContextUserContact(contactData));
	}

	getCustomer() {
		return this.store.select(getCustomer).pipe(take(1));
	}

	setCustomer(customerData) {
		return this.store.dispatch(new SetContextCustomer(customerData));
	}

	getCustomerId() {
		return this.store.select(getCustomerId).pipe(take(1));
	}

	getAccountId() {
		return this.store.select(getAccountId).pipe(take(1));
	}

	getCustomerTypeCode() {
		return this.store.select(getCustomerTypeCode).pipe(take(1));
	}

	getResellerId() {
		return this.store.select(getResellerId).pipe(take(1));
	}

}
