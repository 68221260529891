<div *ngIf="!showFullHeader" class="container cds-grid--no-padding pt-5">
	<app-brand></app-brand>
</div>
<div *ngIf="showFullHeader" class="pb-0" style="width: 100%; height: 72px !important; border-bottom: 1px solid #e7e7e7">
	<div class="container cds-grid--no-padding">
		<div class="d-flex justify-content-between align-items-center w-100">
			<app-brand></app-brand>
			<ng-container *ngIf="showLoginButton">
				<button cdsButton class="cds-button-secondary" style="margin-bottom: -6px; margin-top: 14px"
								(click)="onClick()">Login
				</button>
			</ng-container>
		</div>
	</div>
</div>