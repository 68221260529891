import { Injectable } from '@angular/core';
import { NavigationExtras, Router, RoutesRecognized } from '@angular/router';
import { Location } from '@angular/common';
import { BoxConfigurationService } from './box-configuration.service';
import { BoxParam } from '../models/box-param';
import { BehaviorSubject } from 'rxjs';

export interface CustomBreadcrumb {
	path?: string;
	label: string;
}

@Injectable({
	providedIn: 'root',
})
export class AppPathService {

	private boxUrlParam: BoxParam = null;
	lastUrl: string;
	customBreadcrumbs: BehaviorSubject<CustomBreadcrumb[]> = new BehaviorSubject<CustomBreadcrumb[]>(null);

	constructor(private router: Router, private boxConfigurationService: BoxConfigurationService, private location: Location) {
		this.boxConfigurationService.boxParam.subscribe((value: BoxParam) => {
			this.boxUrlParam = value;
		});
		this.router.events.pipe()
			.subscribe((events) => {
				if (events instanceof RoutesRecognized) {
					this.lastUrl = events.url;
				}
			});
	}

	navigate(path: any[], extras?: NavigationExtras) {
		if (this.boxConfigurationService.isBoxFeatureEnabled() && this.boxUrlParam) {
			this.router.navigate(['/' + this.boxUrlParam.shortCode.toLowerCase() + path[0]]);
		} else {
			this.router.navigate(path, extras);
		}
		this.customBreadcrumbs.next(null);
	}

	getPath(path: string): string {
		if (this.boxConfigurationService.isBoxFeatureEnabled() && this.boxUrlParam) {
			return '/' + this.boxUrlParam.shortCode.toLowerCase() + path;
		} else {
			return path;
		}
	}

	getBoxShortCode(): string {
		if (this.boxConfigurationService.isBoxFeatureEnabled() && this.boxUrlParam) {
			return this.boxUrlParam.shortCode.toLowerCase();
		}
	}

	getLastUrl(): string {
		return this.lastUrl;
	}

	getBoxPrefixUrl() {
		if (this.boxConfigurationService.isBoxFeatureEnabled()) {
			return `/${this.getBoxShortCode()}`;
		}
		return '';
	}

	//uses browser history
	navigateBack() {
		this.location.back();
	}

	setCustomBreadcrumbs(breadcrumbs: CustomBreadcrumb[]): void {
		this.customBreadcrumbs.next(breadcrumbs);
	}
}
