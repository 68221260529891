import {Injectable} from "@angular/core";
import { HttpParams} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {ApiService} from "./api.service";
import {RequestUtil} from "../utils";
import {Term} from "../models";

@Injectable({
	providedIn: 'root',
})

export class TermsService {
	showTermsModal: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  readOnlyDocumentsCache: BehaviorSubject<any[]>  = new BehaviorSubject<any[]>(null);
  acceptedTerms: BehaviorSubject<Term[]> = new BehaviorSubject<Term[]>([]);

	constructor(protected apiService: ApiService, protected requestUtil: RequestUtil) {}

	public getTerms(type: string, code?: string, relatedTermType? :string): Observable<any> {
		let params = new HttpParams();
		if(type) params = params.set('type', type.toString());
		if(code) params = params.set('code', code.toString());
		if(relatedTermType) params = params.set('relatedTermType', relatedTermType.toString());
		return this.apiService.get('/global/terms', {
			headers: this.requestUtil.injectHeaders(),
			params: params
		});
	}

	public getDocument(id: number, version: number): Observable<any> {
		return this.apiService.get('/global/terms/' + id + '/' + version + '/document', {
			headers: this.requestUtil.injectHeaders(),
			responseType: 'text'
		});
	}

}
