import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {interval} from "rxjs/internal/observable/interval";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs/Subject";
import {Carousel} from "shared/app/index";

@Component({
	selector: 'ws-carousel-container',
	templateUrl: 'carousel-container.component.html',
})
export class CarouselContainerComponent implements OnDestroy, OnInit {
	@Input() carouselItems = [];
	@Input() intervalTime = 10000;
	current: Carousel = null;
	selection: number = null;
	destroyed = new Subject();

	constructor() {

	}

	ngOnInit() {

	}

	ngOnChanges() {
		if(this.carouselItems.length > 0) {
			interval(this.intervalTime).pipe(takeUntil(this.destroyed)).subscribe(() => {
				this.displayNextCarouselItem();
			});
			this.current = this.carouselItems[0];
			this.selection = this.carouselItems[0].id;
		} else {
			this.carouselItems = null;
			this.current = null;
			this.destroyed.next();
		}
	}

	changeCarouselItem(item) {
		this.current = item;
		this.selection = item.id;
	}

	displayNextCarouselItem() {
		let nextItemIndex = null;
		this.carouselItems.forEach((item, index) => {
			if(this.selection == item.id) {
				if(index >= this.carouselItems.length-1) {
					nextItemIndex = 0;
				} else {
					nextItemIndex = index + 1;
				}
			}
		});
		this.changeCarouselItem(this.carouselItems[nextItemIndex]);
	}

	displayPreviousCarouselItem() {
		const currentIndex = this.carouselItems.findIndex(item => item.id === this.selection);
		const newIndex = currentIndex ? currentIndex-1 : this.carouselItems.length-1;
		this.changeCarouselItem(this.carouselItems[newIndex]);
	}

	ngOnDestroy() {
		this.destroyed.next();
		this.destroyed.complete();
	}



}
