import {environment} from "../../environments/environment";
import {AppPath} from "./app-path";
import {AppPathService} from "../services/app-path.service";
import {ProductCatalogService} from "shared/app/index";
import {Injectable} from "@angular/core";

@Injectable({
	providedIn: 'root',
})
export class WebshopUtil {

	constructor(private appPathService: AppPathService, private productCatalogService: ProductCatalogService) {

	}

	redirectToWebshop() {
		if(environment.webshop_url) {
			window.open(environment.webshop_url, '_self')
		} else {
			this.productCatalogService.clearAppLiveTimeCache.next(true);
			this.appPathService.navigate([AppPath.PATH_WEBSHOP_HOME])
		}
	}
	isWebshopEnabled() {
		return environment.webshop;
	}

	isWebshopEnabledAfterLogin() {
		return environment.webshop_after_login;
	}
}
