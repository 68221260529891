export enum AppProfile {
	DEFAULT = 'default',
	XICT = 'xict',
	XVLO = 'xvlo',
	TETHER = 'tether',
	CFOG = 'cfog',
	XICT_INTERNAL = 'xictinternal',
	XDELTA = 'xdelta',
	XEHC = 'xehc',
	XBLUETOWN = 'xbluetown'
}
