import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { catchError, flatMap } from 'rxjs/operators';
import { ContextService } from '../../services/context.service';
import { environment } from '../../../environments/environment';
import { KeycloakService } from 'keycloak-angular';
import { CDSToastService } from '../../services/cds-toast.service';
import { HttpErrorHandler } from './http-error-handler';
import { Store } from '@ngrx/store';
import { ContextState } from '../../core/store/state/context.state';
// @ts-ignore
import * as jwt_decode from 'jwt-decode';
import { AppPathService } from '../../services/app-path.service';
import { ApiService } from 'shared/app/index';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { TokenModalService } from '../../services/token-modal.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	private httpErrorHandler: HttpErrorHandler;

	constructor(cookieService: CookieService,
				private context: ContextService,
				private router: Router,
				private pathService: AppPathService,
				private authService: AuthenticationService,
				private apiService: ApiService,
				private keycloakService: KeycloakService,
				private cdsToast: CDSToastService,
				store: Store<ContextState>,
				http: HttpClient,
				private handlerService: ErrorHandlerService,
				private tokenModalService: TokenModalService) {
		this.httpErrorHandler = new HttpErrorHandler(http, cookieService, store, handlerService);
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const headersConfig = {
			//'Content-Type': 'application/json', //'Content-Type' : 'application/x-www-form-urlencoded',
			'X-Content-Type-Options': 'nosniff',
			'X-XSS-Protection': '1; mode=block',
			'Accept': 'application/json, text/plain, application/pdf, application/octet-stream',
			'Content-Security-Policy': 'script-src \'self\'; base-uri \'none\'; form-action \'self\'; frame-ancestors \'none\'',
			'Lang': this.context.getLanguage(),
		};
		let request = req;

		if (environment.keycloak.enabled) {
			return from(this.keycloakService.isLoggedIn()).pipe(
				flatMap((loggedIn) => {
					return loggedIn ? from(this.keycloakService.getToken()).pipe(
						flatMap((token) => {
							request = this.rewriteHeaders(req, headersConfig, token, request);

							this.authService.setToken(token);

							if (request.headers.get('Skip-interceptor') != null) {
								request = req.clone({headers: req.headers.delete('Skip-interceptor')});
								return next.handle(request).pipe(catchError(this.keyCloakErrorHandler), catchError(this.errorHandler(request)));
							} else {
								return next.handle(request).pipe(catchError(this.keyCloakErrorHandler), catchError(this.errorHandler(request)));
							}
						})) : next.handle(request).pipe(catchError(this.keyCloakErrorHandler), catchError(this.errorHandler(request)));
				}),
			);
		} else {
			const token: string = this.authService.getToken();

			request = this.rewriteHeaders(req, headersConfig, token, request);

			if (request.headers.get('Skip-interceptor') != null) {
				request = req.clone({headers: req.headers.delete('Skip-interceptor')});
				return next.handle(request).pipe(
					catchError(this.errorHandler401and500()),
					catchError(this.errorHandler(request)),
				);
			} else {
				if (this.shouldRefreshToken(this.authService.getToken())) {
					return from(this.authService.refreshToken()).pipe(
						flatMap((newToken) => {
							this.authService.setToken(newToken.token);

							request = this.rewriteHeaders(request, headersConfig, newToken.token, request);

							return next.handle(request);
						}),
						catchError(this.errorHandler401and500()),
						catchError(this.errorHandler(request)),
					);
				} else {
					return next.handle(request).pipe(
						catchError(this.errorHandler401and500()),
						catchError(this.errorHandler(request)),
					);
				}
			}
		}
	}

	private errorHandler401and500() {
		return err => {
			if (err instanceof HttpErrorResponse) {
				if (err.status === 401) {
					if (this.authService.getToken()) {
						this.tokenModalService.setIsTokenMessage(true);
						return null;
					} else {
						throw err;
					}
				} else if (Math.round(err.status / 100) === 5) {
					this.pathService.navigate(['/500']);
				}
			}
			throw err;
		};
	}

	private shouldRefreshToken(token: String): boolean {
		if (!environment.token.refreshEnabled) {
			return false;
		}
		if (token == null) {
			return false;
		}
		let decoded = jwt_decode(token);
		//should refresh token once every refreshPeriodMinutes minutes (assumes token is valid for validForMinutes)
		// or if token is within refreshPeriodMinutes minutes of expiration
		return new Date().getTime() + (environment.token.validForMinutes - environment.token.refreshPeriodMinutes) * 60 * 1000 > decoded.exp * 1000
			|| new Date().getTime() + environment.token.refreshPeriodMinutes * 60 * 1000 > decoded.exp * 1000;
	}

	private rewriteHeaders(req: HttpRequest<any>, headersConfig, token: string, request: HttpRequest<any>) {
		if (req.headers.get(HttpErrorHandler.DISABLE_LOGGING_HEADER) != null) {
			headersConfig[HttpErrorHandler.DISABLE_LOGGING_HEADER] = 'true';
		}
		if (req.headers.get('Skip-interceptor') != null) {
			headersConfig['Skip-interceptor'] = 'true';
		}
		if (token != null) {
			headersConfig['Authorization'] = AuthInterceptor.prepareAuthTokenHeaderValue(token);
			request = req.clone({setHeaders: headersConfig});
		}
		return request;
	}

	private keyCloakErrorHandler: (err: any) => Observable<any> | Promise<any> = (err: any) => {
		if (err instanceof HttpErrorResponse && err.status === 401) {
			this.keycloakService.login({redirectUri: window.location.origin + this.router.url});
			return of<any>({});
		} else if (err instanceof HttpErrorResponse && err.status === 403 && err.url.includes(AuthenticationService.FULL_AUTH_INFO_URL)) {
			//it means logged-in user has no privilege to use selfcare - logout to avoid uncaught error.
			this.keycloakService.logout(window.location.origin + '?al=1');
		} else {
			throw err;
		}
	};

	private errorHandler<T>(req: HttpRequest<any>): (err: any, caught: Observable<T>) => Observable<any> | Promise<any> {
		return this.httpErrorHandler.catchHttpError<T>(req.method, req.url, req.body, {headers: req.headers, params: req.params});
	}

	static prepareAuthTokenHeaderValue(token: string) {
		return 'Bearer ' + token;
	}

}
