export class XICTInstanceCountryMapping {
	private static readonly COUNTRY_MAPPING = {
		'pl': 'Poland',
		'fr': 'France',
		'de': 'Germany',
		'ae': 'United Arab Emirates',
		'au': 'Australia',
	};

	public static mapInstanceToCountryCode(instance: string | null): string | null {
		if (!instance) return null;
		switch (instance) {
			case 'KRN':
			case 'KRK':
				return 'pl';
			case 'LIL':
				return 'fr';
			case 'PVC':
			case 'DRE':
				return 'de';
			case 'DWC':
				return 'ae';
			case 'MEL':
				return 'au';
		}
		return null;
	}

	public static mapCountryName(code: string | null): string | null {
		if (!code) return null;
		return this.COUNTRY_MAPPING[code];
	}
}
