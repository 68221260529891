import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {config} from "config";
import {Constants} from "../utils/constants";
import { AppPath } from '../utils/app-path';
import {AppPathService} from "../services/app-path.service";

@Injectable({
	providedIn: 'root'
})
export class ProfileGuard implements CanActivate {

	constructor(public pathService: AppPathService) { }

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		if(next.data.profile.includes(config.custom)) {
			return true;
		} else {
			console.warn('Page blocked by profile ', next.data.profile, ': ', next.url);
			this.pathService.navigate([AppPath.PATH_NOT_FOUND]);
			return false;
		}
	}
}
