import { Directive, Input, OnInit, TemplateRef } from '@angular/core';

@Directive({
	selector: '[wizard-step]',
})
export class WizardStep implements OnInit {

	@Input()
	title: string = 'Step';

	constructor(public templateRef: TemplateRef<any>) {
	}

	ngOnInit() {
	}


}
