import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContextState } from '../state/context.state';

export const getContextState = createFeatureSelector('context');

export const getContext = createSelector(
	getContextState,
	(state: ContextState) => state,
);

export const getUser = createSelector(
	getContextState,
	(state: ContextState) => state.user,
);

export const getUserName = createSelector(
	getContextState,
	(state: ContextState) => state.user.login,
);

export const getUserId = createSelector(
	getContextState,
	(state: ContextState) => state.user.id,
);

export const getUserContact = createSelector(
	getContextState,
	(state: ContextState) => state.userContact,
);

export const getCustomerId = createSelector(
	getContextState,
	(state: ContextState) => {
		return state.user ? state.user.customerId : null;
	},
);

export const getCustomer = createSelector(
	getContextState,
	(state: ContextState) => state.customer,
);

export const getCustomerTypeCode = createSelector(
	getContextState,
	(state: ContextState) => {
		return state.customer ? state.customer.customerTypeCode : {};
	},
);

export const getAccountData = createSelector(
	getContextState,
	(state: ContextState) => state.account,
);

export const getAccountList = createSelector(
	getContextState,
	(state: ContextState) => state.accounts,
);

export const getAccountId = createSelector(
	getContextState,
	(state: ContextState) => {
		return state.account ? state.account.id : null;
	},
);

export const getNotifications = createSelector(
	getContextState,
	(state: ContextState) => state.notifications,
);

export const getInvoicesToPayForUser = createSelector(
	getContextState,
	(state: ContextState) => state.invoicesToPay,
);

export const getResellerId = createSelector(
	getContextState,
	(state: ContextState) => state.user.resellerId,
);

export const getBanners = createSelector(
	getContextState,
	(state: ContextState) => state.banners,
);

export const getLastUnreadNotification = createSelector(
	getContextState,
	(state: ContextState) => state.lastUnreadNotificationDate,
)
