import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ErrorDirective } from './error.directive';
import { ErrorsComponent } from './errors.component';
import { ErrorsDirective } from './errors.directive';

@NgModule({
	imports: [
		CommonModule,
	],
	declarations: [
		ErrorsComponent,
		ErrorsDirective,
		ErrorDirective,
	],
	exports: [
		ErrorsComponent,
		ErrorsDirective,
		ErrorDirective,
	],
})
export class ErrorsModule {}
