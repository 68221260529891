<ng-content></ng-content>
<span *caError="'positiveAmount'; override: false; let error">Entered amount must be greater than 0</span>
<span *caError="'minAmount'; override: false; let error">Entered amount must be greater than or equal to {{error.formattedMinValue}}</span>
<span *caError="'maxAmount'; override: false; let error">Entered amount must be lower than or equal to {{error.formattedMaxValue}}</span>
<span *caError="'amountOverflow'; override: false">Entered amount is incorrect</span>
<span *caError="'amount'; override: false">Entered amount is incorrect</span>
<span *caError="'iban'; override: false">Entered account number is incorrect</span>
<span *caError="'minlength'; override: false; let error">Please enter at least {{error.requiredLength | i18nPlural:{one: 'one character', other: '# characters' } }}</span>
<span *caError="'maxlength'; override: false; let error">You exceeded maximum number of {{error.requiredLength | i18nPlural:{one: 'one character', other: '# characters'} }}</span>
<span *caError="'length'; override: false; let error">Enter exactly {{error.requiredLength | i18nPlural:{one: 'one character', other: '# characters'} }}</span>
<span *caError="'email'; override: false">Entered value is incorrect</span>
<span *caError="'required'; override: false">This field is required</span>
