import { NgModule } from '@angular/core';
import { GenericTableComponent } from './generic-table/generic-table.component';
import { DataNotFoundPlaceholder } from './data-not-found-placeholder/data-not-found-placeholder';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AppBrandComponent } from './brand/app-brand.component';
import { InternalErrorComponent } from './internal-error/internal-error.component';
import { PaginationComponent } from './pagination/pagination.component';
import { NotEmptyPipe } from '../pipes/not-empty.pipe';
import { NgbTooltip, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TooltipComponent } from './tooltip/tooltip.component';
import { StateTranslationsPipe } from '../pipes/state-translations.pipe';
import { StateComponent } from './state/state.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { ComboModule, ComponentsModule, DialogModule, IndicatorModule, ListModule, PagerModule, SpinnerModule, ToastModule } from '@cds/components-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WizardBaseComponent } from './wizard/wizard-base.component';
import { WizardStep } from './wizard/wizard-step';
import { PageTitleComponent } from './page-title/page-title.component';
import { PageSubtitleComponent } from './page-subtitle/page-subtitle.component';
import { ContractualTermsComponent } from './contractual-term/contractual-terms.component';
import { TabComponent } from './tab/tab.component';
import { AddressTypeCodePipe } from '../pipes/address.type-code.pipe';
import { AddressSingleBoxComponent } from '../selfcare/components/address/address-box/address.single-box.component';
import { ContactDataComponent } from '../selfcare/components/customer/contact-data/contact-data.component';
import { AddressEditComponent } from '../selfcare/components/address/address-edit/address.edit.component';
import { AddressComponent } from '../selfcare/components/address/address.component';
import { FormErrorComponent } from './form-error/form-error.component';
import { AddressCreateComponent } from '../selfcare/components/address/address-create/address-create.component';
import { DateAgoPipe } from '../pipes/date-ago.pipe';
import { PaymentRegulationsComponent } from './payment-regulation/payment-regulations.component';
import { CurrencyProfilePipe } from '../pipes/currency-profile.pipe';
import { CustomizationModule } from 'framework/app/core/customization/customization.module';
import { CustomizationHideModule } from '../directives/customization-hide/customization-hide.module';
import { CustomizationShowModule } from '../directives/customization-show/customization-show.module';
import { ModalModule } from 'framework/app/ui/modal/modal.module';
import { SimpleDialogComponent } from './modal/simple-dialog.component';
import { InvoiceTypeComponent } from './invoice-type/invoice-type.component';
import { NgBreadcrumbModule } from 'ng-breadcrumb';
import { ServiceDeliveryDateComponent } from '../webshop/components/service-delivery-date/service-delivery-date.component';
import { CDSToastComponent } from './cds-toast/cds-toast.component';
import { OptinMarketingAgreementComponent } from '../webshop/components/optin-agreement/optin-marketing-agreement.component';
import { CheckoutShoppingCartListComponent } from '../webshop/components/checkout/shopping-cart-list/checkout-shopping-cart-list.component';
import { HeaderComponent } from './header/header.component';
import { CookiesComponent } from './cookies/cookies.component';
import { CrossFieldFormErrorComponent } from './crossfield-form-error/cross-field-form-error.component';
import { GenericListComponent } from './generic-list/generic-list.component';
import { WrappableTextTemplateComponent } from './generic-list/default-template/wrappable-text-template.component';
import { ConnectionRefusedComponent } from './connection-refused/connection-refused.component';
import { BoxCustomizableElementDirective } from '../directives/box-customizable-element.directive';
import { PageTitledBackButtonComponent } from './page-titled-back-button/page-titled-back-button.component';
import { ResettingMultiComboDirective } from '../directives/resetting-multi-combo.directive';
import { ResettingInputDirective } from '../directives/resetting-input.directive';
import { CustomerPropertyComponent } from './customer-property/customer-property.component';
import { AdditionalInfoComponent } from './additional-info/additional-info.component';
import { CustomizationShowDirective } from '../directives/customization-show/customization-show.directive';
import { OrderStateIndicatorPipe } from '../pipes/order.state-indicator.pipe';
import {PasswordRequirementsComponent} from "./password-requirements/password-requirements.component";
import {SearchFilter} from "./search-filter/search-filter.component";
import { TokenRefreshModalComponent } from './token-refresh-modal/token-refresh-modal.component';
import { HasPermissionDirective } from '../directives/has-permission.directive';
import { TransactionStatePipe } from '../pipes/transaction-state.pipe';
import { TransactionStateIndicatorPipe } from '../pipes/transaction.state-indicator.pipe';
@NgModule({
	declarations: [
		GenericTableComponent,
		DataNotFoundPlaceholder,
		FooterComponent,
		CookiesComponent,
		NotFoundComponent,
		ConnectionRefusedComponent,
		AppBrandComponent,
		InternalErrorComponent,
		HeaderComponent,
		PaginationComponent,
		TooltipComponent,
		StateComponent,
		InvoiceTypeComponent,
		NotEmptyPipe,
		StateTranslationsPipe,
		LanguageSelectorComponent,
		WizardBaseComponent,
		WizardStep,
		PageTitleComponent,
		PageSubtitleComponent,
		ContractualTermsComponent,
		PaymentRegulationsComponent,
		TabComponent,
		AddressComponent,
		AddressEditComponent,
		AddressCreateComponent,
		AddressSingleBoxComponent,
		AddressTypeCodePipe,
		ContactDataComponent,
		FormErrorComponent,
		CrossFieldFormErrorComponent,
		DateAgoPipe,
		CurrencyProfilePipe,
		SimpleDialogComponent,
		ServiceDeliveryDateComponent,
		CDSToastComponent,
		OptinMarketingAgreementComponent,
		CheckoutShoppingCartListComponent,
		GenericListComponent,
		WrappableTextTemplateComponent,
		BoxCustomizableElementDirective,
		PageTitledBackButtonComponent,
		ResettingMultiComboDirective,
		ResettingInputDirective,
		CustomerPropertyComponent,
		AdditionalInfoComponent,
		OrderStateIndicatorPipe,
		PasswordRequirementsComponent,
		SearchFilter,
		TokenRefreshModalComponent,
		HasPermissionDirective,
		TransactionStatePipe,
		TransactionStateIndicatorPipe,
	],
	imports: [
		CommonModule, ModalModule, NgbTooltipModule, ComboModule, FormsModule, ComponentsModule,
		ReactiveFormsModule, SpinnerModule, CustomizationModule, CustomizationHideModule, ListModule,
		NgBreadcrumbModule, DialogModule, ToastModule, PagerModule, IndicatorModule, CustomizationShowModule,

	],
	exports: [
		GenericTableComponent, DataNotFoundPlaceholder, FooterComponent, AppBrandComponent,
		PaginationComponent, TooltipComponent, NotEmptyPipe, NgbTooltip, StateComponent, StateTranslationsPipe, LanguageSelectorComponent,
		WizardBaseComponent, WizardStep, PageTitleComponent, PageSubtitleComponent, ContractualTermsComponent, TabComponent,
		AddressComponent, AddressEditComponent, AddressCreateComponent, AddressSingleBoxComponent, AddressTypeCodePipe, ContactDataComponent,
		ReactiveFormsModule, FormErrorComponent, DateAgoPipe, PaymentRegulationsComponent, CurrencyProfilePipe, SpinnerModule, CustomizationModule,
		CustomizationHideModule, ListModule, InvoiceTypeComponent, NgBreadcrumbModule, DialogModule, ServiceDeliveryDateComponent, CDSToastComponent,
		OptinMarketingAgreementComponent, CheckoutShoppingCartListComponent, CookiesComponent, CrossFieldFormErrorComponent, HeaderComponent, GenericListComponent,
		WrappableTextTemplateComponent, BoxCustomizableElementDirective, SimpleDialogComponent, PagerModule, IndicatorModule, PageTitledBackButtonComponent,
		ResettingMultiComboDirective, ResettingInputDirective, CustomerPropertyComponent, AdditionalInfoComponent, CustomizationShowModule, OrderStateIndicatorPipe,
		PasswordRequirementsComponent, SearchFilter, TokenRefreshModalComponent, HasPermissionDirective, TransactionStatePipe, TransactionStateIndicatorPipe,
	],
	entryComponents: [SimpleDialogComponent],
})
export class SCCommonModule {
}
