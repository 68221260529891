import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable } from "rxjs";
import {BoxParam} from "../models/box-param";
import {environment} from "../../environments/environment";
import {UtilService} from "../utils/util.service";
import {HttpHeaders} from "@angular/common/http";
import {ApiService} from "shared/app/index";

@Injectable({
	providedIn: 'root',
})
export class BoxConfigurationService {
	boxParam: BehaviorSubject<BoxParam> = new BehaviorSubject<BoxParam>(null);

	constructor(private apiService: ApiService, private utilService: UtilService) {

	}

	public getBoxParams(resellerShortCode: string): Observable<BoxParam> {
		let headerBrand = new HttpHeaders({
			'BRAND': resellerShortCode,
		});
		return this.apiService.get('/branding-configuration', {
			headers: headerBrand
		})
	}

	public isBoxFeatureEnabled() {
		return environment.box_feature;
	}

	public getPrimaryColor(): string {
		return this.boxParam.getValue().mainColor;
	}
	public getSecondaryColor(): string {
		return this.boxParam.getValue().secondaryColor;
	}

	public getLogoUrl(): string {
		return this.boxParam.getValue().logo;
	}

	public getName(): string {
		return this.boxParam.getValue().name;
	}

	public getShortCode(): string {
		return this.boxParam.getValue().shortCode;
	}

}
