import { NgModule } from '@angular/core';
import { RadioDirective } from './radio.directive';

@NgModule({
	declarations: [
		RadioDirective,
	],
	exports: [
		RadioDirective,
	],
})
export class RadioModule {}
