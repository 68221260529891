import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Address, AddressService } from 'shared/app/index';
import { Observable } from 'rxjs';
import { AddressFormModelService } from '../../../../services/address-form-model.service';
import { Constants } from '../../../../utils/constants';
import { take } from 'rxjs/operators';
import { CDSToastService } from '../../../../services/cds-toast.service';

@Component({
	selector: 'address-single-box, [address-single-box]',
	templateUrl: 'address.single-box.component.html',
})
export class AddressSingleBoxComponent implements OnInit, OnDestroy {

	@Input() address: Address;
	@Output() editMode = new EventEmitter<{}>();
	@Output() deleted = new EventEmitter<{}>();
	isBlur = false;
	deleteMode: boolean = false;
	@Input() events: Observable<boolean>;
	private eventsSubscription: any;
	profiles = Constants;
	isLoading: boolean;
	availableCountryField: string;

	constructor(private addressService: AddressService, private addressFormService: AddressFormModelService, private cdsToast: CDSToastService) {

	}

	ngOnInit(): void {
		this.eventsSubscription = this.events.subscribe((data) => this.isBlur = data);
		this.addressFormService.isCountryFieldSupported() && this.addressFormService.getCountryName(this.address.country).subscribe(name => {
			this.availableCountryField = name;
		});
	}

	ngOnDestroy() {
		this.eventsSubscription.unsubscribe();
	}

	editAddressMode() {
		this.editMode.emit();
		this.isBlur = !this.isBlur;
	}

	processDelete(doDelete: boolean) {
		this.deleteMode = false;

		if (doDelete) {
			this.isLoading = true;
			this.addressService.delete(this.address.objectId).pipe(take(1)).subscribe(r => {
				this.cdsToast.showSuccess('Address deleted successfully');
				this.deleted.emit();
				this.isLoading = false;
			}, error => {
				this.cdsToast.showDanger('Error while deleting ' + error.error);
				this.isLoading = false;
			});
		}
	}

	showDeleteButton(): boolean {
		return this.addressFormService.isAddressDeletable(this.address.addressTypeCode);
	}

	isDefined() {
		if (this.address.city != null || this.address.postCode != null || this.address.houseNumber != null || this.address.street != null) {
			return true;
		}
	}
}
