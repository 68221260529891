import { EMPTY, Observable } from 'rxjs';

export function isHTMLElement(element: any): element is HTMLElement {
	return element instanceof HTMLElement;
}

export function noop() {}

export function identity(value: any) {
	return value;
}

export interface ConsumerFunction {
	(value: any): void;
}

export interface RunnableFunction {
	(): void;
}

export function groupBy<ItemType>(array: ItemType[], keyGetter: (item: ItemType) => string): { [key: string]: ItemType[] } {
	const result: { [key: string]: ItemType[] } = {};
	for (const item of array) {
		const key: string = keyGetter(item);
		if (Array.isArray(result[key])) {
			result[key].push(item);
		} else {
			result[key] = [item];
		}
	}
	return result;
}

export function keyBy<ItemType>(array: ItemType[], keyGetter: (item: ItemType) => string): { [key: string]: ItemType }  {
	const result: { [key: string]: ItemType } = {};
	for (const item of array) {
		const key: string = keyGetter(item);
		result[key] = item;
	}
	return result;
}

export function keyByMap<ItemType, MappedType>(array: ItemType[], keyGetter: (item: ItemType) => string, mapper: (item: ItemType) => MappedType): { [key: string]: MappedType }  {
	const result: { [key: string]: MappedType } = {};
	for (const item of array) {
		const key: string = keyGetter(item);
		result[key] = mapper(item);
	}
	return result;
}

export function replaceAtIndex<ItemType>(index: number, replaceWith: ItemType): (item: ItemType, currentIndex: number) => ItemType {
	return (item, currentIndex) => {
		if (currentIndex === index) {
			return replaceWith;
		} else {
			return item;
		}
	};
}

export function clone<ObjectType>(object: ObjectType): ObjectType {
	return JSON.parse(JSON.stringify(object));
}

export function field(field: string): (obj: any) => any {
	return obj => obj && obj[field];
}

export function arraysEqual<ItemType>(a1: ItemType[], a2: ItemType[]): boolean {
	return a1.length === a2.length && a1.every((value, i) => value === a2[i]);
}

export function ignoreError<T>(err: any, caught: Observable<T>): Observable<T> {
	return EMPTY;
}

export function onNextMicrotask(callback: () => void): void {
	Promise.resolve().then(callback);
}
