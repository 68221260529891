export class XVLOConstants {
	public static CUSTOMER_CODE_LARGE_ENTERPRISE = 'Large Enterprise';
	public static SMALL_OR_MEDIUM_ENTERPISE = 'Small/Medium Enterprise';

	public static PROVINCES: string[] = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'];

	public static DISTRIBUTION_CHANNEL_TYPE_EMAIL = 'E';

	// ADDRESSES
	public static ADDRESS_TYPE_RESIDENTIAL = 'Residential address';
	public static DEV_DOMAIN = 'dev-valonetworks.net';
	public static TEST_DOMAIN = '@test-valonetworks.net';
	public static PROD_DOMAIN = '@valonetworks.net';
}
