import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { __ as translate } from '@comarch/seed-core/lib/i18n';
import { AppPath } from '../utils/app-path';
import { ContextService } from '../services/context.service';
import { AuthenticationService } from '../services/authentication.service';
import { CDSToastService } from '../services/cds-toast.service';
import { environment } from '../../environments/environment';
import {AppPathService} from "../services/app-path.service";
import {viewKey} from "../decorators/decorators";
import {ViewKeys} from "../utils/view-keys";
import {AppProfile} from "../utils/app-profile";
import {ProfileService} from "../services/profile.service";
import {KeycloakService} from "keycloak-angular";

@Injectable({
	providedIn: 'root',
})
export class WebshopVisibleGuard implements CanActivate {

	private readonly DEFAULT_BLOCKED_REDIRECT = AppPath.PATH_LOGIN;
	isLogged: boolean = false;
	constructor(private authService: AuthenticationService,
				public router: Router,
				private pathService: AppPathService,
				private  contextService: ContextService,
				private profileService: ProfileService,
				protected keycloakService: KeycloakService,
				private cdsToastService: CDSToastService) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		this.keycloakService.isLoggedIn().then((result) => {
			this.isLogged = result;
			if (!this.isPageVisible(route, this.authService, this.profileService, this.isLogged)) {
				return this.redirectToAllowedPage(route);
			}
		});
		return true;
	}

	redirectToAllowedPage(route: ActivatedRouteSnapshot): boolean {
		this.pathService.navigate([route.data.redirectWhenBlocked || this.DEFAULT_BLOCKED_REDIRECT]);
		return false;
	}

	isPageVisible(route: ActivatedRouteSnapshot, authService: AuthenticationService, profileService: ProfileService, isLogged: boolean): boolean {
		if (ViewKeys.V_WEBSHOP_ALLOW_VIEW.toString()===route.data.viewKey.toString()) {
			if (!profileService.isProfileXEHC() || !isLogged) {
				return true;
			} else if (route.data.viewKey && !authService.hasPrivilege(route.data.viewKey)) {
				return false;
			}
		}
		return true;
	}

}
