import { Injectable } from '@angular/core';
import {Title} from "@angular/platform-browser";
import {config} from "config";
import {locales} from "locales";
import { AppProfile } from '../utils/app-profile';

@Injectable({
	providedIn: 'root',
})
export class DynamicTitleService {

	constructor(private titleService: Title) {

	}

	setPageTitle(title: string) {
		this.titleService.setTitle(title);
	}

	setPageTitleWithBrand(title: string) {
		if(config.custom == AppProfile.XVLO) {
			this.titleService.setTitle(title + " " + locales[config.theme].rsp_name)
		}
	}

}
