import { Injectable} from "@angular/core";
import { HttpParams} from "@angular/common/http";
import {Observable} from "rxjs/Observable";
import {ApiService, LocationAddress} from "shared/app/index";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {UtilService} from "../../utils/util.service";
import {environment} from "../../../environments/environment";

@Injectable({
	providedIn: 'root',
})

export class ServiceabilityCheckService {

	providedLocation: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	showModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	headerBrand  = this.utilService.injectHeaders();
	private LOCALSTORAGE_LOCATION_KEY = 'location';

	constructor(private apiService: ApiService, private utilService: UtilService) {

	}


	getAddressSuggestion(requestedField, value, parent_id?): Observable<any> {
		let params = new HttpParams();
		params = params.set('requested-field', requestedField.toString());
		params = params.set('value', value.toString());
		if(parent_id) params = params.set('parent-id', parent_id.toString());
		return this.apiService.get('/global/addresses/suggestions', {
			headers: this.headerBrand,
			params: params
		});
	}

	isCacheData(): boolean {
		if(!localStorage.getItem(this.LOCALSTORAGE_LOCATION_KEY)) {
			return false;
		} else {
			let data: LocationAddress = JSON.parse(localStorage.getItem(this.LOCALSTORAGE_LOCATION_KEY));
			if(!data.stateProvince || !data.city || !data.postcode || !data.street) {
				return false;
			} else {
				return true;
			}
		}
	}

	getCacheData(): LocationAddress  {
		let location: LocationAddress = null;
		if(this.isCacheData()) {
			location = JSON.parse(localStorage.getItem(this.LOCALSTORAGE_LOCATION_KEY))
		} else if(location && (!location.stateProvince || !location.city || !location.street)){
			return null;
		}
		this.providedLocation.next(location);
		return location;
	}

	setCacheData(data: LocationAddress) {
		localStorage.removeItem(this.LOCALSTORAGE_LOCATION_KEY);
		let location: LocationAddress;
		data ? location = data : location = null;
		const dateNow = new Date();
		dateNow.setHours(dateNow.getHours() + 120);
		localStorage.setItem(this.LOCALSTORAGE_LOCATION_KEY, JSON.stringify({...location, created: dateNow}))
		this.providedLocation.next(location);
	}

	getAvailableFromDate() {
		let availableDate = (this.providedLocation.getValue() && this.providedLocation.getValue().availableFromDate) ? this.providedLocation.getValue().availableFromDate : null;
		if(availableDate) {
			let availableDateObject = new Date(availableDate);
			let currentDateObject = new Date();
			return (availableDateObject.getTime() > currentDateObject.getTime()) ? availableDateObject : null;
		} else {
			return null;
		}
	}


	isLocationSupported(): boolean {
		let isSupported = (this.getCacheData() && this.getCacheData().isSupported) ? this.getCacheData().isSupported : false;
		return isSupported;
	}

	isServiceabilityCheckEnabled() {
		return environment.webshop_serviceability_check;
	}
}
