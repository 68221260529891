export class ConfigurationBoolean {
	booleanValue: boolean;

	constructor(value: String | Boolean) {

		if (value == null) {
			this.booleanValue = false;
		}

		if (typeof value === 'boolean') {
			this.booleanValue = value;
		} else if (typeof value == 'string') {
			this.booleanValue = value.toLowerCase().trim() === 'true';
		} else {
			this.booleanValue = false;
		}
	}

	public value(): boolean {
		return this.booleanValue;
	}
}
