import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {RequestUtil} from "../utils";
import {ApiService} from "./api.service";
import {Subscription} from "../models";

@Injectable({
	providedIn: 'root',
})
export class SubscriptionService {

	constructor(protected requestUtil: RequestUtil, protected apiService: ApiService) {

	}

	getSubscriptionInfo(objectId: number): Observable<Subscription> {
		return this.apiService.get(`/subscriptions/${objectId}`);
	}

	modifySubscription(subscription: Subscription): Observable<Subscription> {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type':  'application/json',
			}),
			responseType: 'text',
		};
		return this.apiService.post('/subscriptions/modify', subscription, httpOptions);
	}

	getSubscriptionBindingPeriod(subscriptionId: number): Observable<any> {
		return this.apiService.get('/subscriptions/' + subscriptionId + '/binding-period');
	}

	getTransitions(subscriptionId: number, types: string[]): Observable<any> {
		let params = new HttpParams();
		params = params.append('types', types.toString());
		return this.apiService.get('/subscriptions/' + subscriptionId + '/transitions', {
			headers: this.requestUtil.injectHeaders(),
			params: params,
		});
	}
}
