import { Pipe, PipeTransform } from '@angular/core';
import { XICTConstants } from '../../utils/xict/xict-constants';

@Pipe({
	name: 'XICTSSHKeyPipe',
	pure: true,
})
export class XICTSSHKeyPipe implements PipeTransform {
	transform(value: any, ...args: any[]): any {
		if (value && value.endsWith(XICTConstants.MAIN_USER_KEY_SUFFIX)) {
			return value.substring(0, value.indexOf(XICTConstants.MAIN_USER_KEY_SUFFIX));
		} else {
			return value;
		}

	}
}
