import { Pipe, PipeTransform } from '@angular/core';
import { __ as translate } from '@comarch/seed-core/lib/i18n';

@Pipe({name: 'transactionStatePipe'})
export class TransactionStatePipe implements PipeTransform {
	transform(state: string, prefix?: string): string {
		switch (state) {
			case 'SUCCESSFUL':
				return translate('app.state.paid');
			default:
				return;
		}
	}
}