import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import {Address, AddressService, Customer, CountryService} from "shared/app/index";
import {FormBuilder, FormGroup} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {FormUtils} from '../../../../utils/form-utils';
import {AddressFormModelService} from '../../../../services/address-form-model.service';
import {Store} from '@ngrx/store';
import {ContextState} from '../../../../core/store/state/context.state';
import {getCustomer} from '../../../../core/store/selectors/context.selectors';
import {switchMap, take, first} from 'rxjs/operators';
import {ValueListItem} from '../../../../models/value-list-item';
import {Observable} from 'rxjs';
import {CDSToastService} from '../../../../services/cds-toast.service';
import {ProfileService} from '../../../../services/profile.service';
import { __ as translate } from '@comarch/seed-core/lib/i18n';
import { AppProfile } from '../../../../utils/app-profile';

@Component({
	selector: 'address-edit',
	templateUrl: 'address.edit.component.html',
})

export class AddressEditComponent implements OnChanges, OnInit {

	@Input() addressInput: Address;
	private address: Address;
	profiles = AppProfile;
	editMode: boolean = true;
	addressDetailsForm: FormGroup;
	submitted = false;
	@Output() endEdit: EventEmitter<boolean> = new EventEmitter<boolean>();
	initialized = false;
	mainCountries: ValueListItem[] = [];
	countrySearchValue: string = '';
	countryPlaceHolder: string  = null;
	placeholderProvince: string = null;
	regions: ValueListItem[] = []

	constructor(private addressService: AddressService, private cdsToast: CDSToastService, private formBuilder: FormBuilder, private formUtils: FormUtils,
							public addressFormService: AddressFormModelService, private store: Store<ContextState>, private profile: ProfileService,
							private countryService: CountryService) {
	}

	private getRegions(countryId: number){
		this.countryService.getRegions(countryId)
        	.pipe(take(1))
        	.subscribe((regions)=>{
        		if(regions && regions.length > 0){
        			this.regions = regions;
        		}
        })
	}

	private prepareRegionsAndPlaceholders(countries: any[]){
		if(this.addressDetailsForm.controls.country.value){
			this.countryPlaceHolder = this.addressDetailsForm.controls.country.value;
			const countryId = countries.filter(country=>(country.stringValue) === this.countryPlaceHolder)[0].id
			this.getRegions(countryId)
			if(this.addressDetailsForm.controls['stateProvince'].value){
				this.placeholderProvince = this.addressDetailsForm.controls['stateProvince'].value;
            }
		}else{
			this.addressDetailsForm.controls['stateProvince'].disable();
		}
	}

	ngOnInit(): void {
		this.address = this.addressInput;
		this.addressDetailsForm = this.addressFormService.prepareAddressEditFormGroup(this.address, false, true);

		this.prepareCountryList().subscribe(value => {
			this.mainCountries = value;
			this.prepareRegionsAndPlaceholders(this.mainCountries);
			this.initialized = true;
		});
	}

	ngAfterViewInit(){
		this.checkCountry();
	}

	private prepareCountryList(): Observable<ValueListItem[]> {
		if (this.profile.isProfileXICT() && !this.addressFormService.isInvoiceAddress(this.address.addressTypeCode)) {
			return this.store.select(getCustomer).pipe(switchMap((customer: Customer) => {
				return this.addressService.getAvailableCountriesByCustomerType(customer.customerTypeCode);
			}), take(1));
		} else {
			return this.countryService.getAllCountries().pipe(first())
		}
	}

	selectCountry(country){
		this.countryPlaceHolder = country.stringValue ? country.stringValue : this.countryPlaceHolder;
		this.getRegions(country.id);
		this.placeholderProvince = '';
		this.addressDetailsForm.patchValue({stateProvince:''})
		this.checkCountry()
	}

	private checkCountry(){
		if (this.addressDetailsForm.controls['country'].value){
			this.addressDetailsForm.controls['stateProvince'].enable();
		}
		else{
			this.addressDetailsForm.controls['stateProvince'].disable();
		}
	}
	saveAddress() {
		this.submitted = true;
		if (this.validateForm()) {
			this.editMode = false;
			Object.assign(this.address, this.addressDetailsForm.value);
			this.address.stateProvince = this.addressDetailsForm.value.stateProvince
			this.address.province = this.addressDetailsForm.value.stateProvince
			this.address.postCode = this.addressDetailsForm.value.postcode;
			this.addressService.updateAddress(this.address).subscribe(() => {
				this.cdsToast.showSuccess('Address modified successfully');
				this.endEdit.emit(true);
				this.submitted = false;
			}, (error: HttpErrorResponse) => {
				this.cdsToast.showDanger('Cannot modify address.' + error.message + error.status);
				this.endEdit.emit(false);
				this.submitted = false;
			});

		}
	}

	private validateForm() {
		console.dir(this.addressDetailsForm.valid)
		console.dir(this.addressDetailsForm)
		if (!this.addressDetailsForm.valid) {
			this.formUtils.showAllFormErrors(this.addressDetailsForm);
		}
		return this.addressDetailsForm.valid;
	}

	isControlInvalid(control: string) {
		return this.formUtils.shouldShowErrors(control, this.addressDetailsForm);
	}

	endEditMode() {
		this.endEdit.emit(false);
	}

	ngOnChanges(changes: SimpleChanges): void {
		const addressInput: SimpleChange = changes.addressInput;
		this.address = addressInput.currentValue;
		if (this.initialized) {
			this.initialized = false;
			this.ngOnInit();
		}
	}

	validationFormatMessage = {
		postcode: {
			pattern: translate('validation.message.post_code_format'),
		},
	};
}
