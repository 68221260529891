import { Injectable } from '@angular/core';
import { config } from 'config';
import { AppProfile } from '../utils/app-profile';

@Injectable({
	providedIn: 'root',
})
export class ThemeService {
	isThemeDefault() {
		return config.theme == AppProfile.DEFAULT
	}
	isThemeXDELTA() {
		return config.theme == AppProfile.XDELTA
	}
}
