import { Pipe, PipeTransform } from '@angular/core';
import { config } from 'config';
import { Constants } from '../utils/constants';
import { CurrencyPipe } from '@angular/common';
import { AppProfile } from '../utils/app-profile';

@Pipe({
	name: 'currencyProfile',
})
export class CurrencyProfilePipe implements PipeTransform {

	constructor(private currencyPipe: CurrencyPipe) {

	}

	transform(val: number, currencyCode?: string): string {
		switch (config.custom) {
			case AppProfile.XICT:
			case AppProfile.XICT_INTERNAL:
				if (currencyCode && currencyCode.length === 3) {
					return currencyCode + ' ' + Number(val).toLocaleString(config.lang, {minimumFractionDigits: 2});
				} else {
					return this.currencyPipe.transform(val, currencyCode ? currencyCode : Constants.PROFILE_CURRENCY_XICT);
				}
			case AppProfile.XVLO:
				return this.currencyPipe.transform(val, Constants.PROFILE_CURRENCY_XVLO).toString().toLocaleString();
				break;
			case AppProfile.XEHC:
				if(val % 1 == 0) {
					return this.currencyPipe.transform(val, Constants.PROFILE_CURRENCY_XEHC, 'symbol', '1.0-0');
				}
				return this.currencyPipe.transform(val, Constants.PROFILE_CURRENCY_XEHC, 'symbol', '1.2-2');
			default:
			    if(currencyCode && currencyCode.length >= 1)
					return this.currencyPipe.transform(val, currencyCode ? currencyCode : Constants.PROFILE_CURRENCY_XICT);
				return this.currencyPipe.transform(val, Constants.PROFILE_CURRENCY_DEFAULT);
				break;
		}
	}
}
