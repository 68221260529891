import { AbstractControl, AsyncValidatorFn, FormGroup, ValidatorFn } from '@angular/forms';
import { TaxIdValidationUtils } from '../utils/tax-id-validation-utils';
import { of } from 'rxjs';

export function TaxIdFormatValidator(countryCodeProvider: () => string): ValidatorFn {
	return (control: AbstractControl) => {
		const taxId = control.value ? (control.value as string).toUpperCase() : '';
		if (taxId == '') {
			return null;
		}
		const countryCode = countryCodeProvider();

		const patternWithRegexp = countryCode != null ? TaxIdValidationUtils.getTaxIdPatternForCountryCode(countryCode) : null;
		if (patternWithRegexp != null) {
			if (taxId && patternWithRegexp.regexp.test(taxId)) {
				return null;
			} else {
				return {taxIdFormat: {params: patternWithRegexp.pattern.join('\n  ')}};
			}
		} else {
			if (taxId && /^([A-Za-z0-9]+\-?[A-Za-z0-9]+)+$/.test(taxId)) {
				return null;
			} else {
				return {pattern: true};
			}
		}
	};
}

export function TaxIdValueValidator(countryCodeProvider: () => string): AsyncValidatorFn {
	return (control: AbstractControl) => {
		const taxId = control.value ? (control.value as string).toUpperCase() : '';
		const countryCode = countryCodeProvider();

		const result = TaxIdValidationUtils.validateTaxIdChecksum(taxId, countryCode);

		if (result === false) {
			return of({taxIdChecksum: true});
		} else {
			return of(null);
		}
	};
}
