import { Action, createAction } from '@ngrx/store';
import { AuthInfo } from '../../../models/auth-info';
import {Contact, Customer, User} from "shared/app/index";

export enum ContextActionsTypes {
	AddContextBanners = '[Context] Add Banners',
	AddContextBannersSuccess = '[Context] Add Banners Success',
	AddContextUser = '[Context] Add User',
	AddContextData = '[Context] Add Data',
	AddContextDataSuccess = '[Context] Add Data Success',
	AddContextUserSuccess = '[Context] Add User Success',
	AddContextUserContact = '[Context] Add User Contact',
	AddContextUserContactSuccess = '[Context] Add User Contact Success',
	AddContextCustomer = '[Context] Add Customer',
	AddContextCustomerSuccess = '[Context] Add Customer Success',
	AddContextAccount = '[Context] Add Account',
	AddContextAccountSuccess = '[Context] Add Account Success',
	AddContextAccounts = '[Context] Add Multiple Accounts',
	AddContextAccountsSuccess = '[Context] Add Multiple Accounts Success',
	AddContextInvoicesToPay = '[Context] Add Invoices To Pay',
	AddContextInvoicesToPaySuccess = '[Context] Add Invoices To Pay Success',
	GetContext = '[Context] Get Context',
	GetContextSuccess = '[Context] Get Context Success',
	ClearContext = '[Context] Clear Context',
	SetContextCustomer = '[Context] Set Customer',
	SetContextCustomerSuccess = '[Context] Set Customer Success',
	SetContextLastUnreadNotification = '[Context] Set Last Unread Notification'
}

export class ClearContext implements Action {
	readonly type = ContextActionsTypes.ClearContext;
}

export class AddContextUser implements Action {
	readonly type = ContextActionsTypes.AddContextUser;

	constructor(public payload: User) {
	}
}

export class AddContextData implements Action {
	readonly type = ContextActionsTypes.AddContextData;

	constructor(public payload: AuthInfo) {
	}
}

export class AddContextDataSuccess implements Action {
	readonly type = ContextActionsTypes.AddContextDataSuccess;

	constructor(public payload: AuthInfo) {
	}
}


export class AddContextUserSuccess implements Action {
	readonly type = ContextActionsTypes.AddContextUserSuccess;

	constructor(public payload: User) {
	}
}

export class AddContextUserContact implements Action {
	readonly type = ContextActionsTypes.AddContextUserContact;

	constructor(public payload: Contact) {
	}
}

export class AddContextUserContactSuccess implements Action {
	readonly type = ContextActionsTypes.AddContextUserContactSuccess;

	constructor(public payload: Contact) {
	}
}

export class AddContextCustomer implements Action {
	readonly type = ContextActionsTypes.AddContextCustomer;

	constructor(public payload: Customer) {
	}
}

export class AddContextCustomerSuccess implements Action {
	readonly type = ContextActionsTypes.AddContextCustomerSuccess;

	constructor(public payload: Customer) {
	}
}

export class AddContextAccount implements Action {
	readonly type = ContextActionsTypes.AddContextAccount;

	constructor(public payload: any) {
	}
}

export class AddContextAccountSuccess implements Action {
	readonly type = ContextActionsTypes.AddContextAccountSuccess;

	constructor(public payload: any) {
	}
}

export class AddContextAccounts implements Action {
	readonly type = ContextActionsTypes.AddContextAccounts;

	constructor(public payload: any) {
	}
}

export class AddContextAccountsSuccess implements Action {
	readonly type = ContextActionsTypes.AddContextAccountsSuccess;

	constructor(public payload: any) {
	}
}

export class GetContext implements Action {
	readonly type = ContextActionsTypes.GetContext;
}

export class GetContextSuccess implements Action {
	readonly type = ContextActionsTypes.GetContextSuccess;

	constructor(public payload: any) {
	}
}

export class AddContextInvoicesToPay implements Action {
	readonly type = ContextActionsTypes.AddContextInvoicesToPay;

	constructor(public payload: any[]) {
	}
}

export class AddContextInvoicesToPaySuccess implements Action {
	readonly type = ContextActionsTypes.AddContextInvoicesToPaySuccess;

	constructor(public payload: any[]) {
	}
}

export class AddContextBanners implements Action {
	readonly type = ContextActionsTypes.AddContextBanners;

	constructor(public payload: any) {
	}
}

export class AddContextBannersSuccess implements Action {
	readonly type = ContextActionsTypes.AddContextBannersSuccess;

	constructor(public payload: any) {
	}
}

export class SetContextCustomer implements Action {
	readonly type = ContextActionsTypes.SetContextCustomer;

	constructor(public payload: any) {
	}
}

export class SetContextCustomerSuccess implements Action {
	readonly type = ContextActionsTypes.SetContextCustomerSuccess;

	constructor(public payload: any) {
	}
}

export class SetContextLastUnreadNotification implements Action {
	readonly type = ContextActionsTypes.SetContextLastUnreadNotification;

	constructor(public payload: any) {
	}
}


export type ContextActions =
		AddContextUser
		| AddContextUserSuccess
		| AddContextUserContact
		| AddContextUserContactSuccess
		| AddContextAccount
		| AddContextAccountSuccess
		| AddContextInvoicesToPay
		| AddContextInvoicesToPaySuccess
		| GetContext
		| GetContextSuccess
		| ClearContext
		| AddContextBanners
		| AddContextBannersSuccess
		| AddContextData
		| SetContextCustomer
		| SetContextCustomerSuccess
		| SetContextLastUnreadNotification;
