import {Inject, Injectable} from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class RequestUtil {

  constructor(@Inject('env') public env) {

  }

	public injectHeaders(): HttpHeaders {
		const brand = this.env.brand;
		if (brand.checkBrand) {
			return new HttpHeaders({
				'BRAND': brand.brandCode,
			});
		}
		return new HttpHeaders();
	}
}
