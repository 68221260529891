import { Directive, ElementRef } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';

@Directive({
	selector: '[caVirtualizationContainer]',
})
export class VirtualizationContainerDirective {

	readonly scroll$: Observable<UIEvent>;

	constructor(elementRef: ElementRef) {
		this.scroll$ = fromEvent(elementRef.nativeElement, 'scroll');
	}
}
