import { initialContextState, XICTContextState } from '../state/xict-context.state';
import { XICTContextActionsTypes } from '../actions/xict-context.actions';

export function xictContextReducer(
		state = initialContextState,
		action: any,
): XICTContextState {
	switch (action.type) {
		case XICTContextActionsTypes.AddContextCloudProductsSuccess:
			return {...state, cloudProducts: action.payload};
		case XICTContextActionsTypes.GetContextSuccess:
			return {
				...state,
				cloudProducts: action.payload && action.payload.cloudProducts || [],
			};
		case XICTContextActionsTypes.ClearContext:
			return {
				...state,
				cloudProducts: [],
			};
		default:
			return state;
	}
}
