import { Component, Injector, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalService } from './modal.service';

@Component({
	selector: 'ca-modal-placeholder',
	template: '<div #caModalPlaceholder></div>',
})
export class ModalPlaceholderComponent implements OnInit {

	@ViewChild('caModalPlaceholder', { read: ViewContainerRef, static: true })
	viewContainerRef: ViewContainerRef;

	constructor(
			private modalService: ModalService,
			private injector: Injector) {}

	ngOnInit(): void {
		this.modalService.registerViewContainerRef(this.viewContainerRef);
		this.modalService.registerInjector(this.injector);
	}
}
