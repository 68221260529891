import { PasswordReset } from 'shared/app';
export class PasswordResetDTO implements PasswordReset {
  email: string;
  login: string;

  constructor(object: PasswordReset) {
    this.email = object.email;
    this.login = object.login;
  }
}
