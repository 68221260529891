import {
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
	Injectable,
	Injector,
	Optional,
	Type,
	ValueProvider,
	ViewContainerRef,
} from '@angular/core';
import { Observable } from 'rxjs';
import { RouterFlowStrategyService } from '../../core/router-flow/router-flow-strategy.service';
import { BaseModalInstance, ModalInstance } from './modal-instance';
import { ModalInstanceRegistry } from './modal-instance-registry';

@Injectable()
export class ModalService {

	private vcRef: ViewContainerRef;
	private injector: Injector;
	private registry: ModalInstanceRegistry = new ModalInstanceRegistry();

	constructor(
			private resolver: ComponentFactoryResolver,
			@Optional() flowStrategy: RouterFlowStrategyService) {

		if (flowStrategy) {
			flowStrategy.onPopState(event => {
				if (!flowStrategy.isProgrammatic(event)) {
					this.registry.forEach(instance => {
						instance.dismiss(event);
					});
				}
			});
		}
	}

	getResolver(): ComponentFactoryResolver {
		return this.resolver;
	}

	registerViewContainerRef(vcRef: ViewContainerRef): void {
		this.vcRef = vcRef;
	}

	registerInjector(injector: Injector): void {
		this.injector = injector;
	}

	create(component: Type<any>, parameters?: any): Observable<any> {
		if (!this.vcRef || !this.injector) {
			console.error('ModalService: one of ViewContainerRef and Injector as missing. Did You use ModalPlaceholderComponent?');
		}
		return new Observable<any>(subscriber => {
			let teardown: boolean = true;
			function resolve(value) {
				teardown = false;
				subscriber.next(value);
				subscriber.complete();
			}
			function reject(error) {
				teardown = false;
				subscriber.error(error);
			}
			try {
				const resolveComponent: ComponentFactory<any> = this.getResolver().resolveComponentFactory(component);
				const instance: ModalInstance<any, any> = new ModalInstance<any, any>(parameters, resolve, reject, this.registry);
				instance.onDestroy(next => {
					componentRef.destroy();
					next();
				});
				const provider: ValueProvider = {provide: ModalInstance, useValue: instance};
				const provider2: ValueProvider = {provide: BaseModalInstance, useValue: instance};
				const childInjector = Injector.create({providers: [provider, provider2], parent: this.injector});
				const componentRef: ComponentRef<any> = this.vcRef.createComponent(resolveComponent, undefined, childInjector);

				return () => {
					if (teardown) {
						teardown = false;
						instance.dismiss();
					}
				};
			} catch (e) {
				console.error(e);
			}
		});
	}

	open(component: Type<any>, parameters?: any): Promise<any> {
		return this.create(component, parameters).toPromise();
	}
}
