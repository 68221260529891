import { createFeatureSelector, createSelector } from '@ngrx/store';
import {CartState} from "../state/cart.state";

export const getCartState = createFeatureSelector('cart');

export const getCart = createSelector(
	getCartState,
	(state: CartState) => state
);

export const getCartItems = createSelector(
	getCartState,
	(state: CartState) => state.items

);

export const getCartCurrency = createSelector(
	getCartState,
	(state: CartState) => {
		return state.items[0].price.length > 0 ? state.items[0].price[0].currency : state.items[0].price.currency
	}

);
