<ng-template #simpleDialog let-dialog>
	<cds-dialog class="cds-dialog--large" [ngClass]="cssClasses" (dialogClose)="dialog.reject()">
		<cds-slot-title>
			{{title || 'Confirm'}}
		</cds-slot-title>
		<cds-slot-content>
			<div *ngIf="isLoading" class="py-5">
				<cds-spinner></cds-spinner>
			</div>
			<ng-content *ngIf="!isLoading">
			</ng-content>
		</cds-slot-content>
		<cds-slot-footer>
			<div *ngIf="error" class="cds-errors" style="margin-top: -15px">{{error}}</div>
			<div class="cds-dialog-buttons">
				<button *ngFor="let btn of params.buttons.slice().reverse(); let i = index" cdsButton
								[ngClass]="i == params.buttons.length-1 ?
								   (btn.danger ? 'cds-button-danger' : 'cds-button-primary') : ('cds-button-text px-5 '+ (btn.danger ? 'button-danger' : ''))"
								(click)="_onButtonClick(dialog, btn)" [disabled]="isLoading">
					{{btn.label}}
				</button>
			</div>
		</cds-slot-footer>
	</cds-dialog>
</ng-template>
