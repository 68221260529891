import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { cartReducer } from './core/store/reducers/cart.reducer';
import { CartEffects } from './core/store/effects/cart.effects';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { RadioModule } from 'framework/app/form/radio/radio.module';
import { TypeaheadModule } from 'framework/app/form/typeahead/typeahead.module';
import { ValidationModule } from 'framework/app/form/validation/validation.module';
import { CheckboxModule } from 'framework/app/form/checkbox/checkbox.module';
import { ComboModule } from 'framework/app/form/combo/combo.module';
import { ErrorsModule } from 'framework/app/form/errors/errors.module';
import { RateModule } from 'framework/app/form/rate/rate.module';
import { SwitchModule } from 'framework/app/form/switch/switch.module';
import { DropdownModule } from 'framework/app/form/dropdown/dropdown.module';
import { DatePickerModule } from 'framework/app/form/date-picker/date-picker.module';
import { DebounceModule } from 'framework/app/form/debounce/debounce.module';
import { TagModule } from 'framework/app/form/tag/tag.module';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FileDialogModule } from 'framework/app/form/file-dialog/file-dialog.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthenticationService } from './services/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { PipesModule } from './pipes/pipes.module';
import { SCCommonModule } from './common/sc-common.module';
import { StoreModule } from '@ngrx/store';
import { contextReducer } from './core/store/reducers/context.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ContextEffects } from './core/store/effects/context.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { config } from 'config';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'framework/app/ui/modal/modal.module';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { keycloakInitializer } from './app-init';
import { AuthInterceptor } from './selfcare/interceptors/auth.interceptor';
import { xictContextReducer } from './core/store/reducers/xict-context.reducer';
import { XICTContextEffects } from './core/store/effects/xict-context.effects';
import { SmartBSSLibModule } from 'shared/app/index';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';


@NgModule({
	imports: [
		HttpClientModule,
		AppRoutingModule,
		BrowserModule,
		BrowserAnimationsModule,
		CoreModule,
		CheckboxModule,
		ComboModule,
		CommonModule,
		DatePickerModule,
		DebounceModule,
		DropdownModule,
		ErrorsModule,
		FileDialogModule,
		RadioModule,
		RateModule,
		SwitchModule,
		TagModule,
		TypeaheadModule,
		ValidationModule,
		ToastrModule.forRoot({
			positionClass: 'toast-bottom-right',
			preventDuplicates: true,
		}),
		PipesModule,
		SCCommonModule,
		NgbTooltipModule,
		StoreModule.forRoot({context: contextReducer, cart: cartReducer, xictContext: xictContextReducer}),
		EffectsModule.forRoot([ContextEffects, CartEffects, XICTContextEffects]),
		ModalModule.forRoot(),
		StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
		KeycloakAngularModule,
		SmartBSSLibModule.forRoot({
			api_url: environment.api_url,
			webshop_after_login: environment.webshop_after_login,
			webshop_logged_user_offer: environment.webshop_logged_user_offer,
			brand: environment.brand,
		}), RecaptchaV3Module,
	],
	declarations: [
		AppComponent,
	],
	providers: [
		ReCaptchaV3Service,
		{ provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey },
		AuthenticationService,
		CookieService,
		CurrencyPipe,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		{
			provide: LOCALE_ID, useValue: config.lang,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: keycloakInitializer,
			multi: true,
			deps: [KeycloakService, AuthenticationService],
		},
	],
	entryComponents: [
		AppComponent,
	],
	bootstrap: [AppComponent],
})
export class AppModule {
}
