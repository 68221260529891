import { Directive, EventEmitter, Host, Optional, Output, Self } from '@angular/core';
import { InputComponent } from '@cds/components-angular';

/**
 * workaround for model resetting in resettable cds-input
 * https://medium.com/angular-in-depth/changing-the-behavior-of-a-3rd-party-angular-component-91f84fb9af28
 */
@Directive({
	selector: '[caInputResetting]',
})
export class ResettingInputDirective {
	// tslint:disable-next-line:no-output-native
	@Output()
	change: EventEmitter<any> = new EventEmitter<any>(); //intentionally same name as in cds-input

	constructor(@Host() @Self() @Optional() private cdsInput: InputComponent) {
		cdsInput.clear = (ev?: UIEvent) => {
			cdsInput.value = '';
			this.change.emit();
		};
	}
}
