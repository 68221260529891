import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValidationMessageMap } from '../../../../utils/validation-message-map';
import { AddressFormModelService } from '../../../../services/address-form-model.service';
import { FormUtils } from '../../../../utils/form-utils';
import {Address, AddressService} from "shared/app/index";
import { Store } from '@ngrx/store';
import { ContextState } from '../../../../core/store/state/context.state';
import {CDSToastService} from "../../../../services/cds-toast.service";
import { ValueListItem } from '../../../../models/value-list-item';

@Component({
	selector: 'app-create-address',
	templateUrl: 'address-create.component.html',
})
export class AddressCreateComponent {
	@Output() finished: EventEmitter<boolean> = new EventEmitter();
	@Output() cancelled: EventEmitter<{}> = new EventEmitter();
	invoiceAddressForm: FormGroup;
	validationMessages: ValidationMessageMap = {};
	availableCountries: ValueListItem[] = [];
	countrySearchValue: string = '';

	constructor(private addressService: AddressService, private addressFormService: AddressFormModelService, private formUtils: FormUtils, private cdsToast: CDSToastService, private store: Store<ContextState>) {
		//creates only invoice address
		this.addressService.getAvailableCountries().subscribe(value => {
			this.availableCountries = value
		});

		this.invoiceAddressForm = this.addressFormService.prepareAddressCreateFromGroup({} as Address, true, true);
	}

	createAddress() {
		if (this.invoiceAddressForm.invalid) {
			this.formUtils.showAllFormErrors(this.invoiceAddressForm);
			return;
		}

		const address: Address = {
			country: this.invoiceAddressForm.get('country').value,
			city: this.invoiceAddressForm.get('city').value,
			postCode: this.invoiceAddressForm.get('postCode').value,
			street: this.invoiceAddressForm.get('street').value,
			houseNumber: this.invoiceAddressForm.get('houseNumber').value,
			companyName: this.invoiceAddressForm.get('companyName').value,
			firstName: this.invoiceAddressForm.get('firstName').value,
			lastName: this.invoiceAddressForm.get('lastName').value,
			phoneNumber: this.invoiceAddressForm.get('phoneNumber').value,
			emailAddress: this.invoiceAddressForm.get('emailAddress').value
		} as Address;

		this.addressService.createInvoiceAddress(address).subscribe(() => {
			this.finished.emit(true);
			this.cdsToast.showSuccess('Address created')
		}, err => {
			this.finished.emit(false);
		});
	}
}
