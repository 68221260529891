export class XICTConstants {
	public static readonly ADDRESS_CODE_MAIN_PRIVATE = 'EXTERNAL_PRIVATE';
	public static readonly ADDRESS_CODE_MAIN_BUSINESS = 'EXTERNAL_BUSINESS';
	public static readonly ADDRESS_CODE_MAIN_DEMO = 'EXTERNAL_DEMO';
	public static readonly ADDRESS_CODE_INVOICE = 'EXTERNAL_INVOICE_ADDRESS';
	public static readonly USER_TYPE_SUPERUSER = 'SuperUser';
	public static readonly USER_TYPE_OPENSTACK = 'User';
	public static readonly USER_TYPE_RESTRICTED = 'UserAwaitingPaymentConfirmation';
	public static readonly INSTANCE_STATUS_OK = 'OK';
	public static readonly INSTANCE_STATUS_ERROR = 'ERROR';
	public static readonly CUSTOMER_TYPE_PRIVATE = 'EXTERNAL_PRIVATE';
	public static readonly CUSTOMER_TYPE_BUSINESS = 'EXTERNAL_BUSINESS';
	public static readonly CUSTOMER_TYPE_INTERNAL = 'COMARCH_INTERNAL';
	public static readonly CUSTOMER_TYPE_DEMO = 'EXTERNAL_DEMO';

	public static readonly CPC_ELEMENTS_TOPOLOGY_CODE = 'ELEMENTS';

	public static readonly MACHINE_STATUS_VERIFY_RESIZE = 'VERIFY_RESIZE';
	public static readonly MACHINE_STATUS_RESIZE = 'RESIZE';
	public static readonly MACHINE_STATUS_ACTIVE = 'ACTIVE';
	public static readonly MACHINE_STATUS_SHUTOFF = 'SHUTOFF';
	public static readonly MACHINE_STATUS_ERROR = 'ERROR';
	public static readonly MACHINE_STATUS_DELETING_ERROR = 'ERROR WHILE DELETING';
	public static readonly MACHINE_STATUS_SHUTTING_OFF = 'POWERING OFF';
	public static readonly MACHINE_STATUS_TURNING_ON = 'POWERING ON';
	public static readonly MACHINE_STATUS_REBOOTING = 'RESTARTING';
	public static readonly MACHINE_STATUS_DELETING = 'DELETING';
	public static readonly MACHINE_STATUS_INITIALIZING = "INITIALIZING";
	public static readonly MACHINE_STATUS_DELETED = 'DELETED';
	public static readonly MACHINE_STATUS_CREATING = 'BUILDING';
	public static readonly MACHINE_STATUS_BUILDING = 'BUILD';
	public static readonly MACHINE_STATUS_HARD_REBOOT = 'HARD_REBOOT';
	public static readonly MACHINE_STATUS_PAUSED = 'PAUSED';
	public static readonly MACHINE_STATUS_REBOOT = 'REBOOT';
	public static readonly MACHINE_STATUS_SUSPENDED = 'SUSPENDED';
	public static readonly MACHINE_STATUS_ACTIVE_DST = 'Active';
	public static readonly MACHINE_STATUS_ACTIVATING_DST = 'Activating DST';

	public static readonly MACHINE_SOFT_RESTART = 'SOFT';
	public static readonly MACHINE_HARD_RESTART = 'HARD';

	public static readonly MACHINE_ADDING_STARTED = 'MACHINE_ADDING_STARTED';

	public static readonly MACHINE_OK_HEALTH_VALUE = 'OK';

	public static readonly MACHINE_SHARED_CPU_MODE = 'shared';
	public static readonly MACHINE_DEDICATED_CPU_MODE = 'dedicated';

	public static readonly MB_UNIT = 'MB';
	public static readonly GB_UNIT = 'GB';

	public static readonly SVC_STORAGE_PROVIDER = 'SVC';
	public static readonly SSP_STORAGE_PROVIDER = 'SSP';

	public static readonly MAIN_USER_KEY_SUFFIX = '-main_user_temp_copy';
	public static readonly TEMPORARY_KEY_SUFFIX = '-temporary_key';

	public static readonly MACHINE_REFERENCE_CODE_WITH_ACTIVE_DST = 'A60050A0';

	public static readonly ROLE_CCI_USER = '[CCI] CMP - User';
	public static readonly ROLE_CCI_SUPERUSER = '[CCI] CMP - Administrator';
	public static readonly ROLE_CPC_USER = '[CPC] CMP - PM';
}
