<div class="row m-0">
	<div class="col-xl-5 offset-xl-3 col-lg-6 offset-lg-2 col-md-10 offset-md-1 col-sm-10 offset-sm-1 col-12 carousel-item__container">
		<div class="carousel-item__title" *ngIf="item.title">
			<div class="carousel-item-text__title">
				{{item.title}}
			</div>
		</div>
		<div class="carousel-item__description" *ngIf="item.description">
			<div class="carousel-item-text__description">
				{{item.description}}
			</div>
		</div>
		<div class="carousel-item__button" *ngIf="item.buttonName">
			<button cdsButton class="cds-button-primary" (click)="onClickEvent()">
				{{item.buttonName}}
			</button>
		</div>
	</div>
</div>