import {ApiService} from "./api.service";
import {RequestUtil} from "../utils";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({
	providedIn: 'root',
})

export class RegistrationService {

	constructor(protected apiService: ApiService, protected requestUtil: RequestUtil,
				protected http: HttpClient) {

	}

	public sendOrder(request): Observable<any> {
		return this.apiService.post('/global/registration', request, {
			headers: this.requestUtil.injectHeaders()
		})
	}

	public sendRequest(request): Observable<any> {
		return this.apiService.post('/global/registration/prospect', request, {
			headers: this.requestUtil.injectHeaders()
		})
	}

}
