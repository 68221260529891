export class SubscriptionSwitchOperation {

	subscriptionId: number;
	toProductOfferingId: number;
	toBindingPeriodId: number;
	requestedOperationDate: Date;
	requestedValidFrom: string;
	transitionType: string;
	type: string;
	salesChannelId: number;
	subOperations: SubscriptionSwitchOperation[];

}
