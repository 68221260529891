import { Offer } from 'shared/app';


export class OfferDTO implements Offer {
  id: number;
  name: string;
  description: string;
  salesChannelName: string;
  salesChannelId: number;
  salesChannelCode: string;

  constructor(object: Offer) {
    this.id = object.id;
    this.name = object.name
    this.description = object.description;
    this.salesChannelName = object.salesChannelName;
    this.salesChannelCode = object.salesChannelCode;
    this.salesChannelId = object.salesChannelId;
  }
}
