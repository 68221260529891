import {OfferDTO} from "./offer.dto";
import { Offer, ProductCatalog } from 'shared/app';

export class ProductCatalogDTO implements ProductCatalog {
  id: number;
  name: string;
  code: string;
  offers: Offer[] = [];

  constructor(object: ProductCatalog) {
    this.id = object.id;
    this.name = (object.name) ? object.name : object.id.toString()
    this.code = object.code;
    object.offers.forEach((offer: any) => {
      offer = {...offer, salesChannelName: this.name, salesChannelCode: this.code, salesChannelId: this.id};
      this.offers.push(new OfferDTO(offer));
    })
  }
}
