import {Subscription} from "shared/app/index";
import {Injectable} from "@angular/core";

export class SubscriptionUtils {
	public static isBundleItem(subscription: Subscription) {
		return !!subscription.parentSubscriptionId && !subscription.bundle;
	}

	public static hasBundleItems(subscription: Subscription) {
		return !subscription.parentSubscriptionId && subscription.bundle;
	}
}
