<div class="page-container">
	<app-header></app-header>
	<div class="container cds-grid--no-padding">
		<div class="row">
			<div class="col-md-6">
				<div class="container mt-5">
					<div class="row mb-4">
						<h1 class="cds-headline-1">Service temporarily unavailable.</h1>
					</div>
					<div class="row mb-4">
						<p class="cds-subtitle-1">Please try again later.</p>
					</div>
					<div class="row">
						<button type="submit" cdsButton class="cds-button-primary mr-2" (click)="home()">Home</button>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="not-found-right-image content-image">
				</div>
			</div>
		</div>
	</div>
	<app-footer></app-footer>
</div>
