import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {ApiService} from "./api.service";
import {FileService} from "./file.service";
import {STATE_CONST} from "../constants";
import { Page } from '../utils';
import { Invoice } from '../models';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class InvoicesService {

	private allowStates = [
		STATE_CONST.OST_ISSUED, STATE_CONST.OST_PARTIALLY_PAID, STATE_CONST.OST_WAIT_FOR_PAYMENT, STATE_CONST.OST_PENDING, STATE_CONST.OST_PAID, STATE_CONST.OST_ONLINE_PAID
	];


	constructor(protected http: HttpClient, protected apiService: ApiService, protected fileService: FileService) { }

	getCustomerInvoices(customerId: number, size?: number, page?: number, chosenPeriod?: { from: Date, to: Date }, states?: string[], invoiceNumber?: string): Observable<Page<Invoice>> {
		let params = new HttpParams();
		if (size != null && page != null) {
			params = params.set('size', size.toString()).set('page', page.toString());
		}
		if (chosenPeriod && chosenPeriod.from != null) {
			params = params.set('issuedAfter', new Date(chosenPeriod.from).toISOString());
		}
    if (chosenPeriod && chosenPeriod.to != null) {
      params = params.set('issuedBefore', new Date(chosenPeriod.to).toISOString());
    }
		if (states != null && states.length != 0) {
			params = params.set('states', states.toString());
		} else {
			params = params.set('states', this.allowStates.toString());
		}
		if (invoiceNumber != null && invoiceNumber != '') {
			params = params.set('invoiceNumber', invoiceNumber.toString());
		}
		return this.apiService.get('/v2/customers/' + customerId + '/invoices', {params: params});
	}

	getAllowStates(): string[] {
		return this.allowStates;
	}

	getInvoicesByDatePeriod(customerId: number, dateBefore: string, dateAfter: string) {
		let params = new HttpParams().set('states', this.allowStates.toString());
		if(dateBefore && dateAfter) params = new HttpParams().set('issuedBefore', dateBefore).set('issuedAfter', dateAfter);
		return this.apiService.get('/customers/' + customerId + '/invoices', {params: params});
	}

	getUnpaidInvoices(customerId: number) {
		const states = [STATE_CONST.OST_ISSUED, STATE_CONST.OST_PARTIALLY_PAID, STATE_CONST.OST_WAIT_FOR_PAYMENT, STATE_CONST.OST_PENDING];
		let params = new HttpParams().set('states', states.toString());
		return this.apiService.get('/v2/customers/' + customerId + '/invoices',{params: params});
	}

	getLastInvoices(customerId: number, size: number) {
		let params = new HttpParams().set('size', size.toString()).set('states', this.allowStates.toString());
		return this.apiService.get('/customers/' + customerId + '/invoices', {params: params});
	}

	getInvoiceDetails(invoiceId: number) {
		return this.apiService.get('/v2/invoices/' + invoiceId );
	}

	saveInvoicePdf(invoiceId: number) {
		return this.fileService.getFile('/v2/invoices/' + invoiceId + '/last-report');
	}

	getInvoicePayments(invoiceId: number) {
		return this.apiService.get('/v2/invoices/' + invoiceId + "/payments" );
	}

	getInvoiceItems(invoiceId: number) {
		return this.apiService.get('/v2/invoices/' + invoiceId + "/items" );
	}
}
