import {Component, Input} from '@angular/core';
import {ValidationMessageMap} from '../../utils/validation-message-map';
import {FormGroup} from '@angular/forms';

@Component({
	selector: 'crossfield-form-error',
	templateUrl: 'cross-field-form-error.component.html',
})
export class CrossFieldFormErrorComponent {

	@Input() allMessages: ValidationMessageMap = {};
	@Input() form: FormGroup;

	shouldShowErrors() {
		if (this.form.errors) {
			const isInvalidOrDirty = this.form.invalid && (this.form.touched || this.form.dirty);
			if (isInvalidOrDirty) {
				for (const error of Object.keys(this.form.errors)) {
					if (this.allMessages[error]) {
						return true;
					}
				}
			}
		}

		return false;
	}

	getErrorMessages() {
		const result: string[] = [];
		for (const error of Object.keys(this.form.errors)) {
			if (this.allMessages[error]) {
				result.push(this.allMessages[error]);
			}
		}

		return result;
	}

}
