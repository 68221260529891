<div class="container-brand">
	<div class="d-inline-block" *caCustomizationShow="[profiles.XVLO, profiles.DEFAULT]" (click)="homePage()">
		<div class="brand logo__container" [ngClass]="{'logo__contaner': !isBoxEnabled}"
			 [boxCustomizableElement]="'logo'" style="cursor:pointer;"></div>
		<div class="env-name" *ngIf="!isProdEnvironment">
			{{environmentName | uppercase}}
		</div>
	</div>
	<ng-container *caCustomizationShow="[profiles.XEHC]">
		<div class="d-flex" style="height: 100%; margin-left: 15px;">
			<div class="klinika24-w" style="height: 26px; margin: auto; position: relative; top: 2px;"></div>
			<div class="d-flex align-items-center text-nowrap">
				<div class="text-uppercase ml-4 d-none d-lg-block" style="color: #CD730D;"
						*ngIf="!isProdEnvironment && environmentName">
					|<span class="ml-4">{{environmentName}}</span>
				</div>
			</div>
		</div>
	</ng-container>
	<div *caCustomizationHide="[profiles.XEHC,profiles.XVLO, profiles.DEFAULT]" (click)="homePage()">
		<div class="d-inline-block">
			<div class="d-flex flex-column align-items-start">
				<div class="comarch-logo" style="cursor:pointer;"></div>
				<div style="color: #959595; margin-top: 2px" class="app-title">sc.default.rsp_name</div>
			</div>
		</div>
		<div class="d-inline-block" style="vertical-align: sub;">
			<div class="text-uppercase ml-4 align-self-end" style="color: #CD730D;"
				 *ngIf="!isProdEnvironment && environmentName">
				|&nbsp;&nbsp;&nbsp;{{environmentName}}
			</div>
		</div>
	</div>
</div>


