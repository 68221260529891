<data-not-found-placeholder *ngIf="showPlaceholder" [items]="rows"></data-not-found-placeholder>
<cds-list *ngIf="rows.length > 0" class="cds-list--narrow" cdsListExpandable expandMultiple="true" [appearance]="appearance">
	<cds-list-row head>
		<div *ngFor="let c of columns" role="columnheader" class="cds-list-cell" [ngClass]="{'cds-list-cell--icon': !!c.icon}">{{c.name}}</div>
		<div *ngIf="expandableContainer || showLastIconColumn()" role="cell" class="cds-list-cell cds-list-cell--icon">
			<a class=""></a>
		</div>
	</cds-list-row>
	<cds-list-row #cdsRow *ngFor="let row of rows; let i = index" [expand]="expandableContainer" [expandedContext]="row"
				  [ngClass]="{'clickable': (isRowExpandable(row) || isRowClickable(row))}" class="cds-list-row--alt-expand">
		<div *ngFor="let c of columns" role="cell" class="cds-list-cell" (click)="rowClicked(row, cdsRow, c)" [ngClass]="{'cds-list-cell--icon': !!c.icon}">
			<ng-container [ngSwitch]="true">
				<ng-container *ngSwitchCase="getTemplate(c.key) != null">
					<ng-container *ngTemplateOutlet="getTemplate(c.key); context: {value:row[c.key], column:c.key, row:row}"></ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="isStateValue(c.key)">
					<state [stateInput]="mapState(row[c.key])"></state>
				</ng-container>
				<ng-container *ngSwitchCase="isDateValue(row[c.key])">
					{{row[c.key] | date: "dd.MM.yyyy HH:mm"}}
				</ng-container>
				<ng-container *ngSwitchDefault>
					<wrappable-text-template [value]="row[c.key] | notEmpty"></wrappable-text-template>
				</ng-container>
			</ng-container>
		</div>
		<div *ngIf="isRowExpandable(row)" role="cell" class="cds-list-cell cds-list-cell--icon clickable" cdsListExpand>
			<a class="cds-list-expand-caret cds-icon-angle-down"></a>
		</div>
		<div *ngIf="!isRowExpandable(row) && expandableContainer" role="cell" class="cds-list-cell cds-list-cell--icon">
			<a class=""></a>
		</div>
	</cds-list-row>
</cds-list>