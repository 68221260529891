import { enableProdMode, Injector, ViewEncapsulation } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { config } from 'config';
import { AppModule } from './app/app.module';

export let appInjector: Injector;

if (config.prodMode) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, {
	defaultEncapsulation: ViewEncapsulation.None,
}).then(
	(componentRef) => {
		appInjector = componentRef.injector;
	});
