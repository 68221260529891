import { Injectable } from '@angular/core';
import {ApiService, FileService, InvoicesService} from "shared/app/index";
import {HttpClient} from "@angular/common/http";

@Injectable({
	providedIn: 'root',
})
export class XVLOInvoicesService extends InvoicesService  {

	constructor(http: HttpClient, apiService: ApiService, fileService: FileService) {
		super(http, apiService, fileService);
	}

	payInvoice(invoiceId: number) {
		return this.apiService.post('/vlo/invoice/' + invoiceId + '/payment')
	}

}
