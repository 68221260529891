<div class="footer__container">
	<div class="container cds-grid--no-padding">
		<div class="row">
			<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-left footer__text">
				© {{ currentDate | date: "yyyy" }} - {{title}}, v.{{apiVersion}}
			</div>
			<div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 text-right">
				<language-selector></language-selector>
			</div>
		</div>
	</div>
</div>

