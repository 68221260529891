import { Component, ElementRef, forwardRef, Input, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractToggleComponent } from '../abstract-toggle/abstract-toggle.component';

const SWITCH_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => SwitchComponent),
	multi: true,
};

@Component({
	selector: 'ca-switch',
	templateUrl: 'switch.component.html',
	providers: [ SWITCH_CONTROL_VALUE_ACCESSOR ],
})
export class SwitchComponent extends AbstractToggleComponent {

	@Input()
	label: string;

	constructor(private renderer: Renderer2, private element: ElementRef) {
		super();
		renderer.listen(element.nativeElement, 'click', () => {
			if (this.isBlocked) {
				return;
			}
			this.changeState(!this.isActive);
		});
	}

	protected changeState(state: boolean): void {
		this.setActive(state);
	}

	protected renderDisabled(disabled: boolean): void {
		if (disabled) {
			this.renderer.setAttribute(this.element.nativeElement, 'disabled', 'disabled');
		} else {
			this.renderer.removeAttribute(this.element.nativeElement, 'disabled');
		}
	}

	protected renderBlocked(blocked: boolean): void {}

	protected renderActive(active: boolean): void {
		if (active) {
			this.renderer.addClass(this.element.nativeElement, 'active');
		} else {
			this.renderer.removeClass(this.element.nativeElement, 'active');
		}
	}
}
