import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ApiService {

	constructor(protected http: HttpClient, @Inject('env') public env) {

	}

	get(path: string, options?: {
		headers?: HttpHeaders | {
			[header: string]: string | string[];
		};
		observe?: 'body';
		params?: HttpParams | {
			[param: string]: string | string[];
		};
		reportProgress?: boolean;
		responseType?: any;
		withCredentials?: boolean;
	}): Observable<any> {
		return this.http.get(this.url(path), options);
	}

	put(path: string, body: Object = {}, options = {}): Observable<any> {
		return this.http.put(this.url(path), body, options);
	}

	post(path: string, body: Object = {}, options?: {
		headers?: HttpHeaders | {
			[header: string]: string | string[];
		};
		observe?: 'body';
		params?: HttpParams | {
			[param: string]: string | string[];
		};
		reportProgress?: boolean;
		responseType?: any;
		withCredentials?: boolean;
	}): Observable<any> {
		return this.http.post(this.url(path), body, options);
	}

	postRaw(path: string, body: FormData): Observable<any> {
		return this.http.post(this.url(path), body);
	}

	delete(path, options = {}): Observable<any> {
		return this.http.delete(this.url(path), options);
	}

	toHttpParams(request: any): HttpParams {
		return Object.keys(request)
			.filter(key => request[key] !== undefined && request[key] !== null && typeof request[key] !== 'function')
			.reduce((accumulator, key) => accumulator.append(key, String(request[key])), new HttpParams());
	}

	url(path: string): string {
		return `${this.env.api_url}${path}`;
	}
}
