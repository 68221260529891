import { Pipe, PipeTransform } from '@angular/core';
import { XICTInstanceCountryMapping } from '../../utils/xict/xict-instance-country-mapping';

@Pipe({
	name: 'XICTOpenstackLocationPipe',
	pure: true,
})
export class XICTOpenstackLocationPipe implements PipeTransform {

	transform(value: any, type: string = 'countryCode'): any {
		switch (type) {
			case 'countryCode':
				return XICTInstanceCountryMapping.mapInstanceToCountryCode(value);
			default:
			case 'country':
				return XICTInstanceCountryMapping.mapCountryName(XICTInstanceCountryMapping.mapInstanceToCountryCode(value));
		}
	}
}
