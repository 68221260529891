<div *ngIf="formUtils.shouldShowErrors(control, form)" class="row cds-errors">
	<ng-container *ngIf="message == null">
		<ng-container *ngFor="let err of formUtils.getErrorMsgs(control, form, allMessages)">
			<div class="col-12 mt-2 w-100" style="font-size: inherit">
				{{err}}
			</div>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="message && canShowSingleMessage()">
		<div class="col-12 mt-2 w-100" style="font-size: inherit">
			{{message}}
		</div>
	</ng-container>
</div>
