import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Constants } from '../../utils/constants';

@Component({
	selector: 'generic-table',
	templateUrl: './generic-table.component.html',
	providers: [DatePipe],
})
export class GenericTableComponent implements OnInit {
	@Input() columns: any[] = [{key: '', name: ''}];
	@Input() data: [any];
	@Input() hideColumnKeysOnSmall: any[] = [];
	@Input() hideColumnKeysOnMedium: any[] = [];
	@Input() hideColumnKeysOnLarge: any[] = [];
	@Input() detailsLabel: string;
	@Input() dateFormat: string = 'short';
	@Input() showPlaceholder: boolean = true;
	@Input() cellTemplates: { column: string, ref: TemplateRef<any> }[] = [];
	@Input() entireRowClickable: boolean = false;
	@Output() onRowClick: EventEmitter<any> = new EventEmitter<any>();

	constructor(private datePipe: DatePipe) {

	}

	ngOnInit() {

	}

	isDateFormat(key: string, value: any) {
		return value && (value instanceof Date || Constants.DATE_REGEXP.test(value.toString()));
		// return isNaN(Number(value)) && Date.parse(value) && typeof value != 'number' && key != 'invoiceNumber';
	}

	isCurrencyValue(key, value) {
		if (key == 'totalAmount' || key == 'dueAmount' || key == 'amount') {
			return true;
		} else {
			return false;
		}
	}

	onClick(data) {
		this.onRowClick.emit(data);
	}

	onEntireRowClick(data) {
		if (this.isEntireRowClickable()) {
			this.onClick(data);
		}
	}

	isStateValue(key) {
		return key === 'stateCode';
	}

	getTemplate(column: string): TemplateRef<any> | null {
		const cell = this.cellTemplates.find(t => t.column === column);
		return cell ? cell.ref : null;
	}

	isEntireRowClickable() {
		return !this.detailsLabel && this.entireRowClickable;
	}
}
