import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'XICTRemoveQuotationMarks',
	pure: true,
})
export class XICTRemoveQuotationMarks implements PipeTransform {
	transform(value: any, ...args: any[]): any {
		return !!value && value.replace(/"/g, '');
	}
}
