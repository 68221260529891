import { Component, ElementRef, forwardRef, HostBinding, Input, Renderer2, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Unique } from '../../utils/unique';
import { AbstractToggleComponent } from '../abstract-toggle/abstract-toggle.component';

const CHECKBOX_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CheckboxComponent),
	multi: true,
};

@Component({
	selector: 'ca-checkbox',
	templateUrl: 'checkbox.component.html',
	providers: [ CHECKBOX_CONTROL_VALUE_ACCESSOR ],
})
export class CheckboxComponent extends AbstractToggleComponent {

	labelId = Unique.next('ca-checkbox__label-');

	/** @deprecated */
	@Input()
	id: string;

	@Input()
	label: string;

	@HostBinding('class.disabled')
	get classDisabled(): boolean {
		return this.isDisabled;
	}

	@HostBinding('attr.aria-checked')
	get ariaChecked(): string {
		return this.isActive ? 'true' : 'false';
	}

	@HostBinding('attr.aria-labelledBy')
	get ariaLabelledBy(): string {
		return this.label ? this.labelId : null;
	}

	@HostBinding('attr.aria-disabled')
	get ariaDisabled(): string {
		return this.isDisabled ? 'true' : 'false';
	}

	@HostBinding('attr.tabindex')
	get tabindex(): string {
		return this.isDisabled ? '-1' : '0';
	}

	constructor(renderer: Renderer2, element: ElementRef, private cdRef: ChangeDetectorRef) {
		super();
		renderer.setAttribute(element.nativeElement, 'role', 'checkbox');
		const toggle = event => {
			if (this.isBlocked) {
				return;
			}
			this.setActive(!this.isActive);
		};
		renderer.listen(element.nativeElement, 'click', toggle);
		renderer.listen(element.nativeElement, 'keyup.enter', toggle);
		renderer.listen(element.nativeElement, 'keyup.space', toggle);
		renderer.listen(element.nativeElement, 'keyup.spacebar', toggle);

		/* prevents page from scrolling when using space to toggle checkbox */
		const preventSpacebarScroll = event => {
			event.preventDefault();
		};

		renderer.listen(element.nativeElement, 'keydown.space', preventSpacebarScroll);
		renderer.listen(element.nativeElement, 'keydown.spacebar', preventSpacebarScroll);
	}

	protected renderDisabled(disabled: boolean): void {
		this.cdRef.detectChanges();
	}

	protected renderBlocked(blocked: boolean): void {
		this.cdRef.detectChanges();
	}

	protected renderActive(active: boolean): void {
		this.cdRef.detectChanges();
	}
}
