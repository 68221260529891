import { Directive, Host, Optional, Self } from '@angular/core';
import { ComboMultiComponent } from '@cds/components-angular';

/**
 * workaround for model resetting in cds-combo-multi with Reset button
 * https://medium.com/angular-in-depth/changing-the-behavior-of-a-3rd-party-angular-component-91f84fb9af28
 */
@Directive({
	selector: '[caMultiComboResetting]',
})
export class ResettingMultiComboDirective {
	constructor(@Host() @Self() @Optional() private cdsMultiselect: ComboMultiComponent) {
		cdsMultiselect.resetChanges = (ev?: UIEvent) => {
			cdsMultiselect.clear(); //this updates model so that ngModelChange is called (instead of cdsMultiselect.writeValue([]))
			cdsMultiselect.close();
		};
	}
}
