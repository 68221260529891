import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { first, take } from 'rxjs/operators';
import { ViewKeys } from '../../../../utils/view-keys';
import { AuthenticationService } from '../../../../services/authentication.service';
import { Constants } from '../../../../utils/constants';
import { States } from '../../../../utils/states';
import { Subscription, SubscriptionService } from 'shared/app/index';
import { SubscriptionUtils } from '../../../../utils/subscription.utils';
import { ProfileService } from '../../../../services/profile.service';

@Component({
	selector: 'subscriptions-single-row, [subscription-single-row]',
	templateUrl: 'subscriptions-single-row.component.html',
})
export class SubscriptionSingleRowComponent implements OnInit {
	@Output() changePlanEvent = new EventEmitter<any>();
	@Input() subscription: any;
	isModalLoading: boolean = false;
	isBindingPeriodLoading: boolean = false;

	bindingPeriodInfo: any;
	isSwitchPrivilegeEnable: boolean = false;
	isRenewPrivilegeEnable: boolean = false;

	constructor(private subscriptionService: SubscriptionService, private authService: AuthenticationService, private profileService: ProfileService) {
		this.isSwitchPrivilegeEnable = this.authService.hasPrivilege(ViewKeys.VIEW_KEY_SWITCH_PRODUCT) && !this.profileService.isProfileXICT();
		this.isRenewPrivilegeEnable = !this.profileService.isProfileXICT();
	}

	ngOnInit(): void {
		this.getBindingPeriod(this.subscription, this.isBindingPeriodLoading);
	}

	private getBindingPeriod(subscription: Subscription, loading: boolean) {
		if (!SubscriptionUtils.isBundleItem(subscription)) {
			loading = true;
			this.subscriptionService.getSubscriptionBindingPeriod(subscription.objectId)
				.pipe(take(1))
				.subscribe((bindingPeriod: any) => {
					this.bindingPeriodInfo = bindingPeriod;
					loading = false;
				}, (err) => {
					loading = false;
				});
		}
	}

	public hasBundleItems(subscription: Subscription): boolean {
		return SubscriptionUtils.hasBundleItems(subscription);
	}

	getTransitions(id) {
		this.isModalLoading = true;
		if (this.isSwitchPrivilegeEnable) {
			this.subscriptionService.getTransitions(id, [Constants.TRANSITION_TYPE_SWITCH])
				.pipe(first())
				.subscribe((response) => {
					// tslint:disable-next-line:no-console
					const map = this.reduceToMap(response);
					this.changePlanEvent.emit({map, action: 'switch'});
					this.isModalLoading = false;
				}, (err) => { this.isModalLoading = false; });
		}
	}

	renewSubscription(id) {
		this.isModalLoading = true;
		this.subscriptionService.getTransitions(id, [Constants.TRANSITION_TYPE_END_OF_BINDING_PERIOD])
			.pipe(first())
			.subscribe((response) => {
				const map = this.reduceToMap(response);
				this.changePlanEvent.emit({map, action: 'renew', bindingEndDate: this.bindingPeriodInfo.bindingEndDate});
				this.isModalLoading = false;
			}, (err) => { this.isModalLoading = false; });
	}

	reduceToMap(response) {
		return response.reduce((entryMap, item) => entryMap.set(item.toOffering.name, [...entryMap.get(item.toOffering.name) || [], item]), new Map());
	}

	checkSubscriptionIsActive(subscriptionMode: any) {
		return subscriptionMode === States.OST_SUBSCRIPTION_MODE_ACTIVE;
	}
	checkSubscriptionIsSwitch(subscriptionMode: any) {
		return subscriptionMode === States.OST_SUBSCRIPTION_MODE_SWITCH_IN_PROGRESS;
	}
}
