export class WebshopConstant {
	public static TERMS_TYPE_READ_ONLY = 'ReadOnly';
	public static TERMS_TYPE_REQUIRED = 'Required';
	public static TERMS_TYPE_MARKETING = 'MARKETING';
	public static TERMS_CODE_GENERAL_MARKETING_OPT_IN = 'GENERAL_MARKETING_OPT_IN';
	public static TERMS_CODE_GENERAL_CONTRACT = 'GENERAL_CONTRACT_TERMS_AND_CONDITIONS';
	public static TERMS_CODE_OFFER_TERMS_AND_CONDITIONS = 'OFFER_TERMS_AND_CONDITIONS';

	public static LOCALSTORAGE_CART_DATA = 'cart';

	public static LOCALSTORAGE_CHECKOUT_ORDER_SUCCESS = 'checkout_order';
	public static LOCALSTORAGE_CHECKOUT_DATA = 'checkout_data';
	public static LOCALSTORAGE_CHECKOUT_PAYMENT_ORDER = 'checkout_payment_order';
	public static LOCALSTORAGE_VOUCHER_KEY = 'voucher'

	public static CHECKOUT_STEP_PERSONAL_DATA = {name: 'Personal details', param: 'personal_data'};
	public static CHECKOUT_STEP_TERMS = {name: 'Terms and conditions', param: 'terms'};
	public static CHECKOUT_STEP_PREVIEW = {name: 'Order preview', param: 'preview'};
	public static CHECKOUT_STEP_PAYMENT_METHOD = {name: 'Payment method', param: 'payment_method'};
	public static CHECKOUT_STEP_SUMMARY = {name: 'Summary', param: 'summary'};

}
