import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { take } from 'rxjs/operators';
import { getCustomerId } from '../../../../core/store/selectors/context.selectors';
import { Store } from '@ngrx/store';
import { ContextState } from '../../../../core/store/state/context.state';
import { viewKey } from '../../../../decorators/decorators';
import { CDSToastService } from '../../../../services/cds-toast.service';
import { ProfileService } from '../../../../services/profile.service';
import {FileUtils, Invoice, InvoicesService} from "shared/app/index";
import { Observable, Subject } from 'rxjs';
import {StateTranslationsPipe} from "../../../../pipes/state-translations.pipe"
import { States } from '../../../../utils/states';

@Component({
	selector: 'app-invoice-list',
	templateUrl: './invoice-list.component.html',
	providers: [DatePipe, StateTranslationsPipe],
})
@viewKey('V_INVOICE_VIEW')
export class InvoiceListComponent implements OnInit {
	invoices: Invoice[] = [];
	filteredInvoices: Invoice[]  =[]
	invoicesToShow: (Invoice & { relatedInvoiceItems?: any })[] = [];
	invoicesQuantity: number;
	contextCustomerId: number;
	isLoading: boolean = false;
	allAvailableStatus: string[] = []
	filters: Map<string, ((invoice: Invoice)=>boolean)>;

	@Output() invoiceIdEvent = new EventEmitter<any>();
    private searchTerm = new Subject<string>();
	constructor(private invoiceService: InvoicesService, private datePipe: DatePipe, private store: Store<ContextState>, private cdsToast: CDSToastService, private profile: ProfileService, private statePipe: StateTranslationsPipe) {
		this.store.select(getCustomerId).pipe(take(1))
				.subscribe((customerId: any) => {
					this.contextCustomerId = customerId;
				});
		this.filters = new Map<string, ((invoice: Invoice)=>boolean)>();

	}

	ngOnInit() {
		this.getInvoices();
		this.runFilters = this.runFilters.bind(this);
        this.searchFilterFunction = this.searchFilterFunction.bind(this);
        this.filterDataFunction = this.filterDataFunction.bind(this);
        this.filterStatusFunction = this.filterStatusFunction.bind(this);
	}

    searchFilterFunction(term: string): Function {
        return (invoice: Invoice):boolean=>{
            return this.checkTermCondition(term.toLowerCase(),
                invoice.invoiceNumber,invoice.totalAmount.toString(),
                invoice.referenceNumber,
                this.statePipe.transform(new String(invoice.stateCode)
                                                .toString()
                                                .toLowerCase()) as string);
        }

    }

    filterDataFunction(formDate: {from?: string,to?: string}): Function {
        return (invoice: Invoice)=>{
                return new Date(invoice.createdDate).setHours(0,0,0,0) >= new Date(formDate.from).setHours(0,0,0,0)
                    && new Date(invoice.createdDate).setHours(0,0,0,0) <= new Date(formDate.to).setHours(0,0,0,0);

        }
    }

    filterStatusFunction(dropDownValue: string): Function{
        return (invoice: Invoice)=>{
            return this.checkTermCondition(dropDownValue,invoice.stateCode)
        }
    }

    checkTermCondition(term:string, ...invoiceValues: string[]):boolean{
        return invoiceValues.some(field=>field!==null && field.includes(term));
    }

    runFilters(){
        const filters = Array.from(this.filters.values());
        this.filteredInvoices = this.invoices.filter(ticket=>
            filters.every(f=>f(ticket))
        )
        this.refreshPagination()
    }


	getInvoices(issuedAfter?: string, issuedBefore?: string) {
		this.isLoading = true;

		let stream: Observable<any>;
		if (issuedAfter && issuedBefore) {
			stream = this.invoiceService.getInvoicesByDatePeriod(this.contextCustomerId, this.prepareDateFormat(issuedBefore), this.prepareDateFormat(issuedAfter));
		} else {
			stream = this.invoiceService.getCustomerInvoices((this.contextCustomerId));
		}

		stream
			.pipe(take(1))
			.subscribe((invoices) => {
				this.invoices = this.filteredInvoices = this.addRelatedInvoicesItems(invoices.content);
				this.allAvailableStatus = Array.from(new Set(
				    this.invoices.map(invoice=>invoice.stateCode)));
				this.invoicesQuantity = this.invoices.length;
				this.refreshPagination();
				this.isLoading = false;
			}, (err) => {
				this.isLoading = false;
				console.log(err);
		});
	}

	pdfDownload(item: Invoice) {
		this.invoiceService.saveInvoicePdf(item.id).subscribe((data) => {
			FileUtils.initiateFileDownload(data, item.invoiceNumber.replace('/', '_'), 'application/pdf');
		}, _ => {
			this.cdsToast.showDanger('Cannot download PDF');
		});
	}

	refreshPagination() {
		this.onPaginationChange({ from: 0, to: 5 });
	}

	onPaginationChange(event: { from: number, to: number }) {
		this.invoicesToShow = this.filteredInvoices.slice(event.from, event.to);
	}


	prepareDateFormat(date: string) {
		return date.replace(new RegExp('-', 'g'), '/');
	}

	goToInvoiceDetails(invoiceId) {
		this.invoiceIdEvent.emit(invoiceId);
	}

	isICTProfile(): boolean {
		return this.profile.isProfileXICT();
	}

	addRelatedInvoicesItems(invoiceList) {
		let relatedInvoices = [];
		let referenceInvoices = [];
		for (let invoice of invoiceList) {
			invoice.refInvoiceId ? relatedInvoices.push(invoice) : referenceInvoices.push({...invoice, relatedInvoiceItems: []});
		}
		for (let relInv of relatedInvoices) {
			for (let refInv of referenceInvoices) {
				if (relInv.refInvoiceId == refInv.id && relInv.invoiceType != 'N') {
					refInv.relatedInvoiceItems.push(relInv)
				}
			}
		}
		for (let refInv of referenceInvoices) {
			for (let rel1Inv of refInv.relatedInvoiceItems) {
				for (let rel2Inv of relatedInvoices) {
					if (rel2Inv.refInvoiceId == rel1Inv.id && rel2Inv.invoiceType != 'N') {
						refInv.relatedInvoiceItems.push(rel2Inv)
					}
				}
			}
		}

		return referenceInvoices;
	}

	isInvoiceDetailsEnabled(): boolean {
		return this.profile.isProfileXVLO() || this.profile.isProfileDefault();
	}
}
