import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { __ as translate } from '@comarch/seed-core/lib/i18n';

declare var gtag: Function;

export class GoogleAnalyticsUtils {

	public static initGoogleAnalytics(router: Router) {
		if (environment.google_analytics_track_id) {
			try {
				const asyncScript = document.createElement('script');
				asyncScript.async = true;
				asyncScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.google_analytics_track_id}`;
				document.head.appendChild(asyncScript);

				const script = document.createElement('script');
				script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      `;
				// gtag('config', '${environment.google_analytics_track_id}', {'send_page_view': false});
				document.head.appendChild(script);

				this.listenForRouteChanges(router);
			} catch (ex) {
				console.error(translate('sc.common.ga_adding_error'));
				console.error(ex);
			}
		}
	}

	private static listenForRouteChanges(router: Router) {
		router.events.pipe(
				distinctUntilChanged((previous: any, current: any) => {
					// subscribe to any `NavigationEnd` events where the url has changed
					if (current instanceof NavigationEnd) {
						return previous.url === current.url;
					}
					return true;
				})
		).subscribe((event: any) => {
			gtag('config', environment.google_analytics_track_id, {'page_path': event.urlAfterRedirects});
		});
	}
}
