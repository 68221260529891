<div class="page-container" [style.padding-bottom]="footerHeight + 129 + 'px'">
	<router-outlet name="top-menu" *caCustomizationHide="[profiles.XEHC]"></router-outlet>
	<router-outlet name="xehc-top-menu" *caCustomizationShow="[profiles.XEHC]"></router-outlet>

	<ws-carousel></ws-carousel>

	<div class="container mt-4 cds-grid--no-padding">
		<div class="row">
			<div class="col-xl-12">
				<div class="d-block" *ngIf="isBreadcrumbVisible">
					<ul *ngIf="customBreadcrumbs" class="breadcrumb">
						<ng-container *ngFor="let item of customBreadcrumbs; let last = last">
							<li *ngIf="!last && item.label" class="breadcrumb-item">
								<a (click)="onCustomBreadcrumbClick(item)" role="button">{{ item.label }}</a>
							</li>
							<li *ngIf="last && item.label" class="breadcrumb-item active">
								<span>{{ item.label }}</span>
							</li>
						</ng-container>
					</ul>
					<breadcrumb *ngIf="!customBreadcrumbs" useBootstrap="false" style="display: inline-grid;" [boxCustomizableElement]="'textColor'"></breadcrumb>
				</div>
				<router-outlet></router-outlet>
				<cds-spinner *ngIf="isLoading"></cds-spinner>
			</div>
		</div>
	</div>

	<ws-footer *caCustomizationHide="[profiles.XEHC]" #wsFooter></ws-footer>

	<app-footer></app-footer>
</div>
