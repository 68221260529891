import {ContextState, initialContextState} from '../state/context.state';
import {ContextActionsTypes} from '../actions/context.actions';

export function contextReducer(
	state = initialContextState,
	action: any
): ContextState {
	switch (action.type) {
		case ContextActionsTypes.AddContextBannersSuccess:
			return {...state, banners: action.payload};
		case ContextActionsTypes.AddContextUserSuccess:
			return {...state, user: action.payload};
		case ContextActionsTypes.AddContextUserContactSuccess:
			return {...state, userContact: action.payload};
		case ContextActionsTypes.AddContextCustomerSuccess:
			return {...state, customer: action.payload};
		case ContextActionsTypes.AddContextAccountSuccess:
			return {...state, account: action.payload};
		case ContextActionsTypes.AddContextAccountsSuccess:
			return {...state, accounts: action.payload};
		case ContextActionsTypes.AddContextInvoicesToPaySuccess:
			return {...state, invoicesToPay: action.payload};
		case ContextActionsTypes.SetContextCustomerSuccess:
			return {...state, customer: action.payload};
		case ContextActionsTypes.SetContextLastUnreadNotification:
			return {...state, lastUnreadNotificationDate: action.payload};
		case ContextActionsTypes.GetContextSuccess:
			return {
				...state,
				account: action.payload.account,
				accounts: action.payload.accounts,
				user: action.payload.user,
				userContact: action.payload.userContact,
				notifications: action.payload.notifications,
				customer: action.payload.customer,
				banners: action.payload.banners,
			};
		case ContextActionsTypes.ClearContext:
			return {...state,
				account: null,
				accounts: [],
				user: null,
				userContact: null,
				notifications: 0,
				customer: null,
				invoicesToPay: [],
				banners: null,
			};
		default:
			return state;
	}
}
