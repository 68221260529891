<div>
	<div class="w-100 mt-4" *ngFor="let term of terms">
		<div class="d-flex">
			<div>
				<cds-checkbox [(ngModel)]="term.checked" label="{{term.data.description}}." (click)="term.validate()"></cds-checkbox>
				<ng-container *ngIf="term.data.documentVersion">
					<a *ngIf="term.data.contentPath" class="ml-2 icon-open_in_new term-subtext text-nowrap" [href]="term.data.contentPath" target="_blank">
						&nbsp;Click to see document
					</a>
					<a *ngIf="term.data.documentVersion && term.data.contentPath == null" class="ml-2 icon-open_in_new term-subtext text-nowrap"
					   (click)="viewFile(term.data.documentName, term.data.contractTermId, term.data.documentVersion)">
						&nbsp;Click to see document
					</a>
				</ng-container>
			</div>
			<span class="ml-2 required"><strong>{{term.data.required ? '*' : ''}}</strong></span>
		</div>
		<div *ngIf="!term.valid && term.data.required" class="cds-errors ml-4">This field is required</div>
	</div>
</div>
