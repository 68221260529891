import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { map, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { XICTAddContextCloudProductsSuccess, XICTContextActionsTypes, XICTGetContextSuccess } from '../actions/xict-context.actions';
import { XICTContextState } from '../state/xict-context.state';
import { getXICTContext } from '../selectors/xict-context.selectors';

@Injectable()
export class XICTContextEffects {

	private APP_STORAGE_KEY = 'xictContext';

	@Effect()
	getContext$ = this.actions$.pipe(
			ofType(XICTContextActionsTypes.GetContext),
			map(() => {
				const data = JSON.parse(localStorage.getItem(this.APP_STORAGE_KEY));
				return new XICTGetContextSuccess(data);
			}),
	);

	@Effect({dispatch: false})
	clearContext$ = this.actions$.pipe(
			ofType(XICTContextActionsTypes.ClearContext),
			map(() => {
				localStorage.removeItem(this.APP_STORAGE_KEY);
			}),
	);

	@Effect()
	addContextCloudProducts$ = this.actions$.pipe(
			ofType(XICTContextActionsTypes.AddContextCloudProducts),
			withLatestFrom(this.store.select(getXICTContext)),
			map(([action, state]: any) => {
				state.cloudProducts = action.payload;
				localStorage.setItem(this.APP_STORAGE_KEY, JSON.stringify(state));
				return new XICTAddContextCloudProductsSuccess(action.payload);
			}),
	);

	constructor(private actions$: Actions<any>, private store: Store<XICTContextState>) {

	}

}
