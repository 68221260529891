export class Constants {
	// PROFILES CURRENCY
	public static PROFILE_CURRENCY_DEFAULT = 'EUR';
	public static PROFILE_CURRENCY_XICT = 'EUR';
	public static PROFILE_CURRENCY_XICT_INTERNAL = 'EUR';
	public static PROFILE_CURRENCY_XVLO = 'USD';
	public static PROFILE_CURRENCY_XEHC = 'zł';

	// APP ENVIRONMENT
	public static APP_ENVIRONMENT_PROD = 'prod';
	public static APP_ENVIRONMENT_TEST = 'test';
	public static APP_ENVIRONMENT_DEV = 'dev';
	public static APP_ENVIRONMENT_STAGING = 'staging';
	public static APP_ENVIRONMENT_SMOG = 'smog';
	public static APP_ENVIRONMENT_LOCAL = 'local';

	// TOP MENU CONTEXT
	public static TOP_MENU_CONTEXT_SELFCARE = 'sc';
	public static TOP_MENU_CONTEXT_WEBSHOP = 'ws';

	// LOCALSTORAGE
	public static LOCALSTORAGE_CONTEXT = 'context';
	public static LOCALSTORAGE_TOKEN = 'token';
	public static LOCALSTORAGE_VIEW_KEYS = 'keys';

	// TRANSITION TYPES
	public static TRANSITION_TYPE_UNSUBSCRIBING = 'Unsubscribing';
	public static TRANSITION_TYPE_SUBSCRIBING = 'Subscribing';
	public static TRANSITION_TYPE_SWITCH = 'Switch';
	public static TRANSITION_TYPE_END_OF_BINDING_PERIOD = 'EndOfBindingPeriod';

	public static readonly BROWSER_ID_KEY = 'browser_id';
	public static readonly COOKIES_ACCEPTED_KEY = 'cookiesAccepted';
	public static readonly COOKIES_ACCEPTED_VALUE = 'true';

	public static readonly BACKEND_NOT_AVAILABLE_ERROR_CODE = 423;
	public static readonly BACKEND_NOT_AVAILABLE_ERROR_MESSAGE = 'Backend is not available';

	public static readonly DATE_REGEXP = new RegExp(/^\d{4}-\d{2}-\d{2}[ T]\d{2}:\d{2}:\d{2}/);

	public static readonly EHC_OFFER_1 = 'Wizytówka';
	public static readonly EHC_OFFER_2 = 'START';
	public static readonly EHC_OFFER_3 = 'Komfort';
}
