import { catchError } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthRequestDTO } from '../dto/auth-request.dto';

@Injectable({
    providedIn: "root",
})
export class UserInfoService {

    private readonly useremailcheck: string = `${environment.api_url}/users`
    constructor(private http: HttpClient){}

    private emailCheckErrorHandle(error: HttpErrorResponse): Observable<number> {
        return of(error.status)
    }

    public emailCheck(email: string): Observable<any> {
        const params: HttpParams = new HttpParams().set('email',email);
        return this.http.head(this.useremailcheck,{params: params, observe: 'response'})
                .pipe(
                    map(data=>data.status),
                    catchError(this.emailCheckErrorHandle)
                );
    }

    public userOldPasswordCheck(password: string, userId: number): Observable<boolean> {
        let passwordDTO: Partial<AuthRequestDTO> = {password: password}
        const checkPasswordURL: string = `${environment.api_url}/users/${userId}/password-verification`;
        return this.http.post<boolean>(checkPasswordURL, passwordDTO);
    }
}