import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'page-subtitle',
  templateUrl: './page-subtitle.component.html'
})
export class PageSubtitleComponent implements OnInit {

	@Input()
	pageSubtitle: string = ' '

  	constructor() { }

  	ngOnInit() {

  	}

}
