import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { TicketMetadata } from '../../models/ticket/ticket-metadata';
import {ApiService, FileService} from "shared/app/index";
import {Ticket} from "../../models/ticket/ticket";

@Injectable({
	providedIn: 'root',
})
export class TicketsService {
	constructor(private apiService: ApiService, private fileService: FileService) {
	}

	getCustomerTickets(): Observable<Ticket[]> {
		return this.apiService.get('/tickets/all');
	}

	getTicketInfo(ticketId: number): Observable<Ticket> {
		return this.apiService.get('/tickets/' + ticketId);
	}

	getAttachment(documentId: number) {
		return this.fileService.getFile('/tickets/attachment/' + documentId);
	}

	addTicket(formData: FormData): Observable<any> {
		return this.apiService.postRaw('/tickets/new', formData);
	}

	getTicketMetadata(): Observable<TicketMetadata> {
		return this.apiService.get('/tickets/meta');
	}

	addComment(formData: FormData): Observable<any> {
		return this.apiService.postRaw('/tickets/comment/new', formData);
	}

	addTicketAttachments(ticketId: number, formData: FormData): Observable<any> {
		return this.apiService.post('/tickets/'+ ticketId +'/attachments', formData);
	}

	deleteTicketAttachment(ticketId: number, attachmentId: number): Observable<any> {
		return this.apiService.delete('/tickets/'+ ticketId +'/attachments/' + attachmentId);
	}

	close(ticketId: number): Observable<any> {
		return this.apiService.post('/tickets/' + ticketId + '/close');
	}

}
