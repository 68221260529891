import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ResellerInfo } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ResellerService {

  constructor(protected apiService: ApiService) {

  }

  getResellerInfo(resellerId: number): Observable<ResellerInfo> {
	  return this.apiService.get(`/resellers/${resellerId}/info`);
  }
}
