import { Component, ElementRef, ExistingProvider, forwardRef, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractCombo } from './abstract-combo';
import { COMBO_ITEM_ALL_HOST, ComboItemAllComponent, ComboItemAllHost } from './combo-item-all.component';
import { COMBO_ITEM_HOST, ComboItemDirective, ComboItemHost } from './combo-item.directive';
import { SelectedLabelModel } from './selected-label-model';

const COMBO_MULTI_CONTROL_VALUE_ACCESSOR: ExistingProvider = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => ComboMultiComponent),
	multi: true,
};

const COMBO_ITEM_HOST_PROVIDER: ExistingProvider = {
	provide: COMBO_ITEM_HOST,
	useExisting: forwardRef(() => ComboMultiComponent),
};

const COMBO_ITEM_ALL_HOST_PROVIDER: ExistingProvider = {
	provide: COMBO_ITEM_ALL_HOST,
	useExisting: forwardRef(() => ComboMultiComponent),
};

@Component({
	selector: 'ca-combo-multi',
	templateUrl: 'combo.component.html',
	providers: [COMBO_MULTI_CONTROL_VALUE_ACCESSOR, COMBO_ITEM_HOST_PROVIDER, COMBO_ITEM_ALL_HOST_PROVIDER],
})
export class ComboMultiComponent extends AbstractCombo implements ControlValueAccessor, ComboItemHost, ComboItemAllHost, SelectedLabelModel {

	private itemAll: ComboItemAllComponent = null;
	private items: ComboItemDirective[] = [];
	private modelValue: any[] = null;
	private activeItems: Map<any, ComboItemDirective> = new Map<string, ComboItemDirective>();
	readonly focusActive: boolean = false;
	private registerQueue: Set<ComboItemDirective> = new Set<ComboItemDirective>();

	private _selectedLabel: string = '';
	private _selectedCount: number = 0;
	private _totalCount: number = 0;
	private _selectedAll: boolean = false;

	get selectedCount(): number { return this._selectedCount; }
	get totalCount(): number { return this._totalCount; }
	get selectedAll(): boolean { return this._selectedAll; }
	get selectedLabel(): string { return this._selectedLabel; }

	constructor(elementRef: ElementRef, renderer: Renderer2) {
		super();
		renderer.addClass(elementRef.nativeElement, 'ca-combo');
	}

	upadteSelectedLabel(): void {
		if (this.selectedCount > 0) {
			const activeItem: ComboItemDirective = this.activeItems.values().next().value;
			this._selectedLabel = activeItem.labelValue !== undefined ? activeItem.labelValue : activeItem.value;
		}
	}

	private updateCounters(): void {
		this._totalCount = this.items.length;
		this._selectedCount = this.activeItems.size;
		this._selectedAll = this._totalCount > 0 ? this.selectedCount === this._totalCount : false;
		if (this.itemAll) {
			this.itemAll.isActive = this._selectedAll;
		}
	}

	private setActiveItem(item: ComboItemDirective, active: boolean): boolean {
		const trackByKey: any = this.trackByFunction(item.value);
		const activeItem: ComboItemDirective = this.activeItems.get(trackByKey);
		item.isActive = active;
		let change: boolean = false;
		if (active) {
			this.activeItems.set(trackByKey, item);
			change = !activeItem;
			if (change) {
				this.updateCounters();
				this.upadteSelectedLabel();
			}
		} else {
			if (activeItem) {
				this.activeItems.delete(trackByKey);
				change = true;
				this.updateCounters();
				this.upadteSelectedLabel();
			}
		}
		return change;
	}

	private getModelValue(): any[] {
		const values: any[] = [];
		this.activeItems.forEach(item => {
			values.push(item.value);
		});
		return values;
	}

	writeValue(values: any): void {
		this.modelValue = values;
		for (const item of this.items) {
			const active: boolean = values == null ? false : values.some(value => this.isEqual(value, item.value));
			this.setActiveItem(item, active);
		}
	}

	findItem(value: any): ComboItemDirective {
		for (const item of this.items) {
			if (this.isEqual(item.value, value)) {
				return item;
			}
		}
		return null;
	}

	onItemClick(event: Event, item: ComboItemDirective): void {
		const active: boolean = !item.isActive;
		const changed: boolean = this.setActiveItem(item, active);

		if (changed) {
			this.modelValue = this.getModelValue();
			this.onChangeCallback(this.modelValue);
		}
	}

	registerItem(item: ComboItemDirective): void {
		this.registerQueue.add(item);
		setTimeout(() => {
			this.registerQueue.forEach(queuedItem => {
				this.items.push(queuedItem);
				const trackByKey: any = this.trackByFunction(queuedItem.value);
				if (!this.activeItems.has(trackByKey) && this.modelValue != null) {
					for (const value of this.modelValue) {
						if (this.isEqual(value, queuedItem.value)) {
							this.setActiveItem(queuedItem, true);
							break;
						}
					}
				}
				this.updateCounters();
			});
			this.registerQueue.clear();
		});
	}

	unregisterItem(item: ComboItemDirective): void {
		if (this.registerQueue.delete(item)) {
			return;
		}
		const idx: number = this.items.indexOf(item);
		if (idx !== -1) {
			this.items.splice(idx, 1);
		}
		this.activeItems.forEach((activeItem: ComboItemDirective) => {
			if (this.isEqual(activeItem.value, item.value)) {
				const changed: boolean = this.setActiveItem(item, false);
				if (changed) {
					this.modelValue = this.getModelValue();
					this.onChangeCallback(this.modelValue);
				}
			}
		});
		this.updateCounters();
	}

	registerItemAll(item: ComboItemAllComponent): void {
		this.itemAll = item;
	}

	unregisterItemAll(item: ComboItemAllComponent): void {
		this.itemAll = null;
	}

	toggleSelectAll(event: Event, itemAll: ComboItemAllComponent): void {
		let selectAll: boolean = false;
		for (const item of this.items) {
			if (!item.isActive) {
				selectAll = true;
				break;
			}
		}
		this.activeItems.clear();
		this.modelValue = [];
		for (const item of this.items) {
			this.setActiveItem(item, selectAll);
			if (selectAll) {
				this.modelValue.push(item.value);
			}
		}
		this.updateCounters();
		this.onChangeCallback(this.modelValue);
	}
}
