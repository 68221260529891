import { NgModule } from '@angular/core';
import { VirtualizationContainerDirective } from './virtualization-container.directive';
import { VirtualizationListDirective } from './virtualization-list.directive';
import { VirtualizationLoaderComponent } from './virtualization-loader.component';

@NgModule({
	declarations: [
		VirtualizationContainerDirective,
		VirtualizationListDirective,
		VirtualizationLoaderComponent,
	],
	exports: [
		VirtualizationContainerDirective,
		VirtualizationListDirective,
	],
	entryComponents: [
		VirtualizationLoaderComponent,
	],
})
export class VirtualizationModule {}
