import { Directive, Input, HostListener, HostBinding, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ConsumerFunction, RunnableFunction, noop } from '../../utils/facade';

const RADIO_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => RadioDirective),
	multi: true,
};

@Directive({
	selector: '[caRadio]',
	providers: [RADIO_CONTROL_VALUE_ACCESSOR],
})
export class RadioDirective implements ControlValueAccessor {

	@Input('caRadio')
	set option(value: any) {
		if (Array.isArray(value)) {
			this.options = value;
		} else {
			this.options = [value];
		}
	}

	private options: any[];

	@Input()
	uncheckable: boolean = false;

	@HostBinding('class.active')
	isActive: boolean = false;

	@HostBinding('class.disabled')
	isDisabled: boolean = false;

	@HostListener('click')
	onClick(): void {
		if (this.isDisabled) {
			return;
		}
		if (this.isActive && this.uncheckable) {
			this.isActive = false;
			this.onChange(null);
			this.onTouched();
		} else if (!this.isActive) {
			this.isActive = true;
			this.onChange(this.options[0]);
			this.onTouched();
		}
	}

	writeValue(value: any): void {
		this.isActive = this.options.some(option => option === value);
	}

	private onChange: ConsumerFunction = noop;
	private onTouched: RunnableFunction = noop;

	registerOnChange(fn: ConsumerFunction): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: RunnableFunction): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}
}
