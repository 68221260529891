import { Pipe, PipeTransform } from '@angular/core';
import { XICTMachinePinStates } from '../../models/xict/xict-machine-pin-states';
import { TranslationUtil } from 'shared/app';

@Pipe({
	name: 'XICTPinTypePipe',
	pure: true,
})
export class XICTPinTypePipe implements PipeTransform {

	transform(value: XICTMachinePinStates, ...args: any[]): any {
		return TranslationUtil.getString('machine_pin_type', value.toString().toLowerCase()) || value.toString().toLowerCase();
	}
}
