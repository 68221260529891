import { Component, OnInit, Input } from '@angular/core';
import { ValidationMessageMap } from '../../utils/validation-message-map';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { FormUtils } from '../../utils/form-utils';
import { PasswordRule } from '../../models/password-rule';
import { AppProfile } from '../../utils/app-profile';

@Component({
    selector: 'password-requirements',
    templateUrl: './password-requirements.component.html',
})
export class PasswordRequirementsComponent implements OnInit {
    @Input() validationMessages: ValidationMessageMap;
    @Input() passwordForm: FormGroup;
    @Input() control: string;
	profile = AppProfile;
    allErrors: ValidationErrors = [];
    passwordRules: PasswordRule[] = [];

    constructor(public formUtils: FormUtils) {

    }

    ngOnInit(): void {
        this.allErrors = Object.keys(this.validationMessages[this.control]).reduce(
            (result, validationKey) => {
                const rule = new PasswordRule(this.validationMessages[this.control][validationKey], validationKey);
                rule.markFail();
                this.passwordRules.push(rule);
                return { ...result, [validationKey]: true };
            }, {},
        );
    }

    getUpdatedPasswordRules(): PasswordRule[] {
        const control = this.passwordForm.get(this.control);
        for (const rule of this.passwordRules) {
            if (control.hasError(rule.key)) {
                rule.markFail();
            } else {
                rule.markSuccess();
            }
        }
        return this.passwordRules;
    }
}
