import {Component, Input} from "@angular/core";

@Component({
	selector: 'customer-property',
	templateUrl: './customer-property.component.html'
})
export class CustomerPropertyComponent {

	@Input()
	propertyLabel: string;
	@Input()
	value: string;
}
