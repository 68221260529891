import { Action } from '@ngrx/store';
import { XICTCloudProduct } from '../../../models/xict/cloud-product/xict-cloud-product';
import { XICTContextState } from '../state/xict-context.state';

export enum XICTContextActionsTypes {
	AddContextCloudProducts = '[XICT Context] Add Cloud Products',
	AddContextCloudProductsSuccess = '[XICT Context] Add Cloud Products Success',
	GetContext = '[XICT Context] Get Context',
	GetContextSuccess = '[XICT Context] Get Context Success',
	ClearContext = '[XICT Context] Clear Context',
}

export class XICTClearContext implements Action {
	readonly type = XICTContextActionsTypes.ClearContext;
}

export class XICTGetContext implements Action {
	readonly type = XICTContextActionsTypes.GetContext;
}

export class XICTGetContextSuccess implements Action {
	readonly type = XICTContextActionsTypes.GetContextSuccess;

	constructor(public payload: XICTContextState) {
	}
}

export class XICTAddContextCloudProducts implements Action {
	readonly type = XICTContextActionsTypes.AddContextCloudProducts;

	constructor(public payload: XICTCloudProduct[]) {
	}
}

export class XICTAddContextCloudProductsSuccess implements Action {
	readonly type = XICTContextActionsTypes.AddContextCloudProductsSuccess;

	constructor(public payload: XICTCloudProduct[]) {
	}
}

export type XICTContextActions =
		XICTClearContext
		| XICTGetContext
		| XICTGetContextSuccess
		| XICTAddContextCloudProducts
		| XICTAddContextCloudProductsSuccess;
