import {
	Component,
	ContentChild,
	ElementRef,
	EventEmitter,
	ExistingProvider,
	forwardRef,
	HostBinding,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { defer, EMPTY, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map, onErrorResumeNext, skip, switchMap, tap } from 'rxjs/operators';
import { VirtualizationContainerDirective } from '../../ui/virtualization/virtualization-container.directive';
import { TYPEAHEAD_ITEM_HOST, TypeaheadItemHost } from './typeahead-item.directive';

const TYPEAHEAD_ITEM_HOST_PROVIDER: ExistingProvider = {
	provide: TYPEAHEAD_ITEM_HOST,
	useExisting: forwardRef(() => TypeaheadComponent),
};

const VIRTUALIZATION_CONTAINER_PROVIDER: ExistingProvider = {
	provide: VirtualizationContainerDirective,
	useExisting: forwardRef(() => TypeaheadComponent),
};

@Component({
	selector: 'ca-typeahead',
	templateUrl: 'typeahead.template.html',
	providers: [
		TYPEAHEAD_ITEM_HOST_PROVIDER,
		VIRTUALIZATION_CONTAINER_PROVIDER,
	],
})
export class TypeaheadComponent implements OnInit, OnDestroy, TypeaheadItemHost, VirtualizationContainerDirective {

	scroll$: Observable<UIEvent>;

	@ViewChild('scroll', { read: ElementRef, static: true })
	virtualizationContainer: ElementRef;

	@ContentChild(NgModel, { static: true })
	input: NgModel;

	@ContentChild(NgModel, { read: ElementRef, static: true })
	inputElement: ElementRef;

	@ContentChild(TemplateRef, { static: true })
	dropdown: TemplateRef<any>;

	@Input()
	loadFunction: LoadSuggestionsFunction;

	@Input()
	loadDebounce: number = 300;

	@Input()
	minQueryLength: number = 0;

	@Output()
	suggestionChange: EventEmitter<any> = new EventEmitter<any>();

	@HostBinding('class.ca-typeahead-open') isOpen: boolean = false;
	@HostBinding('class.ca-typeahead-loading') isLoading: boolean = false;

	isFocus: boolean = false;

	queryChanges$: Subject<string> = new Subject<string>();
	valueChangesSubscription: Subscription;

	context: { $implicit: any } = { $implicit: undefined };

	constructor(private renderer: Renderer2) {
		this.scroll$ = defer(() => fromEvent(this.virtualizationContainer.nativeElement, 'scroll') as Observable<UIEvent>);
	}

	ngOnInit(): void {
		this.valueChangesSubscription = this.input.control.valueChanges.pipe(
			tap(query => this.closeIfInvalidQuery(query)),
			debounceTime(this.loadDebounce),
		).subscribe(this.queryChanges$);

		this.queryChanges$.pipe(
			skip(1),
			map(query => query || ''),
			filter(query => this.openIfValidQuery(query)),
			switchMap(query => {
				const observable = this.loadFunction(query);
				if (observable) {
					this.isLoading = true;
					return observable.pipe(map(results => ({ query, results })), onErrorResumeNext());
				} else {
					this.isOpen = false;
					return EMPTY;
				}
			}),
		).subscribe((response: QueryResults) => {
			if (response.query === (this.input.control.value || '')) {
				this.isLoading = false;
				this.setSuggestions(response.results);
			}
		});

		this.renderer.listen(this.inputElement.nativeElement, 'focus', () => {
			this.isFocus = true;
			if (!this.isOpen) {
				this.clearSuggestions();
				this.queryChanges$.next(this.input.control.value);
			}
		});

		this.renderer.listen(this.inputElement.nativeElement, 'blur', () => {
			this.isFocus = false;
		});

		// handling TAB button navigation
		this.renderer.listen(this.inputElement.nativeElement, 'keydown', (event) => {
			if (event.key === 'Tab') {
				this.isOpen = false;
			}
		});
	}

	// handling ESC button from dropdown
	onDropdownChange(isOpen): void {
		if (!isOpen) {
			if (document.activeElement) {
				(document.activeElement as HTMLElement).blur();
			}
		}
	}

	ngOnDestroy(): void {
		this.queryChanges$.complete();
		this.valueChangesSubscription.unsubscribe();
	}

	selectSuggestion(value: any, labelValue: string): void {
		this.isOpen = false;
		this.suggestionChange.emit(value);
		setTimeout(() => this.input.control.setValue(labelValue));
	}

	private setSuggestions(suggestions: any): void {
		this.context = {
			$implicit: suggestions,
		};
	}

	private clearSuggestions() {
		if (this.context.$implicit) {
			this.setSuggestions(undefined);
		}
	}

	private closeIfInvalidQuery(query) {
		if (!query || query.length < this.minQueryLength) {
			this.isOpen = false;
		}
	}

	private openIfValidQuery(query) {
		if (this.isFocus && query.length >= this.minQueryLength) {
			this.isOpen = true;
			return true;
		}
		return false;
	}

}

export interface LoadSuggestionsFunction {
	(value: string): Observable<any>|null;
}

interface QueryResults {
	query: string;
	results: any;
}
