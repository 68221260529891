export enum ViewKeys {
	VIEW_KEY_SWITCH_PRODUCT = 'V_SWITCH_PRODUCT',
	VIEW_KEY_WEBSHOP_ORDER = 'V_WEBSHOP_ORDER',

	V_INVOICE_VIEW = 'V_INVOICE_VIEW',
	V_OPEN_STACK = 'V_OPEN_STACK',
	V_SELFCARE = 'V_SELFCARE',
	// V_SELFCARE_ACCOUNT_DETAILS_VIEW = 'V_SELFCARE_ACCOUNT_DETAILS_VIEW',
	// V_SELFCARE_ACCOUNT_STATISTICS_VIEW = 'V_SELFCARE_ACCOUNT_STATISTICS_VIEW',
	// V_SELFCARE_ACTIVITY_VIEW = 'V_SELFCARE_ACTIVITY_VIEW',
	// V_SELFCARE_ADMIN_VIEW = 'V_SELFCARE_ADMIN_VIEW',
	// V_SELFCARE_ADVERTISEMENT_MESSAGES_VIEW = 'V_SELFCARE_ADVERTISEMENT_MESSAGES_VIEW',
	// V_SELFCARE_BANNERS = 'V_SELFCARE_BANNERS',
	// V_SELFCARE_CHANGE_PASSWORD_EMAIL_VIEW = 'V_SELFCARE_CHANGE_PASSWORD_EMAIL_VIEW',
	// V_SELFCARE_CHANGE_PASSWORD_VIEW = 'V_SELFCARE_CHANGE_PASSWORD_VIEW',
	V_SELFCARE_CLOUD_MACHINES_DELETE_EXECUTE = 'V_SELFCARE_CLOUD_MACHINES_DELETE_EXECUTE',
	V_SELFCARE_CLOUD_MACHINES_CONSOLE_VIEW = 'V_SELFCARE_CLOUD_MACHINES_CONSOLE_VIEW',
	V_SELFCARE_CLOUD_MACHINES_EDIT = 'V_SELFCARE_CLOUD_MACHINES_EDIT',
	V_SELFCARE_CLOUD_MACHINES_VIEW = 'V_SELFCARE_CLOUD_MACHINES_VIEW',
	V_SELFCARE_CLOUD_PROJECTS_EDIT = 'V_SELFCARE_CLOUD_PROJECTS_EDIT',
	V_SELFCARE_CLOUD_PROJECTS_VIEW = 'V_SELFCARE_CLOUD_PROJECTS_VIEW',
	V_SELFCARE_CLOUD_SSH_MANAGE = 'V_SELFCARE_CLOUD_SSH_MANAGE',
	V_SELFCARE_OTHER_USER_PROJECT_CONNECTION_EDIT = 'V_SELFCARE_OTHER_USER_PROJECT_CONNECTION_EDIT',
	// V_SELFCARE_CONTACT_VIEW,
	// V_SELFCARE_CUSTOMER_PROFILE_VIEW,
	V_SELFCARE_DASHBOARD_VIEW = 'V_SELFCARE_DASHBOARD_VIEW',
	V_SELFCARE_FAQ_VIEW = 'V_SELFCARE_FAQ_VIEW',
	// V_SELFCARE_HISTORY_VIEW,
	// V_SELFCARE_IDS_PROFILE_VIEW,
	V_SELFCARE_INVOICE_VIEW = 'V_SELFCARE_INVOICE_VIEW',
	// V_SELFCARE_ORDER_HISTORY_VIEW,

	V_SELFCARE_OTHER_USER_EDIT = 'V_SELFCARE_OTHER_USER_EDIT',
	V_SELFCARE_OTHER_USER_VIEW = 'V_SELFCARE_OTHER_USER_VIEW',
	V_SELFCARE_EXTERNAL_USER_VIEW = 'V_SELFCARE_EXTERNAL_USER_VIEW',
	V_SELFCARE_EXTERNAL_USER_EDIT = 'V_SELFCARE_EXTERNAL_USER_EDIT',
	V_SELFCARE_OWN_ACCOUNT_VIEW = 'V_SELFCARE_OWN_ACCOUNT_VIEW',
	V_SELFCARE_OWN_ACCOUNT_EDIT = 'V_SELFCARE_OWN_ACCOUNT_EDIT',
	V_SELFCARE_OWN_ACCOUNT_CREATE = 'V_SELFCARE_OWN_ACCOUNT_CREATE',
	V_SELFCARE_OWN_CUSTOMER_EDIT = 'V_SELFCARE_OWN_CUSTOMER_EDIT',
	V_SELFCARE_OWN_CUSTOMER_VIEW = 'V_SELFCARE_OWN_CUSTOMER_VIEW',
	// V_SELFCARE_PAYMENT_ACTIVITY_VIEW,
	V_SELFCARE_PAYMENT_EDIT = 'V_SELFCARE_PAYMENT_EDIT',
	// V_SELFCARE_PAYMENT_HISTORY_VIEW,
	V_SELFCARE_PAYMENT_VIEW = 'V_SELFCARE_PAYMENT_VIEW',
	// V_SELFCARE_PAYMENTS,
	// V_SELFCARE_PROFILE_EDIT_VIEW,
	// V_SELFCARE_PROFILE_VIEW,
	// V_SELFCARE_REPLENISHMENT_VIEW,
	// V_SELFCARE_REPORTS_VIEW,
	// V_SELFCARE_SERVICE_OPTIONS_VIEW,
	// V_SELFCARE_SESSION_HISTORY_VIEW,
	// V_SELFCARE_SET_EMAIL,
	// V_SELFCARE_STD_VIEW,
	// V_SELFCARE_SUBSCRIPTION_HISTORY_VIEW,
	V_SELFCARE_SUBSCRIPTION_VIEW = 'V_SELFCARE_SUBSCRIPTION_VIEW',
	V_SELFCARE_TICKET_EDIT = 'V_SELFCARE_TICKET_EDIT',
	V_SELFCARE_TICKET_VIEW = 'V_SELFCARE_TICKET_VIEW',
	V_SELFCARE_USAGE_VIEW = 'V_SELFCARE_USAGE_VIEW',
	// V_SELFCARE_VOIP_ACTIVITY_VIEW,
	// V_SELFCARE_WIFI_ACTIVITY_VIEW,
	// V_SELFCARE_WIFI_CHANGE_PASSWORD_VIEW,
	V_SWITCH_PRODUCT = 'V_SWITCH_PRODUCT',
	V_WEBSHOP_ORDER = 'V_WEBSHOP_ORDER',
	V_SELFCARE_CLOUD_RESERVATIONS_VIEW = 'V_SELFCARE_CLOUD_RESERVATIONS_VIEW',
	V_WEBSHOP_ALLOW_VIEW = 'V_WEBSHOP_ALLOW_VIEW',
	V_SELFCARE_EHC_SUBSCRIPTION_VIEW = 'V_SELFCARE_EHC_SUBSCRIPTION_VIEW',
}
