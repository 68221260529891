import { Component, OnInit } from '@angular/core';
import { PaymentsService } from '../../../services/selfcare/payments.service';
import { viewKey } from '../../../decorators/decorators';
import { concatMap } from 'rxjs/operators';
import { ContextService } from '../../../services/context.service';
import { AppPath } from '../../../utils/app-path';
import { Router } from '@angular/router';
import { ProfileService } from '../../../services/profile.service';
import {AppPathService} from "../../../services/app-path.service";
import {CustomerService} from "shared/app/index";

@Component({
	selector: 'payments-list',
	templateUrl: 'payments-list.component.html',
})
@viewKey('V_SELFCARE_PAYMENT_VIEW')
export class PaymentsListComponent implements OnInit {

	public payments: any[];
	public isLoading = true;

	constructor(private customerService: CustomerService, private contextServcice: ContextService,
				private pathService: AppPathService, private profileService: ProfileService) { }

	ngOnInit(): void {
		this.contextServcice.getCustomerId().pipe(
			concatMap((id : number) => this.customerService.getCustomerPayments(id))
		).subscribe((data: any) => {
			this.payments = data;
			this.isLoading = false;
		});
	}

	goToInvoiceDetails(invoiceId): void {
		this.pathService.navigate([AppPath.PATH_SELFCARE_INVOICE_DETAILS + '/' + invoiceId]);
	}

	isInvoiceDetailsEnabled(): boolean {
		return this.profileService.isProfileXVLO() || this.profileService.isProfileDefault();
	}

}
