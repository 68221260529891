import { States } from '../utils/states';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class StateMapper {
	public mapToState(value): string {
		const validState = Object.keys(States)
				.map(state => state.slice(4))
				.filter(state => value.toUpperCase() === state);
		if (validState.length === 1) {
			return 'OST_' + validState[0];
		} else {
			return value;
		}
	}
}
