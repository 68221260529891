import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { DialogContext, DialogInstance, DialogService } from '@cds/components-angular';
import { Observable, of, zip , from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { __ as translate } from '@comarch/seed-core/lib/i18n';
import { CDSToastService } from '../../services/cds-toast.service';

@Component({
	selector: 'simple-dialog',
	templateUrl: 'simple-dialog.component.html',
})
export class SimpleDialogComponent {
	private _params: SimpleDialogParams<any>;
	get params(): SimpleDialogParams<any> {
		return this._params;
	}

	private _data: any;
	get data(): any {
		return this._data;
	}

	private _isLoading: boolean = true;
	get isLoading(): boolean {
		return this._isLoading;
	}

	@Input()
	title: string = '';
	@Input()
	cssClasses: string = '';
	error: string;

	@ViewChild('simpleDialog')
	private readonly dialogRef: TemplateRef<DialogContext<string> & { context: any }>;

	constructor(private dialogService: DialogService, private cdsToastService: CDSToastService) {
	}

	show<T>(params: SimpleDialogParams<T> = null, task: Observable<T> = null): Observable<SimpleDialogResult<T>> {
		if (params == null) {
			params = {
				buttons: null,
			};
		}
		if (params.buttons == null) {
			params.buttons = [
				{key: 'ok', label: translate('sc.common.ok'), noAutoReject: true},
				{key: 'cancel', label: translate('sc.common.cancel')},
			];
		}
		this._params = params;
		return zip(
			this.mapTask(task ? task : of(null)),
			from(this.dialogService.open(this.dialogRef, {context: params})),
		).pipe(map(zip => {
			return {data: this._data, button: zip[1]};
		}));
	}

	private mapTask(task: Observable<any>) {
		return task.pipe(tap({
			next: r => {
				this._data = r;
				this._isLoading = false;
			},
			error: r => {
				this._isLoading = false;
			},
		}));
	}

	_onButtonClick(dialog: DialogInstance, btn: { key: string, label: string, noAutoReject?: boolean }) {
		if (btn.noAutoReject) {
			if (this.params.validator == null || this.params.validator(this, {data: this._data, button: btn.key})) {
				dialog.resolve(btn.key);
			} else {
				console.debug('Validation error on click ', btn);
			}
		} else {
			dialog.reject(btn.key);
		}
	}

	successUpdateHandler(message = translate('sc.toast.common_data_saved')) {
		return () => this.cdsToastService.showSuccess(message);
	}

	errorUpdateHandler(message = translate('sc.common.unknown_error')) {
		return () => this.cdsToastService.showDanger(message);
	}

	errorUpdateHandlerProvider(message: (e) => string = (e) => translate('sc.common.unknown_error')) {
		return (e) => this.cdsToastService.showDanger(message(e));
	}

}

export interface SimpleDialogParams<T> {
	buttons?: { key: string, label: string, noAutoReject?: boolean, danger?: boolean }[];
	validator?: (dialog: SimpleDialogComponent, result: SimpleDialogResult<T>) => boolean;
	context?: any;
}

export interface SimpleDialogResult<T> {
	data: T;
	button: string;
}
