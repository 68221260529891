export enum AppPath {

	// Generic
	PATH_NOT_FOUND = '/404',
	PATH_ERR_CONNECTION_REFUSED = '/service-not-available',
	PATH_HOME = '/',
	PATH_REGISTER = '/register',
	PATH_FORGOT_PASSWORD = '/forgot-password',
	PATH_CHANGE_PASSWORD = '/change-password',
	PATH_SET_PASSWORD = '/set-password',
	PATH_LOGIN = '/login',
	PATH_WEBSHOP_PAYMENT_GATEWAY_MOCK = '/payment-gateway-mock',
	PATH_ISSUE_FOUND = '/issue',

	// Selfcare
	PATH_SELFCARE_HOME = '/self',
	PATH_SELFCARE_CUSTOMER = '/self/customer',
	PATH_SELFCARE_TICKETS = '/self/tickets',
	PATH_SELFCARE_DASHBOARD = '/self/dashboard',
	PATH_SELFCARE_INVOICES = '/self/invoices',
	PATH_SELFCARE_INVOICE_DETAILS = '/self/invoices/details',
	PATH_SELFCARE_CONTRACTS = '/self/contracts',
	PATH_SELFCARE_RESERVATIONS = '/self/reservations',
	PATH_SELFCARE_USAGE = '/self/usage',
	PATH_SELFCARE_PAYMENTS = '/self/payments',
	PATH_SELFCARE_NOTIFICATIONS = '/self/notifications',
	PATH_SELFCARE_SUPPORT = '/self/support',
	PATH_SELFCARE_PAYMENT_LIST = '/self/payment-list',
	PATH_SELFCARE_FAQ = '/self/faq',
	PATH_SELFCARE_COUNTER = '/self/counter-records',
	PATH_SELFCARE_PAYMENT_GATEWAY = '/self/payment-gateway',
	PATH_SELFCARE_ORDERS = '/self/orders',


	// Webshop
	PATH_WEBSHOP_HOME = '/webshop',
	PATH_WEBSHOP_OFFER = '/webshop/channel/',
	PATH_WEBSHOP_OFFERING = '/webshop/offering/',
	PATH_WEBSHOP_SHOPPING_CART = '/webshop/cart/',
	PATH_WEBSHOP_CHECKOUT = '/webshop/checkout',
	PATH_WEBSHOP_SINGLE_PAYMENT_CHECKOUT = '/webshop/single-payment-checkout',

	// XVLO
	XVLO_PATH_WEBSHOP_CHECKOUT_SME = '/webshop/questionnaire/',
	PATH_WEBSHOP_REQUEST_RESIDENTIAL = '/webshop/request-residential',
	XVLO_PATH_WEBSHOP_TERMS = '/webshop/terms/',
	XVLO_PATH_PARAM_WEBSHOP_TERMS_ACCEPTABLE_USE = 'acceptable_use_policy',
	XVLO_PATH_PARAM_WEBSHOP_TERMS_PRIVACY_POLICY = 'privacy_policy',

	// XICT
	XICT_PATH_PRODUCTS = '/products',
	XICT_PATH_SELFCARE_PROJECTS = '/self/projects',
	XICT_PATH_USERS = '/self/customer/users',
	XICT_PATH_PAYMENT_ACCOUNTS = '/self/customer/paymentaccounts',

	// XEHC
	XEHC_PATH_WEBSHOP_REGISTER = '/webshop/register',
	XEHC_PATH_SELFCARE_PACKAGES = '/self/packages',
}
