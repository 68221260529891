import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, Router} from "@angular/router";
import {catchError, map} from "rxjs/operators";
import {of} from "rxjs";
import {BoxConfigurationService} from "../services/box-configuration.service";
import {AppPath} from "../utils/app-path";
import {BoxParam} from '../models/box-param';

@Injectable({
	providedIn: 'root',
})
export class BoxConfigurationResolver implements Resolve<any> {
	constructor(private boxService: BoxConfigurationService, private router: Router) {}

	resolve(route: ActivatedRouteSnapshot){
		return this.boxService.getBoxParams(route.paramMap.get('box')).pipe(
			catchError(err => {
				if (err.status === 404) {
					this.router.navigate([AppPath.PATH_NOT_FOUND]);
				}
				return of(null);
			}),
			map((data: BoxParam) => {
				if (data) {
					this.boxService.boxParam.next(data);
				}
			})
		)
	}
}
