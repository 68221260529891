import { Directive, EventEmitter, Host, Input, OnInit, Output } from '@angular/core';
import { NgModel } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Directive({
	selector: '[caDebounce]',
})
export class DebounceDirective implements OnInit {

	@Input('caDebounce')
	debounceTime: number = 500;

	@Output()
	debounce: EventEmitter<any> = new EventEmitter<any>();

	constructor(@Host() private ngModel: NgModel) {}

	ngOnInit(): void {
		this.ngModel.valueChanges.pipe(
			debounceTime(this.debounceTime),
			distinctUntilChanged()
		).subscribe(newValue => this.debounce.emit(newValue));
	}
}
