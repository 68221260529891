import { Component, Input, OnInit} from '@angular/core';

@Component({
	selector: 'data-not-found-placeholder',
	templateUrl: './data-not-found-placeholder.html',
})
export class DataNotFoundPlaceholder implements OnInit {
	@Input() items: any = [];

	constructor() {
	}

	ngOnInit() {

	}

}
