import {Pipe, PipeTransform} from '@angular/core';
import { States } from '../utils/states';
import { IndicatorStatus } from '@cds/components-angular/lib/indicator/status-indicator/status-indicator.directive';

@Pipe({name: 'orderStateIndicatorPipe'})
export class OrderStateIndicatorPipe implements PipeTransform {
	transform(state: string): IndicatorStatus {
		switch (state) {
			case States.OST_BOOKED:
				return 'info';
			case States.OST_FULFILLING:
				return 'info';
			case States.OST_FULFILLED:
				return 'success';
			case States.OST_CREATED:
				return;
			case States.OST_CANCELLED:
				return 'danger';
			default:
				return;
		}
	}
}
