import { LocationChangeListener, LocationStrategy, PopStateEvent } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	NavigationEnd,
	NavigationStart,
	PRIMARY_OUTLET,
	Router,
	UrlSegmentGroup,
	UrlTree,
} from '@angular/router';
import { BASE_LOCATION_STRATEGY } from './base-location-strategy';

@Injectable()
export class RouterFlowStrategyService extends LocationStrategy {

	private nextId: number = 0;
	private sessionId: string = Math.random() + '';
	private router: Router;
	private previousUrl: string = null;
	private previousHistoryState: HistoryState;
	private markAsProgrammatic: boolean = false;
	private programmatic: WeakSet<PopStateEvent> = new WeakSet<PopStateEvent>();

	constructor(@Inject(BASE_LOCATION_STRATEGY) private base: LocationStrategy) {
		super();
	}

	initialize(router: Router): void {
		this.router = router;

		router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.previousUrl = router.url;
			} else if (event instanceof NavigationEnd) {
				this.previousUrl = null;
				this.previousHistoryState = window.history.state;
			}
		});
	}

	path(includeHash?: boolean): string {
		return this.base.path(includeHash);
	}

	prepareExternalUrl(internal: string): string {
		return this.base.prepareExternalUrl(internal);
	}

	onPopState(fn: LocationChangeListener): void {
		this.base.onPopState(event => {
			if (this.markAsProgrammatic) {
				this.programmatic.add(event);
				this.markAsProgrammatic = false;
			}
			return fn(event);
		});
	}

	isProgrammatic(event: PopStateEvent): boolean {
		return this.programmatic.has(event);
	}

	getBaseHref(): string {
		return this.base.getBaseHref();
	}

	pushState(state: any, title: string, url: string, queryParams: string): void {
		const changes = this.urlChanges(this.previousUrl, url);
		const historyState: HistoryState = this.getHistoryState();
		const newState: HistoryState = {
			outlets: {},
		};
		for (const outlet of Object.keys(changes)) {
			const change: OutletChange = changes[outlet];
			if (change.current) {
				if (change.previous) {
					const outletState: OutletHistoryState = historyState.outlets[outlet];
					if (change.previous === change.current) {
						if (outletState.back !== -1) {
							newState.outlets[outlet] = {back: outletState.back + 1, id: outletState.id};
						} else {
							newState.outlets[outlet] = {back: -1, id: this.getNextId()};
						}
					} else {
						newState.outlets[outlet] = { back: 1, id: this.getNextId() };
					}
				} else {
					newState.outlets[outlet] = { back: 1, id: this.getNextId() };
				}
			}
		}
		console.debug('push', JSON.stringify(newState, null, 2));
		this.base.pushState(newState, title, url, queryParams);
	}

	replaceState(state: any, title: string, url: string, queryParams: string): void {
		let historyState: any = this.getHistoryState();
		if (!historyState) {
			const urlTree: UrlTree = this.router.parseUrl(url);
			historyState = this.createDefaultState(urlTree);
			console.debug('create default state');
		}
		console.debug('replace', JSON.stringify(historyState, null, 2));
		this.base.replaceState(historyState, title, url, queryParams);
	}

	back(): void {
		this.markAsProgrammatic = true;
		this.base.back();
	}

	forward(): void {
		this.markAsProgrammatic = true;
		this.base.forward();
	}

	getHistoryState(): HistoryState {
		return window.history.state as HistoryState;
	}

	getPreviousHistoryState(): HistoryState {
		return this.previousHistoryState;
	}

	historyGo(delta: number): void {
		this.markAsProgrammatic = true;
		if (delta === -1) {
			this.back();
		} else {
			window.history.go(delta);
		}
	}

	getOutletName(route: ActivatedRouteSnapshot) {
		while (route.parent !== route.root) {
			route = route.parent;
		}
		return route.outlet || PRIMARY_OUTLET;
	}

	private createDefaultState(urlTree: UrlTree): HistoryState {
		const state: HistoryState = {
			outlets: {},
		};
		const outlets = Object.keys(urlTree.root.children);
		for (const outlet of outlets) {
			state.outlets[outlet] = { back: -1, id: this.getNextId() };
		}
		return state;
	}

	private urlChanges(previousUrl: string, currentUrl: string): { [outlet: string]: OutletChange } {
		const previousUrlGroups = this.router.parseUrl(previousUrl).root.children;
		const currentUrlGroup = this.router.parseUrl(currentUrl).root.children;
		const outlets: string[] = Object.keys(currentUrlGroup);
		const changes: { [outlet: string]: OutletChange } = {};
		for (const outlet of outlets) {
			changes[outlet] = { current: this.getUrlSegementGroupIdentity(currentUrlGroup[outlet]) };
			const previous = previousUrlGroups[outlet];
			if (previous) {
				changes[outlet].previous = this.getUrlSegementGroupIdentity(previous);
			}
		}
		return changes;
	}

	private getUrlSegementGroupIdentity(group: UrlSegmentGroup): string {
		return group.toString();
	}

	private getNextId(): string {
		return `${++this.nextId}@${this.sessionId}`;
	}
}

export class HistoryState {
	outlets: { [outlet: string]: OutletHistoryState };
}

export interface OutletHistoryState {
	back: number;
	id: string;
}

interface OutletChange {
	current: string;
	previous?: string;
}
