import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './common/not-found/not-found.component';
import {InternalErrorComponent} from './common/internal-error/internal-error.component';
import {ProfileGuard} from './guards/profile.guard';
import {ConnectionRefusedComponent} from "./common/connection-refused/connection-refused.component";
import {BoxConfigurationResolver} from "./resolvers/box-configuration.resolver";
import {environment} from "../environments/environment";
import {BoxGuard} from './guards/box.guard';
import {AppComponent} from "./app.component";
import { AppProfile } from './utils/app-profile';
import {WebshopComponent} from "./webshop/webshop.component";
import {WebshopVisibleGuard} from "./guards/webshop-visible.guard";


const ROUTES: Routes = [
	{
		path: '404',
		component: NotFoundComponent,
		pathMatch: environment.box_feature ? 'full' : 'prefix'
	},
	{
		path: environment.box_feature ? ':box' : '',
		resolve: environment.box_feature ? {box: BoxConfigurationResolver} : {},
		canActivate: environment.box_feature ? [BoxGuard] : undefined,
		runGuardsAndResolvers: 'always',
		children: [
			{
				path: '',
				component: AppComponent,
				pathMatch: 'prefix'
			},

			{
				path: 'self',
				loadChildren: () => import('./selfcare/self.module').then(m => m.SelfModule),
			},
			{
				path: '',
				loadChildren: () => import('./login/home.module').then(m => m.HomeModule)
			},
			{
				path: 'webshop',
				canActivate: [WebshopVisibleGuard],
				data: {viewKey: WebshopComponent.prototype['key']},
				loadChildren: () => import('./webshop/webshop.module').then(m => m.WebshopModule),
			},
			{
				path: 'payments/bm/rtrn',
				loadChildren: () => import('./login/components/redirect/payments-redirect/payments-redirect.module').then(m => m.PaymentsRedirectModule)
			},
			{
				path: 'payment-gateway-mock',
				canActivate: [ProfileGuard],
				data: {profile: [AppProfile.DEFAULT]},
				loadChildren: () => import('./webshop/components/payment-gateway-mock/payment-gateway-mock.module').then(m => m.PaymentGatewayMockModule)
			},
			{
				path: '500',
				component: InternalErrorComponent,
			},
			{
				path: 'payments/verification',
				loadChildren: () => import('./login/components/redirect/payments-redirect/payments-redirect.module').then(m => m.PaymentsRedirectModule)
			},
			{
				path: '404',
				component: NotFoundComponent
			},
			{
				path: 'service-not-available',
				component: ConnectionRefusedComponent
			},
			{
				path: '**',
				redirectTo: '404',
			}
		],
	}
];

@NgModule({
	imports: [RouterModule.forRoot(ROUTES)],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
