import { NgModule } from '@angular/core';
import { EqualDirective } from './equal.directive';
import { InvalidDirective } from './invalid.directive';
import { LengthDirective } from './length.directive';
import { NotEqualDirective } from './not-equal.directive';
import { UniqueDirective } from './unique.directive';

@NgModule({
	declarations: [
		EqualDirective,
		InvalidDirective,
		LengthDirective,
		NotEqualDirective,
		UniqueDirective,
	],
	exports: [
		EqualDirective,
		InvalidDirective,
		LengthDirective,
		NotEqualDirective,
		UniqueDirective,
	],
})
export class ValidationModule { }
