import {CartState, emptyCartState, initialCartState} from "../state/cart.state";
import {CartActionsTypes} from "../actions/cart.actions";

export function cartReducer (
	state = initialCartState,
	action: any
): CartState {
	switch (action.type) {
		case CartActionsTypes.AddCartItemSuccess:
			return state;
		case CartActionsTypes.RemoveCartItemSuccess:
			return state;
		case CartActionsTypes.GetCartItemsSuccess:
			return {
				...state,
				items: action.payload.items,
				totalCount: action.payload.totalCount,
				totalPriceOneTime: action.payload.totalPriceOneTime,
				totalPricePeriodic: action.payload.totalPricePeriodic
			};
		case CartActionsTypes.ClearCartSuccess:
			return {
				...state,
				items: [],
				totalCount: 0,
				totalPriceOneTime: 0,
				totalPricePeriodic: 0
			};
		default:
			return state;
	}
}
