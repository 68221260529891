import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl } from '@angular/forms';
import { noop, RunnableFunction } from '../../utils/facade';

const NOT_EQUAL_VALIDATOR: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => NotEqualDirective),
	multi: true,
};

@Directive({
	selector: '[caNotEqual]',
	providers: [NOT_EQUAL_VALIDATOR],
})
export class NotEqualDirective {

	private notEqualTo: string;
	private onValidatorChange: RunnableFunction = noop;

	@Input()
	set caNotEqual(notEqualTo: string) {
		this.notEqualTo = notEqualTo;
		this.onValidatorChange();
	}

	validate(control: AbstractControl): {} {
		if (control.value == null) {
			return null;
		}

		if (control.pristine && control.value === this.notEqualTo) {
			return null;
		}

		if (control.value === this.notEqualTo) {
			return {
				notEqual: { valid: false },
			};
		}
		return null;
	}

	registerOnValidatorChange(fn: () => void): void {
		this.onValidatorChange = fn;
	}
}
