<div class="p-4 pt-0 bundle-item__container">
	<div class="row mb-4">
		<div class="offset-xl-1 col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 font-weight-bold">
			Product options<i class="cds-icon-info-circle cds-icon--size-16 ml-2" tooltipClass="tooltip__content"
						 [ngClass]="'tooltip__icon'" [placement]="'top'" [ngbTooltip]="'Details about active or available bundles in this product'"></i>
		</div>
	</div>

	<div class="row mb-4">
		<div class="offset-xl-1 col-xl-4 col-lg-5 col-md-5 col-sm-5 col-5 bundle-item__header">
			Service name
		</div>
		<div class="col-xl-4 col-lg-5 col-md-5 col-sm-5 col-5 bundle-item__header">
			Status (valid from-to)
		</div>
		<div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 bundle-item__header">
			Price
		</div>
	</div>

	<ng-container *ngFor="let bundle of bundleData">
		<div class="row">
			<div class="offset-xl-1 col-xl-4 col-lg-5 col-md-5 col-sm-5 col-5 mb-4">
				{{bundle.productOfferingName}}
			</div>
			<div class="col-xl-4 col-lg-5 col-md-5 col-sm-5 col-5 mb-4">
				<state [stateInput]="bundle.stateCode" class="d-inline-block mr-4"></state>
				<ng-container *ngIf="bundle.validFrom">
					<div class="d-inline-block">
						({{bundle.validFrom | date : 'mediumDate'}} - {{bundle.validTo}})
					</div>
				</ng-container>
			</div>
			<div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 mb-4">
				<ng-container *ngIf="bundle.monthlyNetPrice; else oneTimePrice;">
					{{bundle.monthlyNetPrice | currencyProfile}}/mo
				</ng-container>
				<ng-template #oneTimePrice>
					{{bundle.oneTimeNetPrice | currencyProfile | notEmpty}}
				</ng-template>
			</div>
		</div>
	</ng-container>
</div>