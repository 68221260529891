<!-- FILTER RESULTS -->
<div class="row">
	<div class="col-xl-12 align-items-center">
		<ng-container *ngIf="searchBox || formDate || dropDownValue">
			<p tabindex="0" class="cds-text-1 d-inline-block">Filters</p>
			<additional-info class="d-inline-block" [textOnHover]="mainMessage"></additional-info>
			<p cdsButton tabindex="0" *ngIf="searchBox.value || (formDate?.from && formDate?.to)" class="ml-4 d-inline-block align-middle mb-1" (click)="clearAllFilters(); searchBox.value = ''">
				<span class="cds-button-text font-weight-bold">Clear</span>
			</p>
		</ng-container>
	</div>
</div>
<div class="row pb-4">
	<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
		<cds-chip *ngIf="searchBox && searchBox.value"
							aria-describe="chip-desc"
							[removable]="true"
							(remove)="clearFilter(termFilterName); searchBox.value = ''">
			<span>term: </span>
			<span>{{searchBox.value}}</span>
		</cds-chip>
		<cds-chip *ngIf="formDate && (formDate?.from && formDate?.to)"
							class="ml-2"
							aria-describe="chip-desc"
							[removable]="true"
							(remove)="clearFilter(dateFilterName)">
			<span>{{dateFilterName}}: </span>
			<span>{{formDate.from}}</span> - <span>{{formDate.to}}</span>
		</cds-chip>
		<cds-chip *ngIf="dropDownValue"
							class="ml-1"
							aria-describe="chip-desc"
							[removable]="true"
							(remove)="clearFilter(statusFilterName); dropDownValue=''">
			<span>{{statusFilterName}}: </span>
			<span>{{dropDownValue | stateTranslationsPipe}}</span>
		</cds-chip>
	</div>
</div>

<!-- DATE INPUTS -->
<div class="row pb-4">
	<div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4">
		<cds-input caInputResetting
							 (input)="search(searchBox.value)"
							 [(ngModel)]="inputTerm"
							 #searchBox
							 class="full-width"
							 label="Search"
							 resettable="true"
							 (change)="search(inputTerm)">
			<i class="cds-icon-search"></i>
		</cds-input>
	</div>
	<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
		<cds-date-range-form [(ngModel)]="formDate"
												 labelFrom="Start date"
												 labelTo="End date"
												 placeholder="yyyy/mm/dd"
												 (ngModelChange)="searchByDate()">
			
		</cds-date-range-form>
	</div>
	<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
		<cds-combo aria-owns="statuses-list"
							 [(ngModel)]="dropDownValue"
							 [placeholder]="'Filter by status'"
							 label="Filter by status"
							 (ngModelChange)="searchByStatus()">
			<label>{{dropDownValue | stateTranslationsPipe}}</label>
			<ul id="statuses-list" role="listbox">
				<li [cdsComboItem]="stat" tabindex="-1" *ngFor="let stat of states">{{stat | stateTranslationsPipe}}</li>
			</ul>
		</cds-combo>
		<additional-info class="d-inline-block ml-3" [textOnHover]="dropdownMessage"></additional-info>
	</div>
</div>