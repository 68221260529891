<div class="row">
	<h3 class="col ca-icon-color">
		You are adding Invoice Address
	</h3>
</div>
<div class="row">
	<div class="col-md-4">
		<form [formGroup]="invoiceAddressForm" (ngSubmit)="createAddress()">
			<div class="row mt-4">
				<div class="col-6">
					<cds-input class="cds-input w-100" type="text" label="First name" formControlName="firstName"
							   name="First name"></cds-input>
					<form-error [control]="'firstName'" [allMessages]="validationMessages" [form]="invoiceAddressForm"></form-error>
				</div>
				<div class="col-6">
					<cds-input class="cds-input w-100" type="text" label="Last name" formControlName="lastName"
							   name="Last name"></cds-input>
					<form-error [control]="'lastName'" [allMessages]="validationMessages" [form]="invoiceAddressForm"></form-error>
				</div>
			</div>

			<div class="row mt-4">
				<div class="col-12">
					<cds-input class="cds-input w-100" type="text" label="Company name" formControlName="companyName"
							   name="Company name"></cds-input>
					<form-error [control]="'companyName'" [allMessages]="validationMessages" [form]="invoiceAddressForm"></form-error>
				</div>
			</div>
			<div class="row mt-4">
				<div class="col-12">
					<cds-input class="cds-input w-100" type="text" label="Email address" formControlName="emailAddress" [required]="true"
							   name="city"></cds-input>
					<form-error [control]="'emailAddress'" [allMessages]="validationMessages" [form]="invoiceAddressForm"></form-error>
				</div>
			</div>
			<div class="row mt-4">
				<div class="col-12">
					<cds-combo [(search)]="countrySearchValue" searchDebounceTime="300" formControlName="country" class="w-100" [required]="true" #countryCombo>
						<label class="" title>Country</label>
						<label class="">{{countryCombo.selectedLabel}}</label>
						<ul>
							<li *ngFor="let c of availableCountries" [cdsComboItem]="c.code"
								[cdsComboSearch]="c.name | cdsComboSearchMatch : countrySearchValue" tabindex="-1" [labelValue]="c.name">{{c.name}}</li>
						</ul>
					</cds-combo>
				</div>
			</div>
			<div class="row mt-4">
				<div class="col-12">
					<cds-input class="cds-input w-100" type="text" label="City" formControlName="city" [required]="true"
							   name="city"></cds-input>
					<form-error [control]="'city'" [allMessages]="validationMessages" [form]="invoiceAddressForm"></form-error>
				</div>
			</div>
			<div class="row mt-4">
				<div class="col-12">
					<cds-input class="cds-input w-100" type="text" label="Street" formControlName="street" [required]="true"
							   name="street"></cds-input>
					<form-error [control]="'street'" [allMessages]="validationMessages" [form]="invoiceAddressForm"></form-error>
				</div>
			</div>
			<div class="row mt-4">
				<div class="col-12">
					<cds-input class="cds-input w-100" type="text" label="House number" formControlName="houseNumber" [required]="true"
							   name="houseNumber"></cds-input>
					<form-error [control]="'houseNumber'" [allMessages]="validationMessages" [form]="invoiceAddressForm"></form-error>
				</div>
			</div>
			<div class="row mt-4">
				<div class="col-12">
					<cds-input class="cds-input w-100" type="text" label="Postal code" formControlName="postcode" [required]="true"
							   name="Post code"></cds-input>
					<form-error [control]="'postcode'" [allMessages]="validationMessages" [form]="invoiceAddressForm"></form-error>
				</div>
			</div>
			<div class="row mt-4">
				<div class="col-12">
					<cds-input class="cds-input w-100" type="text" label="Phone number" formControlName="phoneNumber"
							   name="Phone number" [required]="true"></cds-input>
					<form-error [control]="'phoneNumber'" [allMessages]="validationMessages" [form]="invoiceAddressForm"></form-error>
				</div>
			</div>
		</form>
	</div>
</div>

<div class="row">
	<div class="col-12 text-right pb-4">
		<button cdsButton class="cds-button-text button-large" [boxCustomizableElement]="'buttonText'" style="margin-top:10px" (click)="cancelled.emit()">
			Cancel
		</button>
		<button cdsButton class="cds-button-primary ml-4" [boxCustomizableElement]="'buttonPrimary'" (click)="createAddress()">Save</button>
	</div>
</div>


