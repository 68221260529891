import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class TokenModalService {
    private _isTokenMessage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    constructor(){

    }

    get isTokenMessage(): BehaviorSubject<boolean> {
        return this._isTokenMessage;
    }

    public setIsTokenMessage(newmessage: boolean): void {
        this._isTokenMessage.next(newmessage);
    }
}