<ng-container *ngIf="isUnpaidSectionVisible else notFound">
	<page-subtitle [pageSubtitle]="'Unpaid invoices'"></page-subtitle>

	<div class="row">
		<div class="col-xl-12">
			<cds-list>
				<cds-list-row head role="table" tabindex="0" aria-label="Unpaid invoices">
					<div role="cell" class="cds-list-cell" style="width: 20%"></div>
					<div role="cell" class="cds-list-cell">Invoice number</div>
					<div role="cell" class="cds-list-cell">Invoice amount</div>
					<div role="cell" class="cds-list-cell">Due amount</div>
					<div role="cell" class="cds-list-cell d-none d-xl-block">Payment code</div>
					<div role="cell" class="cds-list-cell">Status</div>
					<div role="cell" class="cds-list-cell d-none d-sm-block">Attachments</div>
				</cds-list-row>
				<cds-list-row *ngFor="let item of invoices;" tabindex="-1" 
					(click)="!isValoProfile() && onInvoiceClick(item, $event)"
					[class.pointer]="!isValoProfile()"
					>
					<div role="cell" class="cds-list-cell" style="width: 20%" *caCustomizationHide="[profiles.XVLO]">
						<cds-checkbox [ngModel]="item.isChecked"></cds-checkbox>
					</div>
					<div role="cell" class="cds-list-cell" aria-selected="true" title="Invoice number">
						<button *ngIf="isInvoiceDetailsEnabled()" cdsButton class="cds-button-text" title="Invoice number, click for details"
								(click)="goToInvoiceDetails(item.id)" (keyup.enter)="goToInvoiceDetails(item.id)">
							{{item.invoiceNumber | notEmpty}}
						</button>
						<ng-container *ngIf="!isInvoiceDetailsEnabled()">
							{{item.invoiceNumber | notEmpty}}
						</ng-container>
					</div>
					<div role="cell" class="cds-list-cell font-weight-bold" tabindex="0" title="Total amount">
						{{item.totalAmount | currencyProfile: item?.currencyCode | notEmpty}}
					</div>
					<div role="cell" class="cds-list-cell d-none d-xl-block font-weight-bold color-danger"
						 tabindex="0"
						 title="Due amount">
						{{item.totalDueAmount | currencyProfile: item?.currencyCode | notEmpty}}
					</div>
					<div role="cell" class="cds-list-cell" tabindex="0" title="Payment code">{{ item.referenceNumber }}</div>
					<div role="cell" class="cds-list-cell" tabindex="0" title="Status"><state [stateInput]="item.stateCode"></state></div>
					<div role="cell" class="cds-list-cell d-none d-sm-block" aria-selected="true" title="Download PDF"
						 (click)="pdfDownload(item.id, item.invoiceNumber, $event)"
						 *ngIf="isICTProfile() ? item.lastSentReportFilePath : item.lastReportFilePath;
						 else notFoundInvoiceFile;"
						 (keyup.enter)="pdfDownload(item.id, item.invoiceNumber, $event)">
						<button cdsButton class="cds-button-text">Download PDF</button></div>
				</cds-list-row>
			</cds-list>
		</div>
	</div>

	<div class="container invoice-list-unpaid__container pl-0" *caCustomizationHide="[profiles.XVLO]">
		<div class="row mt-4 mb-5">
			<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 invoice-list-unpaid__amount">
				Amount: {{totalAmount | currencyProfile: unpaidCurrency}}
				<div class="invoice-list-unpaid__button d-inline-block ml-5">
					<button cdsButton class="cds-button-primary"
							(click)="payInvoice()">
						Pay
					</button>
				</div>
			</div>
		</div>
	</div>
	<hr>
</ng-container>

<ng-template #notFound>
	<page-subtitle [pageSubtitle]="'Unpaid invoices'"></page-subtitle>
	<div class="row mb-4" *ngIf="!isUnpaidSectionVisible">
		<div class="col-xl-12 font-weight-bold text-center fs-2 icon-lg">
			<div tabindex="0">All invoices are paid</div>
		</div>
	</div>
</ng-template>

<ng-template #notFoundInvoiceFile>
	<div role="cell" class="cds-list-cell d-none d-sm-block">
		-
	</div>
</ng-template>
