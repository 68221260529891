import {Action} from "@ngrx/store";
import {Offering} from "shared/app/index";
import {CartItem} from "../../../models/cart-item";

export enum CartActionsTypes {
	AddCartItem = '[Cart] Add Cart Item',
	AddCartItemAssociationRequire = '[Cart] Add Cart Item Association Require',
	AddCartItemSuccess = '[Cart] Add Cart Item Success',
	AddCartItemAssociationRequireSuccess = '[Cart] Add Cart Item Association Require Success',
	RemoveCartItem = '[Cart] Remove Cart Item',
	RemoveCartItemSuccess = '[Cart] Remove Item Success',
	GetCartItems = '[Cart] Get Items',
	GetCartItemsSuccess = '[Cart] Get Items Success',
	AddCartItemDeclined = '[Cart] Add Item Declined',
	ClearCart = '[Cart] Clear Cart',
	ClearCartSuccess = '[Cart] Clear Cart Success'
}

export class AddCartItem implements Action {
	readonly type = CartActionsTypes.AddCartItem;
	constructor(public payload: CartItem) { }
}


export class AddCartItemSuccess implements Action {
	readonly type = CartActionsTypes.AddCartItemSuccess;
	constructor(public payload: Offering) { }
}

export class AddCartItemAssociationRequire implements Action {
	readonly type = CartActionsTypes.AddCartItemAssociationRequire;
	constructor(public payload: CartItem) { }
}

export class AddCartItemAssociationRequireSuccess implements Action {
	readonly type = CartActionsTypes.AddCartItemAssociationRequireSuccess;
	constructor(public payload: CartItem) { }
}

export class AddCartItemDeclined implements Action {
	readonly type = CartActionsTypes.AddCartItemDeclined;
}

export class RemoveCartItem implements Action {
	readonly type = CartActionsTypes.RemoveCartItem;
	constructor(public payload: Offering) { }
}

export class RemoveCartItemSuccess implements Action {
	readonly type = CartActionsTypes.RemoveCartItemSuccess;
	constructor(public payload: Offering) { }
}

export class GetCartItems implements Action {
	readonly type = CartActionsTypes.GetCartItems;
}

export class GetCartItemsSuccess implements Action {
	readonly type = CartActionsTypes.GetCartItemsSuccess;
	constructor(public payload: any) { }
}

export class ClearCart implements Action {
	readonly type = CartActionsTypes.ClearCart;
}

export class ClearCartSuccess implements Action {
	readonly type = CartActionsTypes.ClearCartSuccess;
	constructor(public payload: any) { }
}

export type CartActions = AddCartItem | AddCartItemSuccess | AddCartItemAssociationRequire | AddCartItemAssociationRequireSuccess | AddCartItemDeclined | RemoveCartItem | RemoveCartItemSuccess |
	GetCartItems | GetCartItemsSuccess | ClearCart | ClearCartSuccess;
