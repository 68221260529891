import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {ApiService} from "./api.service";

@Injectable({
	providedIn: 'root',
})
export class SystemVersionService {
	constructor(protected apiService: ApiService) {
	}

	getSystemVersion():Observable<string> {
		return this.apiService.get('/version', { responseType: 'text'});
	}

}
