import { noop } from '../../utils/facade';
import { ModalInstanceRegistry } from './modal-instance-registry';

export class ModalInstance<ResponseType, ParamsType> implements BaseModalInstance {

	readonly params: ParamsType;
	readonly close: CloseFunction;
	readonly dismiss: DismissFunction;
	private destroy: DestroyFunction = noop;

	constructor(
			params: ParamsType,
			closeCallback: CloseFunction,
			dismissCallback: DismissFunction,
			registry: ModalInstanceRegistry) {

		this.params = params;

		this.close = result => {
			registry.unregister(this);
			setTimeout(() => {
				closeCallback(result);
				this.destroy();
			});
		};

		this.dismiss = reason => {
			registry.unregister(this);
			setTimeout(() => {
				dismissCallback(reason);
				this.destroy();
			});
		};

		registry.register(this);
	}

	onDestroy(callback: DestroyCallback): void {
		const next: DestroyFunction = this.destroy;
		this.destroy = () => callback(next);
	}
}

export abstract class BaseModalInstance {

	readonly close: CloseFunction;
	readonly dismiss: DismissFunction;
	abstract onDestroy(callback: DestroyCallback);
}

interface DestroyFunction {
	(): void;
}

interface CloseFunction {
	(result?: any): void;
}

interface DismissFunction {
	(reason?: any): void;
}

interface DestroyCallback {
	(next: DestroyFunction): void;
}
