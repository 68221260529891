import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {PaymentRegulationViewModel} from "./payment-regulation-view-model";
import {PaymentsService} from "../../services/selfcare/payments.service";

@Component({
	selector: 'payment-regulations',
	templateUrl: './payment-regulations.component.html',
})
export class PaymentRegulationsComponent {

	@Input() paymentRegulations: PaymentRegulationViewModel[];
	@Output() regulationsConfirmed : EventEmitter<boolean> = new EventEmitter<boolean>();

	getRegulationHtml(paymentRegulation : PaymentRegulationViewModel) {
		return paymentRegulation.paymentRegulation.inputLabel + ` <span class="ml-1 required button-danger" style="color: #da2442;"><strong>*</strong></span>`
	}

	paymentRegulationsConfirmed() {
		const paymentRegulationsValid = [];
		this.paymentRegulations.forEach(r => paymentRegulationsValid.push(r.validate()));
		return !paymentRegulationsValid.includes(false);
	}

	validate() {
		this.regulationsConfirmed.emit(this.paymentRegulationsConfirmed());
	}

}
