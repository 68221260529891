
<div class="footer__webshop">
	<hr>
	<div class="container cds-grid--no-padding">
		<div class="row footer__items-container">
			<div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 d-none d-sm-block">
				<div class="d-inline-block footer__block-of-items" *ngFor="let salesChannel of footerItems; let i = index">
					<div class="footer__block-title font-weight-bold" tabindex="0">
						{{ salesChannel.name }}
					</div>
					<ng-container *ngIf="salesChannel.offers.length > 5; else less_than_five">
						<div class="footer__item" tabindex="0" *ngFor="let offer of salesChannel.offers | slice:0:5"
							(click)="selectOffer(salesChannel.name, offer)"
							(keydown.enter)="selectOffer(salesChannel.name, offer)">
							{{ offer.name }}
						</div>
						<button cdsButton class="cds-button-text" #button aria-label="Down" [attr.aria-expanded]="open"
										aria-haspopup="menu" aria-controls="dropdown-menu"
										(click)="open[i]=!open[i]"><i class="cds-icon-angle-down"></i></button>
						<cds-dropdown *ngIf="open[i]" (dismiss)="open[i]=false" [anchor]="button">
							<ul role="menu" id="dropdown-menu">
								<li role="menuitem" class="footer__item" tabindex="0" *ngFor="let offer of salesChannel.offers | slice:6"
										(click)="selectOffer(salesChannel.name, offer)"
										(keydown.enter)="selectOffer(salesChannel.name, offer)">
									{{offer.name}}
								</li>
							</ul>
						</cds-dropdown>
					</ng-container>
					<ng-template #less_than_five>
						<div class="footer__item" tabindex="0" *ngFor="let offer of salesChannel.offers"
								 (click)="selectOffer(salesChannel.name, offer)"
								 (keydown.enter)="selectOffer(salesChannel.name, offer)">
							{{ offer.name }}
						</div>
					</ng-template>
				</div>
			</div>
			<div class="col-xl-3 xol-lg-3 col-md-3 col-sm-3 col-12 text-xl-right text-lg-right text-md-right text-sm-center">
				<div class="footer__item" tabindex="0" (click)="showPrivacyPolice()" (keydown.enter)="showPrivacyPolice()">
					Privacy policy
				</div>
				<div class="footer__item" tabindex="0" (click)="showUsePolicy()" (keydown.enter)="showUsePolicy()">
					Acceptable use
				</div>
			</div>
		</div>
	</div>
</div>

