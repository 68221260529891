import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { ViewKey } from '../models/view-key';
import { ContextService } from './context.service';
import { map, take, tap } from 'rxjs/operators';
import { Constants } from '../utils/constants';
import { UtilService } from '../utils/util.service';
import { Store } from '@ngrx/store';
import { ContextState } from '../core/store/state/context.state';
import { KeycloakService } from 'keycloak-angular';
import { AppPath } from '../utils/app-path';
import { CDSToastService } from './cds-toast.service';
import { AuthInfo } from '../models/auth-info';
import { AuthInterceptor } from '../selfcare/interceptors/auth.interceptor';
import { Token } from '../models/token';
import { AppPathService } from './app-path.service';
import { AuthRequestDTO } from '../dto/auth-request.dto';
import { ApiService, User } from 'shared/app/index';
import { ViewKeys } from '../utils/view-keys';

@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {

	profiles = Constants;
	logoutEvent: Subject<any> = new Subject();
	loginEvent: Subject<any> = new Subject();

	public static FULL_AUTH_INFO_URL: string = '/v2/auth/full-auth-info';

	constructor(private cookies: CookieService, private router: Router
			, private pathService: AppPathService, private http: HttpClient, private keycloakService: KeycloakService
			, private apiService: ApiService, private activatedRoute: ActivatedRoute
			, private contextService: ContextService, private store: Store<ContextState>
			, private utilService: UtilService, private cdsToast: CDSToastService) {
	}

	public login(username: string, password: string): Observable<string> {

		const auth = new AuthRequestDTO(username, password);

		const env = environment.api_url;

		let headers = this.utilService.injectHeaders();

		headers = headers.append('Skip-interceptor', 'true');

		return this.http.post(env + '/auth/login', auth, {
					headers: headers,
					observe: 'response',
				},
		).pipe(
				map(res => {
					return res.headers.get('Authorization');
				}),
				tap({next: () => this.loginEvent.next()}),
		);
	}

	public loadUser(authToken: string): Observable<User> {
		let headers = new HttpHeaders();
		headers = headers.append('Authorization', AuthInterceptor.prepareAuthTokenHeaderValue(authToken));
		headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
		const env = environment.api_url;
		return this.http.get<User>(env + '/v2/auth/user-info', {headers: headers});
	}

	public getFullAuthInfo(): Observable<AuthInfo> {
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/application-json');
		const env = environment.api_url;
		return this.http.get<AuthInfo>(env + AuthenticationService.FULL_AUTH_INFO_URL, {headers: headers});
	}

	public refreshToken(): Observable<Token> {
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/application-json');
		headers = headers.append('Authorization', AuthInterceptor.prepareAuthTokenHeaderValue(this.getToken()));
		headers = headers.append('Skip-interceptor', '1');
		const env = environment.api_url;
		return this.http.get<Token>(env + '/auth/refresh-token', {headers: headers});
	}

	public loadViewKeys(authToken: string): Observable<ViewKey[]> {
		let headers = new HttpHeaders();
		headers = headers.append('Authorization', AuthInterceptor.prepareAuthTokenHeaderValue(authToken));
		headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
		const env = environment.api_url;
		return this.http.get<ViewKey[]>(env + '/auth/viewKeys', {headers: headers});
	}

	public logOut(redirect: boolean = true, showMessage: boolean = true) {
		this.logoutEvent.next(1);
		if (environment.keycloak.enabled) {
			this.contextService.clearStorage(true);
			this.keycloakService.logout(window.location.origin + '?al=1');
		} else {
			this.contextService.clearStorage(true);

			if (this.router.url != AppPath.PATH_LOGIN && !this.activatedRoute.snapshot.queryParamMap.get('login')) {
				//TODO there was additional condition here
				if (showMessage) {
					this.cdsToast.showInfo('You are logged out', 'Log out');
				}
			}
			if (this.router.url != AppPath.PATH_LOGIN && !this.router.url.startsWith(AppPath.PATH_SET_PASSWORD + '/') && redirect) {
				this.pathService.navigate([AppPath.PATH_LOGIN]);
			}
		}
	}

	public hasPrivilege(viewKey: string | ViewKeys): boolean {
		const keys = this.contextService.getViewKeys() || [];
		for (let i = 0; i < keys.length; i++) {
			const key = keys[i];
			if (key.code == viewKey.toString()) {
				return true;
			}
		}
		return false;
	}

	public isUserNotActivatedError(errorResponse: any): boolean {
		return errorResponse instanceof HttpErrorResponse
				&& errorResponse.status === 406
				&& errorResponse.error.toString().startsWith('NOT_ACTIVATED');
	}

	isLogged(): boolean {
		return !!localStorage.getItem(Constants.LOCALSTORAGE_TOKEN);
	}

	getToken() {
		return localStorage.getItem(Constants.LOCALSTORAGE_TOKEN);
	}

	public setToken(token: string = null) {
		if (token != null) {
			localStorage.setItem(Constants.LOCALSTORAGE_TOKEN, token);
		} else {
			localStorage.removeItem(Constants.LOCALSTORAGE_TOKEN);
		}
	}
}
