import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";

@Injectable({
	providedIn: 'root',
})
export class VoucherService {

	checkVoucherCode(code: string): Observable<any> {
		return of({discount: 100})
	}
}
