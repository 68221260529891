import { FormStyle, getLocaleDayNames, getLocaleMonthNames, TranslationWidth } from '@angular/common';
import { Component, ElementRef, forwardRef, Inject, Input, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConsumerFunction, noop, RunnableFunction } from '../../utils/facade';
import { Time } from '../../utils/time';
import { AllowedDaysData, AllowedDaysMap, getAllowedDaysData } from './allowed-days';

declare const Pikaday: any;

const DATE_PICKER_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => DatePickerComponent),
	multi: true,
};

@Component({
	selector: 'ca-date-picker',
	template: '',
	providers: [DATE_PICKER_CONTROL_VALUE_ACCESSOR],
})
export class DatePickerComponent implements ControlValueAccessor, OnInit, OnDestroy {

	constructor(
			private elementRef: ElementRef,
			@Inject(LOCALE_ID) private localeId: string) {

		this.weekdays = getLocaleDayNames(localeId, FormStyle.Standalone, TranslationWidth.Wide);
		this.weekdaysShort = getLocaleDayNames(localeId, FormStyle.Standalone, TranslationWidth.Short);
		this.months = getLocaleMonthNames(localeId, FormStyle.Standalone, TranslationWidth.Wide);
	}

	private picker: any;

	private onChange: ConsumerFunction = noop;
	private onTouched: RunnableFunction = noop;

	private readonly months: string[];
	private readonly weekdays: string[];
	private readonly weekdaysShort: string[];

	@Input()
	set allowedDays(allowedDays: string[]) {
		const data: AllowedDaysData = getAllowedDaysData(allowedDays);
		if (data) {
			this.from = data.from;
			this.to = data.to;
			this.allowedDaysMap = data.allowedDaysMap;
		} else {
			this.from = null;
			this.to = null;
			this.allowedDaysMap = null;
		}
	}

	@Input()
	set from(from: string) {
		if (from) {
			this._from = Time.stringToDate(from);
		} else {
			this._from = null;
		}
		if (this.picker) {
			this.picker.setMinDate(this._from);
		}
	}

	@Input()
	set to(to: string) {
		if (to) {
			this._to = Time.stringToDate(to);
		} else {
			this._to = null;
		}
		if (this.picker) {
			this.picker.setMaxDate(this._to);
		}
	}

	@Input()
	disableWeekends: boolean = false;

	private _from: Date;
	private _to: Date;
	private allowedDaysMap: AllowedDaysMap;

	ngOnInit(): void {
		const config: any = {
			field: document.createElement('div'),
			container: this.elementRef.nativeElement,
			bound: false,
			firstDay: 1,
			disableWeekends: this.disableWeekends,
			minDate: this._from,
			maxDate: this._to,
			i18n: {
				months: this.months,
				weekdays: this.weekdays,
				weekdaysShort: this.weekdaysShort,
			},
			onSelect: (date: Date) => {
				if (date) {
					date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
					this.onChange(Time.fromDate(date).toString());
				} else {
					this.onChange(null);
				}
			},
			disableDayFn: (date: Date) => {
				if (this.disabled) {
					return true;
				}
				if (this.allowedDaysMap) {
					const time: string = Time.fromDate(date).toString();
					return this.allowedDaysMap[time] !== true;
				}
				return false;
			},
			onClose: () => this.onTouched(),
		};
		this.picker = new Pikaday(config);
		document.removeEventListener('keydown', this.picker._onKeyChange);
	}

	ngOnDestroy(): void {
		this.picker.destroy();
	}

	writeValue(obj: any): void {
		this.picker.setDate(obj, true);
		const date: Date = this.picker.getDate();
		if (date !== null) {
			if (Time.fromDate(date).toString() !== obj) {
				this.picker.setDate(null, true);
			}
		}
	}

	registerOnChange(fn: ConsumerFunction): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: RunnableFunction): void {
		this.onTouched = fn;
	}

	private disabled: boolean = false;

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		if (this.picker) {
			this.picker.gotoDate(this.picker.getDate());
		}
	}

	goToDate(date: string): void {
		this.picker.gotoDate(Time.fromString(date).toDate());
	}
}

try {
	// Test whether Pikaday library is available.
	// This code should stripped by tree-shaking in production mode
	// if DatePickerComponent is not used.
	console.assert(Pikaday && Pikaday.prototype && Pikaday.prototype.gotoDate);
} catch (ex) {
	console.error('Error! Global variable Pikaday doesn\'t exist. Include Pikaday library.');
}
