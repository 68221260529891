import { Term } from 'shared/app';

export class TermDTO implements Term {
  id: number;
  version: number;
  code: string;
  description: string;
  type: string;

  constructor(object: Term) {
    this.id = object.id;
    this.version = object.version;
    this.code = object.code;
    this.description = object.description;
    this.type = object.type;
  }
}
