import { Injectable } from '@angular/core';
import { config } from 'config';
import { AppProfile } from '../utils/app-profile';

@Injectable({
	providedIn: 'root',
})
export class ProfileService {
	isProfileXVLO() {
		return config.custom == AppProfile.XVLO;
	}

	isProfileXICT() {
		return config.custom == AppProfile.XICT || config.custom == AppProfile.XICT_INTERNAL;
	}

	isProfileXEHC() {
		return config.custom == AppProfile.XEHC;
	}

	isProfileDefault(): boolean {
		return config.custom == AppProfile.DEFAULT;
	}
}
