import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DialogContext, DialogService } from '@cds/components-angular';
import { SelfcareToastService } from '../../../services/selfcare-toast.service';
import { ContextService } from '../../../services/context.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { ViewKeys } from '../../../utils/view-keys';
import {environment} from "../../../../environments/environment";
import {WebshopUtil} from "../../../utils/webshop.util";
import {SubscriptionSwitchOperation} from "../../../models/subscription-switch-operation";
import {AccountService, Subscription} from "shared/app/index";
import {map} from "rxjs/operators";
import {SubscriptionUtils} from "../../../utils/subscription.utils";
import {viewKey} from "../../../decorators/decorators";
import { __ as translate } from '@comarch/seed-core/lib/i18n';

@Component({
	selector: 'app-subscriptions',
	templateUrl: 'subscriptions.component.html',
})
@viewKey(ViewKeys.V_SELFCARE_SUBSCRIPTION_VIEW)
export class SubscriptionsComponent {
	@ViewChild('changePlanModal', {static: false})
	public changePlanDialog: TemplateRef<DialogContext<unknown>>;
	@ViewChild('dialogChangePlanModal', {static: false}) elChangePlanModal: ElementRef;

	isLoading: boolean = false;
	isLoadingOverlay: boolean = false;
	isModalOpened: boolean = false;
	isWebshopButtonVisible: boolean = false;

	contracts: any [];
	contextAccountId: number;

	modalContext: any = null;
	modalTransitionResponse: any = null;
	selectedPrice: any = {bindingPeriod: {id: -1}};
	selectedTransition: any = null;
	selectedContract: any = null;
	showErrorMessage: boolean = false;
	errorMessage: any;
	isSwitch: boolean = false;
	switchDate: any = null;

	constructor(private accountService: AccountService, private router: Router, private toastService: SelfcareToastService,
				private contextService: ContextService, private dialogService: DialogService, private webshopUtil: WebshopUtil,
				private authService: AuthenticationService) {
		this.isWebshopButtonVisible = (this.authService.hasPrivilege(ViewKeys.VIEW_KEY_WEBSHOP_ORDER) && environment.webshop_after_login);
		this.contextService.getAccountId()
			.subscribe((accountId: number) => {
				this.contextAccountId = accountId;
			});
	}

	ngOnInit(): void {
		this.isModalOpened = false;
		this.modalContext = null;
		this.modalTransitionResponse = null;
		this.isLoading = true;
		if (this.contextAccountId) {
			this.accountService.getSubscriptionsForAccount(this.contextAccountId)
				.pipe(map((response) => {
					return this.checkSubscriptionBundles(response);
				}))
				.subscribe((subscriptions) => {
					this.contracts = subscriptions;
					this.isLoading = false;
				}, (error) => {
					this.isLoading = false;
				});
		} else {
			this.toastService.warning(`Account for customer does not exists \n Account id: ${this.contextAccountId}`);
		}
	}

	private checkSubscriptionBundles(subscriptionsList: Subscription[]) {
		subscriptionsList.forEach((subscriptionItem: Subscription) => {
			if(SubscriptionUtils.hasBundleItems(subscriptionItem)) {
				this.attachBundlesToSubscriptionItem(subscriptionsList, subscriptionItem);
			}
		})
		return this.removeBundleFromSubscriptionList(subscriptionsList);
	}

	private attachBundlesToSubscriptionItem(subscriptionsList: Subscription[], subscriptionItem: any) {
		subscriptionItem.bundlesList = subscriptionsList.filter((item) => item.parentSubscriptionId == subscriptionItem.objectId);
	}

	private removeBundleFromSubscriptionList(subscriptionsList: Subscription[]) {
		return subscriptionsList.filter((subscription) => !SubscriptionUtils.isBundleItem(subscription));
	}

	showWebshopOffer() {
		this.webshopUtil.redirectToWebshop();
	}

	showChangePlanModal(event, item) {
		if(!this.isModalOpened) {
			this.selectedTransition = {bindingPeriod: {id: 0}};
			this.mapModalValues(event, item);
			this.openChangePlanModal();
		}
	}

	isDataValid(): boolean {
		let isValid = true;
		this.showErrorMessage= false;
		if (this.selectedPrice.bindingPeriod.id == -1) {
			this.errorMessage = translate('sc.subscription.select_modify_option');
			this.showErrorMessage= true;
			isValid = false;
		} else if(this.isSwitch && !this.switchDate) {
			this.errorMessage = translate('sc.subscription.set_service_delivery_date');
			this.showErrorMessage= true;
			isValid = false;
		}
		return isValid;
	}

	modifySubscription() {
		if (this.isDataValid()) {
			this.showErrorMessage = false;
			this.isLoadingOverlay = true;

			let operationDate;
			this.isSwitch ? operationDate = new Date(this.switchDate) : operationDate = new Date()
			const  switchSubscriptionsOperations = [];
			const operation = new SubscriptionSwitchOperation();
			operation.toBindingPeriodId = this.selectedPrice.bindingPeriod.id;
			operation.subscriptionId = this.modalContext.objectId;
			operation.toProductOfferingId = this.selectedTransition.toOffering.id;
			operation.transitionType = this.selectedTransition.type;
			operation.requestedOperationDate = operationDate;
			operation.requestedValidFrom = operationDate;
			operation.type = 'SwitchSubscription';
			// tslint:disable-next-line:no-console

			switchSubscriptionsOperations.push(operation);
			this.accountService.modifySubscriptionForAccount(this.contextAccountId, switchSubscriptionsOperations)
				.subscribe((response) => {
					this.isModalOpened = false;
					this.isLoadingOverlay = false;
					window.location.reload(true);
				}, (err) => {
					console.log(err);
					this.isLoadingOverlay = false;
					this.errorMessage = translate('sc.subscription.error_has_occurred');
					this.showErrorMessage= true;
				});
		}
	}

	selectTransition(transition, price, contract) {
		this.selectedTransition = transition;
		this.selectedPrice = price;
		this.selectedContract = contract;
	}

	setSwitchDate(event) {
		this.switchDate = event;
	}

	private mapModalValues(event, item) {
		this.modalTransitionResponse = event.map;
		event.action == 'switch' ? this.isSwitch = true : this.isSwitch = false;
		this.modalContext = item;
		this.modalContext.bindingEndDate = event.bindingEndDate;
	}

	private openChangePlanModal() {
		this.isModalOpened = true;
		this.dialogService.open(this.changePlanDialog);
		setTimeout(() => {
			this.elChangePlanModal.nativeElement.focus();
		}, 300);
	}

}
