import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { config } from 'config';

@Directive({
  selector: '[caCustomizationShow]'
})
export class CustomizationShowDirective {

	@Input('caCustomizationShow')
	set customization(customization: string[]) {
		if (customization.includes(config.custom)) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		}
	}

	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef) {}
}
