import { Component, Input, OnInit} from '@angular/core';


@Component({
	selector: 'wrappable-text-template',
	templateUrl: './wrappable-text-template.component.html',
})
export class WrappableTextTemplateComponent implements OnInit {

	@Input()
	value: string;

	constructor() {
	}

	ngOnInit() {

	}

}
