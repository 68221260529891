import { Observable, of, throwError } from 'rxjs';
import { catchError, flatMap, map, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { ErrorResponse } from '../../models/error-response';
import { Log } from '../../models/log';
import { CookieService } from 'ngx-cookie-service';
import { HttpErrorWrapper } from '../../utils/http-error-wrapper';
import { Constants } from '../../utils/constants';
import { Store } from '@ngrx/store';
import { ContextState } from '../../core/store/state/context.state';
import { CDSToastService } from '../../services/cds-toast.service';
import { getUser } from '../../core/store/selectors/context.selectors';
import { AuthRequestDTO } from '../../dto/auth-request.dto';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { AppProfile } from '../../utils/app-profile';
import { config } from 'config';

export class HttpErrorHandler {

	static readonly DISABLE_LOGGING_HEADER = 'Disable-Logging';

	constructor(private http: HttpClient, private cookie: CookieService, private store: Store<ContextState>, private handlerService: ErrorHandlerService) {
	}

	catchHttpError<T>(method: string, url: string, body: any = null, options: {
						  headers?: HttpHeaders | {
							  [header: string]: string | string[];
						  };
						  params?: HttpParams | {
							  [param: string]: string | string[];
						  }
					  } = null,
	): (err: any, caught: Observable<T>) => Observable<any> | Promise<any> {
		let headers = new HttpHeaders();
		let params = new HttpParams();

		if (options != null) {
			if (options.headers instanceof HttpHeaders) {
				headers = options.headers;
			} else {
				headers = new HttpHeaders(options.headers);
			}

			if (options.params instanceof HttpParams) {
				params = options.params;
			} else {
				params = new HttpParams(options.params);
			}
		}
		const sendCookies = this.cookie.getAll();

		return (err: any, caught: Observable<T>) => {
			let piped: Observable<HttpErrorWrapper | null>;
			if (err.error instanceof Blob && err.error.type === 'application/json') {
				return new Promise<any>((resolve, reject) => {
					const reader = new FileReader();
					reader.onload = (e: Event) => {
						try {
							const errmsg = JSON.parse((<any> e.target).result);
							const errorWrapper = new HttpErrorWrapper({
								httpMethod: method,
								httpParams: params,
								response: errmsg,
								error: errmsg,
								headers: err.headers,
								status: err.status,
								statusText: err.statusText,
								url: err.url,
							});
							this.logError(errorWrapper, body, url, headers, method, params, sendCookies).subscribe(e => reject(e));
						} catch (e) {
							reject(err);
						}
					};
					reader.onerror = (e) => {
						reject(err);
					};
					reader.readAsText(err.error);
				});
			} else {
				piped = this.logError(err, body, url, headers, method, params, sendCookies);
			}

			return piped.pipe(flatMap(wrapper => {
				return throwError(wrapper);
			}));
		};
	}

	private logError(err: any, body: any, url: string, headers: HttpHeaders, method: string, params: HttpParams, sendCookies: {}): Observable<HttpErrorWrapper | null> {

		let piped: HttpErrorWrapper = new HttpErrorWrapper({
			httpMethod: method,
			httpParams: params,
			error: err,
			headers: headers,
			response: null,
			url: url,
		});

		let log = 'Unknown';
		let errorResponse: ErrorResponse = null;
		if (err == null) {

		} else if (err instanceof HttpErrorResponse || err instanceof HttpErrorWrapper) {
			errorResponse = this.parseError(err.error);
			piped = err as HttpErrorWrapper;
			piped.httpMethod = method;
			piped.httpParams = params;
			piped.response = errorResponse;

			if (errorResponse == null) {
				if (AppProfile.XEHC == config.custom) {
					if(err.status < 500){
						throw err;
					} 
				}
				console.error('INTERNAL error', err);
				let details = err.error;
				if (err.error && err.error.text) {
					details = err.error.text;
				}
				log = this.substring(err.message, 100) + '; ' + this.substring(details, 280);
			}
		} else if (this.isServiceLayerException(err)) {
			errorResponse = err;
		} else if (typeof err === 'string') {
			console.error('INTERNAL error', err);
			log = err;
		}

		if (errorResponse !== null) {
			console.error('API error', err);
			//do not show internal errors
			if (AppProfile.XEHC == config.custom) {
				this.handlerService.xehcShowErrorMsg(err);
			}
			if (environment.httpInterceptorLoggingEnabled && this.isDisplayable(errorResponse)) {
				this.handlerService.showErrorMsg(errorResponse);
			}
			log = `${errorResponse.errorMessage} [code: ${errorResponse.errorCode}]. IssueId: ${errorResponse.issueId}`;

		}

		if (headers.get(HttpErrorHandler.DISABLE_LOGGING_HEADER) == null && environment.httpInterceptorLoggingEnabled) {
			return this.sendLogWithUserDataToAPI(log, piped, body, headers, sendCookies);
		} else {
			return of(piped);
		}
	}

	private isDisplayable(errorResponse: ErrorResponse): boolean {
		if (this.isErrorInternal(errorResponse)) {
			return false;
		}

		if (this.handlerService.isServiceBreakError(errorResponse)) {
			return false;
		}

		return true;
	}

	private isErrorInternal(errorResponse: ErrorResponse) {
		return errorResponse.errorCode <= 0;
	}

	private sendLogWithUserDataToAPI(log: string, piped: HttpErrorWrapper, body: any, headers: HttpHeaders, sendCookies: {}) {
		return this.buildUserData().pipe(
			switchMap((userData) => {
				return this.sendLog(this.buildLogMessage(log, piped, userData, body, headers, sendCookies));
			}),
			catchError(e => {
				console.error('Error while sending log');
				return of();
			}),
			switchMap(() => of(piped)),
		);
	}

	private buildLogMessage(log: string, piped: HttpErrorWrapper, userData: string, body: any, headers: HttpHeaders, sendCookies: {}) {
		return this.prepareMessage(log)
			+ this.buildStatus(piped)
			+ userData
			+ this.buildParams(piped.httpParams)
			+ this.buildBody(body)
			+ this.buildHeaders(headers, piped.headers)
			+ this.buildCookies(sendCookies, this.cookie.getAll());
	}

	private parseError(err): ErrorResponse | null {
		if (err === null) {
			return null;
		}
		if (this.isServiceLayerException(err)) {
			return err;
		} else {
			try {
				return (JSON.parse(err) as ErrorResponse);
			} catch (e) {
				return null;
			}
		}
	}

	private substring(text: string | null, charNum: number) {
		let msg = text;
		if (text == null) {
			return '';
		}
		if (typeof text !== 'string') {
			msg = JSON.stringify(text);
		}
		return msg.length > charNum ? msg.substring(0, charNum) + '(..)' : msg;
	}

	private prepareMessage(log: string) {
		return this.substring(log, 400).replace('\n', ' ');
	}

	private buildStatus(err: HttpErrorWrapper) {
		return `\n${err.httpMethod} ${err.status} ${err.url}`;
	}

	private buildUserData(): Observable<string> {
		const browser = localStorage.getItem(Constants.BROWSER_ID_KEY) || '';
		const agent = navigator.userAgent;
		return this.store.select(getUser).pipe(take(1), map(u => {
			let user = '';
			if (u) {
				user = u.id.toString();
			}
			return `\nU:${browser}|${user}|${window && window.location || ''}|${agent}`;
		}));
	}

	private buildHeaders(request: HttpHeaders, response: HttpHeaders) {
		const sh = request.keys().map(b => {
			if (b == 'Authorization') {
				return 'Authorization: (..)';
			}
			return b + ': ' + request.getAll(b).join(',');
		}).join('|');
		let rh = response.keys().map(b => b + ': ' + response.getAll(b).join(',')).join('|');
		if (sh == rh) {
			rh = '';
		}
		return `\nSH:${sh}\nRH:${rh}`;
	}

	private buildParams(params: HttpParams) {
		const ps = params.keys().filter(b => b !== 'password').map(b => b + '=' + params.get(b)).join('|');
		return `\nSP:${ps}`;
	}

	private buildCookies(sendCookies, receivedCookies) {
		const sc = Object.keys(sendCookies).map(k => {
			const v = sendCookies[k];
			if (k.toLocaleLowerCase() == 'token') {
				return 'token=...' + v.substring(v.length - 15);
			}
			return k + '=' + v;
		}).join('|');
		const rc = !Object.is(sendCookies, receivedCookies)
			? Object.keys(receivedCookies).filter(b => b !== 'token').map(b => b + '=' + receivedCookies[b]).join('|')
			: '';
		return `\nSC:${sc}\nRC:${rc}`;
	}

	private buildBody(body) {
		let b = '';
		if (body != null) {
			if (body instanceof AuthRequestDTO) {
				body = new AuthRequestDTO(body.username, '(..)');
			}
			b = this.substring(JSON.stringify(body), 200);
		}

		return `\nSB:${b}`;
	}

	private isServiceLayerException(response: any): response is ErrorResponse {
		return (response as ErrorResponse).errorCode !== undefined &&
			(response as ErrorResponse).errorMessage !== undefined &&
			(response as ErrorResponse).issueId !== undefined &&
			(response as ErrorResponse).realizationDate !== undefined;
	}

	private sendLog(msg: string) {
		const logWrapper: Log = {message: msg};
		return this.http.post(`${environment.api_url}/logs`, logWrapper, {headers: HttpErrorHandler.disableLoggingForRequestHeaders()});
	}

	public static disableLoggingForRequestHeaders(appendTo?: HttpHeaders): HttpHeaders {
		const httpHeaders = appendTo || new HttpHeaders();
		return httpHeaders.append(HttpErrorHandler.DISABLE_LOGGING_HEADER, 'true');
	}

}
