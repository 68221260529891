<form [formGroup]="addressDetailsForm" (ngSubmit)="saveAddress()">
	<div class="container cds-grid--no-padding w-100">
		<div class="row pt-2 pb-4">
			<!--
			<div class="mt-4 col-12 col-sm-6 col-md-3" *ngIf="addressFormService.isCompanyNameAvailable(addressInput.addressTypeCode)">
				<cds-input class="w-100" id="companyName" [formControlName]="'companyName'" label="Company"
							 [required]="addressFormService.isCompanyNameRequired(addressInput.addressTypeCode)"></cds-input>
				<form-error [control]="'companyName'" [form]="addressDetailsForm"></form-error>
			</div>
			<div class="mt-4 col-12 col-sm-6 col-md-3" *ngIf="addressFormService.isPersonNameAvailable(addressInput.addressTypeCode)">
				<cds-input class="w-100" id="firstName" [formControlName]="'firstName'" label="First name"
							 [required]="addressFormService.isPersonNameRequired(addressInput.addressTypeCode)"></cds-input>
				<form-error [control]="'firstName'" [form]="addressDetailsForm"></form-error>
			</div>
			<div class="mt-4 col-12 col-sm-6 col-md-3" *ngIf="addressFormService.isPersonNameAvailable(addressInput.addressTypeCode)">
				<cds-input class="w-100" id="lastName" [formControlName]="'lastName'" label="Last name"
							 [required]="addressFormService.isPersonNameRequired(addressInput.addressTypeCode)"></cds-input>
				<form-error [control]="'lastName'" [form]="addressDetailsForm"></form-error>
			</div>
			<div class="mt-4 col-12 col-sm-6 col-md-3">
				<cds-input class="w-100" id="emailAddress" [formControlName]="'emailAddress'" label="Email address" required="true"></cds-input>
				<form-error [control]="'emailAddress'" [form]="addressDetailsForm"></form-error>
			</div>
			-->
			<div class="mt-4 col-12 col-sm-6 col-md-3" *ngIf="addressDetailsForm.controls['country'] && mainCountries">
				<cds-combo [(search)]="countrySearchValue" id="country" searchDebounceTime="300" [formControlName]="'country'"
						   class="w-100" required="true" [placeholder]="countryPlaceHolder" #countryCombo>
					<label class="" title>Country</label>
					<label class="">{{countryCombo.selectedLabel}}</label>
					<ul>
						<li *ngFor="let c of mainCountries" [cdsComboItem]="c.stringValue" [cdsComboSearch]="c.stringValue | cdsComboSearchMatch : countrySearchValue"
							tabindex="-1" [labelValue]="c.stringValue" (click)="selectCountry(c)">{{c.stringValue}}</li>
					</ul>
				</cds-combo>
				<form-error [control]="'country'" [form]="addressDetailsForm"></form-error>
			</div>
			<div class="mt-4 col-12 col-sm-6 col-md-3" *ngIf="addressDetailsForm.controls['stateProvince']">
				<cds-combo id="stateProvince" [formControlName]="'stateProvince'"
						   class="w-100" required="true" [placeholder]="placeholderProvince" #regionCombo>
					<label class="" title>Region / Province</label>
					<label class="">{{regionCombo.selectedLabel}}</label>
					<ul>
						<li *ngFor="let c of regions" [cdsComboItem]="c.stringValue"
							tabindex="-1" [labelValue]="c.stringValue">{{c.stringValue}}</li>
					</ul>
				</cds-combo>
				<form-error [control]="'country'" [form]="addressDetailsForm"></form-error>
			</div>
			<div class="mt-4 col-12 col-sm-6 col-md-3">
				<cds-input class="w-100" id="city" [formControlName]="'city'" label="City" required="true"></cds-input>
				<form-error [control]="'city'" [form]="addressDetailsForm"></form-error>
			</div>
			<div class="mt-4 col-12 col-sm-6 col-md-3">
				<cds-input class="w-100" id="postcode" [formControlName]="'postcode'" label="Postal code" required="true"></cds-input>
				<form-error [control]="'postcode'" [form]="addressDetailsForm" [allMessages]="validationFormatMessage"></form-error>
			</div>
			<div class="mt-4 col-12 col-sm-6 col-md-3" *caCustomizationHide="[profiles.DEFAULT]">
				<cds-input class="w-100" id="street" [formControlName]="'street'" label="Street" required="true"></cds-input>
				<form-error [control]="'street'" [form]="addressDetailsForm"></form-error>
			</div>
			<div class="mt-4 col-12 col-sm-6 col-md-3" *caCustomizationHide="[profiles.DEFAULT]" >
				<cds-input class="w-100" id="houseNumber" [formControlName]="'houseNumber'" label="House number" required="true"></cds-input>
				<form-error [control]="'houseNumber'" [form]="addressDetailsForm"></form-error>
			</div>
			<div class="mt-4 col-12 col-sm-6 col-md-3" *ngIf="addressDetailsForm.controls['address'] != null">
				<cds-input class="w-100" id="address" [formControlName]="'address'" label="Address" required="true"></cds-input>
				<form-error [control]="'address'" [form]="addressDetailsForm"></form-error>
			</div>
			<!--
			<div class="mt-4 col-12 col-sm-6 col-md-3">
				<cds-input class="w-100" id="phoneNumber" [formControlName]="'phoneNumber'" label="Phone number" required="true"></cds-input>
				<form-error [control]="'phoneNumber'" [form]="addressDetailsForm"></form-error>
			</div>
			-->
		</div>
		<div class="row w-100">
			<div class="col-12 text-right pb-4">
				<button cdsButton class="cds-button-text button-large" [boxCustomizableElement]="'buttonText'" style="margin-top:10px" (click)="endEditMode()" *ngIf="editMode">
					Cancel
				</button>
				<button cdsButton class="cds-button-primary ml-4" [boxCustomizableElement]="'buttonPrimary'" *ngIf="editMode" type="submit">Save</button>
			</div>
		</div>
		</div>
</form>
