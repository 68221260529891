<page-subtitle [pageSubtitle]="'Contact data'"></page-subtitle>
<form [formGroup]="passwordChangeForm" (ngSubmit)="changeUserPassword()">
	<div class="container cds-grid--no-padding w-100">
		<div class="row">
			<div class="col">
				<div class="container cds-grid--no-padding container-info">
					<div class="row">
						<div class="col"><span class="info-title">First name</span>
							<div class="info-value">{{userContact.firstName | notEmpty}}</div>
						</div>
						<div class="col"><span class="info-title">Last name</span>
							<div class="info-value">{{userContact.lastName | notEmpty}}</div>
						</div>
						<div class="col"><span class="info-title">Phone number</span>
							<div class="info-value">{{userContact.businessPhone | notEmpty}}</div>
						</div>
						<div class="col"><span class="info-title">Email address </span>
							<div class="info-value">{{userContact.emailAddress | notEmpty}}</div>
						</div>
					</div>
					<br/>
					<div class="row">
						<div class="col"><span class="info-title">Preferred contact method </span>
							<div class="info-value">{{userContact.preferredContactMethod | notEmpty}}</div>
						</div>
						<div class="col"><span class="info-title">Cell phone number </span>
							<div class="info-value">{{userContact.cellPhone | notEmpty}}</div>
						</div>
						<div class="col"><span class="info-title">Contact type </span>
							<div class="info-value">{{userContact.contactType | notEmpty}}</div>
						</div>
						<div class="col"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<hr/>
	<page-subtitle [pageSubtitle]="'Password'"></page-subtitle>
	<div class="container cds-grid--no-padding w-100">
		<div class="row">
			<div class="col">
				<div class="col">
					<ng-container *ngIf="!editMode; else editModeTemplate;">
						<span class="info-title">Password </span>
						<div class="info-value">***************</div>
					</ng-container>
					<ng-template #editModeTemplate>
						<cds-input class="cds-input" type="password" formControlName="oldPassword"
								   label="Old password" name="oldPassword"
								   [required]="true" tabindex="-1" (focusout)="checkOldPassword()"
						></cds-input>
						<div class="mb-4 pb-3">
							<form-error [control]="'oldPassword'" [allMessages]="validationMessages"
										[form]="passwordChangeForm"></form-error>
						</div>

						<cds-input class="cds-input" type="password" formControlName="newPassword"
								   label="New password" name="newPassword" [required]="true"></cds-input>
						<div class="mt-2 mr-3 mb-4 pb-3">
							<password-requirements [passwordForm]="passwordChangeForm"
												   [validationMessages]="validationMessages"
												   [control]="'newPassword'"
							></password-requirements>
							<!--<form-error [control]="'newPassword'" [allMessages]="validationMessages" [form]="passwordChangeForm"></form-error>-->
						</div>

						<cds-input class="cds-input" type="password" formControlName="confirmPassword"
								   label="Confirm password" name="confirmPassword"
								   [required]="true"></cds-input>
						<div class="mb-4 pb-2">
							<form-error [control]="'confirmPassword'" [allMessages]="validationMessages"
										[form]="passwordChangeForm"></form-error>
						</div>

						<!-- Spinner -->
						<cds-spinner-wrapper mode="partial" [overlay]="true" *ngIf="isLoading">
							<cds-spinner></cds-spinner>
						</cds-spinner-wrapper>
					</ng-template>
				</div>
			</div>
		</div>
	</div>
	<button cdsButton class="float-right cds-button-text" [boxCustomizableElement]="'buttonText'"
			(click)="changeMode();" *ngIf="!editMode">
		Change password
	</button>
	<div class="row w-100">
		<div class="col-12 text-right pb-4">
			<button cdsButton class="cds-button-text button-large" [boxCustomizableElement]="'buttonText'"
					style="margin-top:10px"
					(click)="changeMode();" *ngIf="editMode">Cancel
			</button>
			<button cdsButton class="cds-button-primary ml-4" [boxCustomizableElement]="'buttonPrimary'"
					*ngIf="editMode" type="submit">Save
			</button>

		</div>
	</div>
</form>
