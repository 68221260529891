import {Component, EventEmitter, OnInit, Output, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {DateFormComponent} from "@cds/components-angular";
import {WebshopConstant} from "../../../utils/webshop.constant";

@Component({
	selector: 'service-delivery-date',
	templateUrl: 'service-delivery-date.component.html',
	providers: [DatePipe]
})
export class ServiceDeliveryDateComponent implements OnInit {
	@Output() dateEvent: EventEmitter<any> = new EventEmitter<any>()
	inputValue: string;
	currentDate: any;

	@ViewChild(DateFormComponent, {static: false}) dateFormComponent: DateFormComponent;

	constructor(protected router: Router, private datePipe: DatePipe) {

	}

	ngOnInit() {
		this.setCurrentDate();
		this.initInputDate();
		this.dateEvent.emit(this.formatDateHours(this.inputValue));
	}

	initInputDate() {
		const storeData: { activationDate: string, optIn: any, personalData: any } = JSON.parse(localStorage.getItem(WebshopConstant.LOCALSTORAGE_CHECKOUT_DATA));
		if(
			storeData?.activationDate &&
			(Date.now()-Date.parse(storeData?.activationDate)) / (1000*60*60*24) < 1 // is today or later
		) {
			this.inputValue = (this.datePipe.transform(storeData.activationDate, 'yyyy-MM-dd'));
		}  else {
			this.inputValue = (this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
		}
	}

	setCurrentDate() {
		this.currentDate = (this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
	}

	changeModelHandler() {
		this.dateEvent.emit(this.formatDateHours(this.inputValue));
	}

	private formatDateHours(dateValue: string) {
		return dateValue ? dateValue : null;
	}
}
