import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {CartState} from "../../core/store/state/cart.state";
import {Store} from "@ngrx/store";
import {getCart, getCartCurrency, getCartItems} from "../../core/store/selectors/cart.selectors";
import {AddCartItem, ClearCart, GetCartItems, RemoveCartItem} from "../../core/store/actions/cart.actions";
import {Offering, Price} from "shared/app/index";
import {AppPath} from "../../utils/app-path";
import {ServiceabilityCheckService} from "./serviceability-check.service";
import {ProfileService} from "../profile.service";
import {CartModalService} from "./cart-modal.service";
import {AppPathService} from "../app-path.service";
import {CartItemDTO} from "../../dto/cart-item.dto";
import {first, map} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable({
	providedIn: 'root',
})

export class CartService {

	cartCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	cartTotalPricePeriodic: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	cartTotalPriceOneTime: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	constructor(private cart: Store<CartState>,
				private pathService: AppPathService,
				private serviceAbilityCheck: ServiceabilityCheckService,
				private profileService: ProfileService,
				private cartModalService: CartModalService) {

	}

	dispatch() {
		this.cart.dispatch(new GetCartItems());
		this.updateCart();
	}

	updateCart() {
		this.cart.select(getCart).subscribe((cart) => {
			this.cartCount.next(cart.totalCount);
			this.cartTotalPricePeriodic.next(cart.totalPricePeriodic);
			this.cartTotalPriceOneTime.next(cart.totalPriceOneTime);
		})
	}


	getCartItems() {
		return this.cart.select(getCartItems);
	}

	addItem(item: Offering, price: Price) {
		if(this.serviceAbilityCheck.isServiceabilityCheckEnabled() && this.serviceAbilityCheck.isLocationSupported()) {
			this.addItemToShoppingCart(item, price);
		} else if(!this.serviceAbilityCheck.isServiceabilityCheckEnabled()) {
			this.addItemToShoppingCart(item, price);
		} else {
			this.cartModalService.showNotSupportedLocationModal.next(true);
		}
	}

	private addItemToShoppingCart(item: Offering, price: Price) {
		this.cart.dispatch(new AddCartItem(new CartItemDTO(item, price)));
		if (item.additionalOfferings.requires && item.additionalOfferings.requires.length > 0) {
			this.addRequiredOfferings(item, price);
		}
		this.updateCart();
	}

	removeItem(item) {
		this.cart.dispatch(new RemoveCartItem(item));
		this.updateCart();
	}

	clearCart() {
		this.cart.dispatch(new ClearCart());
		this.updateCart()
	}

	showCartPage() {
		this.pathService.navigate([AppPath.PATH_WEBSHOP_SHOPPING_CART])
	}

	getCurrency(): Observable<any> {
		return this.cart.select(getCartCurrency);
	}

	private addRequiredOfferings(offering: Offering, price) {
		offering.additionalOfferings.requires.forEach((offering: Offering) => {
			if(offering.isOneTime) {
				offering.transitions.forEach((transition: any) => {
					if(transition.type == 'Subscribing') {
						transition.prices.forEach((searchingPrice: Price) => {
							if(price.bindingPeriod.id == searchingPrice.bindingPeriod.id) {
								this.cart.dispatch(new AddCartItem(new CartItemDTO(offering, searchingPrice)));
							}
						})
					}
				})
			} else if(offering.isPeriodic) {
				offering.prices.forEach((searchingPrice: Price) => {
					if(price.bindingPeriod.id == searchingPrice.bindingPeriod.id) {
						this.cart.dispatch(new AddCartItem(new CartItemDTO(offering, searchingPrice)));
					}
				})
			}
		});
	}
}
