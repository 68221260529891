import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {BoxConfigurationService} from '../services/box-configuration.service';
import {AppPath} from '../utils/app-path';

@Injectable({
	providedIn: 'root'
})
export class BoxGuard implements CanActivate {

	constructor(private router: Router, private boxService: BoxConfigurationService) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const urlParamsCount = state.url.split('/').filter(Boolean).length;

		switch (urlParamsCount) {
			case 0:
				return this.router.parseUrl(AppPath.PATH_NOT_FOUND);
				break;
			case 1:
			case 3:
				return this.boxService.getBoxParams(route.paramMap.get('box')).pipe(
					catchError(err => {
						if (err.status === 404) {
							this.router.navigate([AppPath.PATH_NOT_FOUND]);
						}
						return of(null);
					}),
					map(data => {
						if (data) {
							this.boxService.boxParam.next(data);
							return this.router.parseUrl('/' + data.shortCode.toLowerCase() + AppPath.PATH_WEBSHOP_HOME);
						} else {
							return this.router.parseUrl(AppPath.PATH_NOT_FOUND);
						}
					})
				);
				break;
            default:
                return true;
        }

    }
}
