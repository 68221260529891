import { NgModule } from '@angular/core';
import {CustomizationHideDirective} from "./customization-hide.directive";

@NgModule({
	declarations: [
		CustomizationHideDirective,
	],
	exports: [
		CustomizationHideDirective,
	],
})
export class CustomizationHideModule {
}
