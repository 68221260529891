import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { ContextState } from '../../../../core/store/state/context.state';
import { Store } from '@ngrx/store';
import { getCustomerId } from '../../../../core/store/selectors/context.selectors';
import { AddContextInvoicesToPay } from '../../../../core/store/actions/context.actions';
import { viewKey } from '../../../../decorators/decorators';
import { CDSToastService } from '../../../../services/cds-toast.service';
import { AppPath } from '../../../../utils/app-path';
import { ProfileService } from '../../../../services/profile.service';
import { AppPathService } from '../../../../services/app-path.service';
import { FileUtils, Invoice, InvoicesService } from 'shared/app/index';
import { AppProfile } from '../../../../utils/app-profile';

@Component({
	selector: 'app-invoice-list-unpaid',
	templateUrl: './invoice-list-unpaid.component.html',
	providers: [DatePipe],
})
@viewKey('V_INVOICE_VIEW')
export class InvoiceListUnpaidComponent implements OnInit {
	invoices: (Invoice & { isToggled: boolean, isChecked: boolean })[] = [];
	invoicesQuantity: number;
	contextCustomerId: number;
	totalAmount: number = 0;
	@Output() invoiceIdEvent = new EventEmitter<any>();
	isUnpaidSectionVisible: boolean = false;
	profiles = AppProfile;
    unpaidCurrency: string = '';

	constructor(private invoiceService: InvoicesService,
				private pathService: AppPathService,
				private store: Store<ContextState>,
				private cdsToast: CDSToastService,
				private profile: ProfileService) {
		this.store.select(getCustomerId).pipe(take(1))
			.subscribe((customerId: number) => {
				this.contextCustomerId = customerId;
			});
	}

	ngOnInit() {
		this.invoiceService.getUnpaidInvoices((this.contextCustomerId))
			.pipe(
				take(1),
				map((data: any) => {
					this.invoicesQuantity = data.numberOfElements;
					(data.numberOfElements == 0) ? this.isUnpaidSectionVisible = false : this.isUnpaidSectionVisible = true;
					data.content.forEach(((invoice: Invoice) => {
						if(!invoice.isCorrected && invoice.invoiceType!='N') {
							this.invoices.push({...invoice, isToggled: false, isChecked: false});
						    this.unpaidCurrency = this.unpaidCurrency !== '' ? this.unpaidCurrency : invoice?.currencyCode;
						}
					}));
				})
			).subscribe(() => {
		}, (error) => {
			console.log(error);
		});
	}

	pdfDownload(invoiceId: number, invoiceNumber: string, event: Event): void {
		event.stopPropagation();
		this.invoiceService.saveInvoicePdf(invoiceId).subscribe((data) => {
			FileUtils.initiateFileDownload(data, invoiceNumber.replace('/', '_'), 'application/pdf');
		}, () => {
			this.cdsToast.showDanger('Cannot download PDF')
		});
	}

	unmarkAll(): void {
		this.invoices.forEach((invoice) => {
			invoice.isChecked = false;
			this.totalAmount = 0;
		});
	}


	onInvoiceClick(clickedInvoice: Invoice & { isToggled: boolean, isChecked: boolean }, event: Event): void {
		clickedInvoice.isChecked = !clickedInvoice.isChecked;
		this.totalAmount += clickedInvoice.isChecked ? clickedInvoice.totalAmount : -this.totalAmount;
		event.stopPropagation();
	}

	payInvoice() {
		const invoicesListToPay = [];
		this.invoices.forEach((invoice) => {
			if (invoice.isChecked) {
				invoicesListToPay.push(invoice);
			}
		});
		if (invoicesListToPay.length == 0) {
			this.cdsToast.showWarning('You have to select an invoice to pay');
		} else {
			this.store.dispatch(new AddContextInvoicesToPay(invoicesListToPay));
			this.pathService.navigate([AppPath.PATH_SELFCARE_PAYMENTS]);
		}
	}

	goToInvoiceDetails(invoiceId: number): void {
		this.invoiceIdEvent.emit(invoiceId);
	}

	isICTProfile(): boolean {
		return this.profile.isProfileXICT();
	}

	isValoProfile(): boolean {
		return this.profile.isProfileXVLO();
	}

	isInvoiceDetailsEnabled(): boolean {
		return this.profile.isProfileXVLO() || this.profile.isProfileDefault();
	}

}
