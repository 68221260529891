import {Pipe, PipeTransform} from '@angular/core';
import { IndicatorStatus } from '@cds/components-angular/lib/indicator/status-indicator/status-indicator.directive';

@Pipe({name: 'transactionStateIndicatorPipe'})
export class TransactionStateIndicatorPipe implements PipeTransform {
	transform(state: string): IndicatorStatus {
		switch (state) {
			case 'SUCCESSFUL':
				return 'success';
			default:
				return;
		}
	}
}
