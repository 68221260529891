import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export function SimpleRegexpValidator(regexp: string | RegExp): ValidatorFn {
	return Validators.pattern(regexp);
}

export function BaseRegexpValidator(regexp: string | RegExp, errorKey: string): ValidatorFn {
	return (control: AbstractControl) => {
		const value = control.value as string;
		const expression = new RegExp(regexp);
		if (value && expression.test(value)) {
			return null;
		} else {
			const res = {};
			res[errorKey] = true;
			return res;
		}
	};
}

export const commonFormFieldContentValidator = SimpleRegexpValidator(/^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/);
