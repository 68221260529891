import {Component, Input} from "@angular/core";

@Component({
	selector: 'tooltip',
	templateUrl: 'tooltip.component.html'
})
export class TooltipComponent{

	@Input() text;
	@Input() tooltipMessage;
	@Input() tooltipPlacement;
	@Input() customClass : string = '';
	constructor(){

	}
}
