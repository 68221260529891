<div class="row justify-content-center mt-5">
	
	<div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">
		<div class="container cds-grid--no-padding mb-2">
			<div class="row wizard--top-progress">
				<ng-container *ngFor="let step of steps; let i = index">
					<div class="col wizard--step " [ngClass]="{'wizard--previous-step':currentIndex > i, 'wizard--current-step':currentIndex === i}">
						<span>{{i + 1 + '.'}}</span><span>&nbsp;{{step.title}}</span>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>

<div class="row justify-content-center mt-5 position-relative flex-column">
	<div class="col-md-8 col-lg-6 col-xl-6 p-6 p-sm-5 wizard--card align-self-center order-1">
		<ng-container *ngFor="let step of steps; let i = index">
			<ng-container *ngIf="currentIndex === i">
				<ng-container *ngTemplateOutlet="step.templateRef; context: {model: model}"></ng-container>
			</ng-container>
		</ng-container>
		<div class="row mt-4">
			<div class="col-12 col-md-4 offset-md-2 order-md-2 mt-4 d-flex  justify-content-md-end">
				<button type="submit" cdsButton class="cds-button-primary flex-grow-1 flex-md-grow-0" (click)="goToNextStep()">
					{{currentIndex >= steps.length - 1 ? (submitButtonText ? submitButtonText : 'Save') : 'Next'}}
				</button>
			</div>
			<div class="col-12 col-md-6 mt-4 justify-content-center justify-content-md-start">
				<page-titled-back-button *ngIf="!!previousScreenButtonText || currentIndex > 0"
																 [label]="currentIndex <= 0 ? previousScreenButtonText : 'Previous'"
																 (backed)="goToPreviousStep()"></page-titled-back-button>
			</div>
		</div>
		<div *ngIf="isLoading" class="spinner-cover">
			<cds-spinner></cds-spinner>
		</div>
	</div>
	<ng-content></ng-content>
</div>
