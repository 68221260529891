import {Injectable} from "@angular/core";
import {AbstractControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {CDSToastService} from "./cds-toast.service";

@Injectable({
	providedIn: 'root',
})
export class FormValidationService {

	constructor(private cdsToast: CDSToastService) {

	}

	showFormErrorToast(formData: FormGroup, showToast?: boolean) {
		let message = null;
		Object.keys(formData.controls).forEach(key => {
			if (!formData.controls[key].valid) {
				formData.controls[key].markAsDirty();
				if (formData.controls[key].errors) {
					Object.keys(formData.controls[key].errors).forEach(error => {
						if (!message) message = this.parseCamelToMessage(key) + ' field ' + this.parseErrorMessage(error);
					});
				}
			}
		});
		if(message && showToast) this.cdsToast.showWarning(message);
	}

	oneOfTwoFieldsRequired(form: FormGroup, firstFieldName: string, secondFieldName: string) {
		if(form.controls[firstFieldName].value && form.controls[firstFieldName].value.length > 0) {
			form.controls[secondFieldName].clearValidators();
			form.controls[secondFieldName].updateValueAndValidity();
		} else if(form.controls[secondFieldName].value && form.controls[secondFieldName].value.length > 0) {
			form.controls[firstFieldName].clearValidators();
			form.controls[firstFieldName].updateValueAndValidity();
		} else {
			form.controls[secondFieldName].setValidators([Validators.required]);
			form.controls[secondFieldName].updateValueAndValidity();
			form.controls[firstFieldName].setValidators([Validators.required]);
			form.controls[firstFieldName].updateValueAndValidity();
		}
	}

	private parseErrorMessage(errorMsg) {
		if(errorMsg == 'required') {
			return 'is required';
		} else if(errorMsg == 'email') {
			return 'has wrong format';
		} else if(errorMsg == 'pattern') {
			return 'has wrong format';
		}else if(errorMsg == 'emailNotEqual') {
			return 'is not the same';
		} else {
			return errorMsg;
		}
	}

	private parseCamelToMessage(camelString) {
		let newStr = "";
		if (camelString == 'unit_number') camelString = 'house_number';
		for (let i = 0; i < camelString.length; i++) {
			if (i == 0) {
				newStr += camelString.charAt(0).toUpperCase();
			} else {
				if (camelString.charCodeAt(i) >= 97 && camelString.charCodeAt(i) <= 122) {
					newStr += camelString[i];
				} else {
					newStr += ' ' + camelString[i];
				}
			}
		}
		return newStr;
	}

	public setErrorMessage(formGroup: FormGroup, controlname: string, errorName: string): void {
		const ourControl: AbstractControl = formGroup.get(controlname);
		const oldControlError: ValidationErrors = ourControl.errors;
		ourControl.setErrors({
			[errorName]: true, ...oldControlError
		})
	}


}
