import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ApiService} from "./api.service";
import {Address} from "../models";
@Injectable({
	providedIn: 'root',
})
export class AddressService {
	constructor(protected apiService: ApiService) {
	}

	getAddress(id): Observable<Address> {
		return this.apiService.get('/addresses/' + id);
	}

	// Deprecated
	mergeAddress(address: Address): Observable<Address> {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
		};

		return this.apiService.post('/addresses', address, httpOptions);
	}

	updateAddress(address: Address): Observable<Address> {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
    };
    return this.apiService.put(`/v2/addresses/${address.id}`, address, httpOptions);
  }

	getAvailableCountries(): Observable<any[]> {
		return of([]);
	}

	getAvailableCountriesByCustomerType(customerTypeCode: string): Observable<any[]> {
		return of([]);
	}

	createInvoiceAddress(address: Address): Observable<any> {
		console.error('Not implemented');
		return of();
	}

	delete(id: number): Observable<any> {
		console.error('Not implemented');
		return of();
	}
}
