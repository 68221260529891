import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'XICTProcessorTypePipe',
	pure: true,
})
export class XICTProcessorTypePipe implements PipeTransform {
	transform(value: any, ...args: any[]): any {
		if (value && value.toUpperCase() === 'SHARED') {
			return 'Shared capped';
		} else if (value) {
			return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
		} else {
			return value;
		}
	}
}
