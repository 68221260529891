import {Component, OnInit} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {filter, first} from "rxjs/operators";
import {AppPath} from "../../../utils/app-path";
import {Carousel, CarouselService, ProductCatalogService} from "shared/app/index";
import {AuthenticationService} from "../../../services/authentication.service";

@Component({
	selector: 'ws-carousel',
	templateUrl: 'carousel.component.html',
})
export class CarouselComponent implements OnInit {
	isCarouselVisible: boolean = false;
	salesChannels: [];
	data: Carousel[];

	constructor(private router: Router, private salesChannelService: ProductCatalogService,
				private carouselService: CarouselService, private authService: AuthenticationService) {
		this.salesChannelService.getCatalog(this.authService.isLogged()).pipe(first()).subscribe((data) => {
			this.salesChannels = data;
		});
		this.router.events.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(() => {
				this.showCarousel();
			})
	}

	ngOnInit() {

	}

	private showCarousel() {
		this.data = [];
		if(this.isSalesChannelContext()) {
			this.loadCarousel();
			this.isCarouselVisible = true
		} else {
			this.isCarouselVisible = false;
		}
	}

	private loadCarousel() {
		if(this.getSalesChannelCode()) {
			this.carouselService.getCarouselForSalesChannel(this.getSalesChannelCode()).pipe(first())
				.subscribe((data) => {
					this.data = data;
				})
		} else {
			this.isCarouselVisible = false;
		}
	}

	private isSalesChannelContext() {
		return this.router.url.startsWith('/webshop/channel');
	}

	private getSalesChannelCode() : string {
		let salesChannelCode = null;
		this.salesChannels.forEach((salesChannel:any) => {
			if(window.location.pathname.toString().startsWith(AppPath.PATH_WEBSHOP_OFFER + salesChannel.name.toLowerCase().replace(" ", "_"))) salesChannelCode = salesChannel.name;
		})
		return salesChannelCode;
	}

}
