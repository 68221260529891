import { Component, forwardRef, Host, HostListener, Inject, InjectionToken, OnDestroy, OnInit } from '@angular/core';

@Component({
	selector: 'li[caComboItemAll]',
	templateUrl: 'combo-item-all.component.html',
})
export class ComboItemAllComponent implements OnInit, OnDestroy {

	isActive: boolean = false;

	constructor(@Host() @Inject(forwardRef(() => COMBO_ITEM_ALL_HOST)) private combo: ComboItemAllHost) {}

	@HostListener('click', ['$event'])
	onClick(event: Event): void {
		this.combo.toggleSelectAll(event, this);
	}

	ngOnInit(): void {
		this.combo.registerItemAll(this);
	}

	ngOnDestroy(): void {
		this.combo.unregisterItemAll(this);
	}
}

export const COMBO_ITEM_ALL_HOST: InjectionToken<ComboItemAllHost> = new InjectionToken<ComboItemAllHost>('COMBO_ITEM_ALL_HOST');

export interface ComboItemAllHost {
	registerItemAll(item: ComboItemAllComponent): void;
	unregisterItemAll(item: ComboItemAllComponent): void;
	toggleSelectAll(event: Event, itemAll: ComboItemAllComponent): void;
}
