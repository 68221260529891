import { locales } from 'locales';

export class TranslationUtil {

	private static readonly NOT_ALLOWED_CODE_CHARS_REGEXP: RegExp = new RegExp('[^a-z0-9_ ]', 'g');
	private static readonly SPACE_CHAR_UNDERSCORE_REPEAT_REGEXP: RegExp = new RegExp('_{2,}|  *', 'g');
	static MAP = locales;

	/**
	 * @param localesObject object with translations ex.:
	 * `import { locales } from 'locales';`
	 */
	static init(localesObject) {
		this.MAP = localesObject;
	}

	/**
	 * @param keyWithoutPrefix full translation key without prefix 'sc' ex.: `getByDottedKey('address.city')}`
	 */
	static getByDottedKey(keyWithoutPrefix: string): object | string {
		const filterFun = p => p && p !== ' ';
		return TranslationUtil.get(...keyWithoutPrefix.split('.').filter(filterFun));
	}

	/**
	 * @param segments parts of key without dot and without prefix 'sc', ex.: `getString('address', 'city')`
	 */
	static get(...segments: string[]): object | string {
		let result: any;
		if (segments.length <= 1) {
			result = this.MAP[segments[0]];
		} else {
			result = this.MAP;
			for (const p of segments) {
				if (result == null) {
					break;
				}
				result = result[p];
			}
		}


		if (result == null) {
			console.debug('Translation not found:', segments.join(', '));
			return '';
		}
		return result;
	}

	/**
	 * @param keyWithoutPrefix full translation key without prefix 'sc' ex.: `getByDottedKey('address.city')}` or already translated string
	 */
	static getOrDefault(keyWithoutPrefix: string): string {
		const byDottedKey = this.getByDottedKey(keyWithoutPrefix);
		if (byDottedKey) {
			return byDottedKey.toString();
		}
		return keyWithoutPrefix;
	}

	/**
	 * @param segments parts of key without dot and without prefix 'sc', ex.: `getString('address', 'city')`
	 */
	static getString(...segments: string[]): string {
		return TranslationUtil.get(...segments).toString();
	}

	static nameToCode(name: string): string {
		const s = name.trim().toLowerCase();
		return s
			.replace(TranslationUtil.NOT_ALLOWED_CODE_CHARS_REGEXP, '')
			.replace(TranslationUtil.SPACE_CHAR_UNDERSCORE_REPEAT_REGEXP, '_');
	}
}
