import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { noop, RunnableFunction } from '../../utils/facade';

const UNIQUE_VALIDATOR: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => UniqueDirective),
	multi: true,
};

@Directive({
	selector: '[caUnique]',
	providers: [UNIQUE_VALIDATOR],
})
export class UniqueDirective implements Validator {

	private items: string[];
	private onValidatorChange: RunnableFunction = noop;

	@Input()
	set caUnique(items: string[]) {
		this.items = items;
		this.onValidatorChange();
	}

	validate(control: AbstractControl): {} {
		if (control.value == null) {
			return null;
		}

		if (this.items.indexOf(control.value) !== -1) {
			return {
				unique: { valid: false },
			};
		}

		return null;
	}

	registerOnValidatorChange(fn: () => void): void {
		this.onValidatorChange = fn;
	}
}
