import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ErrorHandler, ErrorsDirective } from './errors.directive';

@Directive({
	selector: '[caError]',
})
export class ErrorDirective implements OnInit, ErrorHandler {

	@Input('caError')
	error: string;

	@Input('caErrorOverride')
	override: boolean = true;

	private context: any = {};

	constructor(
			private vcRef: ViewContainerRef,
			private templateRef: TemplateRef<ImplicitParam>,
			private errorsDirective: ErrorsDirective) {
	}

	ngOnInit(): void {
		if (!this.errorsDirective.hasError(this.error) || this.override) {
			this.errorsDirective.registerError(this.error, this);
		}
	}

	show(error: any): void {
		Object.assign(this.context, error);
		if (!this.vcRef.length) {
			this.vcRef.clear();
			this.vcRef.createEmbeddedView(this.templateRef, {$implicit: this.context});
		}
	}

	hide(): void {
		if (this.vcRef.length) {
			this.context = {};
			this.vcRef.clear();
		}
	}
}

interface ImplicitParam {
	$implicit: void;
}
