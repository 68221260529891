import { FirstPasswordRequest } from 'shared/app/models/requests/first-password-request';


export class FirstPasswordRequestDTO implements FirstPasswordRequest{
	password: string;
	confirmedPassword: string;
	token: string;

	constructor(object: FirstPasswordRequest) {
	  this.password = object.password;
	  this.confirmedPassword = object.confirmedPassword;
	  this.token = object.token;
  }
}
