import { KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';
import { ConfigurationBoolean } from './models/configuration-boolean';
import { AuthenticationService } from './services/authentication.service';

export function keycloakInitializer(keyclak: KeycloakService, authService: AuthenticationService): () => Promise<any> {
	return (): Promise<any> => {
		if (new ConfigurationBoolean(environment.keycloak.enabled).value()) {
			return keyclak
					.init({
						config: environment.keycloak,
						initOptions: {
							onLoad: 'check-sso',
							...new ConfigurationBoolean(environment.keycloak.silentCheckSsoEnabled).value() && {silentCheckSsoRedirectUri: window.location.origin + '/font/check-sso-iframe.html'},
							/* comment this field out (silentCheckSsoRedirectUri) if it doesn't work on smog (https might be required for this option)  */
						},
						enableBearerInterceptor: false,
						loadUserProfileAtStartUp: new ConfigurationBoolean(environment.keycloak.loadUserProfileAtStartUp).value(),
					}).then(logged => {
						authService.setToken(logged ? '???' : null);
					});
		} else {
			return Promise.resolve();
		}

	};

}
