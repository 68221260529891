import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {RequestUtil} from "../utils";
import {ApiService} from "./api.service";

@Injectable({
	providedIn: 'root',
})
export class CountryService {

	constructor(protected apiService: ApiService, protected requestUtil: RequestUtil) {

	}

	getAllCountries(): Observable<any> {
		return this.apiService.get('/countries', {
			headers: this.requestUtil.injectHeaders()
		})
	}

	getRegions(countryId: number) {
		return this.apiService.get(`/countries/${countryId}/regions`, {
			headers: this.requestUtil.injectHeaders()
		})
	}
}
