import {
	Directive,
	forwardRef,
	Host,
	HostBinding,
	HostListener,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit
} from '@angular/core';
import { COMBO_ITEM_HOST, ComboItemDirective, ComboItemHost } from './combo-item.directive';

@Directive({
	selector: '[caComboItemModal]',
})
export class ComboItemModalDirective extends ComboItemDirective implements OnChanges, OnInit, OnDestroy {

	value: any;
	labelValue: any = undefined;

	@HostBinding('class.active')
	isActive: boolean = false;

	@Input('caComboItemModal')
	private modalResponse: ComboItemModalResponse;

	private isRegistered = false;

	constructor(@Host() @Inject(forwardRef(() => COMBO_ITEM_HOST)) protected combo: ComboItemHost) {
		super(combo);
	}

	@HostListener('click', ['$event'])
	onClick(event: Event): void {}

	ngOnChanges(changes): void {
		setTimeout(() => {
			this.updateCombo();
		});
	}

	updateCombo() {
		if (this.modalResponse) {
			this.combo.unregisterItem(this);
			const item: ComboItemDirective = this.combo.findItem(this.modalResponse.value);
			if (item) {
				this.combo.onItemClick(null, item);
			} else {
				this.value = this.modalResponse.value;
				this.labelValue = this.modalResponse.labelValue;
				this.isRegistered = true;
				this.combo.registerItem(this);
				this.combo.onItemClick(null, this);
			}
		}
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {}
}

export interface ComboItemModalResponse {
	value: any;
	labelValue?: any;
}
