import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DialogContext, DialogService} from "@cds/components-angular"
import { TokenModalService } from '../../services/token-modal.service';
import { AuthenticationService } from '../../services/authentication.service';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'token-refresh-modal',
    templateUrl: './token-refresh-modal.component.html', 
})
export class TokenRefreshModalComponent implements OnInit {

    @ViewChild('tokenDialog', {static: false}) dialogOpenRef: TemplateRef<DialogContext<unknown>>
    public isPageLoading: boolean;
    constructor(private dialogService: DialogService,
             private tokenModalService: TokenModalService,
             private authService: AuthenticationService,) {

    }

    ngOnInit() {
        this.tokenModalService.isTokenMessage
            .pipe(debounceTime(500))
            .subscribe( (message: boolean) => {
                this.isPageLoading = message
                if(message){
                    this.dialogService
                        .open(this.dialogOpenRef)
                        .catch(error=>console.log(error));
                    setTimeout(()=>this.logoutCloseModal(),2500)
            }
        })
    }
    private logoutCloseModal(): void {
        this.authService.logOut(true, false);
        this.tokenModalService.setIsTokenMessage(false);
    }
}