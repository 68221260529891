import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { config } from 'config';
import { Constants } from '../../utils/constants';
import { environment } from '../../../environments/environment';
import { AppPath } from '../../utils/app-path';
import { ContextService } from '../../services/context.service';
import { ProfileService } from '../../services/profile.service';
import { AppPathService } from '../../services/app-path.service';
import { BoxConfigurationService } from '../../services/box-configuration.service';
import { AppProfile } from '../../utils/app-profile';

@Component({
	selector: 'app-brand',
	templateUrl: './app-brand.component.html',
})
export class AppBrandComponent implements OnInit {

	subtitle: string;
	brandName: string;
	isProdEnvironment: boolean = false;
	environmentName: string;
	@Input() title: string;
	@Output() onAppBrandComponentClick: EventEmitter<any> = new EventEmitter();
	profiles = AppProfile;
	isSelfcareContext: boolean = false;
	isBoxEnabled: boolean = false;

	constructor(private pathService: AppPathService, private contextService: ContextService,
							private boxConfigurationService: BoxConfigurationService, private profileService: ProfileService) {
		this.isBoxEnabled = this.boxConfigurationService.isBoxFeatureEnabled();
		this.contextService.isSelfcareContextActive
				.subscribe((value: boolean) => {
					this.isSelfcareContext = value;
				});
		if (!profileService.isProfileDefault()) {
			if (environment.selfcare_env == Constants.APP_ENVIRONMENT_PROD) {
				this.isProdEnvironment = true;
			} else {
				this.environmentName = environment.selfcare_env;
			}
		}
	}

	ngOnInit() {
		this.brandName = 'Comarch';
	}

	checkProfile(profiles: string[]): boolean {
		if (profiles.includes(config.custom)) {
			return true;
		} else {
			return false;
		}
	}

	getActiveProfile() {
		return config.custom;
	}

	homePage() {
		if (!this.isSelfcareContext && !this.profileService.isProfileXICT()) {
			this.pathService.navigate([AppPath.PATH_WEBSHOP_HOME])
		} else {
			this.pathService.navigate([AppPath.PATH_SELFCARE_HOME])
		}
	}
}
