<div class="row">
	<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-5">
		<page-title [pageTitle]="'Contracts'" [additionalText]="'Your current contract is shown below.'"></page-title>
	</div>
	<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-7 text-right">
		<button *ngIf="isWebshopButtonVisible" cdsButton cdsRippleDisable class="cds-button-primary cds-button-small" (click)="showWebshopOffer()"
				(keydown.enter)="showWebshopOffer()">
			Subscribe to new product
		</button>
	</div>
</div>

<div class="mt-4">
	<ng-container *ngFor="let contract of contracts">
		<subscriptions-single-row [subscription]="contract"
									   (changePlanEvent)="showChangePlanModal($event, contract)"></subscriptions-single-row>
	</ng-container>
</div>

<data-not-found-placeholder [items]="contracts" *ngIf="!isLoading"></data-not-found-placeholder>

<!-- Loading -->
<cds-spinner *ngIf="isLoading"></cds-spinner>

<!-- MODALS -->
<!-- Change Plan -->
<ng-template #changePlanModal let-changePlanModal>
	<div #dialogChangePlanModal tabindex="0" class="no-focus-outline"
		 aria-labelledby="dialogChangePlanModalTitle"
		 aria-describedby="dialogChangePlanModalContent"
		 role="dialogChangePlan" (keydown.esc)="isModalOpened = false; changePlanModal.resolve()">
		<cds-dialog role="dialog" class="cds-dialog--medium  dialog__container">
			<!-- Header -->
			<cds-slot-title class="dialog__header" id="dialogChangePlanModalTitle">
				Modify subscription
			</cds-slot-title>

			<cds-slot-content class="dialog__content" id="dialogChangePlanModalContent">
				<div class="dialog__message">
					Selected product name: <b>{{ modalContext.productOfferingName | notEmpty }}</b>
				</div>
				<div class="dialog__message mt-4">
					Available plans:
				</div>
				<div class="dialog__message mt-4 mb-4">
					<ng-container *ngIf="modalTransitionResponse && modalTransitionResponse.size > 0; else noData">
						<ng-container *ngFor="let group of modalTransitionResponse | keyvalue">
							<div class="w-100">
								<div class="w-100 font-weight-bold">
									<hr>
									{{ group.key }}
								</div>
								<div class="d-inline-block mt-4" *ngFor="let transition of group.value; index as i">
									<div *ngIf="transition.toOffering && transition.toOffering.prices">

										<div  *ngFor="let price of transition.toOffering.prices">

										<div *ngIf="price.bindingPeriod" class="mr-4 subscription__modify-item"
											 (click)="selectTransition(transition, price, modalContext)"
											 [ngClass]="{'subscription__modify-item--active': selectedPrice.bindingPeriod.id == price.bindingPeriod.id
											 && selectedTransition.id == transition.id
											 && selectedPrice == price}">
											{{ price.amount | currencyProfile }}
											({{price.bindingPeriod.duration.value}} {{price.bindingPeriod.duration.unit}}
											)
										</div>
										</div>
									</div>
								</div>
							</div>
						</ng-container>

						<div class="w-100">
							<hr>
							<div class="dialog__message mt-4">
								Requested service delivery date:
							</div>
							<div class="dialog__message mt-4 w-50">
								<service-delivery-date *ngIf="isSwitch; else isRenew"
									(dateEvent)="setSwitchDate($event)"></service-delivery-date>
								<ng-template #isRenew>
									{{ modalContext.bindingEndDate | date : 'mediumDate' | notEmpty}}
								</ng-template>
							</div>
						</div>

					</ng-container>
					<ng-template #noData>
						<p class="not-found__text">Not found.</p>
					</ng-template>

				</div>
			</cds-slot-content>
			<!-- Footer -->
			<cds-slot-footer>
				<div class="w-100 text-center subscription__modify-error-msg" *ngIf="showErrorMessage">
					<div class="cds-icon-info-circle cds-icon-md mr-2"></div>
					<div class="d-inline-block mt-2">
						{{ errorMessage }}
					</div>
				</div>
				<div class="cds-dialog-buttons">
					<button cdsButton class="cds-button-text" (click)="isModalOpened = false; changePlanModal.resolve()">
						Close
					</button>
					<button *ngIf="modalTransitionResponse && modalTransitionResponse.size > 0" cdsButton
							class="cds-button-primary" (click)="modifySubscription()">
						Confirm
					</button>
				</div>
			</cds-slot-footer>
		</cds-dialog>
		<!-- Loading -->
		<cds-spinner-wrapper mode="partial" [overlay]="true" *ngIf="isLoadingOverlay">
			<cds-spinner></cds-spinner>
		</cds-spinner-wrapper>
	</div>
</ng-template>

