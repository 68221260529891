import { AbstractControl, FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { __ as translate } from '@comarch/seed-core/lib/i18n';

@Injectable({
	providedIn: 'root',
})
export class FormUtils {
	private defaultMessages = {
		required: translate('sc.common.required_field'),
		email: translate('sc.address.invalid_email'),
		firstAndLastNameRequired: translate('sc.address.first_name_and_last_name_required'),
		emailUsed: translate('sc.common.email_used'),
		connectionProblemEmailCheck: translate('sc.common.email_check_connection_problem'),
		connectionProblemVerification: translate('sc.common.connection_problem_verification'),
		companyOrPersonalNameRequired: translate('sc.address.company_or_personal_name_required'),
		minlength: 'Field value is too short',
		maxlength: 'Field value is too long',
		pattern: 'Field value has incorrect format',
	};

	shouldShowErrors(controlName: AbstractControl | string, form: null | FormGroup = null) {
		let control;
		if (!(controlName instanceof AbstractControl)) {
			control = form.get(controlName);
		} else {
			control = controlName;
		}
		if (control) {
			return control.invalid && (control.touched || control.dirty);
		} else {
			console.warn('Control ' + controlName + ' not found when checking for errors');
			return false;
		}
	}

	getErrorMsgs(controlName: string, form: FormGroup, messages: any): string[] {
		const control = form.get(controlName);
		const values = {};
		const result: string[] = [];
		if (control.errors != null && Object.keys(control.errors).length > 0) {
			Object.assign(values, this.defaultMessages);
			Object.assign(values, messages);
			for (const name of Object.keys(control.errors)) {
				let msg: string = null;
				const forControl = values[controlName];
				if (forControl) {
					msg = forControl[name];
				}

				if (msg == null) {
					msg = values[name];
				}
				if (msg) {
					result.push(msg);
				}
			}
		}
		return result;
	}

	showAllFormErrors(form: FormGroup) {
		(<any> Object).values(form.controls).forEach(control => {
			control.markAsDirty();
		});
	}

	setControlsValues(form: FormGroup, values: {}, controlsList: string[] = null) {
		if (controlsList == null || controlsList.length === 0) {
			controlsList = Object.keys(form.controls);
		}
		for (const name of controlsList) {
			const abstractControl = form.get(name);
			if (abstractControl) {
				abstractControl.setValue(values[name]);
			}
		}
		return form;
	}

	controlFieldChanged(prev, controlsKey, next) {
		return prev && prev[controlsKey] != next[controlsKey];
	}

	scrollToInvalidElement() {
		const firstElementWithError = document.querySelector('.ng-invalid');
		if (firstElementWithError) {
			firstElementWithError.scrollIntoView({behavior: 'smooth'});
		}
	}

	replaceEmptyStringsWithNull(form: FormGroup): FormGroup {
		for (const controlName in form.controls) {
			const value = form.controls[controlName].value;
			if (value === '') {
				form.controls[controlName].setValue(null);
			}
		}
		return form;
	}
}
