import { createFeatureSelector, createSelector } from '@ngrx/store';
import { XICTContextState } from '../state/xict-context.state';
import { XICTCloudProduct } from '../../../models/xict/cloud-product/xict-cloud-product';

export const getContextState = createFeatureSelector('xictContext');

export const getXICTContext = createSelector(
		getContextState,
		(state: XICTContextState) => state,
);

export const getXICTActiveCloudProduct = createSelector(
		getContextState,
		(state: XICTContextState) => {
			if (state.cloudProducts.length > 0) {
				if (state.cloudProducts.length > 1) {
					console.error('More than one cloud product active', state.cloudProducts);
				}
				const p = state.cloudProducts[0];
				return new XICTCloudProduct(p.key, p.name, p.state, p.type, p.availableRegions, p.validFrom, p.validTo, p.config);
			}
			return null;
		},
);
