import {Component, Input} from '@angular/core';

@Component({
	selector: 'additional-info',
	templateUrl: './additional-info.component.html',
})
export class AdditionalInfoComponent {
	@Input() text: string;
	@Input() textOnHover: string;
	constructor() {

	}
}
