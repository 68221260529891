import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppPathService } from '../../services/app-path.service';
import { __ as translate } from '@comarch/seed-core/lib/i18n';
import { ProfileService } from '../../services/profile.service';

@Component({
	selector: 'page-titled-back-button',
	templateUrl: './page-titled-back-button.component.html',
})
export class PageTitledBackButtonComponent implements OnInit {

	@Input()
	pathBack: string = null;

	@Output()
	backed: EventEmitter<any> = new EventEmitter<any>();

	/* Custom content means use ng-content */
	@Input()
	type: 'small' | 'smallCustomContent' | 'large' | 'largeCustomContent' = 'small';

	@Input()
	label: string = '';

	useArrowClass = false;

	constructor(private pathService: AppPathService, profileService: ProfileService) {
		this.useArrowClass = profileService.isProfileXICT();
	}

	ngOnInit(): void {
		if (this.type === 'small' && !this.label) {
			this.label = translate('sc.common.back');
		}
	}

	onButtonClick(path: string) {
		if (path) {
			this.pathService.navigate([path]);
		} else if (this.backed.observers.length > 0) {
			this.backed.emit();
		} else {
			this.pathService.navigateBack();
		}
	}
}
