import { Injectable } from '@angular/core';
import { TopMenuItem } from '../selfcare/components/top-menu/top-menu-item';
import { __ as translate } from '@comarch/seed-core/lib/i18n';
import { InvoicesComponent } from '../selfcare/components/invoices/invoices.component';
import { TicketsComponent } from '../selfcare/components/tickets/tickets.component';
import { CustomerComponent } from '../selfcare/components/customer/customer.component';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { CookieService } from 'ngx-cookie-service';
import { ContextService } from './context.service';
import { AuthenticationService } from './authentication.service';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Constants } from '../utils/constants';
import { XVLOConstants } from '../utils/xvlo/xvlo.constants';
import { AppPath } from '../utils/app-path';
import { PaymentsListComponent } from '../selfcare/components/payments-list/payments-list.component';
import {ProductCatalogService} from "shared/app/index";
import {SubscriptionsComponent} from "../selfcare/components/subscriptions/subscriptions.component";
import {ThemeService} from "./theme.service";

@Injectable({
	providedIn: 'root',
})
export class TopMenuItemsProviderService {

	private COOKIE_WS_SALES_OFFER_CODE = 'ws_salesoffer';
	userLogin: string;
	customerId: number;
	srcUrl: string;
	EVUrl = 'https://valo-networks-apps.easyvista.com/s/rspcustomer?name=com.valo-networks.5e8b614db7c0c&';

	constructor(protected cookieService: CookieService, protected contextService: ContextService, private themeService: ThemeService,
							protected authService: AuthenticationService, protected salesChannelService: ProductCatalogService) {
		this.authService.isLogged() && this.contextService.getUser()
				.subscribe((userData) => {
					this.userLogin = userData.login;
					this.customerId = userData.customerId;
					this.srcUrl = this.buildEVUrl(this.userLogin, this.customerId);
				});
	}

	getWebshopItems(): Observable<any> {
		return this.isWebshopMenuCookieValid() ? this.getWebshopMenuCookie() : this.salesChannelService.getCatalog(this.authService.isLogged())
				.pipe(map((salesChannel) => {
					return salesChannel;
				}));
	}

	private isWebshopMenuCookieValid(): boolean {
		return !!this.cookieService.get(this.COOKIE_WS_SALES_OFFER_CODE);
	}

	private getWebshopMenuCookie() {
		return of(JSON.parse(this.cookieService.get(this.COOKIE_WS_SALES_OFFER_CODE)));
	}

	buildEVUrl(userLogin: string, customerId: number): string {
		const loginPart = 'LOGIN=' + userLogin + TopMenuItemsProviderService.getDomain();
		const supplierPart = 'SUPPLIER=' + customerId.toString();
		return this.EVUrl + loginPart + '&' + supplierPart;
	}

	private static getDomain(): string {
		const env = environment.selfcare_env;
		if (env == Constants.APP_ENVIRONMENT_PROD) {
			return XVLOConstants.PROD_DOMAIN;
		} else if (
				env == Constants.APP_ENVIRONMENT_TEST ||
				env == Constants.APP_ENVIRONMENT_STAGING) {
			return XVLOConstants.TEST_DOMAIN;
		} else if (
				env == Constants.APP_ENVIRONMENT_DEV ||
				env == Constants.APP_ENVIRONMENT_SMOG ||
				env == Constants.APP_ENVIRONMENT_LOCAL) {
			return XVLOConstants.DEV_DOMAIN;

		}
		return XVLOConstants.PROD_DOMAIN;
	}

	getTopMenuItems(): TopMenuItem[] | Observable<TopMenuItem[]> {
		let topMenuItems = [
			{name: translate('sc.menu.dashboard'), path: AppPath.PATH_SELFCARE_DASHBOARD, viewKey: null},
			{name: translate('sc.menu.invoices'), path: AppPath.PATH_SELFCARE_INVOICES, viewKey: InvoicesComponent.prototype['key']},
			{name: translate('sc.menu.payments'), path: AppPath.PATH_SELFCARE_PAYMENT_LIST, viewKey: PaymentsListComponent.prototype['key']},
			{name: translate('sc.menu.tickets'), path: AppPath.PATH_SELFCARE_TICKETS, viewKey: TicketsComponent.prototype['key']},
			{name: translate('sc.menu.contracts'), path: AppPath.PATH_SELFCARE_CONTRACTS, viewKey: SubscriptionsComponent.prototype['key']}
		]
		if(this.themeService.isThemeXDELTA()) {
			topMenuItems.push({name: 'Counter records', path: AppPath.PATH_SELFCARE_COUNTER, viewKey: null})
		}
		return topMenuItems;
	}
}
