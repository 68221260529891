<div class="mb-4 address__box" [ngClass]="isBlur || isLoading ? 'blur' : ''">
	<ng-container *ngIf="!deleteMode">
		<div class="row">
			<div class="col">
				<div class="address__box-type">{{ address.addressTypeCode | addressTypeCode }}</div>
			</div>
			<div class="col text-right">
				<button cdsButton class="cds-button-text" [boxCustomizableElement]="'buttonText'" (click)="editAddressMode()">Edit</button>
				<ng-container *ngIf="showDeleteButton()">
					|
					<button cdsButton class="cds-button-text button-danger" [boxCustomizableElement]="'buttonText'" (click)="deleteMode = true">Delete</button>
				</ng-container>
			</div>
		</div>
		<ng-container *ngIf="isDefined(); else notProvidedAddress">
			<div class="row">
				<div class="col">
					<div class="text-break">
						{{address.street}} {{address.houseNumber}}
					</div>
					<div class="text-break">
						{{address.postCode}} {{address.city}}{{ availableCountryField ? ', ' + availableCountryField : '' }}
					</div>
				</div>
			</div>
		</ng-container>
	</ng-container>
	<div class="address__current--edit" *ngIf="deleteMode">
		Are you sure you want to delete this address?
		<div class="row mt-4">
			<div class="col">
				<button cdsButton class="cds-button-text" [boxCustomizableElement]="'buttonText'" (click)="processDelete(false)">Cancel</button>
			</div>
			<div class="col">
				<button cdsButton class="cds-button-text button-danger" [boxCustomizableElement]="'buttonText'" (click)="processDelete(true)">Delete</button>
			</div>
		</div>
	</div>
</div>

<div *ngIf="isLoading" class="ca-whirlpool position-absolute"></div>

<div class="address__current--edit" *ngIf="isBlur">
	You are editing<br>
	{{ address.addressTypeCode | addressTypeCode }}
</div>

<ng-template #notProvidedAddress>
	Not provided yet
</ng-template>