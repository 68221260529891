import { Component, Input } from '@angular/core';
import { SelectedLabelModel } from './selected-label-model';

@Component({
	selector: 'label[caComboMultiSelectedLabel]',
	templateUrl: 'combo-multi-selected-label.component.html',
})
export class ComboMultiSelectedLabelComponent {

	@Input('caComboMultiSelectedLabel')
	combo: SelectedLabelModel;

	@Input()
	titleLabel: string;

	@Input()
	allLabel: string;
}
