<ng-container *ngIf="!createAddressMode">
		<page-subtitle *ngIf="addresses.length > 1" [pageSubtitle]="'Addresses'"></page-subtitle>
		<page-subtitle *ngIf="addresses.length <= 1" [pageSubtitle]="'Address'"></page-subtitle>
	<div class="row">
		<ng-container *ngFor="let address of addresses">
			<div class="col-md-4" address-single-box [address]="address" (editMode)="editAddressMode(address)" (deleted)="refresh()"
				 [events]="blurOff.asObservable()">
			</div>
		</ng-container>
		<div class="col-md-3 d-flex justify-content-center align-items-center address__add-box" *ngIf="isCreateAddressAvailable()">
			<button cdsbutton class="cds-button-text button-large" [boxCustomizableElement]="'buttonText'" (click)="createAddressMode = true">
				Add invoice address
			</button>
		</div>
	</div>
	<ng-container *ngIf="editAddress">
		<address-edit [addressInput]="editAddress" (endEdit)="closeAddressEdit($event)"></address-edit>
	</ng-container>
</ng-container>
<ng-container *ngIf="createAddressMode">
	<app-create-address (finished)="createAddress($event)" (cancelled)="createAddressMode = false"></app-create-address>
</ng-container>