import {Component, OnInit} from "@angular/core";
import {CartService} from "../../../../services/webshop/cart.service";
import {ServiceabilityCheckService} from "../../../../services/webshop/serviceability-check.service";
import {VoucherService} from "../../../../services/voucher.service";
import {WebshopConstant} from "../../../../utils/webshop.constant";
import {first} from "rxjs/operators";

@Component({
	selector: 'checkout-shopping-cart-list',
	templateUrl: 'checkout-shopping-cart-list.component.html',
})
export class CheckoutShoppingCartListComponent implements OnInit {

	cartItems;
	cartTotalPricePeriodic;
	cartTotalPriceOneTime;
	activationDate: any = null;
	discountPercentValue: number = 0;

	constructor(private cartService: CartService, private locationService: ServiceabilityCheckService, private voucherService: VoucherService) {

	}

	ngOnInit(): void {
		this.checkDiscount();
		this.cartService.getCartItems().subscribe((items) => {
			this.cartItems = items;
		});
		this.cartService.cartTotalPricePeriodic.subscribe((price) => {
			this.cartTotalPricePeriodic = price;
		});
		this.cartService.cartTotalPriceOneTime.subscribe((price) => {
			this.cartTotalPriceOneTime = price;
		})
		this.getActivatioNDate();
	}

	private getActivatioNDate() {
		this.activationDate = this.locationService.getAvailableFromDate();
	}

	private checkDiscount() {
		if(localStorage.getItem(WebshopConstant.LOCALSTORAGE_VOUCHER_KEY)) {
			this.voucherService.checkVoucherCode(localStorage.getItem(WebshopConstant.LOCALSTORAGE_VOUCHER_KEY))
				.pipe(first())
				.subscribe((value: {discount: number}) => {
					this.discountPercentValue = value.discount
				})
		}
	}

}
