import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'XICTPriceDisplayAreaCodePipe',
	pure: true,
})
export class XICTPriceDisplayAreaCodePipe implements PipeTransform {
	transform(value: any, ...args: any[]): any {
		if (value === 'IBM i OS') {
			return 'IBM i';
		} else if ( value === 'vCPU(shared)') {
			return 'CPU Shared Capped';
		} else if ( value === 'vCPU(dedicated)') {
			return 'CPU Dedicated';
		} else if ( value === 'Licenced Soft') {
			return 'Licensed Software';
		} else {
			return value;
		}
	}
}
