import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'absoluteValue',
})
export class AbsoluteValuePipe implements PipeTransform {
	transform(val: number): number {
		return Math.abs(val);
	}
}
