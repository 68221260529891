import {Inject, Injectable} from '@angular/core';
import {of} from "rxjs/internal/observable/of";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {HTTP_HEADER_CONST} from "../constants";
import {ApiService} from "./api.service";
import {RequestUtil} from "../utils";
import {ProductCatalogDTO} from "../dto/product-catalog.dto";

/**
 * INFO
 * - clearAppLiveTimeCache - Clear cache manually by emit event for reload salesChannels purpose.
 * - salesChannelAppLivetimeCache - Holds sales channel data till page reload
 */

@Injectable({
	providedIn: 'root',
})

export class ProductCatalogService {

	public clearAppLiveTimeCache: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private catalogAppLivetimeCache = null;

	constructor(protected apiService: ApiService, protected requestUtil: RequestUtil, @Inject('env') public env) {

	}

	getCatalog(isUserLogged: boolean = false): Observable<any> {
		if (this.catalogAppLivetimeCache && !this.clearAppLiveTimeCache.getValue()) {
			return of(this.catalogAppLivetimeCache)
		} else {
			return this.apiService.get('/global/product-catalog/offer-catalogs', {
				headers: this.injectCatalogHeader(isUserLogged)
			}).pipe(
				map((catalogObjects: any) => {
          catalogObjects.forEach((catalog, index) => {
            catalogObjects[index] = new ProductCatalogDTO(catalog);
					});
					this.clearAppLiveTimeCache.next(false);
					this.catalogAppLivetimeCache = catalogObjects;
					return catalogObjects;
				}),
				catchError(error => {
					this.clearAppLiveTimeCache.next(false);
					return throwError(error);
				})
			);
		}
	}

	private injectCatalogHeader(isUserLogged: boolean): HttpHeaders {
		let profileValue: string =
			(isUserLogged && this.env.webshop_after_login && this.env.webshop_logged_user_offer)
			? HTTP_HEADER_CONST.APP_PROFILE_VALUE_SELFCARE
			: HTTP_HEADER_CONST.APP_PROFILE_VALUE_WEBSHOP
		return this.requestUtil.injectHeaders().append(HTTP_HEADER_CONST.APP_PROFILE_PARAM, profileValue);
	}
}
