<ng-container>
	<search-filter
					[filters]="filters"
					[filterSearchFunction]="searchFilterFunction"
					[filterDataFunction]="filterDataFunction"
					[filterStatusFunction]="filterStatusFunction"
					[states]="allAvailableStatus"
					[runFilters]="runFilters"
					[mainMessage]="'A global search for invoices. The invoice can be searched include: Invoice number, Total amount, Payment code, Issued date, Status'"
					[dropdownMessage]="'Only for available invoice'"
	>
	</search-filter>
	<!-- FILTER RESULTS -->

	<div class="row mb-3">
		<div class="col-xl-12">
			<cds-list>
				<cds-list-row head role="table" tabindex="0" aria-label="History of invoices">
					<div role="cell" class="cds-list-cell">Invoice number</div>
					<div role="cell" class="cds-list-cell">Invoice type</div>
					<div role="cell" class="cds-list-cell">Total amount</div>
					<div role="cell" class="cds-list-cell d-none d-xl-block">Payment code</div>
					<div role="cell" class="cds-list-cell d-none d-xl-block">Issued date</div>
					<div role="cell" class="cds-list-cell">Status</div>
					<div role="cell" class="cds-list-cell d-none d-sm-block">Attachments</div>
				</cds-list-row>
				<div *ngFor="let item of invoicesToShow"
							  tabindex="-1" class="cds-list-group">
					<!-- MAIN -->
					<cds-list-row class="cds-list-row invoice__table-row-border" tabindex="-1">
						<div role="cell" class="cds-list-cell" aria-selected="true" title="Invoice number">
							<button *ngIf="isInvoiceDetailsEnabled()" cdsButton class="cds-button-text" title="Invoice number, click for details"
									(click)="goToInvoiceDetails(item.id)" (keyup.enter)="goToInvoiceDetails(item.id)">
								{{item.invoiceNumber | notEmpty}}
							</button>
							<ng-container *ngIf="!isInvoiceDetailsEnabled()">
								{{item.invoiceNumber | notEmpty}}
							</ng-container>
						</div>
						<div role="cell" class="cds-list-cell font-weight-bold" tabindex="0" title="Invoice type"><invoice-type [invoiceTypeInput]="item.invoiceType"></invoice-type></div>
						<div role="cell" class="cds-list-cell font-weight-bold" tabindex="0" title="Total amount">{{item.totalAmount | currencyProfile: item?.currencyCode | notEmpty}}</div>
						<div role="cell" class="cds-list-cell d-none d-xl-block" tabindex="0" title="Payment code">{{item.referenceNumber}}</div>
						<div role="cell" class="cds-list-cell d-none d-xl-block" tabindex="0" title="Issued date">{{item.issuedDate | date : 'mediumDate' | notEmpty}}</div>
						<div role="cell" class="cds-list-cell" tabindex="0" title="Status"><state [stateInput]="item.stateCode"></state></div>
						<div role="cell" class="cds-list-cell d-none d-sm-block" aria-selected="true" title="Download PDF"
							 (click)="pdfDownload(item)" *ngIf="isICTProfile() ? item.lastSentReportFilePath : item.lastReportFilePath; else notFoundInvoiceFile;"
							 (keyup.enter)="pdfDownload(item)"><button cdsButton class="cds-button-text">Download PDF</button></div>
					</cds-list-row>

					<!-- RELATED -->
					<ng-container *ngIf="item.relatedInvoiceItems">
						<cds-list-row class="cds-list-row pt-4" *ngFor="let relItem of item.relatedInvoiceItems" tabindex="-1">
							<div role="cell" class="cds-list-cell" tabindex="-1" aria-selected="true" title="Invoice number">
								<button cdsButton class="cds-button-text ml-4 mb-0" title="Invoice number, click for details" (click)="goToInvoiceDetails(relItem.id)" (keyup.enter)="goToInvoiceDetails(relItem.id)">{{relItem.invoiceNumber | notEmpty}}</button>
							</div>
							<div role="cell" class="cds-list-cell d-none d-sm-block" tabindex="0" title="Invoice type"><invoice-type [invoiceTypeInput]="relItem.invoiceType"></invoice-type></div>
							<div role="cell" class="cds-list-cell" tabindex="0" title="Total amount">{{relItem.totalAmount | currencyProfile: relItem?.currencyCode | notEmpty}}</div>
							<div role="cell" class="cds-list-cell d-none d-xl-block" tabindex="0" title="Payment code">{{relItem.referenceNumber}}</div>
							<div role="cell" class="cds-list-cell d-none d-xl-block" tabindex="0" title="Issued date">{{relItem.issuedDate | date : 'mediumDate' | notEmpty}}</div>
							<div role="cell" class="cds-list-cell" tabindex="0" title="Status"><state [stateInput]="relItem.stateCode"></state></div>
							<div role="cell" class="cds-list-cell d-none d-sm-block" aria-selected="true" title="Download PDF"
								 (click)="pdfDownload(relItem)" *ngIf="isICTProfile() ? relItem.lastSentReportFilePath : relItem.lastReportFilePath; else notFoundInvoiceFile;"
								 (keyup.enter)="pdfDownload(relItem)"><button cdsButton class="cds-button-text">Download PDF</button></div>
						</cds-list-row>
					</ng-container>

				</div>
			</cds-list>
		</div>
	</div>

</ng-container>

<data-not-found-placeholder [items]="invoices" *ngIf="!isLoading"></data-not-found-placeholder>

<ng-template #notFound> - </ng-template>

<!-- Loading -->
<cds-spinner *ngIf="isLoading"></cds-spinner>

<pagination [totalItems]="invoicesQuantity" (pageRangeChange)="onPaginationChange($event)"></pagination>

<ng-template #notFoundInvoiceFile>
	<div role="cell" class="cds-list-cell d-none d-sm-block" aria-label="empty">
		-
	</div>
</ng-template>
