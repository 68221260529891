import { Injectable } from '@angular/core';
import { CDSToastService } from './cds-toast.service';

@Injectable({
	providedIn: 'root',
})
export class SelfcareToastService {

	constructor(private toastr: CDSToastService) {

	}

	success(message: string, title?: string) {
		this.toastr.showSuccess(message, title);
	}

	error(message: string, title?: string) {
		this.toastr.showDanger(message, title);
	}

	info(message: string, title?: string) {
		this.toastr.showInfo(message, title);
	}

	warning(message: string, title?: string) {
		this.toastr.showWarning(message, title);
	}

}
