import {Component, EventEmitter, Input, Output} from "@angular/core";
import {config} from "config";
import {locales} from "locales";
import {OptIn} from "shared/app/index";
import {TermsService} from "../../../services/terms.service";
import {WebshopConstant} from "../../../utils/webshop.constant";

@Component({
	selector: 'optin-marketing-agreement',
	templateUrl: 'optin-marketing-agreement.component.html'
})
export class OptinMarketingAgreementComponent {
	rspName: string = '';
	isOptInVisible: boolean = false;
	@Input() checkboxValue: boolean;
	@Output() optInData: EventEmitter<OptIn> = new EventEmitter<OptIn>();
	@Output() isOptInAccepted: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(private terms: TermsService) {
		this.rspName = locales[config.theme].rsp_name;
		this.getOpInData();
	}

	checkboxListener() {
		this.isOptInAccepted.emit(this.checkboxValue);
	}

	private getOpInData() {
		this.terms.getTerms(WebshopConstant.TERMS_TYPE_READ_ONLY, WebshopConstant.TERMS_CODE_GENERAL_MARKETING_OPT_IN, "MARKETING").subscribe((optInArray: any) => {
			if(optInArray.length > 0) {
				this.isOptInVisible = true;
				optInArray.forEach((optIn) => {
					if (optIn.code == WebshopConstant.TERMS_CODE_GENERAL_MARKETING_OPT_IN) {
						this.optInData.emit(this.optInMapper(optIn));
					}
				})
			} else {
				this.isOptInVisible = false;
			}
		})
	}

	private optInMapper(data): OptIn {
		return {id: data.id, version: data.version, accepted: null}
	}
}
