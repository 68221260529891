import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Address, AddressService } from 'shared/app/index';
import { AddressValidators } from '../validators/address-validators';
import { CustomFormValidators } from '../validators/custom-form-validators';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class AddressFormModelService {
	constructor(protected formBuilder: FormBuilder, protected addressService: AddressService) {

	}

	isCompanyNameAvailable(addressTypeCode: string) {
		return true;
	}

	isPersonNameAvailable(addressTypeCode: string) {
		return true;
	}

	isCompanyNameRequired(addressTypeCode: string) {
		return false;
	}

	isPersonNameRequired(addressTypeCode: string) {
		return true;
	}

	isMainAddress(addressTypeCode: string) {
		return addressTypeCode.toLowerCase().includes('main');
	}


	isInvoiceAddress(addressTypeCode: string) {
		return addressTypeCode.toLowerCase().includes('invoice');
	}

	isInvoiceAddressCreateAvailable(): boolean {
		return false;
	}

	isCountryFieldSupported(): boolean {
		return false;
	}

	isAddressDeletable(addressTypeCode: string): boolean {
		return false;
	}

	getCountryName(code: string): Observable<string> {
		return this.addressService.getAvailableCountries().pipe(first(), map(list => {
			const find = list.find(c => c.code === code);
			return find ? find.name : code;
		}));
	}

	prepareAddressCreateFromGroup(address: Address, withPersonal:boolean, accessForm:boolean): FormGroup {
		return this.formBuilder.group(this.prepareAddressEditFormConfig(address,withPersonal,accessForm));
	}

	prepareAddressEditFormGroup(address: Address, withPersonal:boolean, accessForm:boolean): FormGroup {
		return this.formBuilder.group(this.prepareAddressEditFormConfig(address,withPersonal, accessForm));
	}

	protected prepareAddressEditFormConfig(address: Address, withPersonal: boolean, accessForm:boolean) {
		if(withPersonal){
			return {
        		city: [address.city, AddressValidators.city()],
        		postcode: [address.postCode, AddressValidators.postcodeStrict()],
        		street: [address.street, AddressValidators.street()],
        		houseNumber: [address.houseNumber, AddressValidators.houseNumber()],
        		companyName: [address.companyName, CustomFormValidators.getOptionalCompanyNameValidator()],
        		firstName: [address.firstName, CustomFormValidators.getOptionalFirstNameValidator()],
        		lastName: [address.lastName, CustomFormValidators.getOptionalFirstNameValidator()],
        		mobilePhone: [address.mobilePhone, CustomFormValidators.getOptionalPhoneNumberValidator()],
        		phoneNumber: [address.phoneNumber, CustomFormValidators.getPhoneNumberValidator()],
        		emailAddress: [address.emailAddress, CustomFormValidators.getAdvancedEmailValidator()],
        		address: [address.address, Validators.required],
        		country: [address.country, Validators.required],
        		stateProvince: [address.stateProvince? address.stateProvince : address.province, Validators.required],
        	};
		}
		else if(accessForm){
			return {
				city: [address.city, AddressValidators.city()],
				postcode: [address.postCode, AddressValidators.postcodeStrict()],
				// street: [address.street, AddressValidators.street()],
				// houseNumber: [address.houseNumber, AddressValidators.houseNumber()],
				address: [address.address, Validators.required],
				country: [address.country, Validators.required],
				stateProvince: [address.stateProvince? address.stateProvince : address.province, Validators.required],
			};
		}else {
			return {
				city: [address.city, AddressValidators.city()],
				postcode: [address.postCode, AddressValidators.postcodeStrict()],
				street: [address.street, AddressValidators.street()],
				houseNumber: [address.houseNumber, AddressValidators.houseNumber()],
				address: [address.address, Validators.required],
				country: [address.country, Validators.required],
				stateProvince: [address.stateProvince? address.stateProvince : address.province, Validators.required],
			};
		}
	}
}
