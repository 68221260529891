import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VirtualizationModule } from '../../ui/virtualization/virtualization.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { TypeaheadForceSelectionDirective } from './typeahead-force-selection.directive';
import { TypeaheadItemDirective } from './typeahead-item.directive';
import { TypeaheadComponent } from './typeahead.component';

@NgModule({
	imports: [
		CommonModule,
		DropdownModule,
		VirtualizationModule,
	],
	declarations: [
		TypeaheadComponent,
		TypeaheadItemDirective,
		TypeaheadForceSelectionDirective,
	],
	exports: [
		TypeaheadComponent,
		TypeaheadItemDirective,
		TypeaheadForceSelectionDirective,
	],
})
export class TypeaheadModule {}
