import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class UtilService {

	constructor() {

	}

	public injectHeaders(): HttpHeaders {
		const brand = environment.brand;
		if (brand.checkBrand) {
			return new HttpHeaders({
				'BRAND': brand.brandCode,
			});
		}
		return new HttpHeaders();
	}
}
