import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AccountService, Address, CustomerService} from "shared/app/index";
import {concatMap, first, take} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AddressFormModelService} from '../../../services/address-form-model.service';
import {ContextService} from "../../../services/context.service";
import {ProfileService} from "../../../services/profile.service";

@Component({
	selector: 'app-address',
	templateUrl: 'address.component.html',
})
export class AddressComponent implements OnInit, OnDestroy {
	addresses: Address[] = [];
	contextCustomerId: number;
	contextAccountId: number;
	editAddress: Address;
	createAddressMode: boolean = false;
	@Input() refreshed: EventEmitter<any>;
	@Output() addressChanged: EventEmitter<any> = new EventEmitter();
	public blurOff: Subject<boolean> = new Subject<boolean>();
	private refreshSubscription;


	constructor(private customerService: CustomerService, private accountService: AccountService, private contextService: ContextService,
				public addressFormService: AddressFormModelService, private profileService: ProfileService) {
		this.contextService.getCustomerId()
			.pipe(
				first(),
				concatMap((customerId: number) => {
					this.contextCustomerId = customerId;
					return this.contextService.getAccountId();
				})
			).subscribe((accountId: number) => {
				this.contextAccountId = accountId;
			})
	}

	ngOnInit(): void {
		this.refresh();
		if (this.refreshed) {
			this.refreshSubscription = this.refreshed.subscribe(e => {
				this.refresh();
			});
		}
	}

	private getAddresses() {
		this.customerService.getCustomerAddress(this.contextCustomerId)
			.pipe(first())
			.subscribe((response) => {
				this.addresses = response;
				this.editAddress = null;
			});
	}

	ngOnDestroy(): void {
		if (this.refreshSubscription) {
			this.refreshSubscription.unsubscribe();
		}
	}

	editAddressMode(address: Address) {
		this.closeAddressEdit(false);
		this.blurOff.next(false);
		this.editAddress = address;
	}

	closeAddressEdit(hasChanged: boolean) {
		if (hasChanged) {
			this.addressChanged.emit();
		}
		this.editAddress = null;
		this.blurOff.next(false);
	}

	isCreateAddressAvailable() {
		return this.addressFormService.isInvoiceAddressCreateAvailable()
			&& this.addresses.filter(a => this.addressFormService.isInvoiceAddress(a.addressTypeCode)).length == 0
	}

	createAddress(success: boolean) {
		if (success) {
			this.createAddressMode = false;
			this.getAddresses();
		}
	}

	refresh() {
		this.getAddresses();
	}
}
