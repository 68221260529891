import {Injectable} from "@angular/core";
import {map} from "rxjs/operators";
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {RequestUtil} from "../utils";
import {ApiService} from "./api.service";
import {Offering, Price} from "../models";

@Injectable({
  providedIn: 'root',
})

export class OfferingService {

  constructor(protected apiService: ApiService,
              protected requestUtil: RequestUtil,
              protected http: HttpClient,
              protected sanitizer: DomSanitizer) { }

  public offeringsListCache: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public getOfferings(offerId: number): Observable<any> {
    return this.apiService.get('/global/product-catalog/offers/' + offerId + '/offerings', {
      headers: this.requestUtil.injectHeaders()
    })
      .pipe(map((offeringList) => {
        if(offeringList.length > 0) {
          offeringList.forEach((offering: Offering, index) => {
            offeringList[index] = this.formatOfferingResponse(offering);
          });
          this.offeringsListCache.next(offeringList);
          return offeringList;
        } else {
          this.offeringsListCache.next([]);
          return offeringList;
        }
      }));
  }

  public getOfferingDetails(offeringId: number): Observable<any> {
    return this.apiService.get('/global/product-catalog/offerings/' + offeringId, {
      headers: this.requestUtil.injectHeaders()
    }).pipe(
      map((offering) => {
        return this.formatOfferingResponse(offering)
      })
    )
  }

  private formatOfferingResponse(offering) {
    offering.pricesOneTime = null;
    offering.pricesPeriodic = null;
    offering.lowestPrice = null;
    offering.isPeriodic = false;
    offering.isOneTime = false;
    offering.productOfferingImages.smallImage = this.secureImageURL(offering.productOfferingImages.smallImage);
    offering.productOfferingImages.bigImage = this.secureImageURL(offering.productOfferingImages.bigImage);
    if(this.hasOneTimePrices(offering) && this.hasPeriodicPrices(offering)) {
      offering = {
        ...offering,
        isPeriodic: true,
        pricesOneTime: this.getPricesOneTime(offering),
        pricesPeriodic: this.getPricesPeriodic(offering)
      };
    } else if(this.hasOneTimePrices(offering)) {
      offering = {
        ...offering,
        isOneTime: true,
        pricesOneTime: this.getPricesOneTime(offering),
      }
    } else if(this.hasPeriodicPrices(offering)) {
      offering = {
        ...offering,
        isPeriodic: true,
        pricesPeriodic: this.getPricesPeriodic(offering)
      }
    }
    offering.lowestPrice = this.getTheLowestPrice(offering);
    offering.bindingPeriodsAvailable = this.getAvailableBindingPeriods(offering, offering.isPeriodic),
    offering.additionalOfferings.requires = this.checkAssociationsRequired(offering);
    offering = this.checkUnlimited(offering);
    return offering;
  }

  private secureImageURL(imageUrl: string): SafeUrl | String {
    return imageUrl ? this.sanitizer.bypassSecurityTrustUrl(imageUrl) : "";
  }

  private checkAssociationsRequired(data: Offering) {
    let offeringsRequiredList: Offering[] = data.additionalOfferings.requires;
    if(offeringsRequiredList && offeringsRequiredList.length > 0) {
      offeringsRequiredList.forEach((requiredOffering: Offering, index) => {
        if(this.hasOneTimePrices(requiredOffering)) {
          offeringsRequiredList[index] = {
            ...offeringsRequiredList[index],
            isPeriodic: false,
            isOneTime: true,
            isRequiredForAssociation: true
          };
        } else {
          offeringsRequiredList[index] = {
            ...offeringsRequiredList[index],
            isPeriodic: true,
            isOneTime: false,
            isRequiredForAssociation: true
          };
        }
        offeringsRequiredList[index] = this.checkUnlimited(offeringsRequiredList[index]);
      })
    }
    return offeringsRequiredList;
  }

  private getAvailableBindingPeriods(offering: Offering, isPeriodic: boolean) {
    if(!isPeriodic) {
      return 'One-time payment';
    }
    let bindingPeriods: string = null;
    if(offering.prices) {
      offering.prices.forEach((price: Price) => {
        if(!price.bindingPeriod) {
          bindingPeriods = (price.bindingPeriod && price.bindingPeriod.duration.value) ? price.bindingPeriod.duration.value.toString() : 'Unlimited'
        } else {
          if(bindingPeriods) {
            bindingPeriods = (price.bindingPeriod && price.bindingPeriod.duration.value)
              ? bindingPeriods + " / " + price.bindingPeriod.duration.value.toString()
              : bindingPeriods + " / " + 'Unlimited'
          } else {
            bindingPeriods = (price.bindingPeriod && price.bindingPeriod.duration.value)
              ? price.bindingPeriod.duration.value.toString()
              : 'Unlimited'
          }

        }
      })
    }
    return bindingPeriods;
  }

  private getTheLowestPrice(offering: Offering) {
    let lowestPrice = null;
    if(this.hasPeriodicPrices(offering) && this.hasOneTimePrices(offering)) {
      lowestPrice = this.getLowestPricePeriodic(offering);
    }
    else if(this.hasPeriodicPrices(offering)) {
      lowestPrice = this.getLowestPricePeriodic(offering)
    } else if(this.hasOneTimePrices(offering)) {
      lowestPrice = this.getLowestPriceOneTime(offering);
    }
    return lowestPrice;
  }


  private getPricesOneTime(offering) {
    let prices = null;
    offering.transitions.forEach((transition) => {
      if(transition.type == "Subscribing") {
        prices = transition.prices;
      }
    });
    if(!prices) {
      console.error('One time prices: Not found transition with type "Subscribe" (ID: ' + offering.id + ')');
    }
    return prices;
  }

  private getPricesPeriodic(offering) {
    return offering.prices;
  }

  private getLowestPricePeriodic(offering) {
    let lowestPrice = null;
    offering.prices.forEach((price) => {
      if(!lowestPrice) {
        lowestPrice = price;
      }
      if(lowestPrice.amount > price.amount) {
        lowestPrice = price;
      }
    });
    return lowestPrice;
  }

  private getLowestPriceOneTime(offering) {
    let lowestPrice = null;
    offering.transitions.forEach((transition: any) => {
      transition.prices.forEach((price) => {
        if(!lowestPrice) {
          lowestPrice = price;
        }
        if(lowestPrice.amount > price.amount) {
          lowestPrice = price;
        }
      })
    });
    return lowestPrice;
  }

  private checkUnlimited(offering: Offering) {
    if(this.hasPeriodicPrices(offering)) {
      offering.prices.forEach((price: any) => {
        if(!price.bindingPeriod) {
          price.bindingPeriod = {id: 0, name: 'Unlimited', duration: {value: 'Unlimited', unit: ''}};
        }
      })
    }
    if (this.hasOneTimePrices(offering)) {
      offering.transitions.forEach((transition: any) => {
        transition.prices.forEach((price) => {
          if(!price.bindingPeriod) {
            price.bindingPeriod = {id: 0, name: 'Unlimited', duration: {value: 'Unlimited', unit: ''}};
          }
        })
      });
    }
    return offering;
  }


  private hasOneTimePrices(offering) {
    let isOneTime = false;
    if(offering.transitions.length > 0) {
      offering.transitions.forEach((transition) => {
        if(transition.type == 'Subscribing') {
          isOneTime = true;
        }
      })
    } else {
      isOneTime = false;
    }
    return isOneTime;
  }

  private hasPeriodicPrices(offering) {
    return offering.prices.length > 0;
  }
}
