import { XICTCloudProductContractType } from './xict-cloud-product-contract-type';
import { XICTCloudProductState } from './xict-cloud-product-state';
import { XICTCloudProductKey } from './xict-cloud-product-key';
import { XICTMachineCreationStrategy } from '../xict-machine-creation-strategy';

export class XICTCloudProduct {
	config: {
		blocked: boolean;
		availableProjectRoles: string[];
	};
	name: string;
	key: XICTCloudProductKey;
	type: XICTCloudProductContractType;
	state: XICTCloudProductState;
	validFrom: Date;
	validTo: Date;
	availableRegions: { [key: string]: string };

	constructor(key: XICTCloudProductKey,
							name: string,
							state: XICTCloudProductState,
							type: XICTCloudProductContractType = XICTCloudProductContractType.DEFAULT,
							availableRegions: { [p: string]: string } = {},
							validFrom: Date = null,
							validTo: Date = null,
							config: { blocked: boolean; availableProjectRoles: string[] },
	) {
		this.name = name;
		this.key = key;
		this.type = type;
		this.state = state;
		this.validFrom = validFrom;
		this.validTo = validTo;
		this.availableRegions = availableRegions;
		this.config = config;
	}

	public isRegistered(): boolean {
		return XICTCloudProductState.DISABLED != this.state;
	}

	public isActive(): boolean {
		return XICTCloudProductState.ACTIVE == this.state;
	}

	public isProvisioning(): boolean {
		return XICTCloudProductState.PROVISIONING == this.state;
	}

	public isContracted(): boolean {
		return XICTCloudProductContractType.CONTRACTED == this.type;
	}

	public isInternal(): boolean {
		return XICTCloudProductContractType.INTERNAL == this.type;
	}

	public hasFutureContract(): boolean {
		return this.isRegistered() && Date.now() < new Date(this.validFrom).getTime();
	}

	public isCPC(): boolean {
		return XICTCloudProductKey.CPC == this.key;
	}

	public isCCI(): boolean {
		return XICTCloudProductKey.CCI == this.key;
	}

	public equals(other: XICTCloudProduct): boolean {
		return other != null
				&& this.key === other.key
				&& this.state === other.state
				&& this.type === other.type
				&& this.validTo == other.validTo
				&& this.validFrom == other.validFrom
				&& Object.is(this.availableRegions, other.availableRegions);
	}
}
