import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

const TYPEAHEAD_FORCE_SELECTION: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => TypeaheadForceSelectionDirective),
	multi: true,
};

@Directive({
	selector: '[caTypeaheadForceSelection]',
	providers: [TYPEAHEAD_FORCE_SELECTION],
})
export class TypeaheadForceSelectionDirective implements Validator {

	@Input('caTypeaheadForceSelection')
	selection: any;

	@Input()
	allowAnyValue: boolean = false;

	constructor() {}

	validate(control: AbstractControl): {} {
		if (!control.value || this.allowAnyValue) {
			return null;
		}
		if (this.selection !== control.value) {
			return {
				selected: { valid: false },
			};
		}
		return null;
	}

}
