import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { __ as translate } from '@comarch/seed-core/lib/i18n';
import { Store } from '@ngrx/store';
import { switchMap, take, tap } from 'rxjs/operators';
import { PluralTranslationService } from '../../../../services/plural-translation.service';
import { Contact, PasswordChangeRequest, User, UserService } from 'shared/app/index';
import { PasswordProfile } from 'shared/app/models/password-profile';
import { getUser, getUserContact } from '../../../../core/store/selectors/context.selectors';
import { ContextState } from '../../../../core/store/state/context.state';
import { CDSToastService } from '../../../../services/cds-toast.service';
import { FormValidationService } from '../../../../services/form-validation.service';
import { UserInfoService } from '../../../../services/user-info.service';
import { ValidationMessageMap } from '../../../../utils/validation-message-map';
import { CustomFormValidators } from '../../../../validators/custom-form-validators';
import { PasswordMatchValidator } from '../../../../validators/password-match-validator';

@Component({
	selector: 'app-contact-data',
	templateUrl: './contact-data.component.html',
})
export class ContactDataComponent implements OnInit {

  	@Input()
	contextCustomerId: number;
	user: User;
	userContact: Contact;
	editMode: boolean = false;
	passwordChangeForm: FormGroup;
	isLoading: boolean = false;
	validationMessages: ValidationMessageMap;
    private oldPasswordEqual: boolean = false;
    constructor(private userService: UserService,
                private cdsToast: CDSToastService,
                private router: Router,
                private store: Store<ContextState>,
                private formBuilder: FormBuilder,
                private userInfoService: UserInfoService,
				private formValidationService: FormValidationService,
				private pluralTranslationService: PluralTranslationService) {
	}

	ngOnInit(): void {
		this.store.select(getUserContact).pipe(take(1)).subscribe(contact => this.userContact = contact);
		this.store.select(getUser).pipe(
			take(1),
			tap((user: User) => {
				this.user = user;
			}),
			switchMap(() => this.userService.getUserPasswordProfile(this.user.id)),
		).subscribe((passwordProfile: PasswordProfile) => {
			this.initForm(passwordProfile);
		});
	}

	private initForm(passwordProfile: PasswordProfile): void {
		this.passwordChangeForm = this.formBuilder.group({
			newPassword: ['', CustomFormValidators.getPasswordValidator(passwordProfile)],
			oldPassword: ['', Validators.required],
			confirmPassword: [''],
		}, { validators: [ PasswordMatchValidator('newPassword', 'confirmPassword')] });

        this.validationMessages = this.getValidationMessages(passwordProfile);
	}

	private getValidationMessages(passwordProfile: PasswordProfile) {
		return {
			newPassword: {
				minlength:
					translate(
						'sc.validation.minimum_n',
						passwordProfile.minLength.toString(),
						this.pluralTranslationService.get(
							translate('sc.validation.signs_cases'), passwordProfile.minLength,
						),
					),
				maxlength:
					translate(
						'sc.validation.maximum_n',
						passwordProfile.maxLength.toString(),
						this.pluralTranslationService.get(
							translate('sc.validation.signs_cases'), passwordProfile.maxLength,
						),
					),
				number:
					translate(
						'sc.validation.minimum_n',
						passwordProfile.minNumeric.toString(),
						this.pluralTranslationService.get(
							translate('sc.validation.number_cases'), passwordProfile.minNumeric,
						),
					),
				capitalLetter:
					translate(
						'sc.validation.minimum_n',
						passwordProfile.minUpperCase.toString(),
						this.pluralTranslationService.get(
							translate('sc.validation.capital_letter_cases'), passwordProfile.minUpperCase,
						),
					),
				smallLetter:
					translate(
						'sc.validation.minimum_n',
						passwordProfile.minLowerCase.toString(),
						this.pluralTranslationService.get(
							translate('sc.validation.small_letter_cases'), passwordProfile.minLowerCase,
						),
					),
				specialCharacter:
					translate(
						'sc.validation.minimum_n',
						passwordProfile.minSymbol.toString(),
						this.pluralTranslationService.get(
							translate('sc.validation.special_characters_cases'), passwordProfile.minSymbol,
						),
					),
				whitespaceCharacter: translate('sc.validation.no_white_space'),
				usernameInPassword: translate('sc.validation.first_password_username_in_password_error_message'),
			},
			oldPassword: {
				notSame: translate('sc.validation.not_same_with_old_password'),
			},
			confirmPassword: {
				passwordsNotEqual:translate('sc.validation.not_equals_passwords'),
			},
		};
	}

	changeMode() {
	    this.editMode = !this.editMode;
	    this.passwordChangeForm.reset();
	}

    private ifPasswordsAreEmpty(): boolean {
        return !this.passwordChangeForm.get('newPassword')?.value &&
            !this.passwordChangeForm.get('confirmedPassword')?.value&&
            !this.passwordChangeForm.get('oldPassword')?.value;
    }

    private forcePasswordInputError(): void{
        for (let i of Object.keys(this.passwordChangeForm.controls)){
		    this.passwordChangeForm.get(i).markAsTouched();
		}
    }

    private sendChangePasswordRequest(): void {
	    this.isLoading = true;
		let requestBody: PasswordChangeRequest = {
			newPassword: this.passwordChangeForm.get('newPassword').value,
			confirmedPassword: this.passwordChangeForm.get('confirmPassword').value,
			oldPassword: this.passwordChangeForm.get('oldPassword').value
		};
		this.userService.changePassword(this.user.id, requestBody).subscribe(value => {
			this.cdsToast.showSuccess(translate('sc.password.change_success'));
			this.changeMode();
			this.isLoading = false;
		}, (error) => {
			this.isLoading = false;
			this.cdsToast.showDanger(error);
		});
    }

	changeUserPassword() {
		if (this.passwordChangeForm.valid) {
            this.checkOldPassword(()=>this.sendChangePasswordRequest());
		} else if (this.ifPasswordsAreEmpty()) {
            this.forcePasswordInputError();
		}
	}
    private checkIfFormControlIsValid(controlName: string): boolean{
        return this.passwordChangeForm.get(controlName).valid
    }

	public checkOldPassword(passwordFunction = undefined): void {
	    const oldPassword: string = this.passwordChangeForm.get('oldPassword').value;
	    if (oldPassword && oldPassword.length > 0) {
	        this.userInfoService.userOldPasswordCheck(oldPassword, this.user.id).subscribe(value => {
	            if (!value) {
	                this.formValidationService.setErrorMessage(this.passwordChangeForm, 'oldPassword', 'notSame');
	            } else {
	                if (passwordFunction) {
	                    passwordFunction();
	                }
	            }
	        });
	    }
	}

}
