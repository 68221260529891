import {Banner, Contact, Account, Customer, User} from "shared/app/index";

export interface ContextState {
	customer: Customer;
	account: Account;
	accounts: Account[];
	user: User;
	userContact: Contact;
	notifications: number;
	invoicesToPay: any[];
	banners: Banner[];
	lastUnreadNotificationDate: Date;
}

export const initialContextState: ContextState = {
	customer: null,
	account: null,
	accounts: [],
	user: null,
	userContact: null,
	notifications: 0,
	invoicesToPay: [],
	banners: [],
	lastUnreadNotificationDate: setDateMonthEarlierFrom(new Date())
};

function setDateMonthEarlierFrom(date: Date) {
	date.setMonth(date.getMonth() - 1);
	return date;
}
