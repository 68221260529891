<div class="row">
	<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
		<page-title [pageTitle]="'Support'"></page-title>
	</div>
	<div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-right">
		<button cdsButton class="cds-button-primary" [boxCustomizableElement]="'buttonPrimary'" routerLink="./new">Add ticket</button>
	</div>
</div>

<div *ngIf="customerTickets">

	<!-- TEXT / CLEAR FILTERS -->
	<search-filter
					[filters]="filters"
					[filterSearchFunction]="searchFilterFunction"
					[filterDataFunction]="filterDataFunction"
					[filterStatusFunction]="filterStatusFunction"
					[states]="getAllState"
					[runFilters]="runFilters"
					[mainMessage]="'A global search for tickets. The ticket can be searched include: Topic, Ticket number, Description, State, Assignee name.'"
					[dropdownMessage]="'Only for available tickets'"
	>
	
	</search-filter>
	<div class="row">
		<div class="col-xl-12">
			<cds-list>
				<cds-list-row head role="table" tabindex="0" aria-label="Tickets">
					<div role="cell" class="cds-list-cell">Ticket number</div>
					<div role="cell" class="cds-list-cell">Create date</div>
					<div role="cell" class="cds-list-cell">Topic</div>
					<div role="cell" class="cds-list-cell">Status</div>
				</cds-list-row>
				<cds-list-row *ngFor="let ticket of customerTickets;" tabindex="-1">
					<div role="cell" class="cds-list-cell" aria-selected="true" title="Invoice number">
						<button cdsButton class="cds-button-text"
										title="Ticket number, click to details"
										(click)="onRowClick(ticket)">
							{{ticket.ticketNumber}}
						</button>
					</div>
					<div role="cell" class="cds-list-cell" tabindex="0" title="Create date">{{ticket.createdDate | date: 'mediumDate'}}</div>
					<div role="cell" class="cds-list-cell" tabindex="0" title="Topic">{{ticket.topic}}</div>
					<div role="cell" class="cds-list-cell" tabindex="0" title="Status"><state [stateInput]="ticket.stateCode"></state></div>
				</cds-list-row>
			</cds-list>
		</div>
	</div>
</div>

<!-- Loading -->
<cds-spinner *ngIf="isLoading"></cds-spinner>
