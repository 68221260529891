import {Component} from '@angular/core';
import {Store} from './core/store/store';
import {Router} from '@angular/router';
import {AppPath} from './utils/app-path';
import {Constants} from './utils/constants';
import {ProfileService} from './services/profile.service';
import {GoogleAnalyticsUtils} from './utils/google-analytics-utils';
import {BoxConfigurationService} from "./services/box-configuration.service";
import {AppPathService} from "./services/app-path.service";
import {BrowserUtil, ProductCatalogService} from "shared/app/index";
import {WebshopUtil} from "./utils/webshop.util";
import {CustomBrandConfigurationService} from './services/custom-brand-configuration.service';

@Component({
	selector: 'ca-app',
	templateUrl: 'app.component.html',
})
export class AppComponent {

	isPageLoading: boolean = false;

	constructor(store: Store, private router: Router, private webshopUtil: WebshopUtil,
				private salesChannelService: ProductCatalogService,
				private browserUtil: BrowserUtil, private profile: ProfileService,
				private boxConfigurationService: BoxConfigurationService,
				private pathService: AppPathService,
				private customBrandConfigurationService: CustomBrandConfigurationService) {
		if (localStorage.getItem(Constants.BROWSER_ID_KEY) == null) {
			const id = browserUtil.generateBrowserId();
			localStorage.setItem(Constants.BROWSER_ID_KEY, id);
		}

		store.commit();
		this.checkBranding();
		this.showHomePage();
		this.salesChannelService.clearAppLiveTimeCache.subscribe((value) => {
			this.isPageLoading = value;
		})
		GoogleAnalyticsUtils.initGoogleAnalytics(router);
	}

	private checkBranding() {
		if (!this.profile.isProfileXICT()) {
			this.customBrandConfigurationService.checkApplicationBrand()
		}
	}

	private showHomePage() {
		this.boxConfigurationService.isBoxFeatureEnabled() ? this.redirectToBoxHomePage() : this.redirectToHomePage();
	}

	private redirectToBoxHomePage() {
		if (window.location.pathname.split('/').filter(Boolean).length === 0) {
			this.pathService.navigate([AppPath.PATH_NOT_FOUND]);
		}
	}

	private redirectToHomePage() {
		if (window.location.pathname == AppPath.PATH_HOME) {
			if (this.webshopUtil.isWebshopEnabled()) {
				this.pathService.navigate([AppPath.PATH_WEBSHOP_HOME])
			} else if (this.profile.isProfileXICT()) {
				this.pathService.navigate([AppPath.XICT_PATH_PRODUCTS])
			} else {
				this.pathService.navigate([AppPath.PATH_SELFCARE_HOME])
			}
		}
	}

}
